import axios from "axios";
import address from "./Address";


export function getSanctionRecordSearch(formData) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    const user = JSON.parse(storage.getItem("user"));
    
    if (accessToken && user) {
        return axios.post(address + "/sanctions/" + user.id, formData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getSanctionRecordIndivisual(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    const user = JSON.parse(storage.getItem("user"));
    
    if (accessToken && user) {
        return axios.get(address + "/sanctions/" + id + "/" + user.id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getOpenSanctionRecordSearch(formData) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.post(address + "/open-sanctions-json", formData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getOpenSanctionRecordSearchCsv(formData) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.post(address + "/open-sanctions-csv", formData, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Authorization" : `Bearer ${accessToken}` } });
    } else {
        return false;
    }
}

export function getOpenSanctionRecordIndividual(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.get(address + "/open-sanctions/" + id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getPepRecords (id){
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if(accessToken){
        return axios.get(address + "/wikidata/" + id , { headers: {"Authorization" : `Bearer ${accessToken}`} } )
    }
    else{
        return false
    }
}

export function getSearchLogsScreening() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.get(address + "/sanctions/cases", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getListedInDetailPage(formData) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.post(address + "/open-sanctions-listedin", formData, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Authorization" : `Bearer ${accessToken}` } });
    } else {
        return false;
    }
}

export function getRefreshLists() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.get(address + "/open-sanctions-lists-refresh", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function toggleMonitoring(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.post(address + "/open-sanctions/toggle_monitor", formdata, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Authorization" : `Bearer ${accessToken}` } });
    } else {
        return false;
    }
}