import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import {KycMobContext} from "../../layouts/KYCMob";
import { isMobile } from 'react-device-detect';
import { saveevent } from "../../services/kyc/KycScreens";
import { payment } from "services/kyb/KybBankScreens";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen4() {
  const [disable, setDisable] = React.useState(false);
  const {data, height} = useContext(KycMobContext);
  const history = useHistory();
  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [message, setMessage] = useState("");
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen4) {
        const redirecturl = "/kybverif/d/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kybverif/d/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);
  
  const [formdata, setFormdata] = useState({
    type: "",
    routing_no: "",
    account_no: "",
    inq_id: url,
  });

  const handleInputChange = (event) => {
    setMessage("");
    const name = event.target.name;
    const value = event.target.value;

    setFormdata((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSelect = (event, { name }) => {
    setMessage("");
    setFormdata((values) => ({ ...values, [name]: event }));
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      paddingTop: "5px",
    }),
  };

  const optionsType = [
    { label: "Bank Account", value: 1 },
    { label: "Debit Card", value: 2 },
  ];

  const handleSubmit = () => {
    setMessage("");

    if (formdata.type === "") {
      setMessage("Account type is required!");
      return;
    }
    if (formdata.routing_no === "") {
      setMessage("Routing number is required!");
      return;
    }
    if (formdata.account_no === "") {
      setMessage("Account number is required!");
      return;
    }

    let data_to_send = {
      type: formdata.type.label,
      routing_no: formdata.routing_no,
      account_no: formdata.account_no,
      inq_id: url,
    };
    setDisable(true);
    payment(data_to_send)
      .then((response) => {
        if (response.data.success) {
          // console.log("response: ", response);
          let formdata3 = {
            inq_id: url,
            event_desc: "Payment Information Saved",
            passed: 1,
          };
          saveevent(formdata3)
            .then((response) => {
              if (response.data.success) {
                let temp = screenStates;
                temp.Screen25 = true;
                storage.setItem("screenmeta", JSON.stringify(temp));
                const redirecturl = "/kybverif/h/" + url;
                history.push(redirecturl);
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log("error: ", error);
              setDisable(false);
            });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenThree screenThreeKyb screenThreeDocs h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kybverif/f/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "25%" ,backgroundColor:data ? data.progressbar_color:"#ee416f"}}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button  onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1 style={{ lineHeight: 1 }}>Select an account for payouts.</h1>
          </div>
          <Form className="informationSection mt-8">
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Account Type</span>
                <Select
                  name="type"
                  className="globeSelect"
                  styles={customStyles}
                  value={formdata.type}
                  onChange={handleChangeSelect}
                  options={optionsType}
                />
              </div>
            </div>
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Routing Number</span>
                <Input 
                  type="text" 
                  name="routing_no" 
                  className="input firstnameInput" 
                  value={formdata.routing_no}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Account Number</span>
                <Input 
                  type="text" 
                  name="account_no" 
                  className="input firstnameInput" 
                  value={formdata.account_no}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-xs alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}
          </Form>
        </div>

        <div className="buttonAction">
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#ee416f",
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            Next
          </button>
        </div>

        <div className="footer" >
          <img className="footerImg" src={footerImg} alt="footer"/>
        </div>
      </div>
    </>
  );
}