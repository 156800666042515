import React, { useState,useContext } from "react";
import { Link,useHistory } from "react-router-dom";
import { saveevent } from "services/kyc/KycScreens";
import {KycMobContext} from "../../../layouts/KYCMob"

import backButtonImg from "../../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from "../../../assets/img/kycmobile/cross_white.png";
import footerImg from "../../../assets/img/kycmobile/footer_trademark.png";
import mainImg from "../../../assets/img/kycmobile/rectangle.png";

export default function Screen4() {
  const [disable, setDisable] = React.useState(false);
  const {data} = useContext(KycMobContext);
  const history= useHistory();
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen4) {
        const redirecturl = "/kycreturning/liveness/a/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kycreturning/liveness/a/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  const handleNext = () => {
    setDisable(true);
    let formdata = {
      inq_id: url,
      event_desc: "Selfie video verification started",
      passed: 0
    };
    saveevent(formdata).then((response) => {
      if (response.data.success) {
        let temp = screenStates;
        temp.Screen5 = true;
        storage.setItem("screenmeta", JSON.stringify(temp));
        history.push("/kycreturning/liveness/c/"+url);
        // window.location.reload();
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }
  
  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenFour h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"#"}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "30%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainImage">
            <img className="mainImg" src={mainImg} alt="main image" />
          </div>
          <div className="mainText">
            <h1>Prepare for your selfie video</h1>
          </div>
          <div className="supportingText">
            <p>Position yourself in good lighting and follow instructions on the next screen.</p>
          </div>
        </div>

        <div className="buttonAction">
          {/* <Link to="/kycreturning/liveness/screen5"> */}
          <button onClick={handleNext} disabled={disable} style={{backgroundColor:data?data.button_color:"#ee416f"}}  className="action">Lets do it!</button>
          {/* </Link> */}
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
