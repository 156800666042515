import React, { useState, useContext,useEffect,useRef } from "react";
import Webcam from "react-webcam";
import { Link } from "react-router-dom";
import { uploadFile } from "react-s3";
import { checkblur, saveevent } from "services/kyc/KycScreens";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import {KycMobContext} from "../../layouts/KYCMob"
import { saveidverifications } from "services/kyc/KycScreens";
import { checkrevisionstatus, disablerevision, calculatechecks } from "../../services/kyc/KycScreens";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import cameraFrameImg from " ../../assets/img/kycmobile/screen16Cam.png";
import cameraButton from " ../../assets/img/kycmobile/camera_button.png";
import black from " ../../assets/img/kycmobile/black.png";
import warning from " ../../assets/img/kycmobile/warning.png";
import loading from " ../../assets/img/kycmobile/loading.png";
import cycle from "../../assets/img/icons/cycle.svg";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
};

export default function Screen17(props) {
  const {data} = useContext(KycMobContext)

  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [dataLoaded, setDataLoaded] = React.useState(true);
  const [errorApi, setErrorApi] = React.useState(false);
  const [errorBlur, setErrorBlur] = React.useState(false);
  const [start, setStart] = useState(false);
  const [imageCaptured, setImageCaptured] = useState(false);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [pleaseHold, setPleaseHold] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [showReview, setShowReview] = useState(false);
  const history = useHistory();
  const webcamRef = React.useRef(null);
  let stateData = props.location.state;
  var fileType = stateData["fileType"];
  var idNum = stateData["idNum"];
  var nationality = stateData["nationality"];
  var captureMethod = stateData["captureMethod"];
  var fileFront = stateData["fileFront"];

  const MINUTE_MS = 200;
  // const ws = new WebSocket("wss://ai.hedgetech.ai/card/ws");

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen17) {
        const redirecturl = "/verify/g/"+url;
        history.push(redirecturl);
        window.location.reload(false);
      }
    } else {
      const redirecturl = "/verify/g/"+url;
      history.push(redirecturl);
      window.location.reload(false);
    }
  }, [screenStates]);

  const [facingMode, setFacingMode] = React.useState("user");

  React.useEffect(() => {
    setStart(true);
  }, []);

  React.useEffect(() => {
    if (!fileType) {
      history.push("/verify/h/" + url);
      window.location.reload(false);
    }
  }, [fileType]);

  React.useEffect(() => {
    if (data) {
      if (data.screen_12 === 0) {
        history.push("/verify/k/" + url);
        window.location.reload(false);
      }
    }
  }, [data]);

  const videoConstraints = {
    width: { min: 480 },
    height: { min: 720 },
    aspectRatio: 1.5,
    facingMode:facingMode
  };

  function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  }

  React.useEffect(() => {
    var mediaStream = null;
    if (!showReview) {
      if (start && !imageCaptured) {
        let timeout = setTimeout(() => {
          setPleaseHold(true);
          let timeout1 = setTimeout(() => {
            mediaStream = webcamRef.current.getScreenshot();
            setImageToUpload(mediaStream);
            setShowReview(true);
            setStart(false);
          }, 3000);
          return () => clearTimeout(timeout1);
        }, 5000);
        return () => clearTimeout(timeout);
        
        // ws.addEventListener("open", () => {
        //   const interval = setInterval(() => {
        //     var atob = require("atob");
        //     if (webcamRef.current) {
        //       mediaStream = webcamRef.current.getScreenshot();
        //       if (mediaStream) {
        //         ws.send(mediaStream);
        //       }
        //     }
        //   }, MINUTE_MS);
  
        //   return () => clearInterval(interval);
        // });
  
        // ws.onmessage = (message) => {
        //   let data = JSON.parse(message.data);
        //   let datamain = JSON.parse(data);
        //   setPleaseHold(datamain.msg);
  
        //   if (datamain.success) {
        //     setImageToUpload("data:image/png;base64, "+datamain.img);
        //     setShowReview(true);
        //     setStart(false);
        //     ws.close();
        //   }
        // };
      }
    }
  }, [start, showReview]);

  React.useEffect(() => {
    if (imageCaptured) {
      // ws.close();
      setStart(false);
      setDataLoaded(false);
      // console.log("image to upload: ", imageToUpload);
      
      const blob = dataURItoBlob(imageToUpload);
      let currentTimestamp = Date.now();
      const file = new File([blob], currentTimestamp, {
        type: blob.type,
        lastModified: new Date().getTime(),
      });

      let formdata = {
        fileType: fileType,
        idNum: idNum,
        nationality: nationality,
        captureMethod: captureMethod,
        fileFront: fileFront,
        fileBack: file,
        fileFaceWithDoc: fileFront,
        inq_id: url,
      };

      setDisable(true);

      uploadFile(file, config)
        .then((data) => {
          if (data.key) {
            checkblur(data.key, url)
              .then((response) => {
                // console.log("respose checkblur: ", response);
                if (!response.data.response.success) {
                  saveidverifications(formdata)
                    .then((response) => {
                      // console.log("response: ", response);
                      setErrorApi(false);
                      setDataLoaded(true);
                      if (response.data.success) {
                        let formdata1 = {
                          inq_id: url,
                          event_desc: "Government ID verification submitted",
                          passed: 0,
                        };
                        saveevent(formdata1)
                          .then((response) => {
                            if (response.data.success) {
                              let formdata2 = {
                                inq_id: url,
                                event_desc: "Government ID verification passed",
                                passed: 1,
                              };
                              saveevent(formdata2)
                                .then((response) => {
                                  if (response.data.success) {
                                    let formdatabasic = {
                                      inq_id: url,
                                      section: "idverif"
                                    };
                                    checkrevisionstatus(formdatabasic).then((response) => {
                                      if (response.data.success) {
                                        let formdata = {
                                          inq_id: url,
                                          section: "idverif"
                                        };
                                        disablerevision(formdata).then((response) => {
                                          if (response.data.success) {
                                            let formdataaddress = {
                                              inq_id: url,
                                              section: "addressverif"
                                            };
                                            checkrevisionstatus(formdataaddress).then((response) => {
                                              if (response.data.success) {
                                                let temp = screenStates;
                                                temp.Screen18 = true;
                                                storage.setItem("screenmeta", JSON.stringify(temp));
                                                const redirecturl = "/verify/k/" + url;
                                                history.push(redirecturl);
                                                window.location.reload(false);
                                              } else {
                                                let formdatawallet = {
                                                  inq_id: url,
                                                  section: "wallet"
                                                };
                                                checkrevisionstatus(formdatawallet).then((response) => {
                                                  if (response.data.success) {
                                                    let temp = screenStates;
                                                    temp.Screen3iQR = true;
                                                    storage.setItem("screenmeta", JSON.stringify(temp));
                                                    const redirecturl = "/verify/o/" + url;
                                                    history.push(redirecturl);
                                                    window.location.reload(false);
                                                  } else {
                                                    let temp = screenStates;
                                                    temp.Screen25 = true;
                                                    storage.setItem("screenmeta", JSON.stringify(temp));
                                                    const redirecturl = "/verify/p/" + url;
                                                    history.push(redirecturl);
                                                    window.location.reload(false);
                                                  }
                                                }).catch((error) => {
                                                  console.log("error: ", error);
                                                })
                                              }
                                            }).catch((error) => {
                                              console.log("error: ", error);
                                            })
                                          }
                                        }).catch((error) => {
                                          console.log("error: ", error);
                                        })
                                        // calculatechecks(url).then((response) => {
                                        //   if (response.data.success) {
                                        //   }
                                        // }).catch((error) => {
                                        //   console.log("error: ", error);
                                        // });
                                      } else {
                                        let temp = screenStates;
                                        temp.Screen18 = true;
                                        storage.setItem("screenmeta", JSON.stringify(temp));
                                        history.push("/verify/k/" + url);
                                        window.location.reload(false);
                                      }
                                    }).catch((error) => {
                                      console.log("error: ", error);
                                    });
                                  }
                                })
                                .catch((error) => {
                                  console.log("error: ", error);
                                });
                            }
                          })
                          .catch((error) => {
                            console.log("error: ", error);
                          });
                      }
                    })
                    .then((error) => {
                      console.log("error: ", error);
                      setErrorApi(true);
                    });
                } else {
                  setErrorBlur(true);
                  setShowReview(false);
                }
              })
              .catch((error) => {
                console.log("error checkblur", error);
                setErrorApi(true);
                setDisable(false);
              });
          }
        })
        .catch((err) => {
          console.error("err: ", err);
          setErrorApi(true);
          setDisable(false);
        });
    }
  }, [imageCaptured]);

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      {!showReview && 
        <>
          {!errorBlur && 
            <div className="screenSixteen CameraScreen h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
              <div className="mainContent"  >
              <div className="w-full flex flex-row justify-between items-center p-4 pt-4" style={{position:"absolute", top:"0",zIndex:"100"}}>
                <div className="backArrow">
                  <Link to={"/verify/h/"+url}>
                    <button>
                      <img src={backButtonImg} alt="backArrow" className="" />
                    </button>
                  </Link>
                </div>
                <div className="progressBar w-full py-3 px-12">
                  <div className="relative lg:w-8/12 mx-auto">
                    <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                      <div
                        style={{ width: "70%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="crossArrow">
                  <button onClick={handleCross}>
                    <img src={closeButtonImg} alt="crossArrow" className="" />
                  </button>
                </div>
              </div>
                <div className="cameraFrame">
                <div className="cameraText" style={{position:"absolute", top:"10%", zIndex:"10"}}>
                <div className="alignText">
                  Capture the back side
                </div>
                {!dataLoaded && (
                  <ReactLoading
                    className="margin-auto mt-3 mb-3"
                    type={"bars"}
                    color={"#ee416f"}
                    height={"10%"}
                    width={"10%"}
                  />
                )}
                </div>
                  <img className="cameraFrameImgSq mx-auto" src={cameraFrameImg} alt="camera_frame" style={{ position: "absolute", zIndex: "100", top:"25%"}} />
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={"100%"}
                    mirrored={facingMode=="user"?false:false}
                    videoConstraints={videoConstraints} 
                    forceScreenshotSourceSize
                    // onUserMedia={handleUserMedia}
                  />
                  <div className="footer" style={{position:"absolute", bottom:"0px"}}>
                    <button
                    className="camerafacingchangebutton"
                    style={{outline: "none" ,marginTop:10}}
                      onClick={() =>
                        setFacingMode(facingMode === "user" ? "environment" : "user")
                      }
                    >
                      <img src={cycle} style={{width:"50px"}} alt="Switch" className="switchCamera" />
                    </button>
                    <img className="footerImg" src={footerImg} alt="footer" />
                  </div>
                </div>
                <div className="warningMessage">
                  <div className="message">
                    {pleaseHold ? 
                      <p className="w-full text-white text-xs text-center mt-4 screen15Txt">
                        Please hold as we process
                      </p>
                      :
                      <p className="w-full text-white text-xs text-center mt-4 screen15Txt">
                        Make sure the text is clearly visible
                      </p>
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          {errorBlur && 
            <div className="screenFourteen h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
              <div className="w-full flex flex-row justify-between p-4 pt-4">
                <div className="backArrow">
                  <Link to={"/verify/h/"+url}>
                    <button>
                      <img src={backButtonImg} alt="backArrow" className="" />
                    </button>
                  </Link>
                </div>
                <div className="progressBar w-full py-3 px-12">
                  <div className="relative lg:w-8/12 mx-auto">
                    <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                      <div
                        style={{ width: "30%" }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="crossArrow">
                  <button>
                    <img src={closeButtonImg} alt="crossArrow" className="" />
                  </button>
                </div>
              </div>

              <div className="mainContent">
                <div className="blackImgBackground">
                  <div className="blackImage">
                    <img className="black" src={black} alt="" />
                    <img className="warning" src={warning} alt="" />
                  </div>
                  <div className="points">
                    <ul>
                      <li>Blur/focus Issue</li>
                      <li>Light is too high/low</li>
                      <li>Image size is too large/small</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="buttonAction">
                <Link to={"/verify/h/"+url}>
                  <button style={{backgroundColor:data?data.button_color:"#ee416f"}}  className="action w-full mx-auto flex flex-row items-center text-center justify-center">
                    <img src={loading} alt="" className="mr-2" />
                    Reupload
                  </button>
                </Link>
              </div>

              <div className="footer">
                <img className="footerImg" src={footerImg} alt="footer" />
              </div>
            </div>
          }
        </>
      }

      {showReview && 
        <div
          className="screenOne QRScreen h-full flex flex-col"
          style={{ fontFamily: data ? data.font_family : "Gilroy" }}
        >
          <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
            <div className="backArrow">
              <Link to={"/verify/h/"+url}>
                <button>
                  <img src={backButtonImg} alt="backArrow" className="" />
                </button>
              </Link>
            </div>
            <div className="progressBar w-full py-3 px-12">
              <div className="relative lg:w-8/12 mx-auto">
                <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                  <div
                    style={{
                      width: "70%",
                      backgroundColor: data ? data.progressbar_color : "#ee416f",
                    }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                  ></div>
                </div>
              </div>
            </div>
            <div className="crossArrow">
              <button onClick={handleCross}>
                <img src={closeButtonImg} alt="crossArrow" className="" />
              </button>
            </div>
          </div>

          <div class="mainContent" style={{ marginTop: "0" }}>
            <div class="mainText mt-4">
              <h2>Do you want to continue with this Document?</h2>
            </div>
            <div class="mainImage mt-4">
              <img className="mx-auto" src={imageToUpload} width="200" style={{ width: "100%", maxHeight: "250px" }}/>
            </div>
            <div class="supportingText mt-4">
              <p class="supportingTextPara">
                You can always go back and capture again if you wish to change.
              </p>
            </div>
          </div>

          <div class="buttonAction">
            {!disable && 
              <p onClick={() => window.location.reload(false)} className="colorPrimary text-center font-bold text-sm mb-2 cursor-pointer">I want to capture again!</p>
            }
            <button
              style={{ backgroundColor: data ? data.button_color : "#ee416f" }}
              class="action"
              onClick={() => setImageCaptured(true)}
              disabled={disable}
            >
              Continue
            </button>
          </div>

          <div class="footer">
            <img class="footerImg" src={footerImg} alt="footer" />
          </div>
        </div>
      }
    </>
  );
}
  
