import React from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";
import ExportIcon from "../../assets/img/icons/exporticon.png";
import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import ElipsesYellow from "../../assets/img/event_elp_yellow.png";
import VerticalLine from "../../assets/img/verticalLine.png";

function Table({ data, columns, rightId }) {
  const color = "light";
  const [pageNum, setPageNum] = React.useState(1);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
      ]);
    }
  );

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      padding: 10,
      fontSize: "14px",
    }),
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
            (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
          }
        >
          <div className="rounded-t mb-0 px-4 py-4 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1 mt-8">
                <Form
                  class="filtersTable"
                  // onSubmit={(e) => handleLogin(e)}
                  // ref={c => {form = c;}}
                >
                  <div className="flex flex-wrap justify-between">
                    <div className="flex flex-col">
                      <div class="flex-wrap flex">
                        <div className="searchInputTables">
                          <div className="fieldSearch relative w-full mb-3">
                            <label className="label block font-semibold text-black-600 text-xs mb-2">
                              Search
                            </label>
                            <Input
                              type="text"
                              className="searchFilter casemanagementsearch input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              // onChange={(e) => handleFilter(e.target.value)}
                              placeholder="Search by name or transaction ID"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
            {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '} */}
            <div className="flex flex-row items-center">
              <button
                className="mr-2"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img
                  src={leftPag}
                  alt="previous icon"
                  style={{ width: "20px", height: "20px" }}
                />
              </button>
              <span className="mr-2 text-xs">
                Page
                <strong className="ml-2">
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                <img
                  src={rightPag}
                  alt="previous icon"
                  style={{ width: "20px", height: "20px" }}
                />
              </button>
            </div>
            <div className="flex flex-row items-center cursor-pointer">
              <p className="font-bold text-xs mr-3">Go to page</p>
              <input
                type="number"
                defaultValue={pageIndex + 1}
                value={pageNum}
                onChange={(e) => {
                  const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
                  const page = value ? Number(value) - 1 : 0;
                  gotoPage(page);
                  setPageNum(value);
                }}
                className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
              />
            </div>
            <div className="flex flex-row items-center cursor-pointer">
              <p className="font-bold text-xs mr-3">Records per page</p>
              <input
                type="number"
                min={1}
                value={pageSize}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value < 1
                  ) {
                    setPageSize(Number(1));
                  } else {
                    setPageSize(Number(e.target.value));
                  }
                }}
                className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
              />
              <button
                // onClick={() => }
                style={{ outline: "none" }}
                className="px-2 py-2 bg-white rounded-lg"
              >
                <img
                  src={refresh}
                  alt="refresh"
                  style={{ width: "15px", height: "15px" }}
                />
              </button>
            </div>
          </div>
          <div className="block w-full overflow-x-auto">
            {/* Projects table */}
            <table
              {...getTableProps()}
              className="items-center w-full bg-transparent border-collapse"
            >
              <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className={
                          "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                        }
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={"cursor-pointer " + (rightId === row.original.trxId ? "activeRow" : "")}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}


function DataTableComponent() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const color = "light";
  const [openRight, setOpenRight] = React.useState(null);
  const [rightId, setRightId] = React.useState(null);

  const circularBarColorAccepted = "#15E49A";
  const circularBarColorPending = "#FFC102";
  const circularBarColorDeclined = "#FA364C";

  const openRightSide = (id) => {
    if (rightId !== null) {
      if (id === rightId) {
        setOpenRight(null);
        setRightId(null);
        return;
      } else {
        if(width>=1280) {
          window.location.href = "#";
        }
        else {
          window.location.href = "#right";
        }
        setOpenRight(true);
        setRightId(id);
      }
    }
    if (rightId === null) {
      if(width>=1280) {
        window.location.href = "#";
      }
      else {
        window.location.href = "#right";
      }
      setRightId(id);
      setOpenRight(true);
      return;
    }
  };

  const data = [
    {
      trxId: "5456564121212",
      id: "inq_128710343324adawkm",
      creationDate: "21-02-2022",
      customerName: "Sara Turner 1",
      ruleNo: "668",
      riskLevel: "100",
      trxType: "Moderator",
      reviewedBy: "Moderator",
      kycStatus: "Decline",
      action: "Moderator",
    },
    {
      trxId: "54565643443545",
      id: "inq_128710343324adawkm",
      creationDate: "21-02-2022",
      customerName: "Sara Turner 1",
      ruleNo: "668",
      riskLevel: "90",
      trxType: "Moderator",
      reviewedBy: "Moderator",
      kycStatus: "Decline",
      action: "Moderator",
    },
    {
      trxId: "545656467657657",
      id: "inq_128710343324adawkm",
      creationDate: "21-02-2022",
      customerName: "Sara Turner 1",
      ruleNo: "668",
      riskLevel: "80",
      trxType: "Moderator",
      reviewedBy: "Moderator",
      kycStatus: "Decline",
      action: "Moderator",
    },
  ];
  
  const columns = [
    {
      Header: "TRX ID",
      accessor: "trxId",
      Cell: (tableProps) => (
        <>
          <div className="flex flex-col text-left" onClick={() => openRightSide(tableProps.row.original.trxId)}>
            <span className={"text-sm font-bold text-black-600"}>
              {tableProps.row.original.trxId}
            </span>
            <span className="font-light text-xs textLightGray">
              Inquiry ID: {tableProps.row.original.id}
            </span>
            <span className="font-light text-xs textLightGray">
              Creation Date: {tableProps.row.original.creationDate}
            </span>
          </div>
        </>
      ),
    },
    {
      Header: "Customer Name",
      accessor: "customerName",
    },
    {
      Header: "Rule No.",
      accessor: "ruleNo",
    },
    {
      Header: "Risk Level",
      accessor: "riskLevel",
      Cell: (tableProps) => (
        <div className="circularProgress mx-auto">
          <CircularProgressbar
            value={tableProps.row.original.riskLevel}
            strokeWidth={25}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: `${
                tableProps.row.original.riskLevel >= 0 &&
                tableProps.row.original.riskLevel <= 35
                  ? circularBarColorAccepted
                  : tableProps.row.original.riskLevel >= 36 &&
                    tableProps.row.original.riskLevel <= 65
                  ? circularBarColorPending
                  : circularBarColorDeclined
              }`,
              trailColor: "#FFF",
            })}
          />
        </div>
      ),
    },
    {
      Header: "TRX Type",
      accessor: "trxType",
    },
    {
      Header: "Reviewed By",
      accessor: "reviewedBy",
    },
    {
      Header: "KYC Status",
      accessor: "kycStatus",
      Cell: (tableProps) => (
        <div className="statusGrid flex flex-col max-w-120-px mx-auto text-xs">
          {tableProps.row.original.kycStatus.includes("Decline") && (
            <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg text-xs font-semibold text-white-400 uppercase buttonRed mb-2">
              Decline
            </button>
          )}
          {tableProps.row.original.kycStatus.includes("Pending") && (
            <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg text-xs font-semibold text-white-400 uppercase buttonYellow mb-2">
              Pending
            </button>
          )}
          {tableProps.row.original.kycStatus.includes("Approved") && (
            <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg text-xs font-semibold text-white-400 uppercase buttonGreen">
              Approved
            </button>
          )}
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  return (
    <>
      <div className="casemanagement flex flex-wrap">
        <div className={
            "w-full px-1 " +
            (openRight
              ? "xl:w-9/12 lg:w-12/12 md:w-12/12 sm:w-12/12"
              : "xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12")
          }
          style={{ transition: "all 0.25s ease-in-out" }}
        >
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <Table data={data} columns={columns} rightId={rightId} />
          </div>
        </div>
        {openRight && (
          <div className="w-full xl:w-3/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4" id="right">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-16">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full px-4 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={
                          require("assets/img/ProfilePicture.png").default
                        }
                        style={{height:"100px", width:"100px", objectFit:"cover"}}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-12 lg:-ml-12 max-w-100-px"
                      />
                    </div>
                  </div>
                  <div className="w-full px-4 text-center mt-12">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2 capitalize">
                        Sara Turner
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="text-base leading-normal mt-0 mb-2 text-white-400 font-bold uppercase">
                    <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen text-xs">
                      Approve
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="text-base leading-normal mt-0 mb-2 text-white-400 font-bold uppercase">
                    <Link
                      to={`/case/transmonitoring/details/1`}
                    >
                      <button
                        className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen text-xs"
                        style={{ background: "#ee416f" }}
                      >
                        Open Case
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="py-4 text-left">
                  <div className="flex flex-wrap">
                    <div className="flex items-center justify-center font-semibold w-full">
                      <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                        Info
                      </p>
                      <div
                        className="lg:w-10/12"
                        style={{ height: "0", border: "1px solid #D3D3D3" }}
                      ></div>
                    </div>
                    <div className="mt-2 fields block">
                      <div
                        className="enquiryId mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <p className="text-xs">Trx ID</p>
                        <b className="text-xs">
                          {rightId}
                        </b>
                      </div>
                      <div
                        className="IdNo mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          wordBreak:"break-all"}}
                      >
                        <p className="text-xs">ID Number</p>
                        <b className="text-xs " >
                          848348974947893
                        </b>
                      </div>
                      <div
                        className="dob mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <p className="text-xs">Date of Birth</p>
                        <b className="text-xs">
                          1-1-1990
                        </b>
                      </div>
                      <div
                        className="nationality mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <p className="text-xs">Nationality</p>
                        <b className="text-xs">
                          Pakistani
                        </b>
                      </div>
                      <div
                        className="rule mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <p className="text-xs">Rule No.</p>
                        <b className="text-xs">
                          668
                        </b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-4 text-left">
                  <div className="flex flex-wrap">
                    <div className="flex items-center justify-center font-semibold w-full">
                      <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                        Event
                      </p>
                      <div
                        className="lg:w-10/12"
                        style={{ height: "0", border: "1px solid #D3D3D3" }}
                      ></div>
                    </div>
                    <div className="w-full mt-2 fields block eventTimeline">
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Created
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:33pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Government ID verification started
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:37pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Government ID verification submitted
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:43pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Government ID verification passed
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:44pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Selfie video verification started
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:47pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Selfie video verification submitted
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:51pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Selfie video verification passed
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:52pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Document verification started
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:53pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Document verification submitted
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:57pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Document verification passed
                            <p className="createdDate font-normal mt-2">07/23/2022  |  8:58pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Inquiry completed
                            <p className="createdDate font-normal mt-2">07/23/2022  |  9:01pm</p>
                          </div>
                        </div>
                        <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                          <div className="text text-xs font-semibold"></div>
                        </div>
                      </div>
                      <div className="status mt-2">
                        <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                          <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                          <div className="text text-xs font-semibold">
                            Inquiry approved by Workflow
                            <p className="createdDate font-normal mt-2">07/23/2022  |  11:01pm</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DataTableComponent;