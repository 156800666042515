import React from "react";
import DataTableRules from "../../components/Datatables/DataTableRules";

export default function Rules() {
  return (
    <>
      <DataTableRules />
    </>
  );
}
