import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import {KycMobContext} from "../../layouts/KYCMob"
import { savelanguage } from "services/kyc/KycScreens";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import platform from "platform";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen2() {
  const {data} = useContext(KycMobContext)
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [urlId, setUrlId] = React.useState(url);
  const [message, setMessage] = useState("");
  const history = useHistory();
  const [language, setLanguage] = useState(null);
  const [deviceType, setDeviceType] = React.useState(platform.manufacturer);
  const [deviceOs, setDeviceOs] = React.useState(platform.os.family);
  const [browser, setBrowser] = React.useState(platform.name);
  const [latitude, setLatitude] = React.useState(null);
  const [longitude, setLongitude] = React.useState(null);
  const [disable, setDisable] = React.useState(false);
  var fpPromise = null;
  var result_fingerprint = null;

  React.useEffect(()=>{
    if (data) {
      if(data.screen_2 === 0){
        history.push("/kyb/e/"+urlId);
        window.location.reload();
      }
    }
  },[data])
  
  function showPosition(position) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }

  React.useEffect(()=>{
    getLocation();
  },[])

  const options = [
    { label: "English", value: 1 },
    { label: "French (France)", value: 3 },
    { label: "Arabic (Saudia Arabia)", value: 4 },
    { label: "German (Germany)", value: 5 },
    { label: "Urdu (Pakistan)", value: 6 },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  const handleChangeSelect = (event) => {
    setLanguage(event.label);
  };

  const handleSubmit = () => {
    setMessage("");
    setDisable(true);
    if (language) {
      // fpPromise = FingerprintJS.load({ apiKey: 'shovSBGBTHqND9JRFNaa' });
      // ;(async () => {
      //   // Get the visitor identifier when you need it.
      //   const fp = await fpPromise;
      //   result_fingerprint = await fp.get({extendedResult: true});
        
      //   let visitor_id = result_fingerprint.visitorId;
      //   let os = result_fingerprint.os;
      //   let os_version = result_fingerprint.osVersion;
      //   let first_seen = result_fingerprint.firstSeenAt.global;
      //   let last_seen = result_fingerprint.lastSeenAt.global;
      //   let ip = result_fingerprint.ip;
      //   let browser_name = result_fingerprint.browserName;
      //   let browser_version = result_fingerprint.browserVersion;
      //   let incognito = result_fingerprint.incognito;

        let formdata = {
          inq_id: urlId,
          language: language,
          browser_name: null,
          browser_version: null,
          os: null,
          os_version: null,
          visitor_id: null,
          first_seen: null,
          last_seen: null,
          ip: null,
          incognito: null,
          latitude: latitude,
          longitude: longitude
        };
    
        savelanguage(formdata).then((response) => {
          if (response.data.success) {
            const redirecturl = "/kyb/e/"+urlId;
            history.push(redirecturl);
            window.location.reload();
          }
        }).catch((error) => {
          console.log("error: ", error)
        });
      // })();
    } else {
      setDisable(false);
      setMessage("Please select a language.");
    }
  }
  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };
  return (
    <>
      <div className="screenTwo h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kyb/c/"+urlId}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "20%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div class="mainContent">
          <div class="mainText">
            <h1>Select your language</h1>
          </div>
          <div class="languageSelection">
            <div className="globeDiv py-4 pb-4">
              <Select
                onChange={handleChangeSelect}
                className="globeSelect"
                styles={customStyles}
                options={options}
              />
            </div>
          </div>

          {message && (
            <section className="form-group">
              <section className="text-sm alert alert-danger text-center" role="alert">
                {message}
              </section>
            </section>
          )}
        </div>

        <div class="buttonAction">
          {/* <Link to="/kyb/screen3"> */}
            <button onClick={handleSubmit} disabled={disable} style={{backgroundColor:data?data.button_color:"#ee416f"}}  class="action">Let's Start!</button>
          {/* </Link> */}
        </div>

        <div class="footer">
          <img class="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
