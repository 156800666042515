import React from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import Form from "react-validation/build/form";
import NetworkChart from "../../components/Cards/OnchainChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getGraphData } from "../../services/onchain/Onchain";
import ReactLoading from "react-loading";

import countPlus from "../../assets/img/countPlus.png";
import countMinus from "../../assets/img/countMinus.png";
import tickIcon from "../../assets/img/tickIcon.png";

export default function OnchainGraph(props) {
  const { id, network, typename } = useParams();
  let stateData = props.location.state;
  const [tempLimit, setTempLimit] = React.useState(10);
  const [dateRange, setDateRange] = React.useState(stateData["dateRange"]);
  const [range, setRange] = React.useState(10);
  const [graphData, setGraphData] = React.useState(null);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  const [optionsLimit, setOptionsLimit] = React.useState([
    {label: "10", value: 10},
    {label: "15", value: 15},
    {label: "20", value: 20},
  ]);
  const [defaultValueLimit, setDefaultValueLimit] = React.useState(10);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  }

  const bitcoinWalletArray = [
    "bitcoin",
    "litecoin",
    "dogecoin",
    "dash",
    "zcash",
    "bitcash",
    "bitcoinsv",
    "ethereum",
    "ethclassic",
    "ethclassic_reorg",
    "ethpow",
    "algorand",
    "bsc",
    "celo_rc1",
    "conflux_hydra",
    "eos",
    "tron",
    "solana",
    "matic",
    "velas",
    "klaytn",
    "elrond",
    "avalanche",
    "fantom",
    "moonbeam",
    "cronos",
    "flow",
    "everscale",
    "cardano",
    "algorand_testnet",
    "algorand_beatnet",
    "celo_alfajores",
    "bsc_testnet",
    "goerli",
    "celo_baklava",
    "conflux_oceanus",
    "medalla",
    "velas_testnet",
    "crypto_testnet",
    "binance",
    "consmoshub",
    "crypto_mainnet",
    "hemidall",
    "terra",
    "diem_testnet",
    "libra_testnet",
    "stellar",
    "ripple",
    "hedera",
    "tezos",
    "eth2",
    "filecoin",
  ];
  
  const options = [
    { value: "ETH", label: "Ethereum" },
  ];
  const [defaultValueOptions, setDefaultValueOptions] = React.useState(options[0]);

  const [inboundDepth, setInboundDepth] = React.useState(1);
  const [outboundDepth, setOutboundDepth] = React.useState(1);

  React.useEffect(() => {
    let formdata = {
      "inboundDepth": inboundDepth,
      "outboundDepth": outboundDepth,
      "limit": defaultValueLimit,
      "offset": 0,
      "address": id,
      "network": network,
      "currency": defaultValueOptions.value,
      "date_from": dateRange[0].toISOString().split('T')[0],
      "date_till": dateRange[1].toISOString().split('T')[0]
    };
    getGraphData(formdata).then((response) => {
      if (response) {
        // console.log("response data: ", response);
        let data = {
          "links": response.data.response.links,
          "nodes": response.data.response.nodes
        }
        data.links.forEach(link => {
          var a, b;
          data.nodes.forEach(node => {
            if (link.source === node.id) {
              a = node;
            }
            if (link.target === node.id) {
              b = node;
            }
          });
          !a.neighbors && (a.neighbors = []);
          !b.neighbors && (b.neighbors = []);
          a.neighbors.push(b);
          b.neighbors.push(a);
    
          !a.links && (a.links = []);
          !b.links && (b.links = []);
          a.links.push(link);
          b.links.push(link);
        });
        // console.log("data: ", data);
        setGraphData(data);
        setDataLoaded(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }, []);

  const updateResults = (date) => {
    setDataLoaded(false);
    setDateRange(date);
    if (date[0] && date[1]) {
      let formdata = {
        "inboundDepth": inboundDepth,
        "outboundDepth": outboundDepth,
        "limit": defaultValueLimit,
        "offset": 0,
        "address": id,
        "network": network,
        "currency": "ETH",
        "date_from": date[0].toISOString().split('T')[0],
        "date_till": date[1].toISOString().split('T')[0]
      };

      getGraphData(formdata).then((response) => {
        if (response) {
          let data = {
            "links": response.data.response.links,
            "nodes": response.data.response.nodes
          }
          data.links.forEach(link => {
            var a, b;
            data.nodes.forEach(node => {
              if (link.source === node.id) {
                a = node;
              }
              if (link.target === node.id) {
                b = node;
              }
            });
            !a.neighbors && (a.neighbors = []);
            !b.neighbors && (b.neighbors = []);
            a.neighbors.push(b);
            b.neighbors.push(a);
      
            !a.links && (a.links = []);
            !b.links && (b.links = []);
            a.links.push(link);
            b.links.push(link);
          });
          // console.log("data: ", data);
          setGraphData(data);
          setDataLoaded(true);
        }
      }).catch((error) => {
        console.log("error: ", error);
      });
    }
  }

  const handleSelectChange = (e) => {
    setDefaultValueLimit(e);

    setDataLoaded(false);
    let formdata = {
      "inboundDepth": inboundDepth,
      "outboundDepth": outboundDepth,
      "limit": e.value,
      "offset": 0,
      "address": id,
      "network": network,
      "currency": "ETH",
      "date_from": dateRange[0].toISOString().split('T')[0],
      "date_till": dateRange[1].toISOString().split('T')[0]
    };

    getGraphData(formdata).then((response) => {
      if (response) {
        let data = {
          "links": response.data.response.links,
          "nodes": response.data.response.nodes
        }
        data.links.forEach(link => {
          var a, b;
          data.nodes.forEach(node => {
            if (link.source === node.id) {
              a = node;
            }
            if (link.target === node.id) {
              b = node;
            }
          });
          !a.neighbors && (a.neighbors = []);
          !b.neighbors && (b.neighbors = []);
          a.neighbors.push(b);
          b.neighbors.push(a);
    
          !a.links && (a.links = []);
          !b.links && (b.links = []);
          a.links.push(link);
          b.links.push(link);
        });
        // console.log("data: ", data);
        setGraphData(data);
        setDataLoaded(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }

  React.useEffect(() => {
    onDepthChange();
  }, [inboundDepth, outboundDepth]);

  const onDepthChange = () => {
    setDataLoaded(false);
    let formdata = {
      "inboundDepth": inboundDepth,
      "outboundDepth": outboundDepth,
      "limit": defaultValueLimit,
      "offset": 0,
      "address": id,
      "network": network,
      "currency": defaultValueOptions.value,
      "date_from": dateRange[0].toISOString().split('T')[0],
      "date_till": dateRange[1].toISOString().split('T')[0]
    };
    getGraphData(formdata).then((response) => {
      if (response) {
        let data = {
          "links": response.data.response.links,
          "nodes": response.data.response.nodes
        }
        data.links.forEach(link => {
          var a, b;
          data.nodes.forEach(node => {
            if (link.source === node.id) {
              a = node;
            }
            if (link.target === node.id) {
              b = node;
            }
          });
          !a.neighbors && (a.neighbors = []);
          !b.neighbors && (b.neighbors = []);
          a.neighbors.push(b);
          b.neighbors.push(a);
    
          !a.links && (a.links = []);
          !b.links && (b.links = []);
          a.links.push(link);
          b.links.push(link);
        });
        // console.log("data: ", data);
        setGraphData(data);
        setDataLoaded(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }

  return (
    <>
      <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
        <div className="flex flex-wrap">
          <Link
            to={{
              pathname: "/onchain/smartcontract/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }} 
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/smartcontract") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              {typename == "SmartContract" && 
                <h4 className="font-semibold text-xs">Smart Contract</h4>
              }
              {typename == "Address" && 
                <h4 className="font-semibold text-xs">Address</h4>
              }
            </div>
          </Link>
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/transactions/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/transaction") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Transcations</h4>
              </div>
            </Link>
          }
          {network != "cardano" && 
            <>
              {typename == "SmartContract" && 
                <Link
                  to={{
                    pathname: "/onchain/methods/"+id+"/"+network+"/"+typename,
                    state: {
                        dateRange: dateRange,
                    },
                  }}
                >
                  <div
                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                    style={
                      window.location.href.indexOf("/onchain/methods") !== -1
                        ? { backgroundColor: "#EBEDF2" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    <h4 className="font-semibold text-xs">Methods</h4>
                  </div>
                </Link>
              }
            </>
          }
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/events/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/events") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Events</h4>
              </div>
            </Link>
          }
          <Link
            to={{
              pathname: "/onchain/inflow/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/inflow") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Inflow</h4>
            </div>
          </Link>
          <Link
            to={{
              pathname: "/onchain/outflow/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/outflow") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Outflow</h4>
            </div>
          </Link>
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/callcontracts/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/callcontracts") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Call Contracts</h4>
              </div>
            </Link>
          }
          <Link
            to={{
              pathname: "/onchain/graph/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/graph") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Graph</h4>
            </div>
          </Link>
        </div>
      </div>
      <div className="onchainGraphCard">
        <div className="rounded-lg flex justify-between items-center bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
          <div className="flex flex-row searchContract" style={{ maxWidth: "70%" }}>
            {(bitcoinWalletArray.indexOf(network) > -1) && 
              <img style={{ width: "20px", height: "20px", marginRight: "10px" }} src={require(`assets/img/onchain/${network}.svg`).default} />
            }
            <span className="capitalize text-sm flex flex-row"><b className="mr-2">{network ? network : "N/A"}</b> - <p className="ml-2" style={{ maxWidth: "70%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{id ? id : "N/A"}</p></span>
          </div>
          <div className="filtersTable searchInputTables linechartfilter">
            <div className="fieldSearch relative w-full">
              <label className="label block font-semibold text-black-600 text-xs mb-2">
                Date range
              </label>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                portalId="root-portal"
                maxDate={new Date()}
                selectsRange={true}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(update) => {
                  updateResults(update)
                }}
                peekNextMonth
                renderCustomHeader={({
                  monthDate,
                  customHeaderCount,
                  decreaseMonth,
                  increaseMonth,
                }) => (
                  <div>
                    <button
                      aria-label="Previous Month"
                      className={
                        "react-datepicker__navigation react-datepicker__navigation--previous"
                      }
                      style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                      onClick={decreaseMonth}
                    >
                      <span
                        className={
                          "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                        }
                      >
                        {"<"}
                      </span>
                    </button>
                    <span className="react-datepicker__current-month">
                      {monthDate.toLocaleString("en-US", {
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                    <button
                      aria-label="Next Month"
                      className={
                        "react-datepicker__navigation react-datepicker__navigation--next"
                      }
                      style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                      onClick={increaseMonth}
                    >
                      <span
                        className={
                          "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                        }
                      >
                        {">"}
                      </span>
                    </button>
                  </div>
                )}
                monthsShown={2}
              />
            </div>
          </div>
          <div className="filtersTable limitChangeField">
            <div className="flex flex-row limitField mainField">
              <div className="inputfield limit">
                <label className="label block font-semibold text-black-600 text-xs mb-2">Limit</label>
                {/* <Select
                  styles={customStyles}
                  onChange={handleSelectChange}
                  className="globeSelect"
                  options={optionsLimit}
                  defaultValue={defaultValueLimit}
                  menuPortalTarget={document.body}
                  isSearchable={false}
                /> */}
                <input
                  type="number"
                  min={1}
                  value={tempLimit}
                  onChange={e => {
                    if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                      setTempLimit(Number(1));
                    } else {
                      setTempLimit(Number(e.target.value));
                    }
                  }}
                  className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 w-16 h-8 ease-linear transition-all duration-150"
                />
              </div>
              <button
                onClick={() => {
                  setDefaultValueLimit(tempLimit);
                }}
                style={{outline:"none"}}
                className="px-2 py-2 bg-white rounded-lg mr-2"
              >
                <img src={tickIcon} alt="refresh" style={{width:"15px", height:"15px"}} />
              </button>
            </div>
          </div>
        </div>
        <div className=" xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg">
          <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6  ">
            <Form className="inquireForm w-full flex justify-between items-end">
              <div className="filtersTable limitChangeField lg:w-3/12 md:w-4/12 sm:w-6/12">
                <div className="filtersTable limitField mainField searchInputTables">
                  <div className="fieldInquire inputfield limit relative w-full mb-3">
                    <label className="label block font-semibold text-black-600 text-xs mb-2">
                      Show currency transfers
                    </label>
                    <Select
                      name="country"
                      className="globeSelect"
                      style={{ all: "unset" }}
                      options={options}
                      defaultValue={defaultValueOptions}
                      styles={customStyles}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>
              </div>
              <div className="input-group mx-auto flex flex-col items-center">
                <p className="font-bold text-sm mb-2">Depth Level</p>
                <div className="flex">
                <div className="flex items-center justify-center">
                  <p className="text-xs mr-2">Inbound</p>
                  <div className="flex flex-col">
                    <img 
                      src={countPlus}
                      alt="countplus"
                      onClick={() => {
                        setInboundDepth(inboundDepth + 1);
                      }}
                    />
                    <img
                      src={countMinus}
                      alt="countminus"
                      onClick={() => {
                        setInboundDepth(inboundDepth - 1);
                      }}
                    />
                  </div>
                  <input
                    className="max-w-100-px"
                    name="count"
                    type="text"
                    value={inboundDepth}
                    style={{
                      maxWidth: "35px",
                      height: "35px",
                      textAlign: "center",
                      margin: "0 5px 0 10px",
                      padding: "0",
                      borderRadius: "6px",
                      border: "2px solid #c4c4c4",
                    }}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <input
                    className="max-w-100-px"
                    name="count"
                    type="text"
                    value={outboundDepth}
                    style={{
                      maxWidth: "35px",
                      height: "35px",
                      textAlign: "center",
                      margin: "0 10px 0 5px",
                      padding: "0",
                      borderRadius: "6px",
                      border: "2px solid #c4c4c4",
                    }}
                  />
                  <div className="flex flex-col">
                    <img 
                      src={countPlus}
                      alt="countplus"
                      onClick={() => {
                        setOutboundDepth(outboundDepth + 1);
                      }}
                    />
                    <img
                      src={countMinus}
                      alt="countminus"
                      onClick={() => {
                        setOutboundDepth(outboundDepth - 1);
                      }}
                    />
                  </div>
                  <p className="text-xs ml-2">Outbound</p>
                </div>
                </div>
              </div>
              <div className="flex flex-col ">
                <p className="font-bold text-sm mb-2">Detail Level</p>
                <div className="range">
                  <div class="field">
                    <input
                      className="rangeInput"
                      type="range"
                      min="10"
                      max="1000"
                      step="10"
                      value={range}
                      onChange={(e) => setRange(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      {dataLoaded ? 
        <div className={"networkanalysisGraph relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white"}>
          <div className="rounded-t mb-0 px-4 py-4 border-0">
            {graphData && 
              <NetworkChart 
                key="network-0" 
                nodeColor="blue" 
                myData={graphData} 
                setData={setGraphData} 
                idVal={id} 
                network={network} 
                dateRange={dateRange} 
                defaultValueLimit={defaultValueLimit} 
                inboundDepth={inboundDepth} 
                outboundDepth={outboundDepth}
              />
            }
          </div>
        </div>
        :
        <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
          <div className="lg:w-12/12 md:w-12/12 sm:w-6/12">
            <ReactLoading
              className="margin-auto mt-3 mb-3"
              type={"bars"}
              color={"#ee416f"}
              height={"50px"}
              width={"50px"}
            />
          </div>
        </div>
      }
    </>
  );
}
