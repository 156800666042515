import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// asset
// import BackgroundImg from "../assets/img/dashboardBg.png";
import BackgroundImg from "../assets/img/bglo.svg";
import BackgroundImageSvg from "../assets/img/backgroundsvgclear.svg";
import sidebarIcon from "../assets/img/icons/sidebarIcon.png";

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";

// views
import Dashboard from "views/admin/Dashboard.js";
import { rotate } from "tailwindcss/defaultTheme";
// import Tables from "views/admin/Tables.js";

export default function Admin() {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} />
      {sidebarOpen ? (
        <button
          className=" sidebarOpenButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen && <img src={sidebarIcon} />}
        </button>
      ) : (
        <button
          className=" sidebarCloseButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {!sidebarOpen && <img src={sidebarIcon} className="sidebarRotated" />}
        </button>
      )}
      <div
        className={
          "relative bg-no-repeat bg-cover " +
          (sidebarOpen ? "md:ml-52" : "md:ml-18")
        }
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundPosition: 'center', 
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />

            <Redirect from="/admin/case/casemanagement/:category" to="/case/casemanagement/:category" />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
