import React from "react";
import DataTablesWebHooks from "../../components/Datatables/DataTablesWebHooks";

export default function Rules() {
  return (
    <>
      <DataTablesWebHooks />
    </>
  );
}
