/*eslint-disable*/
import React from "react";
import { createPopper } from "@popperjs/core";
import { Link } from "react-router-dom";

// images import
import Logo from "../../assets/img/hedge-logo.png";
import LogoResp from "../../assets/img/hedge-logo-resp.png";
import CaseManagementIcon from "../../assets/img/icons/casemanag-icon.png";
import DashboardIcon from "../../assets/img/icons/dashboard-icon.png";
import DevIcon from "../../assets/img/icons/dev-icon.png";
import ReportIcon from "../../assets/img/icons/report-icon.png";
import ScreeningIcon from "../../assets/img/icons/screening-icon.png";
import SettingIcon from "../../assets/img/icons/setting-icon.png";
import DocIcon from "../../assets/img/icons/docu-icon.png";
import WebHookIcon from "../../assets/img/icons/webhooks-icon.png";
import OnboardingIcon from "../../assets/img/icons/onboarding-icon.png";
import TransIcon from "../../assets/img/icons/transac-monit-icon.png";
import RiskIcon from "../../assets/img/icons/risk-manag-icon.png";
import alertsIcon from "../../assets/img/icons/alerts-icon.png";
import rulesIcon from "../../assets/img/icons/rules-icon.png";
import profileIcon from "../../assets/img/icons/profile-icon.png";
import teamIcon from "../../assets/img/icons/team-icon.png";
import customizationIcon from "../../assets/img/icons/customization-icon.png";
import auditIcon from "../../assets/img/icons/audit-icon.png";
import riskScoringIcon from "../../assets/img/icons/risk-scoring-icon.png";
import screeningIcon from "../../assets/img/icons/screeningIcon2.png";
import listsIcon from "../../assets/img/icons/listsIcon.png";
import AmlScreeningIcon from "../../assets/img/icons/amlscreening-icon.png"
import OnchainIcon from "../../assets/img/icons/onchain-icon.png"

import DashboardIconClr from "../../assets/img/icons/dashboard-iconClr.png";
import DevIconClr from "../../assets/img/icons/dev-iconClr.png";
import ReportIconClr from "../../assets/img/icons/report-iconClr.png";
import ScreeningIconClr from "../../assets/img/icons/screening-iconClr.png";
import SettingIconClr from "../../assets/img/icons/setting-iconClr.png";
import DocIconClr from "../../assets/img/icons/docu-iconClr.png";
import WebHookIconClr from "../../assets/img/icons/webhooks-iconClr.png";
import OnboardingIconClr from "../../assets/img/icons/onboarding-iconClr.png";
import TransIconClr from "../../assets/img/icons/transac-monit-iconClr.png";
import RiskIconClr from "../../assets/img/icons/risk-manag-iconClr.png";
import NetworkIcon from "../../assets/img/icons/network-icon.png"
import GraphIcon from "../../assets/img/icons/graph-icon.png"
import IndividualKycIcon from "../../assets/img/icons/individualkyc-icon.png";
import NetworkSettingIcon from "../../assets/img/icons/networkSetting-icon.png"
import CorporateIcon from "../../assets/img/icons/corporate-icon.png"
import TrasnsactionMonitorIcon from "../../assets/img/icons/transMonitor-icon.png"

import IndividualKycIconClr from "../../assets/img/icons/individualkyc-iconClr.png";
import CaseManagementIconClr from "../../assets/img/icons/casemanag-iconClr.png";
import alertsIconClr from "../../assets/img/icons/alerts-iconClr.png";
import screeningIconClr from "../../assets/img/icons/screeningIcon2Clr.png";
import listsIconClr from "../../assets/img/icons/listsIconClr.png";
import profileIconClr from "../../assets/img/icons/profile-iconClr.png";
import teamIconClr from "../../assets/img/icons/team-iconClr.png";
import customizationIconClr from "../../assets/img/icons/customization-iconClr.png";
import auditIconClr from "../../assets/img/icons/audit-iconClr.png";
import riskScoringIconClr from "../../assets/img/icons/risk-scoring-iconClr.png";
import NetworkIconClr from "../../assets/img/icons/network-iconClr.png"
import GraphIconClr from "../../assets/img/icons/graph-iconClr.png"
import NetworkSettingIconClr from "../../assets/img/icons/networkSetting-iconClr.png"
import AmlScreeningIconClr from "../../assets/img/icons/amlscreening-iconClr.png"
import CorporateIconClr from "../../assets/img/icons/corporate-iconClr.png"
import TrasnsactionMonitorIconClr from "../../assets/img/icons/transMonitor-iconClr.png"
import OnchainIconClr from "../../assets/img/icons/onchain-iconClr.png"

// component imports
import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import TransactionMonitoring from "views/admin/TransactionMonitoring";

export default function Sidebar(props) {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [isShown, setIsShown] = React.useState("");
  const [width, setWidth] = React.useState(window.innerWidth);
  const [popover1Show, setPopover1Show] = React.useState(false);
  const [popover2Show, setPopover2Show] = React.useState(false);
  const [popover3Show, setPopover3Show] = React.useState(false);
  const [popover4Show, setPopover4Show] = React.useState(false);
  const [popover5Show, setPopover5Show] = React.useState(false);
  const [popover6Show, setPopover6Show] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize",handleResize);
    };
  }, []);

  const btn1Ref = React.createRef();
  const btn2Ref = React.createRef();
  const btn3Ref = React.createRef();
  const btn4Ref = React.createRef();
  const btn5Ref = React.createRef();
  const btn6Ref = React.createRef();

  const popover1Ref = React.createRef();
  const popover2Ref = React.createRef();
  const popover3Ref = React.createRef();
  const popover4Ref = React.createRef();
  const popover5Ref = React.createRef();
  const popover6Ref = React.createRef();

  const openPopover1 = () => {
    createPopper(btn1Ref.current, popover1Ref.current, {
      placement: width > 767 ? "right-start" : "bottom-end",
    });
    setPopover1Show(true);
    setPopover2Show(false);
    setPopover3Show(false);
    setPopover4Show(false);
    setPopover5Show(false);
    setPopover6Show(false);
  };

  const openPopover2 = () => {
    createPopper(btn2Ref.current, popover2Ref.current, {
      placement: width > 767 ? "right-start" : "bottom-end",
    });
    setPopover1Show(false);
    setPopover2Show(true);
    setPopover3Show(false);
    setPopover4Show(false);
    setPopover5Show(false);
    setPopover6Show(false);
  };

  const openPopover3 = () => {
    createPopper(btn3Ref.current, popover3Ref.current, {
      placement: width > 767 ? "right-start" : "bottom-end",
    });
    setPopover1Show(false);
    setPopover2Show(false);
    setPopover3Show(true);
    setPopover4Show(false);
    setPopover5Show(false);
    setPopover6Show(false);
  };

  const openPopover4 = () => {
    createPopper(btn4Ref.current, popover4Ref.current, {
      placement: width > 767 ? "right-start" : "bottom-end",
    });
    setPopover1Show(false);
    setPopover2Show(false);
    setPopover3Show(false);
    setPopover4Show(true);
    setPopover5Show(false);
    setPopover6Show(false);
  };

  const openPopover5 = () => {
    createPopper(btn5Ref.current, popover5Ref.current, {
      placement: width > 767 ? "right-start" : "bottom-end",
    });
    setPopover1Show(false);
    setPopover2Show(false);
    setPopover3Show(false);
    setPopover4Show(false);
    setPopover5Show(true);
    setPopover6Show(false);
  };

  const openPopover6 = () => {
    createPopper(btn6Ref.current, popover6Ref.current, {
      placement: width > 767 ? "right-start" : "bottom-end",
    });
    setPopover1Show(false);
    setPopover2Show(false);
    setPopover3Show(false);
    setPopover4Show(false);
    setPopover5Show(false);
    setPopover6Show(true);
  };

  const closePopover1 = () => {
    setPopover1Show(false);
  };
  const closePopover2 = () => {
    setPopover2Show(false);
  };
  const closePopover3 = () => {
    setPopover3Show(false);
  };
  const closePopover4 = () => {
    setPopover4Show(false);
  };
  const closePopover5 = () => {
    setPopover5Show(false);
  };
  const closePopover6 = () => {
    setPopover6Show(false);
  };

  const closeAllPopovers = () => {
    setPopover1Show(false);
    setPopover2Show(false);
    setPopover3Show(false);
    setPopover4Show(false);
    setPopover5Show(false);
    setPopover6Show(false);
  };

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (popover1Ref.current && !popover1Ref.current.contains(event.target)) {
        closePopover1()
      }
      if(popover2Ref.current && !popover2Ref.current.contains(event.target)) {
        closePopover2()
      }
      if(popover3Ref.current && !popover3Ref.current.contains(event.target)) {
        closePopover3()
      }
      if(popover4Ref.current && !popover4Ref.current.contains(event.target)) {
        closePopover4()
      }
      if(popover5Ref.current && !popover5Ref.current.contains(event.target)) {
        closePopover5()
      }
      if(popover6Ref.current && !popover6Ref.current.contains(event.target)) {
        closePopover6()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popover1Ref, popover2Ref]);

  return (
    <>
      <nav
        className={
          "sideBarNav md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-2xl shadow-inner bg-white flex flex-wrap items-center justify-between relative z-40 py-4 " +
          (props.sidebarOpen ? "md:w-52" : "md:w-18")
        }
        style={{
          borderTopRightRadius: "50px",
          borderBottomRightRadius: "50px",
        }}
      >
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-start w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md text-left md:pb-2 text-black-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0 px-6"
            to="/admin/dashboard"
          >
            {props.sidebarOpen ? (
              <img
                className="w-auto logoRespNav"
                src={Logo}
                style={{ width: "60%", margin: "0 auto" }}
                alt="Regly logo"
              />
            ) : (
              <img
                className="w-auto logoRespNav"
                src={LogoResp}
                style={{ maxWidth: "40px", width: "100%", margin: "0 auto" }}
                alt="Regly logo"
              />
            )}
            <img
              className=" logoNav"
              src={Logo}
              style={{ width: "60%", margin: "0" }}
              alt="Regly logo"
            />
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            style={{ maxHeight: "70vh" }}
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none  shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded sideBarLinksCont " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div>
              <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-black-200 px-6">
                <div className="flex flex-wrap">
                  <div className="w-6/12">
                    <Link
                      className="md:block text-left md:pb-2 text-black-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                      to="/"
                    >
                      <img
                        className="w-auto "
                        src={Logo}
                        style={{ width: "100%", margin: "0 auto" }}
                        alt="Regly logo"
                      />
                    </Link>
                  </div>
                  <div className="w-6/12 flex justify-end">
                    <button
                      type="button"
                      className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                      onClick={() => setCollapseShow("hidden")}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <ul className="md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain">
                <li
                  className="items-center mb-2"
                  onClick={() => closeAllPopovers()}
                >
                  <Link
                    className={
                      "text-xs flex items-center py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/admin/dashboard") !==
                        -1 || isShown === "dashboard"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    onMouseEnter={() => setIsShown("dashboard")}
                    onMouseLeave={() => setIsShown("")}
                    to="/admin/dashboard"
                  >
                    {window.location.href.indexOf("/admin/dashboard") !== -1 ||
                    isShown === "dashboard" ? (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={DashboardIconClr}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    ) : (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={DashboardIcon}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    )}{" "}
                    {(props.sidebarOpen || width<767) && (
                      <p style={{ letterSpacing: "1px" }}>Dashboard</p>
                    )}
                  </Link>
                </li>

                {/* <li className="items-center mb-2">
                  <button
                    style={{ outline: "none" }}
                    className={
                      "w-full flex items-center text-left text-xs  py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/case/casemanagement") !== -1 ||
                      window.location.href.indexOf("/case/corporate/casemanagement") !== -1 ||
                      window.location.href.indexOf("/case/corporate/kyc/casemanagement") !== -1 ||
                      window.location.href.indexOf("/case/bank/casemanagement") !== -1 ||
                      isShown === "onboarding"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    onClick={() => {
                      popover1Show ? closePopover1() : openPopover1();
                    }}
                    ref={btn1Ref}
                    onMouseEnter={() => setIsShown("onboarding")}
                    onMouseLeave={() => setIsShown("")}
                  >
                    {window.location.href.indexOf("/case/casemanagement") !== -1 ||
                    window.location.href.indexOf("/case/corporate/casemanagement") !== -1 ||
                    window.location.href.indexOf("/case/corporate/kyc/casemanagement") !== -1 ||
                    window.location.href.indexOf("/case/bank/casemanagement") !== -1 ||
                    isShown === "onboarding" ? (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={OnboardingIconClr}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    ) : (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={OnboardingIcon}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    )}{" "}
                    {(props.sidebarOpen || width<767) && (
                      <p style={{ letterSpacing: "1px" }}>Onboarding</p>
                    )}
                  </button>
                </li> */}

                <li className="items-center mb-2">
                  <button
                    style={{ outline: "none" }}
                    className={
                      "w-full flex items-center text-left text-xs  py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/aml/") !== -1 ||
                      isShown === "aml"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    onMouseEnter={() => setIsShown("aml")}
                    onMouseLeave={() => setIsShown("")}
                    onClick={() => {
                      popover5Show ? closePopover5() : openPopover5();
                    }}
                    ref={btn5Ref}
                  >
                    {window.location.href.indexOf("/aml/") !== -1 ||
                    isShown === "aml" ? (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={AmlScreeningIconClr}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    ) : (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={AmlScreeningIcon}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    )}{" "}
                    {(props.sidebarOpen || width<767) && (
                      <p style={{ letterSpacing: "1px" }}>AML Screening</p>
                    )}
                  </button>
                </li>

                {/* <li
                  className="items-center rounded-lg"
                >
                  <Link
                    className={
                      "text-xs flex items-center py-3 font-bold block px-6 rounded-lg " +
                      (window.location.href.indexOf("/businesslookup") !== -1 ||
                      isShown === "businesslookup"
                        ? "text-black-500 hover:text-black-600 colorPrimary"
                        : "text-black-700 hover:text-black-500")
                    }
                    to="/businesslookup/index"
                    onMouseEnter={() => setIsShown("businesslookup")}
                    onMouseLeave={() => setIsShown("")}
                  >
                    {window.location.href.indexOf("/businesslookup") !== -1 ||
                    isShown === "businesslookup" ? (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={CorporateIconClr}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    ) : (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={CorporateIcon}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    )}{" "}
                    {(props.sidebarOpen || width<767) && (
                      <p style={{ letterSpacing: "1px" }}>Business Lookup</p>
                    )}
                  </Link>
                </li> */}

                {/* <li className="items-center mb-2">
                  <button
                    style={{ outline: "none" }}
                    className={
                      "w-full flex items-center text-left text-xs  py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/network") !== -1 || isShown=="network"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    onMouseEnter={() => setIsShown("network")}
                    onMouseLeave={() => setIsShown("")}
                    onClick={() => {
                      popover6Show ? closePopover6() : openPopover6();
                    }}
                    ref={btn6Ref}
                  >
                    {
                      (window.location.href.indexOf("/network") !== -1 || isShown=="network")
                      ? <img
                          className={"w-auto mr-4 text-sm "}                      
                          src={NetworkIconClr}
                          style={{ width: "30px", display: "inline-block" }}
                          alt="Regly logo"
                        /> 
                        :<img
                          className={"w-auto mr-4 text-sm "}                      
                          src={NetworkIcon}
                          style={{ width: "30px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                    }{" "}
                    {(props.sidebarOpen || width<767) && 
                      <p style={{ letterSpacing: "1px" }}>Network Analysis</p>
                    }
                  </button>
                </li> */}

                {/* <li
                  className="items-center mb-2"
                  onClick={() => closeAllPopovers()}
                >
                  <Link
                    className={
                      "text-xs flex items-center py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/onchain") !==
                        -1 || isShown === "onchain"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    onMouseEnter={() => setIsShown("onchain")}
                    onMouseLeave={() => setIsShown("")}
                    to="/onchain"
                  >
                    {window.location.href.indexOf("/onchain") !== -1 ||
                    isShown === "onchain" ? (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={OnchainIconClr}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    ) : (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={OnchainIcon}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    )}{" "}
                    {(props.sidebarOpen || width<767) && (
                      <p style={{ letterSpacing: "1px" }}>On-Chain Analysis</p>
                    )}
                  </Link>
                </li> */}

                {/* <li className="items-center mb-2">
                  <button
                    style={{ outline: "none" }}
                    className={
                      "w-full flex items-center text-left text-xs  py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/case/transmonitor") !== -1 || isShown=="transmonitor"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    onMouseEnter={() => setIsShown("transmonitor")}
                    onMouseLeave={() => setIsShown("")}
                    onClick={() => {
                      popover2Show ? closePopover2() : openPopover2();
                    }}
                    ref={btn2Ref}
                  >
                    {
                      (window.location.href.indexOf("/case/transmonitor") !== -1 || isShown=="transmonitor")
                      ? <img
                          className={"w-auto mr-4 text-sm "}                      
                          src={TrasnsactionMonitorIconClr}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        /> 
                        :<img
                          className={"w-auto mr-4 text-sm "}                      
                          src={TrasnsactionMonitorIcon}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                    }{" "}
                    {(props.sidebarOpen || width<767) && 
                      <p style={{ letterSpacing: "1px" }}>Transaction Monitoring</p>
                    }
                  </button>
                </li> */}

                <li className="items-center mb-2">
                  <button
                    style={{ outline: "none" }}
                    className={
                      "w-full text-left text-xs flex items-center py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/risk/riskmanagement") !== -1 || isShown=="riskmanagement"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    onMouseEnter={() => setIsShown("riskmanagement")}
                    onMouseLeave={() => setIsShown("")}
                    onClick={() => {
                      popover4Show ? closePopover4() : openPopover4();
                    }}
                    ref={btn4Ref}
                  >
                    {
                      (window.location.href.indexOf("/risk/riskmanagement") !== -1 || isShown=="riskmanagement")
                      ? <img
                          className={"w-auto mr-4 text-sm "}                      
                          src={RiskIconClr}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        /> 
                        :<img
                          className={"w-auto mr-4 text-sm "}                      
                          src={RiskIcon}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                    }{" "}
                    {(props.sidebarOpen || width<767) && 
                      <p style={{ letterSpacing: "1px" }}>Risk Management</p>
                    }
                  </button>
                </li>

                {/* <li className="items-center mb-2" onClick={() => closeAllPopovers()}>
                  <Link
                    className={
                      "text-xs flex py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/reports/list") !== -1 || isShown=="reports"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    onMouseEnter={() => setIsShown("reports")}
                    onMouseLeave={() => setIsShown("")}
                    to="/reports/list"
                  >
                    {
                      (window.location.href.indexOf("/reports/list") !== -1 || isShown=="reports")
                      ? <img
                          className={"w-auto mr-4 text-sm "}                      
                          src={ReportIconClr}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        /> 
                        :<img
                          className={"w-auto mr-4 text-sm "}                      
                          src={ReportIcon}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                    }{" "}
                    {(props.sidebarOpen || width<767) && 
                    <p style={{ letterSpacing: "1px" }}> Reports</p> 
                    }
                  </Link>
                </li>    */}

                {/* <li
                  className="items-center rounded-lg"
                >
                  <Link
                    className={
                      "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                      (window.location.href.indexOf("/setting/") !== -1 ||
                      isShown === "kyc"
                        ? "text-black-500 hover:text-black-600 colorPrimary"
                        : "text-black-700 hover:text-black-500")
                    }
                    to="/setting/kyc"
                    onMouseEnter={() => setIsShown("kyc")}
                    onMouseLeave={() => setIsShown("")}
                  >
                    {window.location.href.indexOf("/setting/") !== -1 ||
                    isShown === "kyc" ? (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={customizationIconClr}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    ) : (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={customizationIcon}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    )}{" "}
                    {(props.sidebarOpen || width<767) && (
                    <p style={{ letterSpacing: "1px" }}>Template</p>
                    )}
                  </Link>
                </li> */}

                <li className="items-center mb-2">
                  <button
                    className="w-full text-left"
                    onClick={() => {
                      popover3Show ? closePopover3() : openPopover3();
                    }}
                    ref={btn3Ref}
                    style={{ outline: "none" }}

                  >
                    <div
                      className={
                        "text-xs flex items-center  py-3 font-bold block px-6 " +
                        (window.location.href.indexOf("/settings") !== -1 ||
                        isShown === "setting"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                          : "text-black-700 hover:text-black-500")
                      }
                      onMouseEnter={() => setIsShown("setting")}
                      onMouseLeave={() => setIsShown("")}
                      // to="/settings"
                    >
                      {window.location.href.indexOf("/settings") !== -1 ||
                      isShown === "setting" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={SettingIconClr}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={SettingIcon}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      )}{" "}
                      {(props.sidebarOpen || width<767) && (
                        <p style={{ letterSpacing: "1px" }}>Settings</p>
                      )}
                    </div>
                  </button>
                </li>

                {/* <li
                  className="items-center mb-2"
                  onClick={() => closeAllPopovers()}
                >
                  <Link
                    className={
                      "text-xs flex items-center  py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/dev/developers") !== -1 ||
                      isShown === "developers"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    to="/dev/developers"
                    onMouseEnter={() => setIsShown("developers")}
                    onMouseLeave={() => setIsShown("")}
                  >
                    {window.location.href.indexOf("/dev/developers") !== -1 ||
                    isShown === "developers" ? (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={DevIconClr}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    ) : (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={DevIcon}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    )}{" "}
                    {(props.sidebarOpen || width<767) && (
                      <p style={{ letterSpacing: "1px" }}>Documents</p>
                    )}
                  </Link>
                </li> */}

                {/* <li
                  className="items-center mb-2"
                  onClick={() => closeAllPopovers()}
                >
                  <Link
                    className={
                      "text-xs flex items-center  py-3 font-bold block px-6 " +
                      (window.location.href.indexOf("/web/webhooks") !== -1 ||
                      isShown === "webhooks"
                        ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-grey"
                        : "text-black-700 hover:text-black-500")
                    }
                    to="/web/webhooks"
                    onMouseEnter={() => setIsShown("webhooks")}
                    onMouseLeave={() => setIsShown("")}
                  >
                    {window.location.href.indexOf("/web/webhooks") !== -1 ||
                    isShown === "webhooks" ? (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={WebHookIconClr}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    ) : (
                      <img
                        className={"w-auto mr-4 text-sm "}
                        src={WebHookIcon}
                        style={{ width: "20px", display: "inline-block" }}
                        alt="Regly logo"
                      />
                    )}{" "}
                    {(props.sidebarOpen || width<767) && (
                      <p style={{ letterSpacing: "1px" }}>Webhooks</p>
                    )}
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* subnav popovers */}
      <div
        className={
          (popover1Show ? "" : "hidden ") +
          "bg-white border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg shadow-2xl popover-content"
        }
        ref={popover1Ref}
      >
        <ul className="md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain">
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/case/casemanagement") !== -1 ||
                isShown === "casemanagement"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/case/casemanagement/all"
              onMouseEnter={() => setIsShown("casemanagement")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/case/casemanagement") !== -1 ||
              isShown === "casemanagement" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={IndividualKycIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={IndividualKycIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Individual - KYC</p>
            </Link>
          </li>
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/case/corporate/casemanagement") !== -1 ||
                isShown === "corporatecasemanagement"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/case/corporate/casemanagement"
              onMouseEnter={() => setIsShown("corporatecasemanagement")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/case/corporate/casemanagement") !== -1 ||
              isShown === "corporatecasemanagement" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={CorporateIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={CorporateIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Business - KYB</p>
            </Link>
          </li>
          {/* <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/case/bank/casemanagement") !== -1 ||
                isShown === "bankcasemanagement"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/case/bank/casemanagement"
              onMouseEnter={() => setIsShown("bankcasemanagement")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/case/bank/casemanagement") !== -1 ||
              isShown === "bankcasemanagement" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={CorporateIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={CorporateIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Bank - KYB</p>
            </Link>
          </li> */}
        </ul>
      </div>

      <div
        className={
          (popover5Show ? "" : "hidden ") +
          "bg-white border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg shadow-2xl popover-content"
        }
        ref={popover5Ref}
      >
        <ul className="md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain">
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/aml/amlscreeninginquire") !==
                  -1 || isShown === "amlscreeninginquire"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/aml/amlscreeninginquire"
              onMouseEnter={() => setIsShown("amlscreeninginquire")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/aml/amlscreeninginquire") !==
                -1 || isShown === "amlscreeninginquire" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={ScreeningIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={ScreeningIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Screening</p>
            </Link>
          </li>
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/aml/cases") !== -1 ||
                isShown === "amlalerts"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/aml/cases"
              onMouseEnter={() => setIsShown("amlalerts")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/aml/cases") !== -1 ||
              isShown === "amlalerts" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={alertsIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={alertsIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Case Management</p>
            </Link>
          </li>
          
          
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/aml/lists") !== -1 ||
                isShown === "amllists"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/aml/lists"
              onMouseEnter={() => setIsShown("amllists")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/aml/lists") !== -1 ||
              isShown === "amllists" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={listsIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={listsIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Lists</p>
            </Link>
          </li>
        </ul>
      </div>

      <div
        className={
          (popover2Show ? "" : "hidden ") +
          "bg-white border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg shadow-2xl popover-content"
        }
        ref={popover2Ref}
      >
        <ul className="md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain">
          <li className="items-center rounded-lg" onClick={() => closeAllPopovers()}>
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/case/transmonitoring") !== -1 || isShown=="casetransmonitoring"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/case/transmonitoring"
              onMouseEnter={() => setIsShown("casetransmonitoring")}
                  onMouseLeave={() => setIsShown("")}
            >
                {
                  (window.location.href.indexOf("/case/transmonitoring") !== -1 || isShown=="casetransmonitoring")
                  ? <img
                      className={"w-auto mr-4 text-sm "}                      
                      src={CaseManagementIconClr}
                      style={{ width: "20px", display: "inline-block" }}
                      alt="Regly logo"
                    /> 
                    :<img
                      className={"w-auto mr-4 text-sm "}                      
                      src={CaseManagementIcon}
                      style={{ width: "20px", display: "inline-block" }}
                      alt="Regly logo"
                    />
                }{" "}
            <p style={{letterSpacing:"1px"}}>Transaction Monitoring</p>
            </Link>
          </li>
          <li className="items-center rounded-lg" onClick={() => closeAllPopovers()}>
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/case/transmonitor/alerts") !== -1 || isShown=="transmonitorAlerts"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/case/transmonitor/alerts"
              onMouseEnter={() => setIsShown("transmonitorAlerts")}
                  onMouseLeave={() => setIsShown("")}
            >
            {
                  (window.location.href.indexOf("/case/transmonitor/alerts") !== -1 || isShown=="transmonitorAlerts")
                  ? <img
                      className={"w-auto mr-4 text-sm "}                      
                      src={alertsIconClr}
                      style={{ width: "20px", display: "inline-block" }}
                      alt="Regly logo"
                    /> 
                    :<img
                      className={"w-auto mr-4 text-sm "}                      
                      src={alertsIcon}
                      style={{ width: "20px", display: "inline-block" }}
                      alt="Regly logo"
                    />
                }{" "}
            <p style={{letterSpacing:"1px"}}>Alerts</p>
            </Link>
          </li>
          <li className="items-center rounded-lg" onClick={() => closeAllPopovers()}>
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/case/transmonitor/rules") !== -1 || isShown=="transmonitorRules"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/case/transmonitor/rules"
              onMouseEnter={() => setIsShown("transmonitorRules")}
                  onMouseLeave={() => setIsShown("")}
            >
              {
                  (window.location.href.indexOf("/case/transmonitor/rules") !== -1 || isShown=="transmonitorRules")
                  ? <img
                      className={"w-auto mr-4 text-sm "}                      
                      src={auditIconClr}
                      style={{ width: "20px", display: "inline-block" }}
                      alt="Regly logo"
                    /> 
                    :<img
                      className={"w-auto mr-4 text-sm "}                      
                      src={auditIcon}
                      style={{ width: "20px", display: "inline-block" }}
                      alt="Regly logo"
                    />
                }
              
              {" "}
              <p style={{letterSpacing:"1px"}}>Rules / Case Scenarios</p>
            </Link>
          </li>
        </ul>
      </div>

      <div
        className={
          (popover3Show ? "" : "hidden ") +
          "bg-white border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg shadow-2xl popover-content"
        }
        ref={popover3Ref}
      >
        <ul className="md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain">
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/settings/profile") !== -1 ||
                isShown === "profile"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/settings/profile"
              onMouseEnter={() => setIsShown("profile")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/settings/profile") !== -1 ||
              isShown === "profile" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={profileIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={profileIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Profile</p>
            </Link>
          </li>
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/settings/team") !== -1 ||
                isShown === "team"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/settings/team"
              onMouseEnter={() => setIsShown("team")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/settings/team") !== -1 ||
              isShown === "team" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={teamIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={teamIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Team</p>
            </Link>
          </li>
          {/* <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/settings/kyc") !== -1 ||
                isShown === "kyc"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/settings/kyc"
              onMouseEnter={() => setIsShown("kyc")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/settings/kyc") !== -1 ||
              isShown === "kyc" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={customizationIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={customizationIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Template</p>
            </Link>
          </li> */}
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/settings/audit") !== -1 ||
                isShown === "audit"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/settings/audit"
              onMouseEnter={() => setIsShown("audit")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/settings/audit") !== -1 ||
              isShown === "audit" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={auditIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={auditIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Audit Logs</p>
            </Link>
          </li>
        </ul>
      </div>

      <div
        className={
          (popover4Show ? "" : "hidden ") +
          "bg-white border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg shadow-2xl popover-content"
        }
        ref={popover4Ref}
      >
        <ul className="md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain">
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/risk/riskmanagement/scoring") !== -1 ||
                isShown === "scroing"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/risk/riskmanagement/scoring"
              onMouseEnter={() => setIsShown("scroing")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/risk/riskmanagement/scoring") !==
                -1 || isShown === "scroing" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={riskScoringIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={riskScoringIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Risk Scoring and Rating</p>
            </Link>
          </li>
        </ul>
      </div>

      <div
        className={
          (popover6Show ? "" : "hidden ") +
          "bg-white border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg shadow-2xl popover-content"
        }
        ref={popover6Ref}
      >
        <ul className="md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain">
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/network/graph") !== -1 ||
                isShown === "networkgraph"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/network/graph"
              onMouseEnter={() => setIsShown("networkgraph")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/network/graph") !==
                -1 || isShown === "networkgraph" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={GraphIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={GraphIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Graph View</p>
            </Link>
          </li>
          <li
            className="items-center rounded-lg"
            onClick={() => closeAllPopovers()}
          >
            <Link
              className={
                "text-xs flex items-center  py-3 font-bold block px-6 rounded-lg " +
                (window.location.href.indexOf("/network/customization") !== -1 ||
                isShown === "networksettigs"
                  ? "text-black-500 hover:text-black-600 colorPrimary"
                  : "text-black-700 hover:text-black-500")
              }
              to="/network/customization"
              onMouseEnter={() => setIsShown("networksettigs")}
              onMouseLeave={() => setIsShown("")}
            >
              {window.location.href.indexOf("/network/customization") !==
                -1 || isShown === "networksettigs" ? (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={NetworkSettingIconClr}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              ) : (
                <img
                  className={"w-auto mr-4 text-sm "}
                  src={NetworkSettingIcon}
                  style={{ width: "20px", display: "inline-block" }}
                  alt="Regly logo"
                />
              )}{" "}
              <p style={{ letterSpacing: "1px" }}>Settings</p>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
