import React from "react";
import 'react-circular-progressbar/dist/styles.css';
import {Link} from 'react-scroll';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

// components
import { MemoizedMap } from "components/Maps/MapExample.js";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";
import downloadIcon from "../../assets/img/icons/downloadIcon.png";
import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import VerticalLine from "../../assets/img/verticalLine.png";
import dropDownIcon from "../../assets/img/dropdown.png";
import idVerRectImg from "../../assets/img/idVerRectImg.png";
import greenWarning from "../../assets/img/greenWarning.png";
import leftArrow from "../../assets/img/leftarrow.png";
import rightArrow from "../../assets/img/rightarrow.png";
import longVertLine from "../../assets/img/longVertLine.png";
import smallVertLine from "../../assets/img/smallVertLine.png";
import locIcon from "../../assets/img/locIcon.png";

export default function CaseManagementDetailAml() {
  const color = "light";
  const [idVerActive, setIdVerActive]  = React.useState(true);
  const [addressVerActive, setAddressVerActive]  = React.useState(false);
  const [screeningActive, setScreeningActive]  = React.useState(false);
  const [eventTimeActive, setEventTimeActive]  = React.useState(false);
  const [imgFrontActive, setImgFrontActive] = React.useState(true);
  const [imgBackActive, setImgBackActive] = React.useState(false);

  const [idShow, setIdShow] = React.useState(true);
  const [addShow, setAddShow] = React.useState(true);
  const [screenShow, setScreenShow] = React.useState(true);
  const [eventShow, setEventShow] = React.useState(true);

  const [potMatchArr, setPotMatchArray] = React.useState([
    true, 
    false, 
    false, 
    false, 
    false, 
    false, 
    false, 
    false, 
    false, 
    false
  ]);

  // google maps script include
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_API_KEY;
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, []);

  let location = {
    lat: "33.487514",
    long: "74.105076",
  };

  function setActivePotMatchCardLeft(id) {
    if (id>1) {
      let items = [...potMatchArr];
      items[id-1] = false;
      items[id-2] = true;
      setPotMatchArray(items);
    }
  }

  function setActivePotMatchCardRight(id) {
    if (id<10) {
      let items = [...potMatchArr];
      items[id-1] = false;
      items[id] = true;
      setPotMatchArray(items);
    }
  }

  const circularBarColorAccepted = "#15E49A";
  const circularBarColorPending = "#FFC102";
  const circularBarColorDeclined = "#FA364C";

  function setIdActive() {
    setIdVerActive(true);
    setAddressVerActive(false);
    setScreeningActive(false);
    setEventTimeActive(false);
  }
  function setAddActive() {
    setIdVerActive(false);
    setAddressVerActive(true);
    setScreeningActive(false);
    setEventTimeActive(false);
  }
  function setScreenActive() {
    setIdVerActive(false);
    setAddressVerActive(false);
    setScreeningActive(true);
    setEventTimeActive(false);
  }
  function setEvTActive() {
    setIdVerActive(false);
    setAddressVerActive(false);
    setScreeningActive(false);
    setEventTimeActive(true);
  }

  function setFrontActive() {
    setImgFrontActive(true);
    setImgBackActive(false);
  }
  function setBackActive() {
    setImgFrontActive(false);
    setImgBackActive(true);
  }

  const images = [
    {
      original: require("assets/img/blackImgCarousel.png").default,
      originalWidth: '200px',
      originalHeight: '200px',
      thumbnail: require("assets/img/blackImgCarousel.png").default,
    },
    {
      original: require("assets/img/blackImgCarousel.png").default,
      originalWidth: '200px',
      originalHeight: '200px',
      thumbnail: require("assets/img/blackImgCarousel.png").default,
    },
    {
      original: require("assets/img/blackImgCarousel.png").default,
      originalWidth: '200px',
      originalHeight: '200px',
      thumbnail: require("assets/img/blackImgCarousel.png").default,
    },
  ];

  return (
    <>
      <div className="casemanagement casemanagementdetail flex flex-wrap">
        <div className="w-full lg:w-9/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex items-center flex-wrap justify-between">
                <div 
                  className="px-4 mr-4 cursor-pointer mb-4" 
                  style={{ paddingLeft: "0" }}
                >
                  <h4 className="borderDefault summaryHeading py-2 font-bold text-base">Summary</h4>
                </div>
                <div className="flex flex-wrap">
                  <Link to="idRef" spy={true} smooth={true}>
                    <div 
                      className="flex justify-between items-center rounded-lg px-4 py-2 mr-4 cursor-pointer" 
                      style={idVerActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                      onClick={setIdActive}
                    >
                      <h4 className="font-semibold text-xs">Identity Verification</h4>
                    </div>
                  </Link>
                  <Link to="addressRef" spy={true} smooth={true}>
                    <div 
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer" 
                      style={addressVerActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                      onClick={setAddActive}
                    >
                      <h4 className="font-semibold text-xs">Address Verification</h4>
                    </div>
                  </Link>
                  <Link to="scrRef" spy={true} smooth={true}>
                    <div 
                      className="flex justify-between items-center rounded-lg px-4 py-2 mr-4 cursor-pointer" 
                      style={screeningActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                      onClick={setScreenActive}
                    >
                      <h4 className="font-semibold text-xs">Screening</h4>
                    </div>
                  </Link>
                  <Link to="evtRef" spy={true} smooth={true}>
                    <div 
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer" 
                      style={eventTimeActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                      onClick={setEvTActive}
                    >
                      <h4 className="font-semibold text-xs">Event Timeline</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex px-4 py-2 cursor-pointer">
                <img src={ExportIcon} alt="export icon" className="mr-3" style={{width:"18px", height:"18px"}} />
                <h4 className="font-semibold text-xs" style={{color: "#ee416f"}}>Export CSV</h4>
              </div>
            </div>
            <div id="idRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto pb-6">
                <div className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer" onClick={() => setIdShow(!idShow)}>
                  <div className="flex">
                    <div 
                      className="px-4 mr-4 cursor-pointer" 
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">Identity Verification</h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {!idShow && <img src={dropDownIcon} alt="down icon" className="mr-3" style={{width:"15px", height:"10px"}} />}
                    {idShow && <img src={dropDownIcon} alt="down icon" className="mr-3 dropDownIconRotated" style={{width:"15px", height:"10px"}} />}
                  </div>
                </div>
                {idShow && 
                  <div className="flex justify-between flex-wrap w-full px-12">
                    <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                      <div className="flex flex-col idType">
                        <div className="row flex flex-row justify-between items-center">
                          <div className="col-md-6 flex flex-col">
                            <span className="text-stone-500 uppercase text-xs font-bold">ID Type</span>
                            <span className="text-sm font-bold ">ID Card</span>
                          </div>
                          <div className="col-md-6 flex flex-col text-right">
                            <span className="text-stone-500 uppercase text-xs font-bold">Capture Method</span>
                            <span className="text-sm font-bold ">Live</span>
                          </div>
                        </div>
                        <div className="idImage relative">
                          <img className="py-4 w-full" src={idVerRectImg} alt="idImage" />
                          <div className="flex justify-between items-center">
                            <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons absolute text-sm">
                              <img className="mr-2" src={downloadIcon} alt="downloadicon" />
                              ImageName.png
                            </div>
                            <div className="actionButtonsImage text-white">
                              <span 
                                className="rounded-lg px-4 py-2 cursor-pointer"
                                style={imgFrontActive ? { backgroundColor: "rgba(253, 254, 254, 0.7)" } : {backgroundColor: "#000"}}
                                onClick={setFrontActive}
                              >
                                Front
                              </span>
                              <span 
                                className="rounded-lg px-4 py-2 cursor-pointer"
                                style={imgBackActive ? { backgroundColor: "rgba(253, 254, 254, 0.7)" } : {backgroundColor: "#000"}}
                                onClick={setBackActive}
                              >
                                Back
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="manualFieldsOCR flex flex-row flex-wrap justify-between items-center">
                          <div className="manualFields xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-12/12 py-4">
                            <h4 className="font-bold text-sm mb-3">Manual Fields</h4>
                            <table className="w-full table-fixed">
                              <tbody>
                                <tr>
                                  <td className="font-light text-xs">Full Name</td>
                                  <td className="font-semibold text-xs">John Snow</td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">Full Address</td>
                                  <td className="font-semibold text-xs">lorem ipsum lorem</td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">Issue Date</td>
                                  <td className="font-semibold text-xs">12/4/2022</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="documentOCR xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-12/12">
                            <h4 className="font-bold text-sm mb-3">Document OCR</h4>
                            <table className="w-full table-fixed">
                              <tbody>
                                <tr>
                                  <td className="font-light text-xs">Full Name</td>
                                  <td className="font-semibold text-xs">John Snow</td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">Full Address</td>
                                  <td className="font-semibold text-xs">lorem ipsum lorem</td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">Issue Date</td>
                                  <td className="font-semibold text-xs">12/4/2022</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                      <div className="flex flex-col idType">
                        <span className="text-sm font-bold mb-5">Self Liveness</span>
                        <div className="imagevideogallery relative">
                          <ImageGallery 
                            items={images}
                            showPlayButton={false}
                            showFullscreenButton={false}
                            showNav={false}
                          />
                        </div>
                        <div className="flex flex-row w-full justify-between items-center">
                          <div className="xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-12/12 margin-auto py-4">
                            <table className="w-full table-fixed">
                              <tbody>
                                <tr>
                                  <td className="font-light text-xs">
                                    <div className="py-1 flex items-center">
                                      <img className="mr-2" src={greenWarning} alt="icon" />
                                      Bluriness test
                                    </div>
                                  </td>
                                  <td className="font-light text-xs">
                                    <div className="py-1 flex items-center">
                                      <img className="mr-2" src={greenWarning} alt="icon" />
                                      Low light test
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    <div className="py-1 flex items-center">
                                      <img className="mr-2" src={greenWarning} alt="icon" />
                                      Audio test
                                    </div>
                                  </td>
                                  <td className="font-light text-xs">
                                    <div className="py-1 flex items-center">
                                      <img className="mr-2" src={greenWarning} alt="icon" />
                                      Audio test
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div id="addressRef" className="address-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto pb-6">
                <div className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer" onClick={() => setAddShow(!addShow)}>
                  <div className="flex">
                    <div 
                      className="px-4 mr-4 cursor-pointer" 
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">Address Verification</h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {!addShow && <img src={dropDownIcon} alt="down icon" className="mr-3" style={{width:"15px", height:"10px"}} />}
                    {addShow && <img src={dropDownIcon} alt="down icon" className="mr-3 dropDownIconRotated" style={{width:"15px", height:"10px"}} />}
                  </div>
                </div>
                {addShow && 
                  <div className="flex flex-wrap w-full px-12">
                    <div className="col-1 px-4 xl:w-4/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                      <div className="flex flex-col idType">
                        <span className="text-stone-500 uppercase text-xs font-bold">Document Type</span>
                        <span className="text-sm font-bold ">Bank Statement</span>
                        <div className="idImage relative">
                          <img className="py-4 pb-0 w-full" src={idVerRectImg} alt="idImage" />
                          <div className="flex justify-between items-center">
                            <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons absolute text-sm">
                              <img className="mr-2" src={downloadIcon} alt="downloadicon" />
                              ImageName.png
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-1 px-4 xl:w-4/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                      <div className="manualFields">
                        <h4 className="font-bold text-sm mb-3">Manual Fields</h4>
                        <table className="w-full table-fixed">
                          <tbody>
                            <tr>
                              <td className="font-light text-xs">Full Name</td>
                              <td className="font-semibold text-xs">John Snow</td>
                            </tr>
                            <tr>
                              <td className="font-light text-xs">Full Address</td>
                              <td className="font-semibold text-xs">lorem ipsum lorem</td>
                            </tr>
                            <tr>
                              <td className="font-light text-xs">Issue Date</td>
                              <td className="font-semibold text-xs">12/4/2022</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-1 px-4 xl:w-4/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                      <div className="documentOCR">
                        <h4 className="font-bold text-sm mb-3">Document OCR</h4>
                        <table className="w-full table-fixed">
                          <tbody>
                            <tr>
                              <td className="font-light text-xs">Full Name</td>
                              <td className="font-semibold text-xs">John Snow</td>
                            </tr>
                            <tr>
                              <td className="font-light text-xs">Full Address</td>
                              <td className="font-semibold text-xs">lorem ipsum lorem</td>
                            </tr>
                            <tr>
                              <td className="font-light text-xs">Issue Date</td>
                              <td className="font-semibold text-xs">12/4/2022</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div id="scrRef" className="screening relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto pb-6">
                <div className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer" onClick={() => setScreenShow(!screenShow)}>
                  <div className="flex">
                    <div 
                      className="px-4 mr-4 cursor-pointer" 
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">Screening</h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {!screenShow && <img src={dropDownIcon} alt="down icon" className="mr-3" style={{width:"15px", height:"10px"}} />}
                    {screenShow && <img src={dropDownIcon} alt="down icon" className="mr-3 dropDownIconRotated" style={{width:"15px", height:"10px"}} />}
                  </div>
                </div>
                {screenShow && 
                  <div className="flex flex-wrap w-full px-12">
                    <div className="col-1 px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                      <div className="flex flex-col idType">
                        <div className="idImage relative">
                          <div className="flex items-center">
                            <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons mr-4">
                              <img
                                alt="..."
                                src={require("assets/img/ProfilePicture.png").default}
                                className="shadow-xl rounded-full h-auto align-middle border-none max-w-100-px"
                              />
                            </div>
                            <table className="w-full table-fixed ml-3 max-w-210-px">
                              <tbody>
                                <tr>
                                  <td className="font-light text-xs">Full Name</td>
                                  <td className="font-semibold text-xs">John</td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">ID Number</td>
                                  <td className="font-semibold text-xs">Snow</td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">Date of Birth</td>
                                  <td className="font-semibold text-xs">12/5/1994</td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">Full Address</td>
                                  <td className="font-semibold text-xs">lorem ipsum lorem</td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">Nationality</td>
                                  <td className="font-semibold text-xs">Pakistani</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-1 px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                      <div className="documentOCR">
                        <div className="w-full flex items-center mb-2">
                          <h4 className="text-base mr-2"><b>10 Potential Matches</b></h4>
                          <h4 className="text-xs"> (Match against 1500 lists)</h4>
                        </div>
                        {potMatchArr && potMatchArr.map((potMatch, index) => (
                          <div key={index}>
                            {potMatch && 
                              <div>
                                <div className="w-full flex justify-between items-center redbg whiteText rounded-lg p-5">
                                  <div className="md:w-6/12">
                                    <h4 className="text-sm">John Snow</h4>
                                  </div>
                                  <div className="md:w-4/12 navigator flex justify-between items-center">
                                    <div className="cursor-pointer" onClick={() => setActivePotMatchCardLeft(index+1)}>
                                      <img className="" src={leftArrow} alt="leftarrow" />
                                    </div>
                                    <div className="text-sm px-4">
                                      {index+1} of 10
                                    </div>
                                    <div className="cursor-pointer" onClick={() => setActivePotMatchCardRight(index+1)}>
                                      <img className="" src={rightArrow} alt="rightarrow" />
                                    </div>
                                  </div>
                                </div>
                                <div className="rounded-lg mt-2 greybg p-5">
                                  <table className="w-full table-fixed">
                                    <tbody>
                                      <tr>
                                        <td className="font-light text-xs">First Name</td>
                                        <td className="font-semibold text-xs">John</td>
                                      </tr>
                                      <tr>
                                        <td className="font-light text-xs">Last Name</td>
                                        <td className="font-semibold text-xs">Snow</td>
                                      </tr>
                                      <tr>
                                        <td className="font-light text-xs">Date of Birth</td>
                                        <td className="font-semibold text-xs">12/5/1994</td>
                                      </tr>
                                      <tr>
                                        <td className="font-light text-xs">Nationality</td>
                                        <td className="font-semibold text-xs">Pakistani</td>
                                      </tr>
                                      <tr>
                                        <td className="font-light text-xs">ID Number</td>
                                        <td className="font-semibold text-xs">xxxxx-xxxxxxx-x</td>
                                      </tr>
                                      <tr>
                                        <td className="font-light text-xs">Expiration Date</td>
                                        <td className="font-semibold text-xs">12/4/2022</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            }
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div id="evtRef" className="event-timeline relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto pb-6">
                <div className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer" onClick={() => setEventShow(!eventShow)}>
                  <div className="flex">
                    <div 
                      className="px-4 mr-4 cursor-pointer" 
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">Event Timeline</h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {!eventShow && <img src={dropDownIcon} alt="down icon" className="mr-3" style={{width:"15px", height:"10px"}} />}
                    {eventShow && <img src={dropDownIcon} alt="down icon" className="mr-3 dropDownIconRotated" style={{width:"15px", height:"10px"}} />}
                  </div>
                </div>
                {eventShow &&
                  <div className="flex flex-row w-full p-12 mapSection">
                    <div className="p-12 md:w-12/12">
                      <div className="text-left">
                        <div className="flex flex-wrap">
                          <div className="w-full px-12 mt-2 fields block">
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "10% 90%", gridGap: "20px" }}>
                                <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                                <div className="text text-base font-semibold">
                                  Created
                                </div>
                              </div>
                              <div className="w-full items-center justify-center mt-2" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                                <div className="imgTag px-2"><img src={longVertLine} alt="created" /></div>
                                <div className="text font-semibold w-full h-full ml-6">
                                  <p className="createdDate text-sm font-normal mt-2">10/20/22  |  12:30am</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-sm font-normal">192.162.1.10</p>
                                  </div>
                                  <div className="w-full items-center justify-center mt-8">
                                    <table className="w-full table-fixed">
                                      <tbody>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">IP Address</h4>
                                            <h4 className="font-semibold text-sm">127.12.78.1</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Network Level Threat</h4>
                                            <h4 className="font-semibold text-sm">lorem</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Network Country</h4>
                                            <h4 className="font-semibold text-sm">Pakistan</h4>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Latitude</h4>
                                            <h4 className="font-semibold text-sm">71.12</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Longitude</h4>
                                            <h4 className="font-semibold text-sm">72.66</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Tor Connection</h4>
                                            <h4 className="font-semibold text-sm">lorem</h4>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Public Proxy</h4>
                                            <h4 className="font-semibold text-sm">12.124.12.54</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Private Proxy</h4>
                                            <h4 className="font-semibold text-sm">12.54.65.25</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">ISP</h4>
                                            <h4 className="font-semibold text-sm">Nayatel</h4>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Device Type</h4>
                                            <h4 className="font-semibold text-sm">Desktop</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Device OS</h4>
                                            <h4 className="font-semibold text-sm">Windows</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Browser</h4>
                                            <h4 className="font-semibold text-sm">Google Chrome</h4>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Browser Fingerprint</h4>
                                            <h4 className="font-semibold text-sm">21.232.235</h4>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "10% 90%", gridGap: "20px" }}>
                                <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                                <div className="text text-base font-semibold">
                                  Govt. ID Uploaded
                                </div>
                              </div>
                              <div className="w-full items-center justify-center mt-2" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold w-full h-full ml-6">
                                  <p className="createdDate text-sm font-normal mt-2">10/20/22  |  12:30am</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-sm font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "10% 90%", gridGap: "20px" }}>
                                <div className="imgTag"><img src={ElipsesError} alt="created" /></div>
                                <div className="text text-base font-semibold">
                                  Selfie Uploaded Failed
                                </div>
                              </div>
                              <div className="w-full items-center justify-center mt-2" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold w-full h-full ml-6">
                                  <p className="createdDate text-sm font-normal mt-2">10/20/22  |  12:30am</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-sm font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "10% 90%", gridGap: "20px" }}>
                                <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                                <div className="text text-base font-semibold">
                                  Completed
                                </div>
                              </div>
                              <div className="w-full items-center justify-center mt-2" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                                <div className="imgTag px-2">
                                  {/* <img src={VerticalLine} alt="created" /> */}
                                </div>
                                <div className="text text-xs font-semibold w-full h-full ml-6">
                                  <p className="createdDate text-sm font-normal mt-2">10/20/22  |  12:30am</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-sm font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-12 md:w-12/12">
                      <div className="flex flex-wrap">
                        <div className="w-full px-4">
                          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                            <MemoizedMap location={location} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="profileright w-full lg:w-3/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-16">
            <div className="flex flex-wrap justify-center">
              <div className="w-full px-4 flex justify-center">
                <div className="relative">
                  <img
                    alt="..."
                    src={require("assets/img/ProfilePicture.png").default}
                    className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-12 lg:-ml-12 max-w-100-px"
                  />
                </div>
              </div>
              <div className="w-full px-4 text-center mt-12">
                <div className="flex justify-center py-4 lg:pt-4 pt-8">
                  <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2">
                    John Snow
                  </h3>
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className="action-buttons text-base leading-normal mt-0 mb-2 text-white-400 font-bold">
                <button className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen">
                  Approve
                </button>
                <button className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonRed">
                  Decline
                </button>
                <button className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonYellow">
                  Review
                </button>
              </div>
            </div>
            <div className="py-4 text-left">
              <div className="flex flex-wrap">
                <div className="flex items-center justify-center font-semibold w-full">
                  <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">Info</p>
                  <div className="lg:w-10/12 md:w-10/12 sm:w-10/12" style={{ height: "0", border: "1px solid #D3D3D3" }}></div>
                </div>
                <div className="mt-2 fields block">
                  <div className="enquiryId mt-2" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                    <p className="text-xs">Inquiry ID</p>
                    <b className="text-xs">inq_128710343324adawkm</b>
                  </div>
                  <div className="IdNo mt-2" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                    <p className="text-xs">ID Number</p>
                    <b className="text-xs">1234 5678832 3</b>
                  </div>
                  <div className="dob mt-2" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                    <p className="text-xs">Date of Birth</p>
                    <b className="text-xs">10/12/2022</b>
                  </div>
                  <div className="nationality mt-2" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                    <p className="text-xs">Nationality</p>
                    <b className="text-xs">Pakistani</b>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-4 text-left">
              <div className="flex flex-wrap">
                <div className="flex items-center justify-center font-semibold w-full">
                  <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">Event</p>
                  <div className="lg:w-10/12 md:w-10/12 sm:w-10/12" style={{ height: "0", border: "1px solid #D3D3D3" }}></div>
                </div>
                <div className="w-full mt-2 fields block eventTimeline">
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Created
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Government ID verification started
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Government ID verification submitted
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Government ID verification passed
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Selfie video verification started
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Selfie video verification submitted
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Selfie video verification passed
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Document verification started
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Document verification submitted
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Document verification passed
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Inquiry completed
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Inquiry approved by Workflow
                        <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
