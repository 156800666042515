import axios from "axios";
import address from "./Address";

export function checkUser() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    // console.log("accessToken", accessToken);

    if (accessToken) {
        return true;
    } else {
        return false;
    }
}

export function getUserCurrent(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/setting/header_info/" + id, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function loginApi(formData) {
    return axios.post(address + "/login", formData);
}

export function validateToken() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/validate-token", { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function logout(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/logout/" + id, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}