import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { KycMobContext } from "../../../layouts/KYCMob";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import mainImg from " ../../assets/img/kycmobile/frame_6.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import { checkrevisionstatus } from "../../../services/kyc/KycScreens";

export default function Screen1() {
  const { data } = useContext(KycMobContext);
  // console.log(data);

  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const urlId = useState(url);
  const history = useHistory();
  const [linkExpired, setLinkExpired] = React.useState(false);

  const [screenStates, setScreenStates] = React.useState({
    Screen3eth: false,
    Screen26: false,
  });
  const storage = window.localStorage;

  React.useEffect(() => {
    storage.removeItem("screenmeta");

    let id = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    let formdata = {
      inq_id: id,
      section: "wallet"
    };
    checkrevisionstatus(formdata).then((response) => {
      if (!response.data.success) {
        setLinkExpired(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }, []);

  const handleNext = () => {
    if (screenStates) {
      let temp = screenStates;
      temp.Screen3eth = true;
      storage.setItem("screenmeta", JSON.stringify(temp));
      const redirecturl = "/kycreturning/wallet/b/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div
        className="screenOne h-full flex flex-col"
        style={{ fontFamily: data ? data.font_family : "Gilroy" }}
      >
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"#"}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{
                    width: "5%",
                    backgroundColor: data ? data.progressbar_color : "#ee416f",
                  }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        {linkExpired ? 
          <div class="mainContent">
            <div class="mainImage">
              <img class="mainImg" src={mainImg} alt="main image" />
            </div>
            <div class="mainText">
              <h2>Link Expired!</h2>
            </div>
            <div class="supportingText">
              <p class="supportingTextPara">
                You have already done your revision. We will let you know your KYC status soon!
              </p>
            </div>
          </div>
          : 
          <div class="mainContent">
            <div class="mainImage">
              <img class="mainImg" src={mainImg} alt="main image" />
            </div>
            <div class="mainText">
              <h2>Welcome back!</h2>
            </div>
            <div class="supportingText">
              <p class="supportingTextPara">
                Let's continue from where we left off!
              </p>
            </div>
          </div>
        }

        {linkExpired ? 
          <div class="buttonAction">
            
          </div>
          :
          <div class="buttonAction">
            {/* <Link to={"/kycreturning/addressverif/b/"+urlId}> */}
              <button
                style={{ backgroundColor: data ? data.button_color : "#ee416f" }}
                class="action"
                onClick={handleNext}
              >
                Let's Continue!
              </button>
            {/* </Link> */}
          </div>
        }

        <div class="footer">
          <img class="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}