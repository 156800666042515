import React from "react";
import DataTableTransMonitoringAlerts from "../../components/Datatables/DataTableTransMonitoringAlerts";

export default function AlertsTransMonitoring() {
  return (
    <>
      <DataTableTransMonitoringAlerts />
    </>
  );
}
