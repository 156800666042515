import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTable, usePagination, useRowSelect } from "react-table";
import ReactApexChart from "react-apexcharts";
import { getInflowsData } from "../../services/onchain/Onchain";
import ReactLoading from "react-loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import tickIcon from "../../assets/img/tickIcon.png";

export default function OnchainInflow(props) {
  const { id, network, typename } = useParams();
  let stateData = props.location.state;
  const [tempLimit, setTempLimit] = React.useState(10);
  const [dateRange, setDateRange] = React.useState(stateData["dateRange"]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [inboundData, setInboundData] = React.useState([]);
  const [inboundCardanoData, setInboundCardanoData] = React.useState([]);
  const [inboundVolumeByOutputTypeSeries, setInboundVolumeByOutputTypeSeries] = React.useState([]);
  const [inboundVolumeByOutputTypeOptions, setInboundVolumeByOutputTypeOptions] = React.useState({
    labels: [],
    chart: {
      type: 'donut',
      fontFamily: 'Gilroy',
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 10
    },
  });
  const [inboundTransferCurrencyGraphSeries, setInboundTransferCurrencyGraphSeries] = React.useState([]);
  const [inboundTransferCurrencyGraphOptions, setInboundTransferCurrencyGraphOptions] = React.useState({
    chart: {
      height: 350,
      type: 'bar',
      stacked: true,
    },
    stroke: {
      width: [0, 4]
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001'],
    xaxis: {
      type: 'datetime'
    },
    yaxis: [{
      title: {
        text: 'Volume',
      },
    
    }]
  });
  const [inboundTransferTokensGraphSeries, setInboundTransferTokensGraphSeries] = React.useState([]);
  const [inboundTransferTokensGraphOptions, setInboundTransferTokensGraphOptions] = React.useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    }
  });
  const [topSenderInCurrencyTable, setTopSendersInCurrencyTable] = React.useState([]);
  const [topSenderInTokensTable, setTopSendersInTokensTable] = React.useState([]);
  const [tokenSenderCardanoData, setTokenSenderCardanoData] = React.useState([]);

  const [optionsLimit, setOptionsLimit] = React.useState([
    {label: "10", value: 10},
    {label: "15", value: 15},
    {label: "20", value: 20},
  ]);
  const [defaultValueLimit, setDefaultValueLimit] = React.useState(10);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  }

  const bitcoinWalletArray = [
    "bitcoin",
    "litecoin",
    "dogecoin",
    "dash",
    "zcash",
    "bitcash",
    "bitcoinsv",
    "ethereum",
    "ethclassic",
    "ethclassic_reorg",
    "ethpow",
    "algorand",
    "bsc",
    "celo_rc1",
    "conflux_hydra",
    "eos",
    "tron",
    "solana",
    "matic",
    "velas",
    "klaytn",
    "elrond",
    "avalanche",
    "fantom",
    "moonbeam",
    "cronos",
    "flow",
    "everscale",
    "cardano",
    "algorand_testnet",
    "algorand_beatnet",
    "celo_alfajores",
    "bsc_testnet",
    "goerli",
    "celo_baklava",
    "conflux_oceanus",
    "medalla",
    "velas_testnet",
    "crypto_testnet",
    "binance",
    "consmoshub",
    "crypto_mainnet",
    "hemidall",
    "terra",
    "diem_testnet",
    "libra_testnet",
    "stellar",
    "ripple",
    "hedera",
    "tezos",
    "eth2",
    "filecoin",
  ];

  const inboundColumn = [
    {
      Header: "Timestamp",
      accessor: "timestamp",
    },
    {
      Header: "Block",
      accessor: "block",
    },
    {
      Header: "Sender",
      accessor: "sender",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Transaction",
      accessor: "transcation",
    },
  ];

  const inboundCardanoColumn = [
    {
      Header: "Timestamp",
      accessor: "timestamp",
    },
    {
      Header: "Block",
      accessor: "block",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Output Index in TX",
      accessor: "tx",
    },
    {
      Header: "Transaction",
      accessor: "transaction",
    }
  ];

  const ethSenderColumn = [
    {
      Header: "Sender",
      accessor: "sender",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Latest Date",
      accessor: "latestDate",
    },
    {
      Header: "Transfer Count",
      accessor: "transferCount",
    },
  ];

  const tokenSenderColumn = [
    {
      Header: "Sender",
      accessor: "sender",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Latest Date",
      accessor: "latestDate",
    },
    {
      Header: "Transfer Count",
      accessor: "transferCount",
    },
  ];

  const tokenSenderCardanoColumn = [
    {
      Header: "Timestamp",
      accessor: "timestamp",
    },
    {
      Header: "Block",
      accessor: "block",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Amount (USD)",
      accessor: "amount_usd",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Output Index in TX",
      accessor: "tx",
    },
    {
      Header: "Transaction",
      accessor: "transaction",
    },
  ];

  React.useEffect(() => {
    setDataLoaded(false);
    if (id && network) {
      let formdata = {
        "type_name": typename,
        "address": id,
        "network": network,
        "date_from": dateRange[0].toISOString().split('T')[0],
        "date_till": dateRange[1].toISOString().split('T')[0],
        "limit": defaultValueLimit,
        "nativeCurrency": "string",
        "offset": 0
      };

      getInflowsData(formdata).then((response) => {
        if (response.data.success) {
          if (network == "cardano") {
            if (response.data.response.Latest_Inbound_Transfers_from_Transaction_Outputs.data) {
              let data = response.data.response.Latest_Inbound_Transfers_from_Transaction_Outputs.data;
  
              let tableData = data.cardano.outputs.map((ele) => (
                {
                  timestamp: ele.block.timestamp.time,
                  block: ele.block.height,
                  amount: ele.value,
                  currency: ele.currency.symbol,
                  type: ele.outputDirection,
                  tx: ele.outputIndex,
                  transaction: ele.transaction.hash,
                }
              ));
  
              setInboundCardanoData(tableData);
            }

            if (response.data.response.Inbound_transfers_in_ADA_currency.data) {
              let data = response.data.response.Inbound_transfers_in_ADA_currency.data;
  
              let seriesData = [
                {
                  name: 'Volume in, ADA',
                  type: 'column',
                  data: data.cardano.outputs.map((ele) => {
                    return ele.value
                  })
                }, 
                {
                  name: 'Transaction Count',
                  type: 'line',
                  data: data.cardano.outputs.map((ele) => {
                    return ele.count
                  })
                }
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'bar',
                  stacked: true,
                },
                stroke: {
                  width: [0, 4]
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                labels: data.cardano.outputs.map((ele) => {
                  return ele.date.date
                }),
                xaxis: {
                  type: 'datetime'
                },
                yaxis: [{
                  title: {
                    text: 'Volume in, ADA | Transaction Count',
                  },
                
                }]
              };
  
              setInboundTransferCurrencyGraphSeries(seriesData);
              setInboundTransferCurrencyGraphOptions(optionsData);
            }

            if (response.data.response.Inbound_Volumes_By_Output_Type.data) {
              let data = response.data.response.Inbound_Volumes_By_Output_Type.data;
  
              let seriesData = data.cardano.outputs.map((ele) => {
                return ele.value
              });
  
              let optionsData = {
                labels: data.cardano.outputs.map((ele) => {
                  return ele.outputDirection
                }),
                chart: {
                  type: 'donut',
                  fontFamily: 'Gilroy',
                },
                plotOptions: {
                  pie: {
                    expandOnClick: false,
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  width: 10
                },
              };
  
              setInboundVolumeByOutputTypeSeries(seriesData);
              setInboundVolumeByOutputTypeOptions(optionsData);
            }

            if (response.data.response.Top_Inbound_Transfers_from_Transaction_Outputs.data) {
              let data = response.data.response.Top_Inbound_Transfers_from_Transaction_Outputs.data;
  
              let tableData = data.cardano.outputs.map((ele) => (
                {
                  timestamp: ele.block.timestamp.time,
                  block: ele.block.height,
                  amount: ele.value,
                  amount_usd: ele.value_usd,
                  type: ele.outputDirection,
                  tx: ele.outputIndex,
                  transaction: ele.transaction.hash,
                }
              ));
  
              setTokenSenderCardanoData(tableData);
            }
          } else if (network == "ethereum") {
            if (response.data.response.Latest_Inbound_Transfers.data) {
              let data = response.data.response.Latest_Inbound_Transfers.data;
  
              let tableData = data.ethereum.transfers.map((ele) => (
                {
                  timestamp: ele.block.timestamp.time,
                  block: ele.block.height,
                  sender: ele.address.address,
                  amount: ele.amount,
                  currency: ele.currency.symbol,
                  transcation: ele.transaction.hash,
                }
              ));
  
              setInboundData(tableData);
            }
  
            if (response.data.response.Inbound_Transfers_Currency.data) {
              let data = response.data.response.Inbound_Transfers_Currency.data;
  
              let seriesData = [
                {
                  name: 'Volume',
                  type: 'column',
                  data: data.ethereum.transfers.map((ele) => {
                    return ele.amount
                  })
                }, 
                {
                  name: 'Recieve Count',
                  type: 'line',
                  data: data.ethereum.transfers.map((ele) => {
                    return ele.count
                  })
                }
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'bar',
                  stacked: true,
                },
                stroke: {
                  width: [0, 4]
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                labels: data.ethereum.transfers.map((ele) => {
                  return ele.date.date
                }),
                xaxis: {
                  type: 'datetime'
                },
                yaxis: [{
                  title: {
                    text: 'Volume',
                  },
                
                }]
              };
  
              setInboundTransferCurrencyGraphSeries(seriesData);
              setInboundTransferCurrencyGraphOptions(optionsData);
            }
  
            if (response.data.response.Inbound_Transfers_In_Tokens.data) {
              let data = response.data.response.Inbound_Transfers_In_Tokens.data;
  
              let seriesData = [
                {
                  name: "Recieve Count",
                  data: data.ethereum.transfers.map((ele) => {
                    return ele.count
                  })
                }
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'line',
                  zoom: {
                    enabled: false
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  curve: 'straight'
                },
                grid: {
                  row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                  },
                },
                xaxis: {
                  categories: data.ethereum.transfers.map((ele) => {
                    return ele.date.date
                  }),
                }
              };
  
              setInboundTransferTokensGraphSeries(seriesData);
              setInboundTransferTokensGraphOptions(optionsData);
            }
  
            if (response.data.response.Top_Senders_In_Currency.data) {
              let data = response.data.response.Top_Senders_In_Currency.data;
  
              let tableData = data.ethereum.transfers.map((ele) => (
                {
                  sender: ele.address.address,
                  amount: ele.amount,
                  latestDate: ele.max_date,
                  transferCount: ele.count,
                }
              ));
  
              setTopSendersInCurrencyTable(tableData);
            }
  
            if (response.data.response.Top_Senders_In_Tokens.data) {
              let data = response.data.response.Top_Senders_In_Tokens.data;
  
              let tableData = data.ethereum.transfers.map((ele) => (
                {
                  sender: ele.address.address,
                  amount: ele.amount,
                  currency: ele.currency.symbol,
                  latestDate: ele.max_date,
                  transferCount: ele.count,
                }
              ));
  
              setTopSendersInTokensTable(tableData);
            }
          }

          setDataLoaded(true);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("error: ", error);
      });
    }
  }, [id, network, defaultValueLimit]);

  const updateResults = (date) => {
    setDateRange(date);
    if (date[0] && date[1]) {
      setDataLoaded(false);
      let formdata = {
        "type_name": typename,
        "address": id,
        "network": network,
        "date_from": date[0].toISOString().split('T')[0],
        "date_till": date[1].toISOString().split('T')[0],
        "limit": defaultValueLimit,
        "nativeCurrency": "string",
        "offset": 0
      };

      getInflowsData(formdata).then((response) => {
        if (response.data.success) {
          if (network == "cardano") {
            if (response.data.response.Latest_Inbound_Transfers_from_Transaction_Outputs.data) {
              let data = response.data.response.Latest_Inbound_Transfers_from_Transaction_Outputs.data;
  
              let tableData = data.cardano.outputs.map((ele) => (
                {
                  timestamp: ele.block.timestamp.time,
                  block: ele.block.height,
                  amount: ele.value,
                  currency: ele.currency.symbol,
                  type: ele.outputDirection,
                  tx: ele.outputIndex,
                  transaction: ele.transaction.hash,
                }
              ));
  
              setInboundCardanoData(tableData);
            }

            if (response.data.response.Inbound_transfers_in_ADA_currency.data) {
              let data = response.data.response.Inbound_transfers_in_ADA_currency.data;
  
              let seriesData = [
                {
                  name: 'Volume in, ADA',
                  type: 'column',
                  data: data.cardano.outputs.map((ele) => {
                    return ele.value
                  })
                }, 
                {
                  name: 'Transaction Count',
                  type: 'line',
                  data: data.cardano.outputs.map((ele) => {
                    return ele.count
                  })
                }
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'bar',
                  stacked: true,
                },
                stroke: {
                  width: [0, 4]
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                labels: data.cardano.outputs.map((ele) => {
                  return ele.date.date
                }),
                xaxis: {
                  type: 'datetime'
                },
                yaxis: [{
                  title: {
                    text: 'Volume in, ADA | Transaction Count',
                  },
                
                }]
              };
  
              setInboundTransferCurrencyGraphSeries(seriesData);
              setInboundTransferCurrencyGraphOptions(optionsData);
            }

            if (response.data.response.Inbound_Volumes_By_Output_Type.data) {
              let data = response.data.response.Inbound_Volumes_By_Output_Type.data;
  
              let seriesData = data.cardano.outputs.map((ele) => {
                return ele.value
              });
  
              let optionsData = {
                labels: data.cardano.outputs.map((ele) => {
                  return ele.outputDirection
                }),
                chart: {
                  type: 'donut',
                  fontFamily: 'Gilroy',
                },
                plotOptions: {
                  pie: {
                    expandOnClick: false,
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  width: 10
                },
              };
  
              setInboundVolumeByOutputTypeSeries(seriesData);
              setInboundVolumeByOutputTypeOptions(optionsData);
            }

            if (response.data.response.Top_Inbound_Transfers_from_Transaction_Outputs.data) {
              let data = response.data.response.Top_Inbound_Transfers_from_Transaction_Outputs.data;
  
              let tableData = data.cardano.outputs.map((ele) => (
                {
                  timestamp: ele.block.timestamp.time,
                  block: ele.block.height,
                  amount: ele.value,
                  amount_usd: ele.value_usd,
                  type: ele.outputDirection,
                  tx: ele.outputIndex,
                  transaction: ele.transaction.hash,
                }
              ));
  
              setTokenSenderCardanoData(tableData);
            }
          } else if (network == "ethereum") {
            if (response.data.response.Latest_Inbound_Transfers.data) {
              let data = response.data.response.Latest_Inbound_Transfers.data;
  
              let tableData = data.ethereum.transfers.map((ele) => (
                {
                  timestamp: ele.block.timestamp.time,
                  block: ele.block.height,
                  sender: ele.address.address,
                  amount: ele.amount,
                  currency: ele.currency.symbol,
                  transcation: ele.transaction.hash,
                }
              ));
  
              setInboundData(tableData);
            }
  
            if (response.data.response.Inbound_Transfers_Currency.data) {
              let data = response.data.response.Inbound_Transfers_Currency.data;
  
              let seriesData = [
                {
                  name: 'Volume',
                  type: 'column',
                  data: data.ethereum.transfers.map((ele) => {
                    return ele.amount
                  })
                }, 
                {
                  name: 'Recieve Count',
                  type: 'line',
                  data: data.ethereum.transfers.map((ele) => {
                    return ele.count
                  })
                }
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'bar',
                  stacked: true,
                },
                stroke: {
                  width: [0, 4]
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                labels: data.ethereum.transfers.map((ele) => {
                  return ele.date.date
                }),
                xaxis: {
                  type: 'datetime'
                },
                yaxis: [{
                  title: {
                    text: 'Volume',
                  },
                
                }]
              };
  
              setInboundTransferCurrencyGraphSeries(seriesData);
              setInboundTransferCurrencyGraphOptions(optionsData);
            }
  
            if (response.data.response.Inbound_Transfers_In_Tokens.data) {
              let data = response.data.response.Inbound_Transfers_In_Tokens.data;
  
              let seriesData = [
                {
                  name: "Recieve Count",
                  data: data.ethereum.transfers.map((ele) => {
                    return ele.count
                  })
                }
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'line',
                  zoom: {
                    enabled: false
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  curve: 'straight'
                },
                grid: {
                  row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                  },
                },
                xaxis: {
                  categories: data.ethereum.transfers.map((ele) => {
                    return ele.date.date
                  }),
                }
              };
  
              setInboundTransferTokensGraphSeries(seriesData);
              setInboundTransferTokensGraphOptions(optionsData);
            }
  
            if (response.data.response.Top_Senders_In_Currency.data) {
              let data = response.data.response.Top_Senders_In_Currency.data;
  
              let tableData = data.ethereum.transfers.map((ele) => (
                {
                  sender: ele.address.address,
                  amount: ele.amount,
                  latestDate: ele.max_date,
                  transferCount: ele.count,
                }
              ));
  
              setTopSendersInCurrencyTable(tableData);
            }
  
            if (response.data.response.Top_Senders_In_Tokens.data) {
              let data = response.data.response.Top_Senders_In_Tokens.data;
  
              let tableData = data.ethereum.transfers.map((ele) => (
                {
                  sender: ele.address.address,
                  amount: ele.amount,
                  currency: ele.currency.symbol,
                  latestDate: ele.max_date,
                  transferCount: ele.count,
                }
              ));
  
              setTopSendersInTokensTable(tableData);
            }
          }

          setDataLoaded(true);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("error: ", error);
      });
    }
  }

  return (
    <>
      <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
        <div className="flex flex-wrap">
          <Link
            to={{
              pathname: "/onchain/smartcontract/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }} 
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/smartcontract") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              {typename == "SmartContract" && 
                <h4 className="font-semibold text-xs">Smart Contract</h4>
              }
              {typename == "Address" && 
                <h4 className="font-semibold text-xs">Address</h4>
              }
            </div>
          </Link>
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/transactions/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/transaction") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Transcations</h4>
              </div>
            </Link>
          }
          {network != "cardano" && 
            <>
              {typename == "SmartContract" && 
                <Link
                  to={{
                    pathname: "/onchain/methods/"+id+"/"+network+"/"+typename,
                    state: {
                        dateRange: dateRange,
                    },
                  }}
                >
                  <div
                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                    style={
                      window.location.href.indexOf("/onchain/methods") !== -1
                        ? { backgroundColor: "#EBEDF2" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    <h4 className="font-semibold text-xs">Methods</h4>
                  </div>
                </Link>
              }
            </>
          }
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/events/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/events") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Events</h4>
              </div>
            </Link>
          }
          <Link
            to={{
              pathname: "/onchain/inflow/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/inflow") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Inflow</h4>
            </div>
          </Link>
          <Link
            to={{
              pathname: "/onchain/outflow/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/outflow") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Outflow</h4>
            </div>
          </Link>
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/callcontracts/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/callcontracts") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Call Contracts</h4>
              </div>
            </Link>
          }
          <Link
            to={{
              pathname: "/onchain/graph/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/graph") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Graph</h4>
            </div>
          </Link>
        </div>
      </div>

      {!dataLoaded ? 
        <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
          <div className="lg:w-12/12 md:w-12/12 sm:w-6/12">
            <ReactLoading
              className="margin-auto mt-3 mb-3"
              type={"bars"}
              color={"#ee416f"}
              height={"50px"}
              width={"50px"}
            />
          </div>
        </div>
        :
        <>
          <div className="inflowCards">
            <div className="rounded-lg flex justify-between items-center bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex flex-row searchContract" style={{ maxWidth: "70%" }}>
                {(bitcoinWalletArray.indexOf(network) > -1) && 
                  <img style={{ width: "20px", height: "20px", marginRight: "10px" }} src={require(`assets/img/onchain/${network}.svg`).default} />
                }
                <span className="capitalize text-sm flex flex-row"><b className="mr-2">{network ? network : "N/A"}</b> - <p className="ml-2" style={{ maxWidth: "70%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{id ? id : "N/A"}</p></span>
              </div>
              <div className="filtersTable searchInputTables linechartfilter">
                <div className="fieldSearch relative w-full">
                  <label className="label block font-semibold text-black-600 text-xs mb-2">
                    Date range
                  </label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    portalId="root-portal"
                    maxDate={new Date()}
                    selectsRange={true}
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    onChange={(update) => {
                      updateResults(update)
                    }}
                    peekNextMonth
                    renderCustomHeader={({
                      monthDate,
                      customHeaderCount,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div>
                        <button
                          aria-label="Previous Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--previous"
                          }
                          style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                          onClick={decreaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                            }
                          >
                            {"<"}
                          </span>
                        </button>
                        <span className="react-datepicker__current-month">
                          {monthDate.toLocaleString("en-US", {
                            month: "long",
                            year: "numeric",
                          })}
                        </span>
                        <button
                          aria-label="Next Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--next"
                          }
                          style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                          onClick={increaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                            }
                          >
                            {">"}
                          </span>
                        </button>
                      </div>
                    )}
                    monthsShown={2}
                  />
                </div>
              </div>
              <div className="filtersTable limitChangeField">
                <div className="flex flex-row limitField mainField">
                  <div className="inputfield limit">
                    <label className="label block font-semibold text-black-600 text-xs mb-2">Limit</label>
                    {/* <Select
                      styles={customStyles}
                      onChange={(e) => setDefaultValueLimit(e)}
                      className="globeSelect"
                      options={optionsLimit}
                      defaultValue={defaultValueLimit}
                      menuPortalTarget={document.body}
                      isSearchable={false}
                    /> */}
                    <input
                      type="number"
                      min={1}
                      value={tempLimit}
                      onChange={e => {
                        if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                          setTempLimit(Number(1));
                        } else {
                          setTempLimit(Number(e.target.value));
                        }
                      }}
                      className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 w-16 h-8 ease-linear transition-all duration-150"
                    />
                  </div>
                  <button
                    onClick={() => {
                      setDefaultValueLimit(tempLimit);
                    }}
                    style={{outline:"none"}}
                    className="px-2 py-2 bg-white rounded-lg mr-2"
                  >
                    <img src={tickIcon} alt="refresh" style={{width:"15px", height:"15px"}} />
                  </button>
                </div>
              </div>
            </div>
            {network == "cardano" && 
              <>
                <div className=" xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg">
                  <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                    <div className="w-full flex  items-center">
                      <h4
                        className="borderDefault summaryHeading pb-2 font-bold text-base"
                        style={{ borderBottom: "2px solid #ee416f" }}
                      >
                        Latest Inbound Transfers from Transaction Outputs
                      </h4>
                    </div>
                    <Table data={inboundCardanoData} columns={inboundCardanoColumn} limit={defaultValueLimit} />
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pr-4">
                    <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart h-full">
                      <div className="w-full flex  items-center">
                        <h4
                          className="borderDefault summaryHeading pb-2 font-bold text-base"
                          style={{ borderBottom: "2px solid #ee416f" }}
                        >
                          Inbound transfers in ADA currency
                        </h4>
                      </div>
                      <ReactApexChart options={inboundTransferCurrencyGraphOptions} series={inboundTransferCurrencyGraphSeries} type="line" height={350} width={500}/>
                    </div>
                  </div>
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pl-4">
                    <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart h-full">
                      <div className="w-full flex  items-center">
                        <h4
                          className="borderDefault summaryHeading pb-2 font-bold text-base"
                          style={{ borderBottom: "2px solid #ee416f" }}
                        >
                          Inbound Volumes By Output Type
                        </h4>
                      </div>
                      <ReactApexChart options={inboundVolumeByOutputTypeOptions} series={inboundVolumeByOutputTypeSeries} type="donut" height={350} width={500}/>
                    </div>
                  </div>
                </div>
                <div className="xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg mt-6">
                  <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                    <div className="w-full flex  items-center">
                      <h4
                        className="borderDefault summaryHeading pb-2 font-bold text-base"
                        style={{ borderBottom: "2px solid #ee416f" }}
                      >
                        Top Inbound Transfers from Transaction Outputs
                      </h4>
                    </div>
                    <Table data={tokenSenderCardanoData} columns={tokenSenderCardanoColumn} limit={defaultValueLimit} />
                  </div>
                </div>
              </>
            }
            {network == "ethereum" && 
              <>
                <div className=" xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg">
                  <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                    <div className="w-full flex  items-center">
                      <h4
                        className="borderDefault summaryHeading pb-2 font-bold text-base"
                        style={{ borderBottom: "2px solid #ee416f" }}
                      >
                        Latest Inbound Transfers
                      </h4>
                    </div>
                    <Table data={inboundData} columns={inboundColumn} limit={defaultValueLimit} />
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pr-4">
                    <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                      <div className="w-full flex  items-center">
                        <h4
                          className="borderDefault summaryHeading pb-2 font-bold text-base"
                          style={{ borderBottom: "2px solid #ee416f" }}
                        >
                          Inbound Transfers in ETH Currency
                        </h4>
                      </div>
                      <ReactApexChart options={inboundTransferCurrencyGraphOptions} series={inboundTransferCurrencyGraphSeries} type="line" height={350} width={500}/>
                    </div>
                  </div>
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pl-4">
                    <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                      <div className="w-full flex  items-center">
                        <h4
                          className="borderDefault summaryHeading pb-2 font-bold text-base"
                          style={{ borderBottom: "2px solid #ee416f" }}
                        >
                          Inbound Transfers in Tokens
                        </h4>
                      </div>
                      <ReactApexChart options={inboundTransferTokensGraphOptions} series={inboundTransferTokensGraphSeries} type="line" height={350} width={500}/>

                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pr-4">
                    <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                      <div className="w-full flex  items-center">
                        <h4
                          className="borderDefault summaryHeading pb-2 font-bold text-base"
                          style={{ borderBottom: "2px solid #ee416f" }}
                        >
                          Top Senders in ETH Currency
                        </h4>
                      </div>
                      <Table data={topSenderInCurrencyTable} columns={ethSenderColumn} limit={defaultValueLimit} />
                    </div>
                  </div>
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pl-4">
                    <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                      <div className="w-full flex  items-center">
                        <h4
                          className="borderDefault summaryHeading pb-2 font-bold text-base"
                          style={{ borderBottom: "2px solid #ee416f" }}
                        >
                          Top Senders in Tokens
                        </h4>
                      </div>
                      <Table data={topSenderInTokensTable} columns={tokenSenderColumn} limit={defaultValueLimit} />
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </>
      }
    </>
  );
}

function Table({ columns, data, limit }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: limit },
    },
    usePagination,
    useRowSelect
  );
  return (
    <>
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table
          {...getTableProps()}
          className="items-center w-full bg-transparent border-collapse"
        >
          <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={
                      "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                    }
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="cursor-pointer">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
