import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {KycMobContext} from "../../../layouts/KYCMob";
import QrReader from 'react-web-qr-reader';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { savewalletinfo, validatewallet, saveevent, calculatechecks } from "../../../services/kyc/KycScreens";
import { disablerevision } from "../../../services/kyc/KycScreens";

import backButtonImg from "../../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from "../../../assets/img/kycmobile/cross_white.png";
import footerImg from "../../../assets/img/kycmobile/footer_trademark.png";
import cycle from "../../../assets/img/icons/cycle.svg";

export default function Screen3i() {
  const {data} = useContext(KycMobContext);
  const [facingMode, setFacingMode] = React.useState("user");
  const [message, setMessage] = useState("");
  const [disable, setDisable] = React.useState(false);
  const history = useHistory();
  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen3eth) {
        const redirecturl = "/kycreturning/wallet/a/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kycreturning/wallet/a/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  const [result, setResult] = useState(null);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  const handleScan = (result) => {
    if (result) {
      setResult(result.data);
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  // const linkADA = () => {
  //   setMessage("");
  //   if (result) {
  //     setDisable(true);
  //     validatewallet({address: result}).then((response) => {
  //       // console.log("response: ", response);
  //       if (response.data.success) {
  //         let formdata = {
  //           inq_id: url,
  //           wallet_address: result,
  //           network: "ETH",
  //           agix_eligible: check1,
  //           sdao_eligible: check2
  //         };
  //         savewalletinfo(formdata).then((response) => {
  //           // console.log("response: ", response);
  //           if (response.data.success) {
  //             const redirecturl = "/kycreturning/wallet/c/" + url;
  //             history.push(redirecturl);
  //             window.location.reload();
  //           }
  //         }).catch((error) => {
  //           console.log("error: ", error);
  //           setDisable(false);
  //         });
  //       } else {
  //         setMessage("Wallet address in invalid!");
  //         setDisable(false);
  //       }
  //     }).catch((error) => {
  //       console.log("error: ", error);
  //       setDisable(false);
  //     });
  //   } else {
  //     setMessage("Wallet address not detected!");
  //   }
  // }

  const handleSubmit = () => {
    setMessage("");

    // if (!check1 && !check2) {
    //   setMessage("Please select atleast one TGE Contribution!");
    //   return;
    // }

    if (result) {
      setDisable(true);
      validatewallet({address: result}).then((response) => {
        // console.log("response: ", response);
        if (response.data.success) {
          let formdata = {
            inq_id: url,
            wallet_address: result,
            network: "ETH",
            tge_contribution: check1 ? "Stablecoin" : "Ecosystem Token"
          };
          savewalletinfo(formdata).then((response) => {
            // console.log("response: ", response);
            if (response.data.success) {
              let formdata1 = {
                inq_id: url,
                event_desc: "Wallet verification started",
                passed: 0,
              };
              saveevent(formdata1)
                .then((response) => {
                  if (response.data.success) {
                    let formdata2 = {
                      inq_id: url,
                      event_desc: "Wallet verification submitted",
                      passed: 0,
                    };
                    saveevent(formdata2)
                      .then((response) => {
                        if (response.data.success) {
                          let formdata3 = {
                            inq_id: url,
                            event_desc: "Wallet verification passed",
                            passed: 1,
                          };
                          saveevent(formdata3)
                            .then((response) => {
                              if (response.data.success) {
                                calculatechecks(url).then((response) => {
                                  if (response.data.success) {
                                    let formdata = {
                                      inq_id: url,
                                      section: "wallet"
                                    };
                                    disablerevision(formdata).then((response) => {
                                      if (response.data.success) {
                                        let temp = screenStates;
                                        temp.Screen26 = true;
                                        storage.setItem("screenmeta", JSON.stringify(temp));
                                        const redirecturl = "/kycreturning/wallet/d/" + url;
                                        history.push(redirecturl);
                                        window.location.reload();
                                      }
                                    }).catch((error) => {
                                      console.log("error: ", error);
                                    })
                                  }
                                }).catch((error) => {
                                  console.log("error: ", error);
                                });
                              }
                            })
                            .catch((error) => {
                              console.log("error: ", error);
                              setDisable(false);
                            });
                        }
                      })
                      .catch((error) => {
                        console.log("error: ", error);
                        setDisable(false);
                      });
                  }
                })
                .catch((error) => {
                  console.log("error: ", error);
                  setDisable(false);
                });
            }
          }).catch((error) => {
            console.log("error: ", error);
            setDisable(false);
          });
        } else {
          setMessage("Wallet address in invalid!");
          setDisable(false);
        }
      }).catch((error) => {
        console.log("error: ", error);
        setDisable(false);
      });
    } else {
      setMessage("Wallet address not detected!");
    }
  }

  return (
    <>
      <div className="screenTwo screenThree screenQR h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"#"}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "92%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div class="mainContent mt-0">
          <div class="mainText">
            <h1 style={{ height: "40px", fontSize: "16px" }}>Connect your ETH/BNB Wallet</h1>
            <p className="text-xs mb-4 text-center">Scan QR or manually enter below.</p>
          </div>

          <Form className="informationSection">
            <div className="emailFields">
              <div className="firstNameField firstField">
                <div className="inputfield firstname">
                  <span className="label text-sm">Wallet Address</span>
                  <Input 
                    type="text" 
                    name="wallet_address" 
                    className="input firstnameInput" 
                    value={result}
                    onChange={(e) => {setResult(e.target.value)}}
                  />
                </div>
              </div>
            </div>
            {/* <div class="selectboxlabel">
              <h1 className="text-sm">TGE Contribution Selection</h1>
            </div>
            <div className="mb-1">
              <div className="firstNameField firstField">
                <div className="firstname flex flex-row items-center">
                  <Input 
                    type="radio" 
                    name="contribution" 
                    className="firstnameInput mr-2 cursor-pointer" 
                    value={check1}
                    onChange={() => {setCheck1(!check1); setCheck2(check1)}}
                    style={{ borderRadius: "5px" }}
                  />
                  <span className="label text-sm">Stablecoin</span>
                </div>
              </div>
              <div className="lastNameField">
                <div className="lastname flex flex-row items-center">
                  <Input 
                    type="radio" 
                    name="contribution"
                    className="lastnameInput mr-2 cursor-pointer" 
                    value={check2}
                    onChange={() => {setCheck2(!check2); setCheck1(check2)}}
                    style={{ borderRadius: "5px" }}
                  />
                  <span className="label text-sm">Ecosystem Token (AGIX/SDAO)</span>
                </div>
              </div>
            </div>

            <div class="selectboxlabel mb-2">
              <h1 className="text-xs">SDAO Allocations accept SDAO/Stable</h1>
              <h1 className="text-xs">AGIX Allocations accept AGIX/Stable</h1>
            </div> */}

            {message && (
              <section className="form-group mt-1">
                <section className="text-sm alert alert-danger text-center" role="alert">
                  {message}
                </section>
              </section>
            )}
          </Form>

          <div class="languageSelection" style={{ width: "180px" }}>
            <QrReader
              onError={handleError}
              onScan={handleScan}
              facingMode={facingMode}
            />
          </div>
          <button
            className="camerafacingchangebutton flex flex-row items-center justify-center"
            style={{outline: "none", width: "30%", margin: "0 auto", marginTop: "10px"}}
            onClick={() =>
              setFacingMode(facingMode === "user" ? "environment" : "user")
            }
          >
            <img src={cycle} style={{ width: "17px" }} alt="cycle" />
          </button>
        </div>

        <div className="buttonAction">
          {/* <p onClick={linkADA} className="colorPrimary text-center font-bold text-sm mb-2 cursor-pointer">Link ADA wallet aswell?</p> */}
          
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#ee416f",
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            Continue
          </button>
        </div>

        <div class="footer">
          <img class="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
