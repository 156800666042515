import React from "react";
import DataTableDoc from "../../components/Datatables/DataTableDoc";

export default function Documentation() {
  return (
    <>
      <DataTableDoc />
    </>
  );
}
