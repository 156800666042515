import React, { useState,useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import countryList from 'react-select-country-list';
import {KycMobContext} from "../../../layouts/KYCMob"
import { getacceptablecountries } from "../../../services/settings/Acceptable";
import { getacceptableidbycountry } from "../../../services/settings/Acceptable";

import backButtonImg from "../../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from "../../../assets/img/kycmobile/cross_white.png";
import footerImg from "../../../assets/img/kycmobile/footer_trademark.png";

export default function Screen11() {
  const {data} = useContext(KycMobContext)

  const history = useHistory();
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [message, setMessage] = useState("");
  const [value, setValue] = React.useState("")
  const [options, setOptions] = React.useState(() => countryList().getData(), []);
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  const [idNum, setIdNum] = useState(null);
  const [disable, setDisable] = React.useState(false);
  const [optionsDocuments, setOptionsDocuments] = React.useState(null);
  const [showList, setShowList] = React.useState(false);
  const [showNextButton, setShowNextButton] = React.useState(false);
  const [fileType, setFileType] = React.useState(null);

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen11) {
        const redirecturl = "/kycreturning/idverif/a/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kycreturning/idverif/a/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  React.useEffect(() => {
    getacceptablecountries().then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        if (response.data.acceptable_countries.length > 0) {
          var filteredArray = options.filter(function(item_op) {
            return response.data.acceptable_countries.filter(function(item_resp) {
              return item_resp.country === item_op.label;
            }).length !== 0
          });
          setOptions(filteredArray);
        }
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }, []);

  const changeHandler = v => {
    setValue(v);
    setShowList(false);
    getacceptableidbycountry(v.label).then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        let options = [];
        if (response.data.acceptable_id.govt_id) {
          options.push({label: "National ID Card", value: 'id'});
        }
        if (response.data.acceptable_id.license) {
          options.push({label: "Driving License", value: 'license'});
        }
        if (response.data.acceptable_id.passport) {
          options.push({label: "Passport", value: 'passport'});
        }
        setOptionsDocuments(options);
        setShowList(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }

  const handleInputChange = (e) => {
    setIdNum(e.target.value);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  }

  const handleSelectChange = (e) => {
    // console.log("label: ", e.label);
    // console.log("value: ", e.value);
    setFileType(e.value);
    setShowNextButton(true);
  }

  const handleNext = () => {
    setMessage("");

    if (idNum === null || idNum === "") {
      setMessage("Please enter ID number!");
      return;
    }

    if (value) {
      setDisable(true);
      let nationality = value.label;

      const redirecturl = "/kycreturning/idverif/d/"+url;
      let temp = screenStates;
      temp.Screen16 = true;
      storage.setItem("screenmeta", JSON.stringify(temp));
      history.push({
        pathname: redirecturl,
        state: { 
          nationality: nationality,
          idNum: idNum,
          fileType: fileType
        }
      });
      window.location.reload();
    } else {
      setMessage("Please select a nationality!");
    }
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenEleven h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kycreturning/idverif/b/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "45%" ,backgroundColor:data ? data.progressbar_color:"#ee416f"}}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1>What country is your document from?</h1>
            <p>Please select the country from the dropdown list and fill your identification number.</p>
          </div>

          <div className="informationSection">
            <div className="nationalityField mainField">
              <div className="inputfield dob">
                <span className="label text-sm">Issuance Country</span>
                <Select 
                  className=""
                  style={{ all: "unset" }} 
                  placeholder="Search Country" 
                  options={options} 
                  value={value} 
                  onChange={changeHandler}
                  menuPortalTarget={document.body} 
                  styles={customStyles} 
                />
              </div>
            </div>
            <div className="idField mainField">
              <div className="inputfield dob">
                <span className="label text-sm">ID Number</span>
                <input 
                  name="idNum" 
                  type="text" 
                  className="inputSelect idInput" 
                  value={idNum}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {showList && 
              <div className="nationalityField mainField">
                <div className="inputfield dob">
                  <span className="label text-sm">Document Type</span>
                  <Select
                    styles={customStyles}
                    onChange={handleSelectChange}
                    className="globeSelect"
                    options={optionsDocuments}
                    placeholder={"Please select the document type."}
                  />
                </div>
              </div>
            }

            {message && (
              <section className="form-group">
                <section className="text-sm alert alert-danger" role="alert">
                  {message}
                </section>
              </section>
            )}
          </div>
        </div>

        {showNextButton && 
          <div className="buttonAction">
            {/* <Link to="/kycreturning/idverif/screen12"> */}
              <button onClick={handleNext} disabled={disable} style={{backgroundColor:data?data.button_color:"#ee416f"}}  className="action">Next!</button>
            {/* </Link> */}
          </div>
        }

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
