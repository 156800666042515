import axios from "axios";
import address from "../Address";

export function savecustomization(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/setting/save_customization_options_desktop", formdata, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}