import React from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table'
import Select from 'react-select';
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import countPlus from "../../assets/img/countPlus.png";
import countMinus from "../../assets/img/countMinus.png";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <div className="selectBox">
          <label className="inline-flex items-center cursor-pointer text-sm">
            <input
              ref={resolvedRef} {...rest}
              id="customCheckLogin"
              type="checkbox"
              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
            />
          </label>
        </div>
      </>
    )
  }
)

const IndeterminateCheckboxHeader = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
          <input type="checkbox" id="test" ref={resolvedRef} {...rest} style={{display:"none"}} /><label for="test" className="cursor-pointer">Select All</label>
      </>
    )
  }
)

function Table({columns, data}) {
  const color = "light";

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckboxHeader {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  return (
    <>
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table {...getTableProps()} className="items-center w-full bg-transparent border-collapse">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th 
                    {...column.getHeaderProps()}
                    className={
                      "px-6 align-middle py-3 text-xs capitalize whitespace-nowrap font-semibold text-center text-black-600"
                    }
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="cursor-pointer">
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className="border-t-0 px-6 align-middle align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

function DataTableComponent() {
  const circularBarColorAccepted = "#15E49A";
  const circularBarColorPending = "#FFC102";
  const circularBarColorDeclined = "#FA364C";

  const selectOptions = [
    { label: "Search By", value: 1 },
    { label: "Search By Name", value: 2 },
    { label: "Search By ID Number", value: 3 },
    { label: "Search By User", value: 4 },
  ];

  const [count, setCount] = React.useState(0);
  const increment = () => {
    setCount(count + 1);
  }
  const decrement = () => {
    setCount(count - 1);
  }

  const defaultValueSelectOptions = selectOptions[0];

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#828282' : '#828282',
      backgroundColor: state.isSelected ? '#EBEDF2' : '#FFF',
      padding: 10,
      fontSize: '14px',
      
    }),
  }

  const data = [
    {
      riskType: 'Country/Jurisdiction',
      weights: 'lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
      riskScore: 20,
      lastTwo: 'Low',
      lastOne: '12-09-1993',
    },
    {
      riskType: 'Nationality',
      weights: 'lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
      riskScore: 20,
      lastTwo: 'Medium',
      lastOne: '12-09-1993',
    },
    {
      riskType: 'Product',
      weights: 'lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
      riskScore: 20,
      lastTwo: 'High',
      lastOne: '12-09-1993',
    },
    {
      riskType: 'Customer',
      weights: 'lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
      riskScore: 20,
      lastTwo: 'Medium',
      lastOne: '12-09-1993',
    },
    {
      riskType: 'Occupation',
      weights: 'lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
      riskScore: 20,
      lastTwo: 'Medium',
      lastOne: '12-09-1993',
    }
  ]

  const columns = [
    {
      Header: 'Risk Type',
      accessor: 'riskType',
      Cell: tableProps => (
        <div className="input-group mx-auto">
          {tableProps.row.original.riskType.includes("Country/Jurisdiction") ?
            <Link to="/risk/riskmanagement/scorecountry">
              <p className="text-xs">{tableProps.row.original.riskType}</p>
            </Link>
            :
            <p className="text-xs">{tableProps.row.original.riskType}</p>
          }
        </div>
      )
    },
    {
      Header: 'Weights',
      accessor: 'weights'
    },
    {
      Header: 'Risk Score',
      accessor: 'riskScore',
      Cell: tableProps => (
        <div className="input-group mx-auto">
          <div className="flex items-center justify-center">
            <img src={countMinus} alt="countminus" onClick={decrement} />
            <input 
              className="max-w-100-px" 
              name="count" 
              type="text" 
              value={count} 
              style={{maxWidth: "50px", textAlign: "center", margin: "0 10px", borderRadius: "15px", border: "2px solid #c4c4c4"}} 
            />
            <img src={countPlus} alt="countplus" onClick={increment} />
          </div>
        </div>
      )
    }, 
    {
      Header: '',
      accessor: 'lastTwo',
      Cell: tableProps => (
        <div className="statusGrid mx-auto">
          {tableProps.row.original.lastTwo.includes("High") && 
            <button className="w-full px-4 py-2 rounded-lg font-semibold text-white-400 buttonRed mb-2">
              High
            </button>
          }
          {tableProps.row.original.lastTwo.includes("Medium") && 
            <button className="w-full px-4 py-2 rounded-lg font-semibold text-white-400 buttonYellow mb-2">
              Medium
            </button>
          }
          {tableProps.row.original.lastTwo.includes("Low") && 
            <button className="w-full px-4 py-2 rounded-lg font-semibold text-white-400 buttonGreen">
              Low
            </button>
          }
        </div>
      )
    },
    {
      Header: '',
      accessor: 'lastOne',
      Cell: tableProps => (
        <div className="tablerisk field dropdown relative w-full mb-3" style={{minWidth:"100px"}}>
          <Select styles={customStylesSelect } options={ selectOptions } isSearchable={false} defaultValue={defaultValueSelectOptions} />
        </div>
      )
    }
  ]

  return (
      <Table data={data} columns={columns}/>
  )

}

export default DataTableComponent