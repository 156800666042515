import React from "react";
import DataTableAmlCase from "../../components/Datatables/DataTableAmlCase";

export default function AlertsAml() {

  return (
    <>
      <DataTableAmlCase />
    </>
  );
}
