import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { storeactivesession } from "services/settings/ActiveSession";
import platform from "platform";
import ReactLoading from "react-loading";

import {loginApi, checkUser} from "../../services/Auth";

import Logo from "../../assets/img/hedge-logo.png";
import AuthImg from "../../assets/img/authimg.png"
// import BackgroundImg from "../../assets/img/authbg.png"
import BackgroundImg from "../../assets/img/bglo.svg"
import BackgroundImageSvg from "../../assets/img/backgroundsvgclear.svg";
import DividerImg from "../../assets/img/divider.png"
import Alert from "../../assets/img/alert.png"


export default function Login() {
    const history = useHistory();
    const [dataLoaded, setDataLoaded] = React.useState(true);
    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [deviceOs, setDeviceOs] = React.useState(platform.os.family);
    const [browser, setBrowser] = React.useState(platform.name);
    const [isMobile, setIsMobile] = React.useState(0);
    var checkBtn;
    var form;

    useEffect(() => {
        if (checkUser()) {
            history.push("/admin");
            window.location.reload();
        }

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        if (isMobileDevice) {
            setIsMobile(1);
        } else {
            setIsMobile(0);
        }
    }, [])
    
    function handleLogin(e) {
        setMessage("");
        setDataLoaded(false);
        e.preventDefault();
        // console.log(formData);

        loginApi(formData).then((response) => {
            // console.log("response: ", response.data)
            if (response.data.success) {
                const accessToken = response.data.token;
                const token_access = response.data.access_token;
                if(accessToken) {
                    const storage = window.localStorage;
                    storage.setItem("token", token_access);
                    storage.setItem("access_token", accessToken);
                    storage.setItem("user_name", response.data.user.name);
                    storage.setItem("user", JSON.stringify(response.data.user));
    
                    let data = {
                        device_os: deviceOs,
                        device_browser: browser,
                        is_mobile: isMobile
                    }
    
                    storeactivesession(data).then((response) => {
                        if (response.data.success) {
                            setDataLoaded(true);
                            history.push("/admin");
                            window.location.reload();
                        }
                    }).catch((error) => {
                        console.log("error: ", error);
                    });
                }
            } else {
                setDataLoaded(true);
                setMessage(response.data.error_message);
            }
        }).catch((error) => {
            setDataLoaded(true);
            setMessage("Please try again!");
        });

    }
    return (
      <>
        {!dataLoaded && (
            <>
                <ReactLoading
                    className="margin-auto mt-3 mb-3 absolute"
                    type={"bars"}
                    style={{
                        zIndex: "100000",
                        top: "30%",
                        left: "39%",
                        width: "8%",
                        height: "8%",
                        fill: "#ee416f",
                    }}
                />
                <div
                    id="overlay"
                    className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer"
                    style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}
                ></div>
            </>
        )}
        <div className="row w-full h-full min-h-screen relative flex flex-row flex-wrap login-page">
            <div className="lg:w-5/12 md:w-12/12 sm:w-12/12 px-12" style={{backgroundColor: "#FFF"}}>
                <div className="logo pt-12" style={{paddingBottom: "20px"}}>
                    <Link
                        to="/"
                        className=""
                    >
                        <img className="w-auto" src={Logo} style={{width: "25%"}} alt="Regly logo" />
                    </Link>
                </div>
                <div className="loginForm">
                    <div className="headingSignIn">
                        <h1 className="signin-heading font-900-bold" style={{ textTransform: "none" }}>Sign in</h1>
                        <p>Welcome back! Use your email to sign in.</p>
                    </div>
                    <Form class="signinForm" onSubmit={(e) => handleLogin(e)} ref={c => {form = c;}}>
                        <div className="field relative w-full mb-3">
                            <label
                                className="label block uppercase text-blueGray-600 text-xs mb-2"
                            >
                                Email
                            </label>
                            <Input
                                type="email"
                                className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="johndoe@email.com"
                                defaultValue={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value})}
                            />
                        </div>

                        <div className="field relative w-full mb-3">
                            <label
                                className="label block uppercase text-blueGray-600 text-xs mb-2"
                            >
                                Password
                            </label>
                            <Input
                                type="password"
                                className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="**********"
                                defaultValue={formData.password}
                                onChange={(e) => setFormData({ ...formData, password: e.target.value})}
                            />
                        </div>

                        {message && (
                            <div className="form-group">
                                <div className="text-sm alert alert-danger w-full text-right mb-6" role="alert">
                                    <img src={Alert} alt="alert-icon" className="mr-3 inline" width={15} />
                                    {message}
                                </div>
                            </div>
                        )}

                        <div className="text-center mt-6">
                            <button
                                className="signInButton text-white active:bg-blueGray-600 text-xs font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="submit"
                            >
                                Sign In
                            </button>
                        </div>

                        {/* <div className="signUpForgotBlock">
                            <div className="signup text-xs">Don’t have an Account? <p className="pink">
                            <Link
                                to="/auth/register"
                                className="text-xs"
                            >Sign Up</Link>
                            </p></div>
                            <div className="forgot text-xs">Forgot password?</div>
                        </div> */}

                        <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                                checkBtn = c;
                            }}
                        />
                    </Form>
                </div>
            </div>
            <div className="relative lg:w-7/12 md:w-12/12 sm:w-12/12" style={{ backgroundImage: `url(${BackgroundImg})` ,backgroundSize: 'cover' , backgroundRepeat: 'no-repeat' , backgroundPosition: 'center center' }}>
                <div className="auth-image relative" style={{minHeight: "80%"}}>
                    <img className="absolute w-auto" alt="auth" style={{minHeight: "70vh", right: "20%", width: "90%", top: "40px"}} src={AuthImg} />
                </div>
                {/* <div className="row w-full flex flex-row px-16 flex-wrap kyc-text-flex">
                    <div className="relative font-Gilroy lg:w-3/12 ">
                        <h2 className="kyc-heading">INTELLIGENT AML SOLUTION</h2>
                    </div>
                    <div className="lg:w-1/12 px-12 horizontalDivider" style={{paddingLeft: "2rem"}}>
                        <img className="w-auto" alt="divider" src={DividerImg} />
                    </div>
                    <div className="relative lg:w-8/12">
                        <p className="simple-para font-bold">Regly provides Know Your Customer (KYC) and Anti-Money Laundering (AML) solutions that enable clients to onboard their customers smoothly with low verification costs, a streamlined KYC/AML program, and the ability to verify their identities and documents automatically.</p>
                    </div>
                </div> */}
            </div>
        </div>
      </>
    );
}
  
