import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link as Link1 } from "react-scroll";
import { Link as Link2 } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useParams } from "react-router";
import ReactLoading from "react-loading";
import { getdetails } from "../../services/opencorporates/Lookup";
import Moment from "moment";
import countryList from "react-select-country-list";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";

// images
import dropDownIcon from "../../assets/img/dropdown.png";
import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";

function Table({ finalData, columns }) {
    const color = "light";
    const [pageNum, setPageNum] = React.useState(1);
  
    const {
      table,
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      selectedFlatRows,
      state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
      {
        columns,
        data: finalData,
        initialState: { pageIndex: 0 },
      },
      useSortBy,
      usePagination,
      useRowSelect,
      hooks => {
        hooks.visibleColumns.push(columns => [
          ...columns,
        ])
      }
    )
  
    return (
      <>
        <div
            className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
            }
        >
            <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
                {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '} */}
                <div className="flex flex-row items-center">
                    <button className="mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <img src={leftPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                    </button>
                    <span className="mr-2 text-xs">
                        Page
                        <strong className="ml-2">
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        <img src={rightPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                    </button>
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                    <p className="font-bold text-xs mr-3">Go to page</p>
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        value={pageNum}
                        onChange={(e) => {
                            const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
                            const page = value ? Number(value) - 1 : 0;
                            gotoPage(page);
                            setPageNum(value);
                        }}
                        className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                    />
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                    <p className="font-bold text-xs mr-3">Records per page</p>
                    <input
                        type="number"
                        min={1}
                        value={pageSize}
                        onChange={e => {
                            if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                                setPageSize(Number(1));
                            } else {
                                setPageSize(Number(e.target.value));
                            }
                        }}
                        className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                    />
                </div>
            </div>
            <div className="block w-full overflow-x-auto">
                <table
                    {...getTableProps()}
                    className="items-center w-full bg-transparent border-collapse"
                >
                    <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className={
                                            "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-black-600 text-left"
                                        }
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className={"cursor-pointer "}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
      </>
    );
}

export default function Details(props) {
  const { jurisdiction, number } = useParams();
  const history = useHistory();
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [data, setData] = React.useState({});
  
  const options = React.useMemo(() => countryList().getData(), []);
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  
  const [basicActive, setBasicActive] = React.useState(true);
  const [officersActive, setOfficersActive] = React.useState(false);
  const [sourceActive, setSourceActive] = React.useState(false);
  const [industryActive, setIndustryActive] = React.useState(false);
  const [fillingsActive, setFillingsActive] = React.useState(false);

  const [basicShow, setBasicShow] = React.useState(true);
  const [officerShow, setOfficerShow] = React.useState(true);
  const [sourceShow, setSourceShow] = React.useState(true);
  const [industryShow, setIndustryShow] = React.useState(true);
  const [fillingsShow, setFillingsShow] = React.useState(true);

  const columns = [
    {
        Header: "Date",
        accessor: "date",
        Cell: (tableProps) => (
            <>
                <Link2 to={`/businesslookup/fillings/${tableProps.row.original.filing.id}`}>
                    <div className="flex flex-col text-left">
                        <span className={"text-xs text-black-600"}>
                            {tableProps.row.original.filing.date ? tableProps.row.original.filing.date : "N/A"}
                        </span>
                    </div>
                </Link2>
            </>
        ),
    },
    {
        Header: "Title",
        accessor: "title",
        Cell: (tableProps) => (
            <>
                <Link2 to={`/businesslookup/fillings/${tableProps.row.original.filing.id}`}>
                    <div className="flex flex-col text-left">
                        <span className={"text-xs text-black-600"}>
                            {tableProps.row.original.filing.title ? tableProps.row.original.filing.title : "N/A"}
                        </span>
                    </div>
                </Link2>
            </>
        ),
    },
    {
        Header: "Description",
        accessor: "description",
        Cell: (tableProps) => (
            <>
                <Link2 to={`/businesslookup/fillings/${tableProps.row.original.filing.id}`}>
                    <div className="flex flex-col text-left">
                        <span className={"text-xs text-black-600"}>
                            {tableProps.row.original.filing.description ? tableProps.row.original.filing.description : "N/A"}
                        </span>
                    </div>
                </Link2>
            </>
        ),
    }
  ];

  React.useEffect(() => {
    if (!jurisdiction || !number) {
        history.push("/businesslookup/index");
        window.location.reload();
    } else {
        getdetails({jurisdiction_code: jurisdiction, company_number: number}).then((response) => {
            if (response.status == 200) {
                // console.log("response: ", response);
                setData(response.data.results.company);
                setDataLoaded(true);
            }
        }).catch((error) => {
            console.log("error: ", error);
        });
    }
  }, []);


  function setBasActive() {
    setBasicActive(true);
    setOfficersActive(false);
    setSourceActive(false);
    setIndustryActive(false);
    setFillingsActive(false);
  }
  function setOffActive() {
    setBasicActive(false);
    setOfficersActive(true);
    setSourceActive(false);
    setIndustryActive(false);
    setFillingsActive(false);
  }
  function setSoActive() {
    setBasicActive(false);
    setOfficersActive(false);
    setSourceActive(true);
    setIndustryActive(false);
    setFillingsActive(false);
  }
  function setIndActive() {
    setBasicActive(false);
    setOfficersActive(false);
    setSourceActive(false);
    setIndustryActive(true);
    setFillingsActive(false);
  }
  function setFillActive() {
    setBasicActive(false);
    setOfficersActive(false);
    setSourceActive(false);
    setIndustryActive(false);
    setFillingsActive(true);
  }

  return (
    <>
      {dataLoaded && (
        <div className="casemanagement casemanagementdetail flex flex-wrap">
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
                <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                    <div className="w-full flex items-center flex-wrap justify-between">
                        <div
                            className="px-4"
                            style={{ paddingLeft: "0" }}
                        >
                            <h4 className="borderDefault summaryHeading pb-1 font-bold text-base">
                                {data.name ? data.name : "N/A"}
                            </h4>
                        </div>
                        <span className="text-xs text-gray-500">Last updated: {data.updated_at ? Moment(data.updated_at).format("MM-DD-YYYY hh:mm a") : "N/A"}</span>
                    </div>
                </div>

                <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                    <div className="flex flex-wrap">
                        <Link1 to="basicRef" spy={true} smooth={true}>
                            <div
                                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                                style={
                                basicActive
                                    ? { backgroundColor: "#EBEDF2" }
                                    : { backgroundColor: "#FFF" }
                                }
                                onClick={setBasActive}
                            >
                                <h4 className="font-semibold text-xs">
                                    Basic
                                </h4>
                            </div>
                        </Link1>
                        {data.officers.length > 0 && (
                            <Link1 to="officerRef" spy={true} smooth={true}>
                                <div
                                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                                    style={
                                    officersActive
                                        ? { backgroundColor: "#EBEDF2" }
                                        : { backgroundColor: "#FFF" }
                                    }
                                    onClick={setOffActive}
                                >
                                    <h4 className="font-semibold text-xs">
                                        Officers
                                    </h4>
                                </div>
                            </Link1>
                        )}
                        {data.filings.length > 0 && (
                            <Link1 to="fillingsRef" spy={true} smooth={true}>
                                <div
                                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                                    style={
                                    fillingsActive
                                        ? { backgroundColor: "#EBEDF2" }
                                        : { backgroundColor: "#FFF" }
                                    }
                                    onClick={setFillActive}
                                >
                                    <h4 className="font-semibold text-xs">
                                        Fillings
                                    </h4>
                                </div>
                            </Link1>
                        )}
                        {data.industry_codes.length > 0 && (
                            <Link1 to="industryRef" spy={true} smooth={true}>
                                <div
                                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                                    style={
                                    industryActive
                                        ? { backgroundColor: "#EBEDF2" }
                                        : { backgroundColor: "#FFF" }
                                    }
                                    onClick={setIndActive}
                                >
                                    <h4 className="font-semibold text-xs">
                                        Industry Codes
                                    </h4>
                                </div>
                            </Link1>
                        )}
                        <Link1 to="sourceRef" spy={true} smooth={true}>
                            <div
                                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                                style={
                                sourceActive
                                    ? { backgroundColor: "#EBEDF2" }
                                    : { backgroundColor: "#FFF" }
                                }
                                onClick={setSoActive}
                            >
                                <h4 className="font-semibold text-xs">
                                    Sources
                                </h4>
                            </div>
                        </Link1>
                    </div>
                </div>

                <div id="basicRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                    <div className="block w-full overflow-x-auto pb-6">
                        <div
                            className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                            onClick={() => setBasicShow(!basicShow)}
                        >
                            <div className="flex">
                                <div
                                    className="px-4 mr-4 cursor-pointer"
                                    style={{ paddingLeft: "0" }}
                                >
                                    <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                                        Basic Details
                                    </h4>
                                </div>
                            </div>
                            <div className="flex px-4 py-2 cursor-pointer">
                                {!basicShow && (
                                    <img
                                    src={dropDownIcon}
                                    alt="down icon"
                                    className="mr-3"
                                    style={{ width: "15px", height: "10px" }}
                                    />
                                )}
                                {basicShow && (
                                    <img
                                    src={dropDownIcon}
                                    alt="down icon"
                                    className="mr-3 dropDownIconRotated"
                                    style={{ width: "15px", height: "10px" }}
                                    />
                                )}
                            </div>
                        </div>
                        {basicShow && (
                            <div className="flex justify-between flex-wrap w-full px-12">
                                <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                                    <div className="flex flex-col idType">
                                        <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                            <h4 className="font-bold text-sm mb-2 flex flex-row">
                                                <p className="w-full">Company Information</p>
                                            </h4>
                                            <table className="w-full table-fixed">
                                                <tbody>
                                                    <tr>
                                                        <td className="font-light text-xs">
                                                            Company Number
                                                        </td>
                                                        <td className="font-semibold text-xs text-right">
                                                            {data.company_number ? data.company_number : "N/A"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-light text-xs">
                                                            Status
                                                        </td>
                                                        <td className="font-semibold text-xs text-right">
                                                            {data.current_status ? data.current_status : "N/A"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-light text-xs">
                                                            Incorporation Date
                                                        </td>
                                                        <td className="font-semibold text-xs text-right">
                                                            {data.incorporation_date ? data.incorporation_date : "N/A"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-light text-xs">
                                                            Dissolution Date
                                                        </td>
                                                        <td className="font-semibold text-xs text-right">
                                                            {data.dissolution_date ? data.dissolution_date : "N/A"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-light text-xs">
                                                            Company Type
                                                        </td>
                                                        <td className="font-semibold text-xs text-right">
                                                            {data.company_type ? data.company_type : "N/A"}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {data.data != null && data.data.most_recent != null && data.data.most_recent.length > 0 && (
                                    <>
                                        <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                                            <div className="flex flex-col idType">
                                                <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                                    <h4 className="font-bold text-sm mb-2 flex flex-row">
                                                        <p className="w-full">Company Address</p>
                                                    </h4>
                                                    <table className="w-full table-fixed">
                                                        <tbody>
                                                            {data.data.most_recent.map((ele, ind) => (
                                                                <tr data-index={ind}>
                                                                    <td className="font-light text-xs">
                                                                        {ele.datum.title ? ele.datum.title : "N/A"}
                                                                    </td>
                                                                    <td className="font-semibold text-xs text-right">
                                                                        {ele.datum.description ? ele.datum.description : "N/A"}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {data.registered_address && 
                                    <div className="right_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                                        <div className="flex flex-col idType">
                                            <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                                <h4 className="font-bold text-sm mb-2 flex flex-row">
                                                    <p className="w-full">Registered Address</p>
                                                </h4>
                                                <table className="w-full table-fixed">
                                                    <tbody>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Jurisdiction
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.registered_address ? (data.registered_address.country ? data.registered_address.country : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Street Address
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.registered_address ? (data.registered_address.street_address ? data.registered_address.street_address : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Locality
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.registered_address ? (data.registered_address.locality ? data.registered_address.locality : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Postal Code
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.registered_address ? (data.registered_address.postal_code ? data.registered_address.postal_code : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Region
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.registered_address ? (data.registered_address.region ? data.registered_address.region : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {data.agent_address && 
                                    <div className="right_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                                        <div className="flex flex-col idType">
                                            <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                                <h4 className="font-bold text-sm mb-2 flex flex-row">
                                                    <p className="w-full">Agent Address</p>
                                                </h4>
                                                <table className="w-full table-fixed">
                                                    <tbody>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Street Address
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.agent_address ? (data.agent_address.street_address ? data.agent_address.street_address : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Postal Code
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.agent_address ? (data.agent_address.postal_code ? data.agent_address.postal_code : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Locality
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.agent_address ? (data.agent_address.locality ? data.agent_address.locality : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Region
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.agent_address ? (data.agent_address.region ? data.agent_address.region : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {data.ultimate_controlling_company && 
                                    <div className="right_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                                        <div className="flex flex-col idType">
                                            <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                                <h4 className="font-bold text-sm mb-2 flex flex-row">
                                                    <p className="w-full">Parent/Controlling Company(s)</p>
                                                </h4>
                                                <table className="w-full table-fixed">
                                                    <tbody>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Company Name
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.ultimate_controlling_company ? (data.ultimate_controlling_company.name ? data.ultimate_controlling_company.name : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Company Mumber
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                {data.ultimate_controlling_company ? (data.ultimate_controlling_company.company_number ? data.ultimate_controlling_company.company_number : "N/A") : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Jurisdiction
                                                            </td>
                                                            {data.ultimate_controlling_company ? 
                                                                <td className="font-semibold text-xs text-right">
                                                                    {data.ultimate_controlling_company.jurisdiction_code ? 
                                                                        <>
                                                                            {options.map((country) => (
                                                                                <>
                                                                                    {country.value.toLowerCase() === data.ultimate_controlling_company.jurisdiction_code && (
                                                                                        <p>
                                                                                            {country.label}
                                                                                        </p>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    : "N/A"}
                                                                </td>
                                                                :
                                                                <td className="font-semibold text-xs text-right">
                                                                    N/A
                                                                </td>
                                                            }
                                                            {/* <td className="font-semibold text-xs text-right">
                                                                {data.ultimate_controlling_company ? 
                                                                    <>
                                                                        {data.ultimate_controlling_company.jurisdiction_code ? 
                                                                            <>
                                                                                {options.forEach((ele) => (
                                                                                    <>
                                                                                        {(ele.value.toLowerCase() === data.ultimate_controlling_company.jurisdiction_code) && 
                                                                                            <p>{ele.label}</p>
                                                                                        }
                                                                                    </>
                                                                                ))}
                                                                            </>
                                                                            : 
                                                                            "N/A"
                                                                        } 
                                                                    </>
                                                                    : 
                                                                    "N/A"
                                                                }
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td className="font-light text-xs">
                                                                Source Url
                                                            </td>
                                                            <td className="font-semibold text-xs text-right">
                                                                <a href={data.ultimate_controlling_company ? (data.ultimate_controlling_company.opencorporates_url ? data.ultimate_controlling_company.opencorporates_url : "#") : "#"} target="_blank" rel="noreferrer">
                                                                    {data.ultimate_controlling_company ? (data.ultimate_controlling_company.opencorporates_url ? data.ultimate_controlling_company.opencorporates_url : "N/A") : "N/A"}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                </div>

                {data.officers.length > 0 && 
                    <div id="officerRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                        <div className="block w-full overflow-x-auto pb-6">
                            <div
                                className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                                onClick={() => setOfficerShow(!officerShow)}
                            >
                                <div className="flex">
                                    <div
                                        className="px-4 mr-4 cursor-pointer"
                                        style={{ paddingLeft: "0" }}
                                    >
                                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                                            Officers' Details
                                        </h4>
                                    </div>
                                </div>
                                <div className="flex px-4 py-2 cursor-pointer">
                                    {!officerShow && (
                                        <img
                                        src={dropDownIcon}
                                        alt="down icon"
                                        className="mr-3"
                                        style={{ width: "15px", height: "10px" }}
                                        />
                                    )}
                                    {officerShow && (
                                        <img
                                        src={dropDownIcon}
                                        alt="down icon"
                                        className="mr-3 dropDownIconRotated"
                                        style={{ width: "15px", height: "10px" }}
                                        />
                                    )}
                                </div>
                            </div>
                            {officerShow && (
                                <div className="flex justify-between flex-wrap w-full px-12">
                                    {data.officers.length > 0 && 
                                        <>
                                            {data.officers.map((ele, ind) => (
                                                <>
                                                    <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                                                        <div className="flex flex-col idType">
                                                            <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                                                <h4 className="font-bold text-sm mb-2 flex flex-row">
                                                                    <p className="w-full">{ele.officer.name ? ele.officer.name : "N/A"}</p>
                                                                </h4>
                                                                <table className="w-full table-fixed">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="font-light text-xs">
                                                                                Position
                                                                            </td>
                                                                            <td className="font-semibold text-xs text-right">
                                                                                {ele.officer.position ? ele.officer.position : "N/A"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-light text-xs">
                                                                                Nationality
                                                                            </td>
                                                                            <td className="font-semibold text-xs text-right">
                                                                                {ele.officer.nationality ? ele.officer.nationality : "N/A"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-light text-xs">
                                                                                Address
                                                                            </td>
                                                                            <td className="font-semibold text-xs text-right">
                                                                                {ele.officer.address ? ele.officer.address : "N/A"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-light text-xs">
                                                                                Status
                                                                            </td>
                                                                            <td className="font-semibold text-xs text-right">
                                                                                {ele.officer.inactive ? (ele.officer.inactive == true ? "Inactive" : "Active") : "N/A"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-light text-xs">
                                                                                Start Date
                                                                            </td>
                                                                            <td className="font-semibold text-xs text-right">
                                                                                {ele.officer.start_date ? ele.officer.start_date : "N/A"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-light text-xs">
                                                                                End Date
                                                                            </td>
                                                                            <td className="font-semibold text-xs text-right">
                                                                                {ele.officer.end_date ? ele.officer.end_date : "N/A"}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                }

                {data.filings.length > 0 && 
                    <div id="fillingsRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                        <div className="block w-full overflow-x-auto pb-6">
                            <div
                                className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                                onClick={() => setFillingsShow(!fillingsShow)}
                            >
                                <div className="flex">
                                    <div
                                        className="px-4 mr-4 cursor-pointer"
                                        style={{ paddingLeft: "0" }}
                                    >
                                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                                            Fillings
                                        </h4>
                                    </div>
                                </div>
                                <div className="flex px-4 py-2 cursor-pointer">
                                    {!fillingsShow && (
                                        <img
                                        src={dropDownIcon}
                                        alt="down icon"
                                        className="mr-3"
                                        style={{ width: "15px", height: "10px" }}
                                        />
                                    )}
                                    {fillingsShow && (
                                        <img
                                        src={dropDownIcon}
                                        alt="down icon"
                                        className="mr-3 dropDownIconRotated"
                                        style={{ width: "15px", height: "10px" }}
                                        />
                                    )}
                                </div>
                            </div>
                            {fillingsShow && (
                                <div className="flex justify-between flex-wrap w-full px-12">
                                    <Table 
                                        finalData={data.filings}
                                        columns={columns}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                }

                {data.industry_codes.length > 0 &&
                    <div id="industryRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                        <div className="block w-full overflow-x-auto pb-6">
                            <div
                                className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                                onClick={() => setIndustryShow(!industryShow)}
                            >
                                <div className="flex">
                                    <div
                                        className="px-4 mr-4 cursor-pointer"
                                        style={{ paddingLeft: "0" }}
                                    >
                                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                                            Industry Codes
                                        </h4>
                                    </div>
                                </div>
                                <div className="flex px-4 py-2 cursor-pointer">
                                    {!industryShow && (
                                        <img
                                        src={dropDownIcon}
                                        alt="down icon"
                                        className="mr-3"
                                        style={{ width: "15px", height: "10px" }}
                                        />
                                    )}
                                    {industryShow && (
                                        <img
                                        src={dropDownIcon}
                                        alt="down icon"
                                        className="mr-3 dropDownIconRotated"
                                        style={{ width: "15px", height: "10px" }}
                                        />
                                    )}
                                </div>
                            </div>
                            {industryShow && (
                                <div className="flex justify-between flex-wrap w-full px-12">
                                    {data.industry_codes.length > 0 && 
                                        <>
                                            {data.industry_codes.map((ele, ind) => (
                                                <>
                                                    <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                                                        <div className="flex flex-col idType">
                                                            <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                                                <h4 className="font-bold text-sm mb-2 flex flex-row">
                                                                    <p className="w-full">{ele.industry_code.code_scheme_name ? ele.industry_code.code_scheme_name : "N/A"}</p>
                                                                </h4>
                                                                <table className="w-full table-fixed">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="font-light text-xs">
                                                                                Description
                                                                            </td>
                                                                            <td className="font-semibold text-xs text-right">
                                                                                {ele.industry_code.description ? ele.industry_code.description : "N/A"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-light text-xs">
                                                                                Code
                                                                            </td>
                                                                            <td className="font-semibold text-xs text-right">
                                                                                {ele.industry_code.code ? ele.industry_code.code : "N/A"}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                }

                {data.source != null &&
                    <div id="sourceRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                        <div className="block w-full overflow-x-auto pb-6">
                            <div
                                className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                                onClick={() => setSourceShow(!sourceShow)}
                            >
                                <div className="flex">
                                    <div
                                        className="px-4 mr-4 cursor-pointer"
                                        style={{ paddingLeft: "0" }}
                                    >
                                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                                            Data Source(s)
                                        </h4>
                                    </div>
                                </div>
                                <div className="flex px-4 py-2 cursor-pointer">
                                    {!sourceShow && (
                                        <img
                                        src={dropDownIcon}
                                        alt="down icon"
                                        className="mr-3"
                                        style={{ width: "15px", height: "10px" }}
                                        />
                                    )}
                                    {sourceShow && (
                                        <img
                                        src={dropDownIcon}
                                        alt="down icon"
                                        className="mr-3 dropDownIconRotated"
                                        style={{ width: "15px", height: "10px" }}
                                        />
                                    )}
                                </div>
                            </div>
                            {sourceShow && (
                                <div className="flex justify-between flex-wrap w-full px-12">
                                    {data.source != null && 
                                        <>
                                            <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                                                <div className="flex flex-col idType">
                                                    <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                                        <table className="w-full table-fixed">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="font-light text-xs">
                                                                        Publisher
                                                                    </td>
                                                                    <td className="font-semibold text-xs text-right">
                                                                        {data.source.publisher ? data.source.publisher : "N/A"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="font-light text-xs">
                                                                        URL
                                                                    </td>
                                                                    <td className="font-semibold text-xs text-right">
                                                                        <a href={data.source.url ? data.source.url : "#"} target="_blank" rel="noreferrer">
                                                                            {data.source.url ? data.source.url : "N/A"}
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="font-light text-xs">
                                                                        Retrieved at
                                                                    </td>
                                                                    <td className="font-semibold text-xs text-right">
                                                                        {data.source.retrieved_at ? Moment(data.source.retrieved_at).format("MM-DD-YYYY hh:mm a") : "N/A"}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
          </div>
        </div>
      )}

      {!dataLoaded && (
        <>
          <ReactLoading
            className="margin-auto mt-3 mb-3 absolute"
            type={"bars"}
            style={{
              zIndex: "100000",
              top: "30%",
              left: "39%",
              width: "8%",
              height: "8%",
              fill: "#ee416f",
            }}
          />
          <div
            id="overlay"
            className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer"
            style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}
          ></div>
        </>
      )}
    </>
  );
}