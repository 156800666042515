import React, { useState } from "react";
import { Link } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from "react-select";
import "react-circular-progressbar/dist/styles.css";
import { getOpenSanctionRecordSearch, getOpenSanctionRecordSearchCsv } from "../../services/AMLInquire";
import { sanctionSearch, sanctionSearchCSV } from "../../services/aml/Screening";
import countryList from "react-select-country-list";
import Moment from 'moment';
import moment from 'moment-timezone';
import ReactLoading from "react-loading";
import address from "../../services/Address";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import Pusher from 'pusher-js';

import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import "react-circular-progressbar/dist/styles.css";

import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <div className="selectBox">
          <label className="inline-flex items-center cursor-pointer text-sm">
            <input
              ref={resolvedRef}
              {...rest}
              id="customCheckLogin"
              type="checkbox"
              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-4 h-4 ease-linear transition-all duration-150 selectBoxStyle"
            />
          </label>
        </div>
      </>
    );
  }
);

const IndeterminateCheckboxHeader = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          id="test"
          ref={resolvedRef}
          {...rest}
          style={{ display: "none" }}
        />
        <label for="test" className="cursor-pointer">
          Select All
        </label>
      </>
    );
  }
);

export default function AMLScreeningTable(props) {
  var filecheck = props.filecheck;
  var data = props.data;
  var checks = props.checks;
  var individualscreening = props.individualscreening;
  var batchscreening = props.batchscreening;
  const countryoptions = React.useMemo(() => countryList().getData(), []);
  countryoptions.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  const [tableData, setTableData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(false);
  const [showDataTable, setShowDataTable] = React.useState(false);
  const [taskProgress, setTaskProgress] = React.useState(0);
  const [taskLogs, setTaskLogs] = React.useState([]);
  const [channelId, setChannelId] = React.useState(null);
  const [csvCalled, setCsvCalled] = React.useState(false);

  const sanctionArray = ['sanction'];
  const pepArray = ['debarment', 'fin.bank', 'role.oligarch', 'role.pep', 'role.rca'];
  const adverseArray = ['crime', 'crime.boss', 'crime.fin', 'crime.terror', 'crime.theft', 'crime.war'];

  const [searchWord, setSearchWord] = useState("");

  const handleFilter = () => {
    const newFilter = tableData.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });
    setFilteredData(newFilter);
  };

  const handleCross = () => {
    setFilteredData(tableData);
    setSearchWord("");
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (tableProps) => (
        <Link
          to={{
            pathname: "/aml/amlscreeningdetail/"+tableProps.row.original.entity_id+"/"+individualscreening+"/"+batchscreening,
            // state: {
            //   entity_id: tableProps.row.original.entity_id,
            //   individualscreening: individualscreening,
            //   batchscreening: batchscreening
            // }
          }}
        >
          <span className={"text-xs font-bold text-black-600 block"}>
            <abbr title={tableProps.row.original.name}>
              {tableProps.row.original.name}
            </abbr>
          </span>
          <small className="text-xs">{tableProps.row.original.entity_id}</small>
        </Link>
      ),
    },
    {
      Header: "Country",
      accessor: "nationality",
      Cell: (tableProps) => (
        <p className="text-xs font-normal">
          {(tableProps.row.original.nationality) ? 
            <>
              {countryoptions.map((item, key) => (
                <>
                  {item.value === tableProps.row.original.nationality.toUpperCase() && (
                    <abbr title={item.label}>
                      {item.label}
                    </abbr>
                  )}
                </>
              ))}
            </>
            :
            <>
              <abbr title={"N/A"}>
                {"N/A"}
              </abbr>
            </>
          }
        </p>
      ),
    },
    {
      Header: "Date of Birth",
      accessor: "birthDate",
      Cell: (tableProps) => (
        <p className="text-xs font-normal">
          <abbr title={tableProps.row.original.birthDate ? tableProps.row.original.birthDate : "N/A"}>
            {tableProps.row.original.birthDate ? tableProps.row.original.birthDate : "N/A"}
          </abbr>
        </p>
      ),
    },
    {
      Header: "Category",
      accessor: "topics",
      Cell: (tableProps) => (
        <div className="statusGrid flex flex-col max-w-120-px mx-auto">
          {pepArray.includes(tableProps.row.original.topics) && (
            <button className="w-full amlbuttonsSmall px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen mb-2 text-xsx">
              PEP
            </button>
          )}
          {sanctionArray.includes(tableProps.row.original.topics) && (
            <button className="w-full amlbuttonsSmall px-4 rounded-lg font-semibold text-white-400 uppercase buttonRed mb-2 text-xs">
              SAN
            </button>
          )}
          {adverseArray.includes(tableProps.row.original.topics) && (
            <button className="w-full amlbuttonsSmall px-4 rounded-lg font-semibold text-white-400 uppercase buttonYellow text-xs">
              AM
            </button>
          )}
        </div>
      ),
    },
    {
      Header: "Match Score",
      accessor: "",
      Cell: (tableProps) => (
        <div className="relative pt-1 max-w-120-px mx-auto">
          <div className="overflow-hidden h-5 mb-4 text-xs flex rounded greyBg">
            <div
              style={{ width: "30%" }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bgYellow"
            ></div>
          </div>
          <p className="text-right font-bold text-xs">30%</p>
        </div>
      ),
    },
    {
      Header: "Potential Match",
      accessor: "",
      Cell: (tableProps) => (
        <p className="text-xs font-normal">Potential Match</p>
      ),
    },
  ];

  const color = "light";

  const searchby = [
    { label: "Search By", value: 1 },
    { label: "Search By Name", value: 2 },
  ];

  const risklevel = [{ label: "Risk Level", value: 1 }];

  const countrywise = [{ label: "Country Wise", value: 1 }];

  const status = [
    { label: "Status", value: 1 },
    { label: "Active", value: 2 },
    { label: "Inactive", value: 3 },
  ];

  const defaultValueSearchBy = searchby[0];
  const defaultValueRiskLevel = risklevel[0];
  const defaultValueCountryWise = countrywise[0];
  const defaultValueStatus = status[0];

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      padding: 10,
      fontSize: "12px",
    }),
  };

  const [totalCount, setTotalCount] = React.useState(0);

  React.useEffect(() => {
    // console.log("first pusher called");
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true
    });
    const channelmain = pusher.subscribe('csv-process');

    channelmain.bind('process-id', data => {
      // console.log("message: ", data);
      setChannelId(data.message);
      // pusher.unsubscribe('csv-process');
    });

    if (channelId) {
      pusher.unsubscribe('csv-process');
    }
  }, [csvCalled]);

  React.useEffect(() => {
    if (channelId) {
      // console.log("channel id found");
      setShowProgress(true);
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true
      });
      
      const channelName = 'csv-process-'+channelId;
      const channelindiv = pusher.subscribe(channelName);
      // progress
      channelindiv.bind('csv-progress', data => {
        // console.log("message: ", data);
        setTaskProgress(parseInt(data.message));
        if (parseInt(data.message) === 100) {
          pusher.unsubscribe(channelName);
        }
      });
      // log message row processed
      channelindiv.bind('row-process', data => {
        // console.log("message: ", data);
        setTaskLogs(current => [data.message, ...current]);
      });
      // log message row skipped
      channelindiv.bind('row-skipped', data => {
        // console.log("message: ", data);
        setTaskLogs(current => [data.message, ...current]);
      });
    }
  }, [channelId]);

  React.useEffect(() => {
    if (filecheck) {
      // setCsvCalled(true);
      // setShowProgress(true);
      let datatosend = {
        file: props.file[0], 
        checks: JSON.stringify(checks)
      };
      sanctionSearchCSV(datatosend)
        .then((response) => {
          // console.log("response: ", response);
          if (response.status === 200) {
            if (response.data.length > 0) {
              let dataArray = [];
              response.data.forEach(ele => {
                ele.forEach(intEle => {
                  dataArray.push(intEle);
                })
              })
              setTotalCount(dataArray.length);
              setTableData(dataArray);
              setFilteredData(dataArray);
            }

            setDataLoaded(true);
            setShowProgress(true);
            setShowDataTable(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      let datatosend = {
        name: data.name,
        checks: data.checks,
        country: data.country,
        identifier: data.identifier,
        birthYear: data.birthYear,
        is_case: true
      };
      sanctionSearch(datatosend)
        .then((response) => {
          // console.log("response: ", response);
          if (response.data.success) {
            setTotalCount(response.data.results.length);
            setTableData(response.data.results);
            setFilteredData(response.data.results);
          }
          setDataLoaded(true);
          setShowDataTable(true);
          setShowProgress(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, []);

  return (
    <>
      {!showProgress &&
        <div>
          <ReactLoading
            className="margin-auto mt-3 mb-3"
            type={"bars"}
            color={"#ee416f"}
            height={"50px"}
            width={"50px"}
          />
        </div>
      }

      {/* {!filecheck ? 
        <>
          {!dataLoaded &&
            <div>
              <ReactLoading
                className="margin-auto mt-3 mb-3"
                type={"bars"}
                color={"#ee416f"}
                height={"50px"}
                width={"50px"}
              />
            </div>
          }
        </>
        :
        <>
          {!dataLoaded && !showProgress &&
            <div>
              <ReactLoading
                className="margin-auto mt-3 mb-3"
                type={"bars"}
                color={"#ee416f"}
                height={"50px"}
                width={"50px"}
              />
            </div>
          }
        </>
      } */}
      
      {/* {showProgress && filecheck && 
        <div className="w-full flex flex-row">
          <div className="lg:w-12/12 h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
              <div className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full">
                <div className="flex">
                  <div
                    className="px-4 mr-4"
                    style={{ paddingLeft: "0" }}
                  >
                    <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                      Query in Progress
                    </h4>
                    <p className="text-sm">
                      Your batch screening inquiry is in progress, you may check progress below!
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative px-12 py-6">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightPink-600 bg-lightPink-200">
                      Task in progress
                    </span>
                  </div>
                  <div className="text-right">
                    <span className="text-xs font-semibold inline-block text-lightPink-600">
                      {taskProgress}%
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-lightPink-200">
                  <div style={{ width: `${taskProgress}%`, backgroundColor: "#ee416f" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></div>
                </div>
              </div>
              {taskLogs.length > 0 && 
                <div className="relative flex flex-col mx-12 px-12 py-6 bg-black" style={{ maxHeight: "200px", overflowY: "scroll", flexDirection: "column-reverse" }}>
                  {taskLogs.map((ele, ind) => (
                    <code data-index={ind} className="text-white">
                      {ele} <br /> <br />
                    </code>
                  ))}
                </div>
              }
            </div>
          </div>
        </div>
      } */}

      {showDataTable && 
        <div className="casemanagement flex flex-wrap">
          {/* removed px-4 from below */}
          <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
            <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
              <div
                className={
                  "relative flex flex-col min-w-0 break-words w-full mb-6 pb-6 shadow-lg rounded-lg " +
                  (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                }
              >
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full max-w-full flex-grow flex-1">
                      <Form
                        class="filtersTable"
                        // onSubmit={(e) => handleLogin(e)}
                        // ref={c => {form = c;}}
                      >
                        <div className="flex justify-between items-start justify-start flex-wrap">
                          <div className="flex flex-col amlSearchDiv">
                            <div className="flex justify-between items-center justify-center flex-wrap">
                              <div className="searchInputTables flex mr-3 items-center">
                                <div className="fieldSearch relative w-full mb-3">
                                  <label className="label block font-semibold text-black-600 text-xs mb-2">
                                    Search
                                  </label>
                                  <Input
                                    type="text"
                                    value={searchWord}
                                    style={{ paddingRight: "40px",height:"35px" }}
                                    placeholder={"Search by Name"}
                                    onChange={(e) =>
                                      setSearchWord(e.target.value)
                                    }
                                    className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  />
                                </div>
                                <div
                                  className="label block font-semibold text-black-600 text-sm mb-2 cursor-pointer"
                                  style={{ marginLeft: "-30px", zIndex: "10" }}
                                  onClick={handleCross}
                                >
                                  <img src={closeButtonImg} style={{width:"80%"}} alt="Delete Person" />
                                </div>
                              </div>
                              {/* <div className="">
                                <div className="field dropdown relative w-full mb-3">
                                  <Select
                                    styles={customStylesSelect}
                                    options={searchby}
                                    isSearchable={false}
                                    defaultValue={defaultValueSearchBy}
                                  />
                                </div>
                              </div> */}
                            </div>
                            <div className="searchButton ">
                              <button
                                type="button"
                                className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs"
                                onClick={handleFilter}
                              >
                                Search
                              </button>
                            </div>

                          </div>
                          <div className="flex items-start flex-wrap">
                            <h1 className="text-base font-bold items-center mr-3 mt-2">
                              Filter
                            </h1>
                            <div className="mr-3">
                              <div className="field dropdown relative w-full mb-3">
                                <Select
                                  styles={customStylesSelect}
                                  options={risklevel}
                                  isSearchable={false}
                                  defaultValue={defaultValueRiskLevel}
                                />
                              </div>
                            </div>
                            <div className="mr-3">
                              <div className="field dropdown relative w-full mb-3">
                                <Select
                                  styles={customStylesSelect}
                                  options={status}
                                  isSearchable={false}
                                  defaultValue={defaultValueStatus}
                                />
                              </div>
                            </div>
                            <div className="mr-3">
                              <div className="field dropdown relative w-full mb-3">
                                <Select
                                  styles={customStylesSelect}
                                  options={countrywise}
                                  isSearchable={false}
                                  defaultValue={defaultValueCountryWise}
                                />
                              </div>
                            </div>

                            {/* <div className="flex px-4 py-2 cursor-pointer">
                              <img
                                src={ExportIcon}
                                alt="export icon"
                                className="mr-3"
                                style={{ width: "15px", height: "15px" }}
                              />
                              <h4
                                className="font-semibold text-xs"
                                style={{ color: "#ee416f" }}
                              >
                                Export CSV
                              </h4>
                            </div> */}
                          </div>
                        </div>
                        <div className="text-sm mt-4 matchesfoundpill">
                          <b>{totalCount}</b> matches found
                        </div>
                        <div className="text-sm mt-4 timedatepill">
                          Searched on: <b>{moment.tz(Moment(), "America/New_York").format('hh:mm A - MM/DD/YYYY')} (ET)</b>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>

                {!dataLoaded && (
                  <div>
                    <ReactLoading
                      className="margin-auto mt-3 mb-3"
                      type={"bars"}
                      color={"#ee416f"}
                      height={"50px"}
                      width={"50px"}
                    />
                  </div>
                )}
                {dataLoaded && (
                  <DataTableComponent
                    data={filteredData}
                    columns={columns}
                    loaded={dataLoaded}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

function DataTableComponent({ columns, data, loaded }) {
  const [pageNum, setPageNum] = React.useState(1);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckboxHeader
                {...getToggleAllPageRowsSelectedProps()}
              />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  return (
    <>
      <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
        <div className="flex flex-row items-center">
          <button
            className="mr-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            style={{outline:"none"}}
          >
            <img
              src={leftPag}
              alt="previous icon"
              style={{ width: "20px", height: "20px" }}
            />
          </button>
          <span className="mr-2 text-xs">
            Page
            <strong className="ml-2">
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage} style={{outline:"none"}}>
            <img
              src={rightPag}
              alt="previous icon"
              style={{ width: "20px", height: "20px" }}
            />
          </button>
        </div>
        <div className="flex flex-row items-center cursor-pointer">
          <p className="font-bold text-xs mr-3">Go to page</p>
          <input
            type="number"
            defaultValue={pageIndex + 1}
            value={pageNum}
            onChange={(e) => {
              const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
              const page = value ? Number(value) - 1 : 0;
              gotoPage(page);
              setPageNum(value);
            }}
            className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
          />
        </div>
        <div className="flex flex-row items-center cursor-pointer">
          <p className="font-bold text-xs mr-3">Records per page</p>
          <input
            type="number"
            min={1}
            value={pageSize}
            onChange={(e) => {
              if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                setPageSize(Number(1));
              } else {
                setPageSize(Number(e.target.value));
              }
            }}
            className="cursor-pointer text-xs border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
          />
          <button
            // onClick={() => }
            className="px-2 py-2 bg-white rounded-lg"
            style={{outline:"none"}}
            onClick={() => window.location.reload()}
          >
            <img
              src={refresh}
              alt="refresh"
              style={{ width: "13px", height: "13px" }}
            />
          </button>
        </div>
      </div>
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table
          {...getTableProps()}
          className="items-center w-full bg-transparent border-collapse"
        >
          <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                    }
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {loaded && (
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="cursor-pointer">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                          style={{ maxWidth: "250px", overflow: "hidden" }}
                        >
                          {cell.render("Cell") !== null
                            ? cell.render("Cell")
                            : "N/A"}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}
