import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
// import FooterAdmin from "components/Footers/FooterAdmin.js";

// asset
// import BackgroundImg from "../assets/img/dashboardBg.png";
import BackgroundImg from "../assets/img/bglo.svg";
import sidebarIcon from "../assets/img/icons/sidebarIcon.png";

// views
import AMLScreening from "views/admin/AMLScreening.js";
import AMLScreeningDetail from "views/admin/AMLScreeningDetail";
import AMLScreeningInquire from "views/admin/AMLScreeningInquire";
import AMLScreeningTable from "views/admin/AMLScreeningTable";
import AMLScreeningTableLogs from "views/admin/AMLScreeningTableLogs";
import Lists from "views/admin/Lists";
import CaseManagementAml from "views/admin/CaseManagementAml";
import CaseManagementDetailAml from "views/admin/CaseManagementDetailAml";
import AlertsAml from "views/admin/AlertsAml";

export default function AML() {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} />
      {sidebarOpen ? (
        <button
          className=" sidebarOpenButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen && <img src={sidebarIcon} />}
        </button>
      ) : (
        <button
          className=" sidebarCloseButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {!sidebarOpen && <img src={sidebarIcon} className="sidebarRotated" />}
        </button>
      )}
      <div 
        className={"relative bg-no-repeat bg-cover " + (sidebarOpen ? "md:ml-52" : "md:ml-18")}
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundPosition: 'center', 
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="md:px-4 mx-auto w-full -m-24">
          <Switch>
            <Route path="/aml/amlscreeninginquire" exact component={AMLScreeningInquire} />
            {/* <Route path="/aml/amlscreeninglist/:data" exact component={AMLScreeningTable} /> */}
            <Route path="/aml/amlscreeninglist" exact component={AMLScreeningTable} />
            <Route path="/aml/amlscreeninglistlogs" exact component={AMLScreeningTableLogs} />
            <Route path="/aml/amlscreening" exact component={AMLScreening} />
            <Route path="/aml/amlscreeningdetail/:entity_id/:individualscreening/:batchscreening" exact component={AMLScreeningDetail} />
            <Route path="/aml/lists" exact component={Lists} />
            {/* casemanagement aml */}
            <Route path="/aml/casemanagement" exact component={CaseManagementAml} />
            <Route path="/aml/casemanagementdetail" exact component={CaseManagementDetailAml} />
            <Route path="/aml/cases" exact component={AlertsAml} />
            
            <Redirect from="/aml" to="/aml/amlscreeninginquire" />
          </Switch>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
