import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
// import FooterAdmin from "components/Footers/FooterAdmin.js";

// asset
// import BackgroundImg from "../assets/img/dashboardBg.png";
import BackgroundImg from "../assets/img/bglo.svg";
import sidebarIcon from "../assets/img/icons/sidebarIcon.png";

// views
import Developers from "views/admin/Developers";

export default function Developer() {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} />
      {sidebarOpen ? (
        <button
          className=" sidebarOpenButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen && <img src={sidebarIcon} />}
        </button>
      ) : (
        <button
          className=" sidebarCloseButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {!sidebarOpen && <img src={sidebarIcon} className="sidebarRotated" />}
        </button>
      )}
      <div 
        className={"relative bg-no-repeat bg-cover " + (sidebarOpen ? "md:ml-52" : "md:ml-18")}
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundPosition: 'center', 
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="md:px-4 mx-auto w-full -m-24">
          <Switch>
            <Route path="/dev/developers" exact component={Developers} />
            <Redirect from="/dev" to="/dev/developers" />
          </Switch>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
