import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { KycMobContext } from "../../layouts/KYCMob";
import { savelanguage } from "services/kyc/KycScreens";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import platform from "platform";
import {isMobile} from 'react-device-detect';

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen1info() {
  const { data } = useContext(KycMobContext);
  // console.log(data);

  const [disable, setDisable] = React.useState(false);
  const url = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
  const [urlId, setUrlId] = React.useState(url);
  const history = useHistory();
  const [language, setLanguage] = useState(null);
  const [deviceType, setDeviceType] = React.useState(platform.manufacturer);
  const [deviceOs, setDeviceOs] = React.useState(platform.os.family);
  const [browser, setBrowser] = React.useState(platform.name);
  const [latitude, setLatitude] = React.useState(null);
  const [longitude, setLongitude] = React.useState(null);
  var fpPromise = null;
  var result_fingerprint = null;
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen1info) {
        const redirecturl = "/kybkyc/a/"+urlId;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kybkyc/a/"+urlId;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  function showPosition(position) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }

  React.useEffect(()=>{
    getLocation();
  },[]);

  const handleSubmit = () => {
    setDisable(true);
    // fpPromise = FingerprintJS.load({ apiKey: 'cAI4XJPsx0MaxCh5XnTK' });
    // ;(async () => {
    //   // Get the visitor identifier when you need it.
    //   const fp = await fpPromise;
    //   result_fingerprint = await fp.get({extendedResult: true});
      
    //   let visitor_id = result_fingerprint.visitorId;
    //   let os = result_fingerprint.os;
    //   let os_version = result_fingerprint.osVersion;
    //   let first_seen = result_fingerprint.firstSeenAt.global;
    //   let last_seen = result_fingerprint.lastSeenAt.global;
    //   let ip = result_fingerprint.ip;
    //   let browser_name = result_fingerprint.browserName;
    //   let browser_version = result_fingerprint.browserVersion;
    //   let incognito = result_fingerprint.incognito;

      let formdata = {
        inq_id: urlId,
        language: null,
        browser_name: null,
        browser_version: null,
        os: null,
        os_version: null,
        visitor_id: null,
        first_seen: null,
        last_seen: null,
        ip: null,
        incognito: null,
        latitude: latitude,
        longitude: longitude
      };
  
      savelanguage(formdata).then((response) => {
        if (response.data.success) {
          if (isMobile) {
            let temp = screenStates;
            temp.Screen4 = true;
            storage.setItem("screenmeta", JSON.stringify(temp));
            const redirecturl = "/kybkyc/d/"+urlId;
            history.push(redirecturl);
            window.location.reload();
          } else {
            let temp = screenStates;
            temp.Screen1i = true;
            storage.setItem("screenmeta", JSON.stringify(temp));
            const redirecturl = "/kybkyc/c/"+urlId;
            history.push(redirecturl);
            window.location.reload();
          }
        }
      }).catch((error) => {
        console.log("error: ", error)
      });
    // })();
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div
        className="screenOne screenInfoOne h-full flex flex-col"
        style={{ fontFamily: data ? data.font_family : "Gilroy" }}
      >
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kybkyc/a/" + urlId}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{
                    width: "10%",
                    backgroundColor: data ? data.progressbar_color : "#ee416f",
                  }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div class="mainContent screen1info">
          <div class="mainText">
            <h2>Prepare for your verification</h2>
          </div>
          <div className="screen1Info-textdiv overflow-y-auto max-h-40-vh flex flex-col px-4 mb-6 mt-4">
            <div className="textdiv mb-4">
              <p className="mb-2 font-bold text-sm">Identity document</p>
              <p className="font-normal text-xs" style={{ color: "#a8a8a8" }}>Provide your identity document (Passport, Driver's license, ID card) for visual scanning. Ensure that it is not expired or physically damaged.</p>
            </div>
            <div className="textdiv mb-4">
              <p className="mb-2 font-bold text-sm">Selfie</p>
              <p className="font-normal text-xs" style={{ color: "#a8a8a8" }}>Go through our liveness-detection check. This involves turning your head in front of a camera for a few minutes.</p>
            </div>
          </div>
          <div class="supportingText">
            <p class="supportingTextPara text-xs">
              By clicking “Continue”, you acknowledge  to the Regly Technologies <a href="https://www.regly.ai/privacy-policy" target="__blank">Privacy Policy</a>.
            </p>
          </div>
        </div>

        <div class="buttonAction">
          {/* <Link to={"/kybkyc/c/" + urlId}> */}
          <button
            style={{ backgroundColor: data ? data.button_color : "#ee416f" }}
            class="action"
            onClick={handleSubmit}
            disabled={disable}
          >
            Continue
          </button>
          {/* </Link> */}
        </div>

        <div class="footer">
          <img class="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
