import { getuniqueid } from "services/kyc/KycScreens";
import ReactGA from "react-ga4";
import React, { useState,useContext } from "react";
import {KycMobContext} from "../../layouts/KYCMob"
import { Link, useHistory } from "react-router-dom";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import map from " ../../assets/img/kycmobile/screenStartImage.png";
import ReglyLogo from " ../../assets/img/hedge-logo-resp.png";

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

export default function KycStart() {
  const {data} = useContext(KycMobContext);
  const history = useHistory();
  
  const alreadyVerifiied = () => {
    const redirecturl = "/verify/exists/a";
    history.push(redirecturl);
    window.location.reload(false);
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenNineteen screenVerifyStart h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <button>
              <img src={backButtonImg} alt="backArrow" className="" />
            </button>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "0%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" style={{ marginTop: "0" }}>
          <div className="mapImage">
            <img className="map mx-auto" src={ReglyLogo} alt="" style={{ width: "10%" }} />
          </div>
          <div className="mainText">
            <h1>Welcome to Regly KYC</h1>
          </div>
          <div className="mapImage">
            <img className="map mx-auto" src={map} alt="" style={{ width: "80%" }} />
          </div>
          <div className="mainText mt-4">
            <p className="justify-center">Please proceed below to start your verification process.</p>
          </div>

          {/* <div className="uploadButtons mt-10">
            <div className="openCameraButton" onClick={newVerification}>
              <p>New verification</p>
            </div>
            <div className="uploadFileButton" onClick={alreadyVerifiied}>
              <p>Already verified on HyperCycle</p>
            </div>
          </div> */}
        </div>

        <div className="buttonAction">
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#ee416f",
            }}
            onClick={alreadyVerifiied}
            // disabled={disable}
          >
            Next
          </button>
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
