import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import {KycMobContext} from "../../layouts/KYCMob";
import { isMobile } from 'react-device-detect';
import { saveevent } from "../../services/kyc/KycScreens";
import { verifybusiness } from "services/kyb/KybBankScreens";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { addDays } from "date-fns";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen3docs() {
  const [disable, setDisable] = React.useState(false);
  const {data, height} = useContext(KycMobContext);
  const history = useHistory();
  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [message, setMessage] = useState("");
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen3docs) {
        const redirecturl = "/kybverif/e/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kybverif/e/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);
  
  const [formdata, setFormdata] = useState({
    legal_name: "",
    email: "",
    job_title: "",
    date_of_birth: "",
    home_address: "",
    phone_number: "",
    SSN: "",
    inq_id: url,
  });

  const handleInputChange = (event) => {
    setMessage("");
    var name = event.target.name;
    var value = event.target.value;

    if (name === "SSN") {
      value = value.slice(0, 4);
    }

    setFormdata((values) => ({ ...values, [name]: value }));
  };
  
  const handleInputChangeDate = (date) => {
    setMessage("");
    const name = "date_of_birth";
    const value = date;
    setFormdata((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = () => {
    setMessage("");

    if (formdata.legal_name === "") {
      setMessage("Legal name is required!");
      return;
    }
    if (formdata.email === "") {
      setMessage("Email is required!");
      return;
    }
    if (formdata.job_title === "") {
      setMessage("Job title is required!");
      return;
    }
    if (formdata.date_of_birth === "") {
      setMessage("Date of birth is required!");
      return;
    }
    if (formdata.home_address === "") {
      setMessage("Home address is required!");
      return;
    }
    if (formdata.phone_number === "") {
      setMessage("Phone no is required!");
      return;
    }
    if (formdata.SSN === "") {
      setMessage("Social security number is required!");
      return;
    }

    let data_to_send = {
      legal_name: formdata.legal_name,
      email: formdata.email,
      job_title: formdata.job_title,
      date_of_birth: new Date(formdata.date_of_birth).toLocaleDateString(),
      home_address: formdata.home_address,
      phone_number: formdata.phone_number,
      SSN: formdata.SSN,
      inq_id: url,
    };

    setDisable(true);
    verifybusiness(data_to_send)
      .then((response) => {
        if (response.data.success) {
          // console.log("response: ", response);
          let formdata3 = {
            inq_id: url,
            event_desc: "Verify Business Information Saved",
            passed: 1,
          };
          saveevent(formdata3)
            .then((response) => {
              if (response.data.success) {
                let temp = screenStates;
                temp.Screen4 = true;
                storage.setItem("screenmeta", JSON.stringify(temp));
                const redirecturl = "/kybverif/g/" + url;
                history.push(redirecturl);
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log("error: ", error);
              setDisable(false);
            });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenThree screenThreeKyb screenThreeDocs h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kybverif/e/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "25%" ,backgroundColor:data ? data.progressbar_color:"#ee416f"}}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button  onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1 style={{ lineHeight: 1 }}>Verify you represent this business.</h1>
          </div>
          <Form className="informationSection mt-8">
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Legal Name</span>
                <Input 
                  type="text" 
                  name="legal_name" 
                  className="input firstnameInput" 
                  value={formdata.legal_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Email</span>
                <Input 
                  type="email" 
                  name="email" 
                  className="input firstnameInput" 
                  value={formdata.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="nameFields mb-3">
              <div className="dateField mr-1">
                <div className="inputfield dob">
                  <span className="label text-xs">Date of Birth</span>
                  <DatePicker 
                    selected={formdata.date_of_birth} 
                    maxDate={addDays(new Date(), 0)}
                    onChange={(date) => handleInputChangeDate(date)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="mm/dd/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
              <div className="firstNameField firstField">
                <div className="inputfield firstname">
                  <span className="label text-xs">Job Title</span>
                  <Input 
                    type="text" 
                    name="job_title" 
                    className="input firstnameInput" 
                    value={formdata.job_title}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Home Address</span>
                <Input 
                  type="text" 
                  name="home_address" 
                  className="input firstnameInput" 
                  value={formdata.home_address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Phone No</span>
                <Input 
                  type="text" 
                  name="phone_number" 
                  className="input firstnameInput" 
                  value={formdata.phone_number}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="lastNameField">
              <div className="inputfield lastname">
                <span className="label text-xs">Social Security Number (Last 4 digits)</span>
                <Input 
                  type="text" 
                  name="SSN" 
                  className="input lastnameInput" 
                  value={formdata.SSN}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-xs alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}
          </Form>
        </div>

        <div className="buttonAction">
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#ee416f",
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            Next
          </button>
        </div>

        <div className="footer" >
          <img className="footerImg" src={footerImg} alt="footer"/>
        </div>
      </div>
    </>
  );
}