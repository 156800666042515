import React, { useState, useContext,useEffect,useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Webcam from "react-webcam";
import ReactLoading from "react-loading";
import { uploadFile } from "react-s3";
import { checkblur } from "services/kyc/KycScreens";
import {KycMobContext} from "../../../layouts/KYCMob";
import { saveaddressverifications } from "../../../services/kyc/KycScreens";
import { saveevent, calculatechecks } from "services/kyc/KycScreens";
import { disablerevision } from "../../../services/kyc/KycScreens";

import backButtonImg from "../../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from "../../../assets/img/kycmobile/cross_white.png";
import footerImg from "../../../assets/img/kycmobile/footer_trademark.png";
import cameraFrameImg from "../../../assets/img/kycmobile/22_cam.png";
import cameraButton from "../../../assets/img/kycmobile/camera_button.png";
import black from "../../../assets/img/kycmobile/black.png";
import warning from "../../../assets/img/kycmobile/warning.png";
import loading from "../../../assets/img/kycmobile/loading.png";
import cycle from "../../../assets/img/icons/cycle.svg";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
};

export default function Screen22(props) {
  const {data} = useContext(KycMobContext)

  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const webcamRef = React.useRef(null);

  const [dataLoaded, setDataLoaded] = React.useState(true);
  const [errorApi, setErrorApi] = React.useState(false);
  const [errorBlur, setErrorBlur] = React.useState(false);
  const [start, setStart] = useState(false);
  const [imageCaptured, setImageCaptured] = useState(false);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [pleaseHold, setPleaseHold] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [showReview, setShowReview] = useState(false);
  const history = useHistory();
  let stateData = props.location.state;
  var fileType = stateData["fileType"];
  var captureMethod = stateData["captureMethod"];
  var country = stateData["country"];
  var city = stateData["city"];
  var postal = stateData["postal"];
  var address = stateData["address"];

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen22) {
        const redirecturl = "/kycreturning/addressverif/a/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kycreturning/addressverif/a/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  const MINUTE_MS = 200;
  // const ws = new WebSocket("wss://ai.hedgetech.ai/card/ws");

  React.useEffect(() => {
    setStart(true);
  }, []);

  const [facingMode, setFacingMode] = React.useState("user");

  const videoConstraints = {
    width: { min: 480 },
    height: { min: 720 },
    aspectRatio: 1.5,
    facingMode:facingMode
  };

  React.useEffect(() => {
    if (!fileType) {
      history.push("/kycreturning/addressverif/d/" + url);
      window.location.reload();
    }
  }, [fileType]);

  function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  }

  React.useEffect(() => {
    var mediaStream = null;
    if (!showReview) {
      if (start && !imageCaptured) {
        let timeout = setTimeout(() => {
          setPleaseHold(true);
          let timeout1 = setTimeout(() => {
            mediaStream = webcamRef.current.getScreenshot();
            setImageToUpload(mediaStream);
            setShowReview(true);
            setStart(false);
          }, 3000);
          return () => clearTimeout(timeout1);
        }, 5000);
        return () => clearTimeout(timeout);
        
        // ws.addEventListener("open", () => {
        //   const interval = setInterval(() => {
        //     var atob = require("atob");
        //     if (webcamRef.current) {
        //       mediaStream = webcamRef.current.getScreenshot();
        //       if (mediaStream) {
        //         ws.send(mediaStream);
        //       }
        //     }
        //   }, MINUTE_MS);
  
        //   return () => clearInterval(interval);
        // });
  
        // ws.onmessage = (message) => {
        //   let data = JSON.parse(message.data);
        //   let datamain = JSON.parse(data);
        //   setPleaseHold(datamain.msg);
  
        //   if (datamain.success) {
        //     setImageToUpload("data:image/png;base64, "+datamain.img);
        //     setShowReview(true);
        //     setStart(false);
        //     ws.close();
        //   }
        // };
      }
    }
  }, [start, showReview]);

  React.useEffect(() => {
    if (imageCaptured) {
      // ws.close();
      setStart(false);
      setDataLoaded(false);
      // console.log("image to upload: ", imageToUpload);
      
      const blob = dataURItoBlob(imageToUpload);
      let currentTimestamp = Date.now();
      const file = new File([blob], currentTimestamp, {
        type: blob.type,
        lastModified: new Date().getTime(),
      });

      setDisable(true);

      uploadFile(file, config)
      .then((data) => {
        if (data.key) {
          checkblur(data.key, url)
            .then((response) => {
              // console.log("respose checkblur: ", response);
              if (!response.data.response.success) {
                let data = {
                  fileType: fileType,
                  captureMethod: captureMethod,
                  doc: file,
                  country: country,
                  city: city,
                  postal: postal,
                  address: address,
                  inq_id: url,
                };
            
                saveaddressverifications(data)
                  .then((response) => {
                    // console.log("response: ", response);
                    setDataLoaded(true);
                    if (response.data.success) {
                      let formdata1 = {
                        inq_id: url,
                        event_desc: "Document verification submitted",
                        passed: 0,
                      };
                      saveevent(formdata1)
                        .then((response) => {
                          if (response.data.success) {
                            let formdata2 = {
                              inq_id: url,
                              event_desc: "Document verification passed",
                              passed: 1,
                            };
                            saveevent(formdata2)
                              .then((response) => {
                                if (response.data.success) {
                                  calculatechecks(url).then((response) => {
                                    if (response.data.success) {
                                      let formdata = {
                                        inq_id: url,
                                        section: "addressverif"
                                      };
                                      disablerevision(formdata).then((response) => {
                                        if (response.data.success) {
                                          let temp = screenStates;
                                          temp.Screen26 = true;
                                          storage.setItem("screenmeta", JSON.stringify(temp));
                                          history.push("/kycreturning/addressverif/f/" + url);
                                          window.location.reload();
                                        }
                                      }).catch((error) => {
                                        console.log("error: ", error);
                                      })
                                    }
                                  }).catch((error) => {
                                    console.log("error: ", error);
                                  });
                                }
                              })
                              .catch((error) => {
                                console.log("error: ", error);
                              });
                          }
                        })
                        .catch((error) => {
                          console.log("error: ", error);
                        });
                    }
                  })
                  .catch((error) => {
                    console.log("error: ", error);
                  });
              } else {
                setErrorBlur(true);
                setDisable(false);
                setShowReview(false);
              }
            })
            .catch((error) => {
              console.log("error checkblur", error);
              setErrorApi(true);
              setDisable(false);
            });
        }
      })
      .catch((err) => {
        console.error("err: ", err);
        setErrorApi(true);
        setDisable(false);
      });
    }
  }, [imageCaptured]);

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      {!showReview && 
        <div className="screenTwentyTwo CameraScreen h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
          {!errorBlur && 
            <>
              <div className="mainContent" >
                <div className="w-full flex flex-row justify-between items-center p-4 pt-4" style={{position:"absolute", top:"0",zIndex:"100"}}>
                  <div className="backArrow">
                    <Link to={"/kycreturning/addressverif/b/"+url}>
                      <button>
                        <img src={backButtonImg} alt="backArrow" className="" />
                      </button>
                    </Link>
                  </div>
                  <div className="progressBar w-full py-3 px-12">
                    <div className="relative lg:w-8/12 mx-auto">
                      <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                        <div
                          style={{ width: "90%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="crossArrow">
                    <button onClick={handleCross}>
                      <img src={closeButtonImg} alt="crossArrow" className="" />
                    </button>
                  </div>
                </div>
                <div className="cameraFrame">
                  <img className="cameraFrameImgSq cameraFrameAddress mx-auto absolute" src={cameraFrameImg} alt="camera_frame" style={{ zIndex: "10", top: "21%", height: "250px" }} />
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={"100%"}
                    mirrored={facingMode=="user"?false:false}
                    videoConstraints={videoConstraints} 
                    className="z-0"
                    forceScreenshotSourceSize
                  />
                  <div className="footer" style={{position:"absolute", bottom:"0px"}}>
                <button
                className="camerafacingchangebutton"
                style={{outline: "none" ,marginTop:10}}
                  onClick={() =>
                    setFacingMode(facingMode === "user" ? "environment" : "user")
                  }
                >
                  <img src={cycle} style={{width:"50px"}} alt="Switch" className="switchCamera" />
                </button>
                <img className="footerImg" src={footerImg} alt="footer" />
              </div>
                </div>
                <div className="warningMessage z-100">
                  <div className="message">
                    {pleaseHold ? 
                      <p>Please hold as we process</p>
                      :
                      <p>Make sure the text is clearly visible</p>
                    }
                  </div>
                </div>
              </div>
            </>
          }

          {errorBlur && 
            <>
              <div className="mainContent">
                <div className="blackImgBackground">
                  <div className="blackImage">
                    <img className="black" src={black} alt="" />
                    <img className="warning" src={warning} alt="" />
                  </div>
                  <div className="points">
                    <ul>
                      <li>Blur/focus Issue</li>
                      <li>Light is too high/low</li>
                      <li>Image size is too large/small</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="buttonAction">
                <Link to={"/kycreturning/addressverif/b/"+url}>
                  <button style={{backgroundColor:data?data.button_color:"#ee416f"}}  className="action w-full mx-auto flex flex-row items-center text-center justify-center">
                    <img src={loading} alt="" className="mr-2" />
                    Reupload
                  </button>
                </Link>
              </div>

              <div className="footer">
                <img className="footerImg" src={footerImg} alt="footer" />
              </div>
            </>
          }
        </div>
      }

      {showReview && 
        <div
          className="screenOne QRScreen h-full flex flex-col"
          style={{ fontFamily: data ? data.font_family : "Gilroy" }}
        >
          <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
            <div className="backArrow">
              <Link to={"/kycreturning/addressverif/b/"+url}>
                <button>
                  <img src={backButtonImg} alt="backArrow" className="" />
                </button>
              </Link>
            </div>
            <div className="progressBar w-full py-3 px-12">
              <div className="relative lg:w-8/12 mx-auto">
                <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                  <div
                    style={{
                      width: "70%",
                      backgroundColor: data ? data.progressbar_color : "#ee416f",
                    }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                  ></div>
                </div>
              </div>
            </div>
            <div className="crossArrow">
              <button onClick={handleCross}>
                <img src={closeButtonImg} alt="crossArrow" className="" />
              </button>
            </div>
          </div>

          <div class="mainContent" style={{ marginTop: "0" }}>
            <div class="mainText mt-4">
              <h2>Do you want to continue with this Document?</h2>
            </div>
            <div class="mainImage mt-4">
              <img className="mx-auto" src={imageToUpload} width="200" style={{ width: "100%", maxHeight: "250px" }}/>
            </div>
            <div class="supportingText mt-4">
              <p class="supportingTextPara">
                You can always go back and capture again if you wish to change.
              </p>
            </div>
          </div>

          <div class="buttonAction">
            {!disable && 
              <p onClick={() => window.location.reload()} className="colorPrimary text-center font-bold text-sm mb-2 cursor-pointer">I want to capture again!</p>
            }
            <button
              style={{ backgroundColor: data ? data.button_color : "#ee416f" }}
              class="action"
              onClick={() => setImageCaptured(true)}
              disabled={disable}
            >
              Continue
            </button>
          </div>

          <div class="footer">
            <img class="footerImg" src={footerImg} alt="footer" />
          </div>
        </div>
      }
    </>
  );
}
  
