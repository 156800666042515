import React from "react";
import { Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Select from "react-select";
import countryList from "react-select-country-list";
import {
  saveacceptableid,
  getacceptableidinternal,
  deleteacceptableid,
  getacceptableidbycountry
} from "../../services/settings/Acceptable";
import ReactLoading from "react-loading";

import backSetting from "../../assets/img/backSetting.png";
import countryDropdownGlobe from "../../assets/img/countryDropdownGlobe.png";
import success from "../../assets/img/event_elp_2.png";
import error from "../../assets/img/event_elp_error.png";
export default function AcceptableIds() {
  const [isShown, setIsShown] = React.useState("");

  const [acceptIdData, setAcceptIdData] = React.useState();
  const options = React.useMemo(() => countryList().getData(), []);
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  const [message, setMessage] = React.useState("");
  const [formdata, setFormdata] = React.useState({
    country: "",
    govt_id: false,
    passport: false,
    license: false,
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
  };

  React.useEffect(() => {
    getacceptableidinternal()
      .then((response) => {
        // console.log(response.data.acceptable_id);
        setAcceptIdData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const handleChangeSelect = (event, { name }) => {
    getacceptableidbycountry(event.label).then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        setFormdata({ 
          country: event,
          govt_id: response.data.acceptable_id.govt_id ? true : false,
          passport: response.data.acceptable_id.passport ? true : false,
          license: response.data.acceptable_id.license ? true : false,
        });
      } else if (!response.data.success) {
        setFormdata({ 
          country: event,
          govt_id: false,
          passport: false,
          license: false,
        });
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "country") {
      const value = event.target.value;
      setFormdata((values) => ({ ...values, [name]: value }));
    } else {
      const value = event.target.checked;
      setFormdata((values) => ({ ...values, [name]: value }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formdata.country.label) {
      formdata.country = formdata.country.label;
      // console.log("formdata: ", formdata)
      setMessage("");
    } else {
      setMessage("Please select a country!");
      return
    }

    saveacceptableid(formdata)
      .then((response) => {
        // console.log("respose: ", response);
        if (response.data.success) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("error", error);
        setMessage("There was an error, please try again!");
      });
  };

  const handleDelete = (id) => {
    // console.log("id: ", id);
    deleteacceptableid(id)
      .then((response) => {
        if (response.data.success) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("error", error);
        setMessage("There was an error, please try again!");
      });
  };

  return (
    <>
      <div className="amlscreening flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white border-0 w-full mb-6 shadow-lg resSidebar">
              <div
                className="lg:w-2/12 md:w-12/12 sm:w-12/12 nav-bg-grey rounded-lg shadow-2xl customizationSidebar"
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                <ul className="py-16 pt-8 md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain rounded-lg">
                  <li className="backbtn py-10 px-6 ">
                    <Link to="/settings/profile">
                      <button>
                        <img style={{ minWidth: "40px" }} src={backSetting} />
                      </button>
                    </Link>
                  </li>
                  <li className="items-center mb-3 uppercase text-xs font-bold block px-12 greyTxt hiddenSetting">
                    Theme Editor
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 font-bold block px-12 customizationLinks " +
                        (window.location.href.indexOf("/setting/kyc") !== -1 ||
                        isShown === "branding"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      onMouseEnter={() => setIsShown("branding")}
                      onMouseLeave={() => setIsShown("")}
                      to="/setting/kyc"
                    >
                      <p style={{ letterSpacing: "1px" }}>Customization</p>
                    </Link>
                  </li>

                  <li className="items-center mb-3 mt-8 uppercase text-xs font-bold block px-12 greyTxt hiddenSetting">
                    Configurations
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 font-bold block px-12 customizationLinks " +
                        (window.location.href.indexOf(
                          "/setting/acceptedcountries"
                        ) !== -1 || isShown === "acceptCountry"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/setting/acceptedcountries"
                      onMouseEnter={() => setIsShown("acceptCountry")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      <p style={{ letterSpacing: "1px" }}>Acceptable Countries</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 font-bold block px-12 customizationLinks " +
                        (window.location.href.indexOf(
                          "/setting/acceptableids"
                        ) !== -1 || isShown === "acceptId"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/setting/acceptableids"
                      onMouseEnter={() => setIsShown("acceptId")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      <p style={{ letterSpacing: "1px" }}>
                        Acceptable ID Types
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="lg:w-10/12 md:w-12/12 sm:w-12/12 px-16 py-16 acceptIdMain">
                <div className="row flex flex-row items-center acceptIdForm">
                  <Form class="" onSubmit={handleSubmit}>
                    <div className="flex flex-col items-center">
                      <div className="flex w-full flex-row mb-6">
                        <div
                          className="lg:w-12/12 md:w-12/12 sm:w-12/12 relative"
                          style={{ maxWidth: "300px" }}
                        >
                          <img
                            src={countryDropdownGlobe}
                            alt="globe"
                            className="absolute globeimagesettings"
                          />
                          <Select
                            className="globeSelect"
                            name="country"
                            options={options}
                            value={formdata.country}
                            styles={customStyles}
                            onChange={handleChangeSelect}
                          />
                        </div>
                      </div>
                      <div className="flex w-full flex-row mb-8 mt-8">
                        <div className="w-auto mr-8">
                          <div className="selectBox">
                            <label className="inline-flex items-center cursor-pointer text-xs">
                              <input
                                type="checkbox"
                                name="govt_id"
                                checked={formdata.govt_id}
                                onChange={handleChange}
                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                              />
                              Govt. ID
                            </label>
                          </div>
                        </div>
                        <div className="w-auto mr-8">
                          <div className="selectBox">
                            <label className="inline-flex items-center cursor-pointer text-xs">
                              <input
                                type="checkbox"
                                name="passport"
                                checked={formdata.passport}
                                onChange={handleChange}
                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                              />
                              Passport
                            </label>
                          </div>
                        </div>
                        <div className="w-auto">
                          <div className="selectBox">
                            <label className="inline-flex items-center cursor-pointer text-xs">
                              <input
                                type="checkbox"
                                name="license"
                                checked={formdata.license}
                                onChange={handleChange}
                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                              />
                              Driving License
                            </label>
                          </div>
                        </div>
                      </div>
                      {message && (
                        <div className="flex w-full form-group">
                          <div
                            className="alert alert-danger text-xs mb-8"
                            role="alert"
                          >
                            {message}
                          </div>
                        </div>
                      )}
                      <div className="form-submit button-action flex w-full flex-row">
                        <button
                          type="submit"
                          className="py-3 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-sm"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="block w-full overflow-x-auto h-600-px overflow-y-auto">
                  {!isLoading ? (
                    <table className="items-center w-full bg-transparent border-collapse mt-8 ">
                      <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                        <tr>
                          <th
                            className={
                              "px-6 align-middle py-3 text-sm whitespace-nowrap font-semibold text-center text-black-600"
                            }
                          >
                            Country
                          </th>
                          <th
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                            }
                          >
                            Govt. ID
                          </th>
                          <th
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                            }
                          >
                            Passport
                          </th>
                          <th
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                            }
                          >
                            Driving License
                          </th>
                          {/* <th
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                            }
                          ></th> */}
                        </tr>
                      </thead>

                      <tbody>
                        {acceptIdData.acceptable_id.map((data) => {
                          return (
                            <tr>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                {data.country}
                              </td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                <img
                                  className="mx-auto"
                                  style={{ width: "20px" }}
                                  src={data.govt_id === 1 ? success : error}
                                />
                              </td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                <img
                                  className="mx-auto"
                                  style={{ width: "20px" }}
                                  src={data.passport === 1 ? success : error}
                                />
                              </td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                <img
                                  className="mx-auto"
                                  style={{ width: "20px" }}
                                  src={data.license === 1 ? success : error}
                                />
                              </td>
                              {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                <button
                                  onClick={() => handleDelete(data.id)}
                                  type="button"
                                  class="buttonPink px-4 py-2 rounded-lg text-xs font-bold"
                                  style={{
                                    background: "#ebedf2",
                                    color: "#ee416f",
                                  }}
                                >
                                  Delete
                                </button>
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    )
                    :
                    <ReactLoading
                      className="margin-auto mt-3 mb-3"
                      type={"bars"}
                      color={"#ee416f"}
                      height={"50px"}
                      width={"50px"}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
