import React from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Link, useParams, useHistory  } from "react-router-dom";
import ReactLoading from "react-loading";
import Select from "react-select";
import countryList from "react-select-country-list";
import { search } from "../../services/opencorporates/Lookup";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import "react-circular-progressbar/dist/styles.css";

import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";
import tickIcon from "../../assets/img/tickIcon.png";

function Table({ finalData, columns, pageNum, setPageNum, numOfRecords, setNumOfRecords, totalPages, allDataLoaded, totalNumOfRecords, showSearchDetails, showLoader }) {
  const color = "light";
  const [tempPageNum, setTempPageNum] = React.useState(numOfRecords);
  // console.log("finalData: ", finalData);

  const {
    table,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: finalData,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        ...columns,
      ])
    }
  )

  return (
    <>
      {showSearchDetails && 
        <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
          <div
            className={
              "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
              (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
            }
          >
            <div className="rounded-t mb-0 px-4 py-4 border-0">
              <div className="flex flex-wrap items-center filtersTable justify-between w-full">
                <span className="text-md font-bold">
                  
                </span>
                <div className="text-sm matchesfoundpill">
                  <b>{totalNumOfRecords}</b> matches found
                </div>
              </div>
            </div>
            <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
              <div className="flex flex-row items-center">
                <button className="mr-2" onClick={() => {
                  const value = Math.max(1, Math.min(totalPages, Number(pageNum-1)));
                  // const page = value ? Number(value) - 1 : 0;
                  // changePage(page);
                  setPageNum(value);
                }} disabled={(pageNum==1) || !allDataLoaded}>
                  <img src={leftPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                </button>
                <span className="mr-2 text-xs">
                  Page
                  <strong className="ml-2">
                    {pageNum} of {totalPages}
                  </strong>
                </span>
                <button onClick={() => {
                  const value = Math.max(1, Math.min(totalPages, Number(pageNum+1)));
                  // const page = value ? Number(value) - 1 : 0;
                  // changePage(page);
                  setPageNum(value);
                }} disabled={(pageNum==totalPages) || !allDataLoaded}>
                  <img src={rightPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                </button>
              </div>
              <div className="flex flex-row items-center cursor-pointer">
                <p className="font-bold text-xs mr-3">Go to page</p>
                <input
                  type="number"
                  defaultValue={pageNum}
                  value={pageNum}
                  onChange={(e) => {
                    const value = Math.max(1, Math.min(totalPages, Number(e.target.value)));
                    // const page = value ? Number(value) - 1 : 0;
                    // changePage(page);
                    setPageNum(value);
                  }}
                  className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  disabled={!allDataLoaded}
                />
              </div>
              <div className="flex flex-row items-center cursor-pointer">
                <p className="font-bold text-xs mr-3">Records per page</p>
                <input
                  type="number"
                  min={1}
                  value={tempPageNum}
                  onChange={e => {
                    if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                      setTempPageNum(Number(1));
                    } else if (e.target.value > 100) {
                      setTempPageNum(Number(100));
                    } else {
                      setTempPageNum(Number(e.target.value));
                    }
                  }}
                  className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  disabled={!allDataLoaded}
                />
                <button
                  onClick={() => {
                    setNumOfRecords(tempPageNum);
                    setPageSize(tempPageNum);
                  }}
                  style={{outline:"none"}}
                  className="px-2 py-2 bg-white rounded-lg mr-2"
                >
                  <img src={tickIcon} alt="refresh" style={{width:"15px", height:"15px"}} />
                </button>
                {/* <button
                  onClick={() => window.location.reload()}
                  style={{outline:"none"}}
                  className="px-2 py-2 bg-white rounded-lg"
                >
                  <img src={refresh} alt="refresh" style={{width:"15px", height:"15px"}} />
                </button> */}
              </div>
            </div>
            <div className="block w-full overflow-x-auto">
              {/* Projects table */}
              <table
                {...getTableProps()}
                className="items-center w-full bg-transparent border-collapse"
              >
                <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={
                            "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-black-600" + (column.render("Header") == "Company Name" ? " text-left" : " text-center")
                          }
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {!allDataLoaded ? 
                  <tbody>
                    <tr className="text-center" colspan="100%">
                      <td></td>
                      <td>
                        <ReactLoading
                          className="margin-auto mt-3 mb-3"
                          type={"bars"}
                          color={"#ee416f"}
                          height={"20%"}
                          width={"20%"}
                        />
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  :
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()} className={"cursor-pointer "}>
                          {row.cells.map(cell => {
                            return (
                              <td {...cell.getCellProps()} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                }
              </table>
            </div>
          </div>
        </div>
      }

      {showLoader && 
        <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
          <div className="lg:w-12/12 md:w-12/12 sm:w-6/12">
            <ReactLoading
              className="margin-auto mt-3 mb-3"
              type={"bars"}
              color={"#ee416f"}
              height={"50px"}
              width={"50px"}
            />
          </div>
        </div>
      }
    </>
  );
}

export const MemoizedTable = React.memo(Table);

export default function Search() {
  let {namep, countryp} = useParams();
  const history = useHistory();
  const [searchValue, setSearchValue] = React.useState(namep);
  const options = React.useMemo(() => countryList().getData(), []);
  // const [country, setCountry] = React.useState({value: "", label: "All Jurisdictions"});
  const [country, setCountry] = React.useState(() => {
    let countryLabel = "All Jurisdictions";
    if (countryp != undefined) {
      countryp = countryp.toLowerCase();
      options.forEach(ele => {
        if (ele.value == countryp.toUpperCase()) {
          countryLabel = ele.label
        }
      });
    } else {
      countryp = "";
    }
    return {value: countryp, label: countryLabel}
  });
  const [showSearchDetails, setShowSearchDetails] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  const [data, setData] = React.useState([]);
  const [pageNum, setPageNum] = React.useState(1);
  const [numOfRecords, setNumOfRecords] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(null);
  const [totalNumOfRecords, setTotalNumOfRecords] = React.useState(0);
  const [allDataLoaded, setAllDataLoaded] = React.useState(false);
  
  const columns = [
    {
      Header: "Company Name",
      accessor: "name",
      Cell: (tableProps) => (
        <>
          <Link to={`/businesslookup/view/${tableProps.row.original.company.jurisdiction_code}/${tableProps.row.original.company.company_number}`}>
            <div className="flex flex-col text-left">
              <span className={"text-sm font-bold text-black-600"}>
                {tableProps.row.original.company.name}
              </span>
              <span className="text-xs">
                Company Number: {tableProps.row.original.company.company_number}
              </span>
              <span className="text-xs">
                Incorporation Date: {tableProps.row.original.company.incorporation_date}
              </span>
              <span className="text-xs">
                Registered Country: {tableProps.row.original.company.registered_address ? (tableProps.row.original.company.registered_address.country ? tableProps.row.original.company.registered_address.country : "N/A") : "N/A"}
              </span>
            </div>
          </Link>
        </>
      ),
    },
    // {
    //   Header: "Company Type",
    //   accessor: "company_type",
    //   Cell: (tableProps) => (
    //     <>
    //       <div className="flex flex-col text-left">
    //         <span className={"text-sm"}>
    //           {tableProps.row.original.company.company_type}
    //         </span>
    //       </div>
    //     </>
    //   ),
    // },
    {
      Header: "Status",
      accessor: "inactive",
      Cell: (tableProps) => (
        <Link to={`/businesslookup/view/${tableProps.row.original.company.jurisdiction_code}/${tableProps.row.original.company.company_number}`}>
          <div className="statusGrid flex flex-col max-w-120-px mx-auto text-xs">
            {tableProps.row.original.company.inactive == false && (
              <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg text-xs font-semibold text-white-400 uppercase buttonGreen">
                Active
              </button>
            )}
            {tableProps.row.original.company.inactive == true && (
              <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg text-xs font-semibold text-white-400 uppercase buttonRed mb-2">
                Inactive
              </button>
            )}
            {tableProps.row.original.company.inactive == null && (
              <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg text-xs font-semibold text-white-400 uppercase buttonYellow mb-2">
                N/A
              </button>
            )}
          </div>
        </Link>
      ),
    }
  ];
  
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  
  React.useEffect(() => {
    let flag = false;
    options.forEach((ele) => {
      if(ele.label === "All Jurisdictions") {
        flag = true;
      }
    });
    if (!flag) {
      options.unshift({
        value: "",
        label: "All Jurisdictions",
      });
    }
  }, []);

  const handleSearch = (e) => {
    if (searchValue == "" || searchValue == undefined) {
      return;
    }
    // setAllDataLoaded(false);
    // setShowLoader(true);

    // search({ name: searchValue, country: country.value.toLowerCase(), pageNum: pageNum, numOfRecords: numOfRecords }).then((res) => {
    //   if (res.status == 200) {
    //     setData(res.data.results.companies);
    //     setTotalPages(res.data.results.total_pages);
    //     setNumOfRecords(res.data.results.per_page);
    //     setPageNum(res.data.results.page);
    //     setAllDataLoaded(true);
    //     setShowSearchDetails(true);
    //     setShowLoader(false);
    //   }
    // }).catch((err) => {
    //   console.log("err: ", err)
    // });
    history.push("/businesslookup/index/" + searchValue + "/" + (country.value == "" ? "" : "/" + country.value.toLowerCase()));
    window.location.reload(false);
  }

  React.useEffect(() => {
    if (searchValue == "" || searchValue == undefined) {
      return;
    }
    setData([]);
    setShowSearchDetails(false);
    setAllDataLoaded(false);
    setShowLoader(true);
    // console.log("numOfRecords: ", numOfRecords);

    let datatosend = {
      name: searchValue,
      country: country.value.toLowerCase(),
      pageNum: pageNum,
      numOfRecords: numOfRecords
    };

    search(datatosend).then((res) => {
      if (res.status == 200) {
        // console.log("res: ", res.data.results.companies);
        setData(res.data.results.companies);
        setTotalPages(res.data.results.total_pages);
        setNumOfRecords(res.data.results.per_page);
        setPageNum(res.data.results.page);
        setTotalNumOfRecords(res.data.results.total_count);
        setAllDataLoaded(true);
        setShowSearchDetails(true);
        setShowLoader(false);
      }
    }).catch((err) => {
      console.log("err: ", err)
    });
  }, [pageNum, numOfRecords, country]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      paddingTop: 0,
    }),
  };

  return (
    <>
      <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
        <div className="amlInquireDiv lg:w-12/12 md:w-12/12 sm:w-6/12">
          <Form class="inquireForm aml flex flex-col">
            <div className="searchInputTables flex w-full flex-row mb-6 items-center amlInquireMain">
              <div className="fieldInquire relative w-full lg:w-8/12 md:w-8/12 sm:w-12/12 mr-4">
                <label className="label block font-semibold text-black-600 text-xs mb-2">
                  Search
                </label>
                <Input
                  type="text"
                  name="searchcompany"
                  className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  style={{ fontSize: "12px" }}
                  value={searchValue}
                  placeholder={"Search company name"}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className="fieldInquire relative w-full lg:w-4/12 md:w-4/12 sm:w-12/12">
                <label className="label block font-semibold text-black-600 text-xs mb-2">
                  Jurisdiction
                </label>
                <Select
                  name="country"
                  className="input_Fields"
                  style={{ all: "unset" }}
                  placeholder="Search Jurisdiction"
                  options={options}
                  value={country}
                  onChange={(e) => setCountry(e)}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                />
              </div>
            </div>
            <button
              onClick={handleSearch}
              type={"button"}
              className="py-3 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs max-w-150-px"
            >
              Search
            </button>
          </Form>
        </div>
      </div>

      <div className={
          "w-full px-1 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12"
        }
        style={{ transition: "all 0.25s ease-in-out" }}
      >
        <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
          <MemoizedTable 
            finalData={data}
            columns={columns}
            pageNum={pageNum}
            setPageNum={setPageNum}
            numOfRecords={numOfRecords}
            setNumOfRecords={setNumOfRecords}
            totalPages={totalPages}
            allDataLoaded={allDataLoaded}
            totalNumOfRecords={totalNumOfRecords}
            showSearchDetails={showSearchDetails}
            showLoader={showLoader}
          />
        </div>
      </div>
    </>
  );
}