import React, { useState,useContext } from "react";
import { Link,useHistory } from "react-router-dom";
import { saveevent } from "services/kyc/KycScreens";
import {KycMobContext} from "../../../layouts/KYCMob"

import backButtonImg from "../../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from "../../../assets/img/kycmobile/cross_white.png";
import footerImg from "../../../assets/img/kycmobile/footer_trademark.png";
import idCard from "../../../assets/img/kycmobile/idcard.png";
import passport from "../../../assets/img/kycmobile/passport.png";
import license from "../../../assets/img/kycmobile/license.png";

export default function Screen10() {
  const [disable, setDisable] = React.useState(false);
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const {data} = useContext(KycMobContext);
  const history = useHistory();

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen10) {
        const redirecturl = "/kycreturning/idverif/a/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kycreturning/idverif/a/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  const handleNext = () => {
    setDisable(true);
    let formdata = {
      inq_id: url,
      event_desc: "Government ID verification started",
      passed: 0
    };
    saveevent(formdata).then((response) => {
      if (response.data.success) {
        let temp = screenStates;
        temp.Screen11 = true;
        storage.setItem("screenmeta", JSON.stringify(temp));
        history.push("/kycreturning/idverif/c/"+url);
        window.location.reload();
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenTem h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"#"}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "40%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button  onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1>To complete Identity verification you will need one of the following</h1>
          </div>

          <div className="documentList">
            <div className="idCard gridDocumentList">
              <div className="idCardImg documentImage">
                <img src={idCard} alt="" />
              </div>
              <div className="idCardText mainText">
                <h3 className="idCardTextHeading">National ID Card</h3>
                <p className="idCardTextPara">You will need to add live picture of your ID card for verification</p>
              </div>
            </div>
            <div className="passport gridDocumentList">
              <div className="passportImg documentImage">
                <img src={passport} alt="" />
              </div>
              <div className="passportText mainText">
                <h3 className="passportTextHeading">Passport</h3>
                <p className="passportTextPara">You will need to add live picture of your passport for verification</p>
              </div>
            </div>
            <div className="drivingLicense gridDocumentList">
              <div className="drivingLicenseImg documentImage">
                <img src={license} alt="" />
              </div>
              <div className="drivingLicenseText mainText">
                <h3 className="drivingLicenseTextHeading">Driving License</h3>
                <p className="drivingLicenseTextPara">You will need to add live picture of your driving license for verification</p>
              </div>
            </div>
          </div>
        </div>

        <div className="buttonAction">
          {/* <Link to="/kycreturning/idverif/screen11"> */}
            <button onClick={handleNext} disabled={disable} style={{backgroundColor:data?data.button_color:"#ee416f"}}  className="action">Got it!</button>
          {/* </Link> */}
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
