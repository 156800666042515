import React from "react";
import { useLocation } from 'react-router-dom';
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { Link } from "react-router-dom";
import { validateToken, logout } from "../../services/Auth";

import { useHistory } from 'react-router-dom';

import LogoutImg from "../../assets/img/logout.png"
import NotifImg from "../../assets/img/bell.png"

export default function Navbar() {
  const location = useLocation();
  const [heading, setHeading] = React.useState("");
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0); // scroll to top on every page load
    const storage = window.localStorage;
    const access_token = storage.getItem("access_token");

    if (access_token) {
      validateToken().then((response) => {
        // console.log("response: ", response);
      }).catch((error) => {
        // console.log("error", error);
        const storage = window.localStorage;
        const userid = JSON.parse(storage.getItem("user")).id;
        logout(userid).then((response) => {
          if (response.data.success) {
            storage.clear();
            history.push("/auth/login");
            window.location.reload();
          }
        }).catch((error) => {
          console.log("error: ", error);
        });
      });
    } else {
      storage.clear();
      history.push("/auth/login");
      window.location.reload();
    }
  }, [])

  React.useEffect(() => {
    if(location.pathname === "/admin/dashboard") {
      setHeading("Dashboard");
    }
    else if (location.pathname === "/businesslookup/index") {
      setHeading("Business Lookup");
    }
    else if (location.pathname === "/network/graph") {
      setHeading("Network Graph");
    }
    else if (location.pathname === "/network/customization") {
      setHeading("Network Settings");
    }
    else if(location.pathname === "/aml/amlscreeninginquire") {
      setHeading("AML Screening");
    }
    else if(location.pathname === "/aml/amlscreeninglist") {
      setHeading("AML Screening");
    }
    else if(location.pathname === "/aml/lists") {
      setHeading("Lists");
    }
    else if(location.pathname === "/onchain/search") {
      setHeading("On-Chain Analysis");
    }
    
    else if(location.pathname === "/case/transmonitoring") {
      setHeading("Transaction Monitoring");
    }
    else if(location.pathname === "/case/casemanagement") {
      setHeading("Case Management");
    }
    else if(location.pathname === "/case/casemanagement/all") {
      setHeading("KYC - Case Management");
    }
    else if(location.pathname === "/case/casemanagement/review") {
      setHeading("KYC - Case Management");
    }
    else if(location.pathname === "/case/casemanagement/complete") {
      setHeading("KYC - Case Management");
    }
    else if(location.pathname === "/case/casemanagement/revision") {
      setHeading("KYC - Case Management");
    }
    else if(location.pathname === "/case/casemanagement/approve") {
      setHeading("KYC - Case Management");
    }
    else if(location.pathname === "/case/casemanagement/duplicate") {
      setHeading("KYC - Case Management");
    }
    else if(location.pathname === "/case/casemanagement/decline") {
      setHeading("KYC - Case Management");
    }
    else if(location.pathname === "/case/corporate/casemanagement") {
      setHeading("KYB - Case Management");
    }
    // else if(window.location.href.indexOf("/aml/amlscreeningdetail")) {
    //   setHeading("AML Screening");
    // }
    // else if(window.location.href.indexOf("/case/casemanagement/")) {
    //   // setHeading("Case Management Onboarding");
    //   setHeading("Case Management");
    // }
    else if(location.pathname === "/aml/casemanagement") {
      setHeading("Case Management");
    }
    else if(location.pathname === "/aml/amlscreeninglistlogs") {
      setHeading("AML Screening");
    }
    else if(location.pathname === "/case/alerts") {
      setHeading("Alerts");
    }
    else if(location.pathname === "/aml/cases") {
      // setHeading("Alerts AML");
      setHeading("AML - Case Management");
    }
    else if(location.pathname === "/case/transmonitor/alerts") {
      setHeading("Alerts");
    }
    else if(location.pathname === "/case/transmonitor/rules") {
      setHeading("Rules / Case Scenarios");
    }
    
    else if(location.pathname === "/risk/riskmanagement") {
      setHeading("Risk Management");
    }
    else if(location.pathname === "/risk/riskmanagement/scoring") {
      setHeading("Risk Scoring And Rating");
    }
    else if(location.pathname === "/risk/riskmanagement/scorecountry") {
      setHeading("Country Risk Scores");
    }

    else if(location.pathname === "/reports/list") {
      setHeading("Reports");
    }

    else if(location.pathname === "/settings") {
      setHeading("Settings");
    }
    else if(location.pathname === "/settings/profile") {
      setHeading("Profile");
    }
    else if(location.pathname === "/settings/team") {
      setHeading("Team");
    }
    else if(location.pathname === "/settings/customization") {
      setHeading("Settings");
    }
    else if(location.pathname === "/settings/audit") {
      setHeading("Audit Logs");
    }
    else if(location.pathname === "/setting/kyc") {
      setHeading("Customization");
    }
    else if(location.pathname === "/setting/acceptedcountries") {
      setHeading("Acceptable Countries");
    }
    else if(location.pathname === "/setting/acceptableids") {
      setHeading("Acceptable ID Types");
    }
    
    else if(location.pathname === "/dev/developers") {
      setHeading("Developers");
    }
    else if(location.pathname === "/web/webhooks") {
      setHeading("Webhooks");
    }
    else if(location.pathname === "/docs/documentation") {
      setHeading("Documentation");
    }
    // else if(window.location.href.indexOf("/onchain/")) {
    //   setHeading("On-Chain Analysis");
    // }
  })

  const handleLogout = () => {
    const storage = window.localStorage;
    const userid = JSON.parse(storage.getItem("user")).id;
    logout(userid).then((response) => {
      if (response.data.success) {
        storage.removeItem("access_token");
        storage.removeItem("user_name");
        storage.removeItem("user");
        window.location.href = "/auth";
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }
  
  return (
    <>
      {/* Navbar */}
      <nav id="navbarMainTop" className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4 ">
          {/* Brand */}
          <a
            className="text-black text-4xl hidden lg:inline-block font-bold topPageHeading"
            onClick={(e) => e.preventDefault()}
            style={{ color: "#263040" }}
          >
            {heading}
          </a>
          {/* Form */}
          <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3" style={{marginRight: "60px",width:"35%",maxWidth:"300px"}}>
            <div className="relative flex w-full flex-wrap items-stretch">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-search" style={{color: "#000"}}></i>
              </span>
              <input
                type="text"
                placeholder="Search"
                className="border-0 px-3 py-3 placeholder-black-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                style={{color: "#000"}}
              />
            </div>
          </form>
          {/* Notification */}
          <div className="flex-col md:flex-row list-none items-center hidden md:flex mr-3 cursor-pointer">
            <img
              alt="..."
              className="w-full align-middle border-none"
              src={NotifImg}
              style={{marginLeft: "20px"}}
            />
          </div>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex mr-3" style={{marginLeft: "20px"}}>
            <UserDropdown />
          </ul>
          {/* Logout */}
          <button onClick={handleLogout} style={{outline:"none"}}>
            <div className="flex-col md:flex-row list-none items-center hidden md:flex cursor-pointer">
              <img
                alt="..."
                className="w-full align-middle border-none"
                src={LogoutImg}
                style={{marginLeft: "20px"}}
              />
            </div>
          </button>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
