import axios from "axios";
import address from "../Address";


export function getdonutvalues() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/dashboard/donutchart/get", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getbarvalues() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/dashboard/barchart/get", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getlinechartvalues(index) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/dashboard/linechart/get/" + index, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getgeochartvalues() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/dashboard/geochart/get", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getradialchartvalues() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/dashboard/radialchart/get", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getriskscores() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/risk_scores", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getwatchlistdata() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/dashboard/watchlist/get", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}