import React from "react";
import Select from "react-select";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import NetworkChart from "../../components/Cards/NetworkChart";
import { getProperties, getSettings, getGraphData } from "../../services/network/Network";
import { WithContext as ReactTags } from 'react-tag-input';
import ReactLoading from "react-loading";
import Moment from "moment";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import {
  viewsingleright,
  getevents,
} from "services/casemanagement/Onboarding";

import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import ElipsesYellow from "../../assets/img/event_elp_yellow.png";
import VerticalLine from "../../assets/img/verticalLine.png";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function NetworkGraphView() {
  const color = "light";
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [propertyOptions, setPropertyOptions] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [properties, setProperties] = React.useState([]);
  const [defProperties, setDefProperties] = React.useState([]);
  const [accountTags, setAccountTags] = React.useState([]);
  const [minCluster, setMinCluster] = React.useState(null);
  const [maxCluster, setMaxCluster] = React.useState(null);
  const [dateRange, setDateRange] = React.useState(null);
  const [datesPicked, setDatesPicked] = React.useState([null, null]);
  const [graphData, setGraphData] = React.useState(null);
  const [graphLoaded, setGraphLoaded] = React.useState(false);

  const [width, setWidth] = React.useState(window.innerWidth);
  const [onboardingEventData, setOnboardingEventData] = React.useState([]);
  const [onboardingEventDataLength, setOnboardingEventDataLength] = React.useState(0);
  const [onboardingRightData, setOnboardingRightData] = React.useState(null);
  const [onboardingRightImage, setOnboardingRightImage] = React.useState(null);
  const [openRight, setOpenRight] = React.useState(null);
  const [rightId, setRightId] = React.useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
  };
  
  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    let tempTags = [...tags, tag];
    let accTag = [];
    tempTags.forEach(ele => {
      accTag.push(ele.text);
    })
    setTags(tempTags);
    setAccountTags(accTag);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    let accTag = [];
    newTags.forEach(ele => {
      accTag.push(ele.text);
    })
    setAccountTags(accTag);
    setTags(newTags);
  };

  React.useEffect(() => {
    getProperties().then((response) => {
      if (response.data.properties.length > 0) {
        let optionsArray = response.data.properties.map(ele => {
          return {
            value: ele.id,
            label: ele.property
          }
        });
        setPropertyOptions(optionsArray);
        
        getSettings().then((response) => {
          setMinCluster(response.data.settings.cluster_min);
          setMaxCluster(response.data.settings.cluster_max);
          setDateRange(response.data.settings.days_range);
          var today = new Date();
          var priorDate = new Date(new Date().setDate(today.getDate() - response.data.settings.days_range));
          var currentDate = new Date();
          setDatesPicked([priorDate, currentDate]);
    
          let tempProp = [];
          if (response.data.settings.properties.length > 0) {
            response.data.settings.properties.forEach(prop => {
              if (optionsArray.length > 0) {
                optionsArray.forEach(propOpt => {
                  if (propOpt.value == prop) {
                    tempProp.push(propOpt);
                  }
                })
              }
            })
          }
          setDefProperties(tempProp);
    
          setProperties(response.data.settings.properties);
          setAccountTags(response.data.settings.tags);
          let tempTags = [];
          response.data.settings.tags.forEach(tag => {
            tempTags.push({
              id: tag,
              text: tag
            });
          });
          setTags(tempTags);

          setIsLoaded(true);
        }).catch((error) => {
          console.log("error: ", error);
        })
      }
    }).catch((error) => {
      console.log("error: ", error);
    });

  }, [])

  const handleSearch = () => {
    setIsLoaded(false);
    setGraphLoaded(false);
    let formdata = {
      properties: properties.toString(),
      tags: accountTags.toString(),
      cluster_min: minCluster,
      cluster_max: maxCluster,
      start_date: datesPicked[0],
      end_date: datesPicked[1]
    };
    getGraphData(formdata).then((response) => {
      // console.log("response: ", response.data.data);
      if (response.data.success) {
        let data = {
          "links": response.data.data.links,
          "nodes": response.data.data.nodes
        }
        data.links.forEach(link => {
          var a, b;
          data.nodes.forEach(node => {
            if (link.source === node.id) {
              a = node;
            }
            if (link.target === node.id) {
              b = node;
            }
          });
          !a.neighbors && (a.neighbors = []);
          !b.neighbors && (b.neighbors = []);
          a.neighbors.push(b);
          b.neighbors.push(a);
    
          !a.links && (a.links = []);
          !b.links && (b.links = []);
          a.links.push(link);
          b.links.push(link);
        });

        // console.log("data: ", data);
        setGraphData(data);
        setGraphLoaded(true);
        setIsLoaded(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }

  React.useEffect(() => {
    if (openRight === false) {
      viewsingleright(rightId)
        .then((response) => {
          if (response.data.success) {
            if (response.data.onboardingsingle) {
              let storage = window.localStorage;
              storage.setItem("latitude", response.data.onboardingsingle.latitude);
              storage.setItem("longitude", response.data.onboardingsingle.longitude);
  
              setOnboardingRightData(response.data.onboardingsingle);
              setOnboardingRightImage(response.data.image);
              getevents(rightId)
                .then((response) => {
                  // console.log("response: ", response);
                  if (response.data.success) {
                    setOnboardingEventData(response.data.event);
                    if (response.data.event.length > 0) {
                      setOnboardingEventDataLength(response.data.event.length);
                    }
                    setOpenRight(!openRight);
                  }
                })
                .catch((error) => {
                  console.log("error: ", error);
                });
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }, [openRight, rightId]);

  const openRightSide = (id) => {
    // setOpenRight(false);
    // console.log("id: ", id);
    if (rightId !== null) {
      if (id === rightId) {
        setOpenRight(null);
        setRightId(null);
        return;
      } else {
        if(width>=1280){}
        else{window.location.href = "#right"}
        setOpenRight(false);
        setRightId(id);
      }
    }
    if (rightId === null) {
      if(width>=1280){}
      else{window.location.href = "#right"}
      setRightId(id);
      setOpenRight(false);
      return;
    }
  };

  return (
    <>
      <div className="casemanagement flex flex-wrap ">
        <div
          className={
            "w-full px-1 " +
            (openRight
              ? "xl:w-9/12 lg:w-12/12 md:w-12/12 sm:w-12/12"
              : "xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12")
          }
          style={{ transition: "all 0.25s ease-in-out" }}
        >
          {isLoaded ? 
            <div className="networkGraph flex flex-wrap">
              <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
                  <div
                    className={
                      "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                      (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                    }
                  >
                    <div className="rounded-t mb-0 px-4 py-4 border-0">
                      <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1 ">
                          <div className="px-4 mr-4" style={{ paddingLeft: "0" }}>
                            <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                              Nodes of Interest
                            </h4>
                          </div>
                          <p class="text-xs mt-2">
                            These settings will be used as default for graph
                            functionality where applicable.
                          </p>
                          <div className="w-full mb-8 mt-6">
                            <Form class="inquireForm w-full">
                              <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 mr-4">
                                <div className="searchInputTables mt-4">
                                  <div className="fieldInquire relative w-full mb-3">
                                    <label className="label block font-semibold text-black-600 text-xs mb-2">
                                      Properties
                                    </label>
                                    <Select
                                      closeMenuOnSelect={false}
                                      isMulti
                                      options={propertyOptions}
                                      value={defProperties}
                                      style={{ all: "unset"}}
                                      className="propertySelect input_Fields"
                                      menuPortalTarget={document.body}
                                      styles={customStyles}
                                      onChange={(e) => {
                                        let temp = [];
                                        e.map(ele => {
                                          temp.push(ele.value);
                                        });
                                        setProperties(temp);
                                        setDefProperties(e);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="searchInputTables mt-6">
                                  <div className="fieldInquire relative w-full mb-3">
                                    <label className="label block font-semibold text-black-600 text-xs mb-2">
                                      Accounts Tags
                                    </label>
                                    <ReactTags
                                      tags={tags}
                                      delimiters={delimiters}
                                      handleDelete={handleDelete}
                                      handleAddition={handleAddition}
                                      handleDrag={handleDrag}
                                      inputFieldPosition="bottom"
                                      placeholder="Enter here..."
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 flex">
                                <div className="searchInputTables mt-4 lg:w-4/12 md:w-12/12 sm:w-12/12 mr-2">
                                  <div className="fieldInquire relative w-full mb-3">
                                    <label className="label block font-semibold text-black-600 text-xs mb-2">
                                      Minimum Cluster Size
                                    </label>
                                    <Input
                                      type="number"
                                      name="minCluster"
                                      value={minCluster}
                                      className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      onChange={(e) => setMinCluster(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="searchInputTables mt-4 lg:w-4/12 md:w-12/12 sm:w-12/12 mr-2">
                                  <div className="fieldInquire relative w-full mb-3">
                                    <label className="label block font-semibold text-black-600 text-xs mb-2">
                                      Maximum Cluster Size
                                    </label>
                                    <Input
                                      type="number"
                                      name="maxCluster"
                                      value={maxCluster}
                                      className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      onChange={(e) => setMaxCluster(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="searchInputTables mt-4 lg:w-4/12 md:w-12/12 sm:w-12/12">
                                  <div className="fieldInquire relative w-full mb-3">
                                    <label className="label block font-semibold text-black-600 text-xs mb-2">
                                      Data Range (days)
                                    </label>
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd"
                                      portalId="root-portal"
                                      name="updateDate"
                                      selectsRange={true}
                                      startDate={datesPicked[0]}
                                      endDate={datesPicked[1]}
                                      onChange={(update) => {
                                        setDatesPicked(update);
                                      }}
                                      maxDate={addDays(new Date(), 0)}
                                      className="amlInquireFields cursor-pointer input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      style={{ fontSize: "0.75rem", height:"41px" }}
                                      showMonthDropdown
                                      showYearDropdown
                                      showPreviousMonths
                                      dropdownMode="select"
                                      peekPreviousMonth
                                      renderCustomHeader={({
                                        monthDate,
                                        customHeaderCount,
                                        decreaseMonth,
                                        increaseMonth,
                                      }) => (
                                        <div>
                                          <button
                                            aria-label="Previous Month"
                                            className={
                                              "react-datepicker__navigation react-datepicker__navigation--previous"
                                            }
                                            style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                                            onClick={decreaseMonth}
                                          >
                                            <span
                                              className={
                                                "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                                              }
                                            >
                                              {"<"}
                                            </span>
                                          </button>
                                          <span className="react-datepicker__current-month">
                                            {monthDate.toLocaleString("en-US", {
                                              month: "long",
                                              year: "numeric",
                                            })}
                                          </span>
                                          <button
                                            aria-label="Next Month"
                                            className={
                                              "react-datepicker__navigation react-datepicker__navigation--next"
                                            }
                                            style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                                            onClick={increaseMonth}
                                          >
                                            <span
                                              className={
                                                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                                              }
                                            >
                                              {">"}
                                            </span>
                                          </button>
                                        </div>
                                      )}
                                      monthsShown={2}
                                    />
                                  </div>
                                </div>
                              </div>

                              <button onClick={handleSearch} type="button" class="buttonPink px-6 py-2 rounded-lg text-sm font-bold mt-4">Search</button>
                            </Form>
                          </div>
                          {/* <div className="w-full mb-8 mt-6">
                            <Form class="inquireForm w-full">
                              <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 mr-4 flex flex-wrap">
                                <div className="searchInputTables mt-4 mr-2" style={{width:"250px"}}>
                                    <div className="fieldInquire relative w-full mb-3">
                                      <label className="label block font-semibold text-black-600 text-xs mb-2">
                                        Properties
                                      </label>
                                      <Select
                                        name="properties"
                                        className="input_Fields"
                                        style={{ all: "unset" }}
                                        placeholder="Properties"
                                        options={options}
                                        
                                        menuPortalTarget={document.body}
                                        styles={customStyles}
                                      />
                                  </div>
                                </div>
                                  <div className="searchInputTables mt-4 mr-2" style={{width:"400px"}}>
                                    <div className="fieldInquire relative w-full mb-3">
                                      <label className="label block font-semibold text-black-600 text-xs mb-2">
                                        Date Updated
                                      </label>
                                      <DatePicker
                                        portalId="root-portal"
                                        name="updateDate"
                                        maxDate={addDays(new Date(), 0)}
                                        className="amlInquireFields cursor-pointer input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        style={{ fontSize: "0.75rem", height:"41px" }}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                      />
                                  </div>
                                </div>
                                <div className="searchInputTables mt-4 mr-2" style={{width:"150px"}}>
                                    <div className="fieldInquire relative w-full mb-3">
                                    <label className="label block font-semibold text-black-600 text-xs mb-2">
                                      Min Cluster Size
                                    </label>
                                    <Input
                                      type="number"
                                      name="minCluster"
                                      className="amlInquireFields input_Fields border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      style={{ fontSize: "0.75rem" }}
                                    />
                                  </div>
                                </div>
                                <div className="searchInputTables mt-4 mr-2" style={{width:"150px"}}>
                                    <div className="fieldInquire relative w-full mb-3">
                                    <label className="label block font-semibold text-black-600 text-xs mb-2">
                                      Max Cluster Size
                                    </label>
                                    <Input
                                      type="number"
                                      name="maxCluster"
                                      className="amlInquireFields input_Fields border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      style={{ fontSize: "0.75rem" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 mr-4 flex flex-wrap">
                                  <div className="searchInputTables mt-4 mr-2" style={{width:"250px"}}>
                                    <div className="fieldInquire relative w-full mb-3">
                                      <label className="label block font-semibold text-black-600 text-xs mb-2">
                                        Type
                                      </label>
                                      <Select
                                        name="type"
                                        className="input_Fields"
                                        style={{ all: "unset" }}
                                        placeholder="Select Type"
                                        options={options}                                          
                                        menuPortalTarget={document.body}
                                        styles={customStyles}
                                      />
                                  </div>
                                </div>
                                  <div className="searchInputTables mt-4" style={{width:"400px"}}>
                                    <div className="fieldInquire relative w-full mb-3">
                                      <label className="label block font-semibold text-black-600 text-xs mb-2">
                                        Search
                                      </label>
                                      <Input
                                        type="text"
                                        name="search"
                                        className="amlInquireFields input_Fields border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        style={{ fontSize: "12px" }}
                                        placeholder="Search"
                                      />
                                  </div>
                                </div>
                                
                              </div>
                              <div className="searchButton mb-2 mt-2">
                                    <button
                                      type="button"
                                      className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs"
                                    >
                                      Search
                                    </button>
                                  </div>
                            </Form>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {graphLoaded && 
                    <div className={"networkanalysisGraph relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white"}>
                      <div className="rounded-t mb-0 px-4 py-4 border-0">
                        <NetworkChart key="network-0" nodeColor="blue" myData={graphData} openRightSide={openRightSide} />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            :
            <div className="networkGraph flex flex-wrap">
              <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
                  <div className={"networkanalysisGraph relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white"}>
                    <div className="rounded-t mb-0 px-4 py-4 border-0">
                      <ReactLoading
                        className="margin-auto mt-3 mb-3"
                        type={"bars"}
                        color={"#ee416f"}
                        height={"50px"}
                        width={"50px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {openRight && (
          <div className="w-full xl:w-3/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4" id="right">
            {onboardingRightData && (
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-16">
                <div className="px-6">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full px-4 flex justify-center">
                      <div className="relative">
                        <img
                          alt="..."
                          src={
                            onboardingRightImage
                              ? onboardingRightImage
                              : require("assets/img/ProfilePicture.png").default
                          }
                          style={{height:"100px", width:"100px", objectFit:"cover"}}
                          className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-12 lg:-ml-12 max-w-100-px"
                        />
                      </div>
                    </div>
                    <div className="w-full px-4 text-center mt-12">
                      <div className="flex justify-center py-4 lg:pt-4 pt-8">
                        {onboardingRightData.basicinfo ? 
                          <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2 capitalize">
                            {onboardingRightData.basicinfo.first_name +
                              " " +
                              onboardingRightData.basicinfo.last_name}
                          </h3>
                          :
                          <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2 capitalize">
                            N/A
                          </h3>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-base leading-normal mt-0 mb-2 text-white-400 font-bold uppercase">
                      {onboardingRightData.status === "review" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonYellow text-xs">
                          Review
                        </div>
                      )}
                      {onboardingRightData.status === "approve" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen text-xs">
                          Approve
                        </div>
                      )}
                      {onboardingRightData.status === "decline" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonRed text-xs">
                          Decline
                        </div>
                      )}
                      {onboardingRightData.status === "complete" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonBlue text-xs">
                          Complete
                        </div>
                      )}
                      {onboardingRightData.status === "revision" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonPurpe text-xs">
                          Revision
                        </div>
                      )}
                      {onboardingRightData.status === "duplicate" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonBlack text-xs">
                          Duplicate
                        </div>
                      )}
                      {onboardingRightData.status === "returning" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonPinkReturn text-xs">
                          Returning
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-base leading-normal mt-0 mb-2 text-white-400 font-bold uppercase">
                      <Link
                        to={`/case/casemanagementdetail/${onboardingRightData.inq_id}`}
                      >
                        <button
                          className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen text-xs"
                          style={{ background: "#ee416f" }}
                        >
                          Open Case
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="py-4 text-left">
                    <div className="flex flex-wrap">
                      <div className="flex items-center justify-center font-semibold w-full">
                        <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                          Info
                        </p>
                        <div
                          className="lg:w-10/12"
                          style={{ height: "0", border: "1px solid #D3D3D3" }}
                        ></div>
                      </div>
                      <div className="mt-2 fields block">
                        <div
                          className="enquiryId mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Inquiry ID</p>
                          <b className="text-xs">
                            {onboardingRightData.inq_id}
                          </b>
                        </div>
                        <div
                          className="IdNo mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            wordBreak:"break-all"
                          }}
                        >
                          <p className="text-xs">ID Number</p>
                          <b className="text-xs " >
                            {onboardingRightData.idverification.id_nnum ? onboardingRightData.idverification.id_nnum : "N/A"}
                          </b>
                        </div>
                        <div
                          className="dob mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Date of Birth</p>
                          <b className="text-xs">
                            {onboardingRightData.basicinfo.dob
                              ? onboardingRightData.basicinfo.dob.includes("T") ?
                                Moment(onboardingRightData.basicinfo.dob.split("T")[0]).format(
                                  "MM-DD-YYYY"
                                )
                                :
                                (Moment(onboardingRightData.basicinfo.dob, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                                  ? Moment(onboardingRightData.basicinfo.dob).format(
                                    "MM-DD-YYYY"
                                  ) : Moment(onboardingRightData.basicinfo.dob, "DD/MM/YYYY").format(
                                    "MM-DD-YYYY"
                                  )
                              : 
                              "N/A"
                            }
                          </b>
                        </div>
                        <div
                          className="nationality mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Nationality</p>
                          <b className="text-xs">
                            {onboardingRightData.basicinfo.nationality}
                          </b>
                        </div>
                        <div
                          className="email mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Email</p>
                          <b className="text-xs">
                            {onboardingRightData.basicinfo.email}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>

                  {onboardingEventData && (
                    <div className="py-4 text-left">
                      <div className="flex flex-wrap">
                        <div className="flex items-center justify-center font-semibold w-full">
                          <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                            Event
                          </p>
                          <div
                            className="lg:w-10/12"
                            style={{ height: "0", border: "1px solid #D3D3D3" }}
                          ></div>
                        </div>
                        <div className="w-full mt-2 fields block eventTimeline">
                          {onboardingEventData.map((element, ind) => (
                            <>
                              {element.event_desc && 
                                <>
                                  <div data-index={ind} className="status mt-2">
                                    <div
                                      className="w-full mt-2"
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "20% 80%",
                                      }}
                                    >
                                      {element.passed === 0 && (
                                        <div className="imgTag">
                                          <img src={ElipsesOne} alt="created" />
                                        </div>
                                      )}
                                      {element.passed === 1 && (
                                        <div className="imgTag">
                                          <img src={ElipsesTwo} alt="created" />
                                        </div>
                                      )}
                                      {element.passed === 2 && (
                                        <div className="imgTag">
                                          <img src={ElipsesYellow} alt="created" />
                                        </div>
                                      )}
                                      {element.passed === 3 && (
                                        <div className="imgTag">
                                          <img src={ElipsesError} alt="created" />
                                        </div>
                                      )}
                                      <div className="text text-xs font-semibold">
                                            <p data-tip data-for={element.event_desc.replaceAll(' ', '')}>
                                              {element.event_desc}
                                            </p>
                                            {element.notes && 
                                              <ReactTooltip id={element.event_desc.replaceAll(' ', '')} place="top" effect="solid">
                                                <span>{element.notes}</span>
                                              </ReactTooltip>
                                            }
                                            <p className="createdDate font-normal mt-2">
                                              {Moment(element.created_at).format(
                                                "MM-DD-YYYY"
                                              )}{" "}
                                              |{" "}
                                              {Moment(element.created_at).format(
                                                "hh:mm a"
                                              )}
                                            </p>
                                          
                                      </div>
                                    </div>
                                    {onboardingEventDataLength - 1 !== ind && (
                                      <div
                                        className="w-full items-center justify-center"
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns: "20% 80%",
                                        }}
                                      >
                                        <div className="imgTag px-2">
                                          <img
                                            className="ml-1"
                                            src={VerticalLine}
                                            alt="created"
                                            />
                                        </div>
                                        <div className="text text-xs font-semibold"></div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              }
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
