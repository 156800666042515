import React from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from "react-select";
import "react-circular-progressbar/dist/styles.css";
import ReactLoading from "react-loading";
import { getAllOpenSanctionList } from "../../services/SanctionList";
import { sanctionActiveLists } from "../../services/aml/Screening";
import { getRefreshLists } from "../../services/AMLInquire";

import ExportIcon from "../../assets/img/icons/exporticon.png";
import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";
import searchIcon from "../../assets/img/search-icon.png";
import createRule from "../../assets/img/createRule.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";

import { useState, useEffect } from "react";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <div className="selectBox">
          <label className="inline-flex items-center cursor-pointer text-sm">
            <input
              ref={resolvedRef}
              {...rest}
              id="customCheckLogin"
              type="checkbox"
              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-4 h-4 ease-linear transition-all duration-150 selectBoxStyle"
            />
          </label>
        </div>
      </>
    );
  }
);

const IndeterminateCheckboxHeader = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          id="test"
          ref={resolvedRef}
          {...rest}
          style={{ display: "none" }}
        />
        <label for="test" className="cursor-pointer">
          Select All
        </label>
      </>
    );
  }
);

function Table({ columns, data, loaded }) {
  const color = "light";
  const [filteredData, setFilteredData] = useState(data);
  const [searchWord, setSearchWord] = useState("");
  const [refreshLoaded, setRefreshLoaded] = useState(true);

  const [pageNum, setPageNum] = React.useState(1);
  
  const [listTypeFilters, setListTypeFilters] = useState({
    sanction: true,
    pep: true,
    am: true
  });

  const handleFilter = () => {
    const newFilter = data.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });
    setFilteredData(newFilter);
  };

  useEffect(() => {
    const toFilter = [];
    if (listTypeFilters.sanction) {
      toFilter.push("sanctions", "default");
    }
    if (listTypeFilters.pep) {
      toFilter.push("peps");
    }
    if (listTypeFilters.am) {
      toFilter.push("crime");
    }

    const newFilter = data.filter((value) => {
      return toFilter.includes(value.category.toLowerCase());
    });

    setFilteredData(newFilter);
  }, [listTypeFilters])

  const handleCross = () => {
    setFilteredData(data);
    setListTypeFilters({
      sanction: true,
      pep: true,
      am: true
    });
    setSearchWord("");
  };

  const searchby = [
    { label: "Search By", value: 1 },
    { label: "Search By Name", value: 2 },
    { label: "Search By ID Number", value: 3 },
    { label: "Search By User", value: 4 },
  ];

  const defaultValueSearchBy = searchby[0];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckboxHeader
                {...getToggleAllPageRowsSelectedProps()}
              />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      padding: 10,
      fontSize: "14px",
    }),
  };

  const refreshLists = () => {
    setRefreshLoaded(false);
    getRefreshLists().then((response) => {
      if (response.data.status) {
        window.location.reload();
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }

  return (
    <>
      <div className="casemanagement flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 mt-8">
                    <Form
                      class="filtersTable"
                      // onSubmit={(e) => handleLogin(e)}
                      // ref={c => {form = c;}}
                    >
                      <div className="flex justify-between items-center justify-center flex-wrap">
                        <div className="flex justify-between items-center justify-center flex-wrap">
                          <div className="searchInputTables flex items-center mr-3">
                            <div className="fieldSearchTables relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-sm mb-2">
                                <img className="searchIcon" src={searchIcon} />
                              </label>
                              <Input
                                type="text"
                                value={searchWord}
                                style={{ paddingRight: "40px" }}
                                onChange={(e) => setSearchWord(e.target.value)}
                                className="searchFilter  input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                // placeholder="Search list by name, type or country"
                                // value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                // validations={[required, validEmail]}
                              />
                            </div>
                            <div
                              className="label block font-semibold text-black-600 text-sm mb-2 cursor-pointer"
                              style={{ marginLeft: "-30px", zIndex: "10" }}
                              onClick={handleCross}
                            >
                              <img src={closeButtonImg} style={{width:"80%"}} alt="Delete Person" />
                            </div>
                          </div>
                          <div className="">
                            <div className="searchButton mb-2">
                              <button
                                type="button"
                                className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs"
                                onClick={handleFilter}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center justify-center flex-wrap">
                          <div className="selectBox mr-4">
                            <label className="inline-flex items-center cursor-pointer text-xs">
                              <input
                                id="sanctioncheck"
                                type="checkbox"
                                checked={listTypeFilters.sanction}
                                defaultChecked={listTypeFilters.sanction}
                                onChange={() => setListTypeFilters((prevState) => ({
                                  ...prevState,
                                  sanction: !listTypeFilters.sanction,
                                }))}
                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                              />
                              Sanctions
                            </label>
                          </div>
                          <div className="selectBox mr-4">
                            <label className="inline-flex items-center cursor-pointer text-xs">
                              <input
                                id="pepcheck"
                                type="checkbox"
                                checked={listTypeFilters.pep}
                                defaultChecked={listTypeFilters.pep}
                                onChange={() => setListTypeFilters((prevState) => ({
                                  ...prevState,
                                  pep: !listTypeFilters.pep,
                                }))}
                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                              />
                              PEP Class
                            </label>
                          </div>
                          <div className="selectBox">
                            <label className="inline-flex items-center cursor-pointer text-xs">
                              <input
                                id="amcheck"
                                type="checkbox"
                                checked={listTypeFilters.am}
                                defaultChecked={listTypeFilters.am}
                                onChange={() => setListTypeFilters((prevState) => ({
                                  ...prevState,
                                  am: !listTypeFilters.am,
                                }))}
                                className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                              />
                              Adverse Media
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
                {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>{' '} */}
                <div className="flex flex-row items-center">
                  <button
                    className="mr-2"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    style={{outline:"none"}}
                  >
                    <img
                      src={leftPag}
                      alt="previous icon"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                  <span className="mr-2 text-xs">
                    Page
                    {(pageOptions.length > 0) ?
                      <strong className="ml-2">
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                      :
                      <strong className="ml-2">
                        {pageIndex} of {pageOptions.length}
                      </strong>
                    }
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage} style={{outline:"none"}}>
                    <img
                      src={rightPag}
                      alt="previous icon"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Go to page</p>
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    value={pageNum}
                    onChange={(e) => {
                      const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
                      const page = value ? Number(value) - 1 : 0;
                      gotoPage(page);
                      setPageNum(value);
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Records per page</p>
                  <input
                    type="number"
                    min={1}
                    value={pageSize}
                    onChange={(e) => {
                      if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                        setPageSize(Number(1));
                      } else {
                        setPageSize(Number(e.target.value));
                      }
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                  {/* <button
                    // onClick={() => }
                    className="px-2 py-2 bg-white rounded-lg"
                    style={{outline:"none"}}
                    onClick={refreshLists}
                  >
                    <img
                      src={refresh}
                      alt="refresh"
                      style={{ width: "13px", height: "13px",outline:"none" }}
                    />
                  </button> */}
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table
                  {...getTableProps()}
                  className="items-center w-full bg-transparent border-collapse"
                >
                  <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  {(!loaded || !refreshLoaded) && (
                    <tbody>
                      <tr className="text-center" colspan="100%">
                        <td></td>
                        <td></td>
                        <td>
                          <ReactLoading
                            className="margin-auto mt-3 mb-3"
                            type={"bars"}
                            color={"#ee416f"}
                            height={"20%"}
                            width={"20%"}
                          />
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                  {(loaded && refreshLoaded) && (
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="cursor-pointer">
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                                >
                                  {cell.render("Cell") !== null
                                    ? cell.render("Cell")
                                    : "N/A"}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function DataTableComponent() {
  const [data, setData] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    sanctionActiveLists()
      .then((response) => {
        if (response.data.success) {
          console.log("response", response);
          setData(response.data.lists);
          setDataLoaded(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const columns = [
    {
      Header: "List Name",
      accessor: "name",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "List Type",
      accessor: "category",
    },
    {
      Header: "Entity Count",
      accessor: "expected_entities",
    },
    // {
    //   Header: "Code",
    //   accessor: "code",
    // },
    // {
    //   Header: "Status",
    //   accessor: "status",
    // },
  ];

  return (
    <>
      {dataLoaded ? (
        <Table data={data} columns={columns} loaded={dataLoaded} />
      ) : (
        <ReactLoading
          className="margin-auto mt-3 mb-3"
          type={"bars"}
          color={"#ee416f"}
          height={"50px"}
          width={"50px"}
        />
      )}{" "}
    </>
  );
}

export default DataTableComponent;
