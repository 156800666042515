import React from "react";
import DataTableReports from "../../components/Datatables/DataTableReports";

export default function Reports() {
  return (
    <>
      <DataTableReports />
    </>
  );
}
