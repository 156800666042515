import React from "react";
import ReactLoading from 'react-loading';
import { getdonutvalues, getbarvalues } from "services/dashboard/Graphs";

// components
import CardLineChart from "components/Cards/CardLineChart.js";
import DonutChart from "components/Cards/DonutChart";
import CardBarChart from "components/Cards/CardBarChart.js";
import CardBarChartFull from "components/Cards/CardBarChartFull.js";
import GeoChart from "components/Cards/GeoChart.js";

export default function Dashboard() {
  const [donutValues, setDonutValues] = React.useState([]);
  const [donutDataLoaded, setDonutDataLoaded] = React.useState(false);
  const [barValuesCounts, setBarValuesCounts] = React.useState([]);
  const [barValuesPerc, setBarValuesPerc] = React.useState([]);
  const [barDataLoaded, setBarDataLoaded] = React.useState(false);

  React.useEffect(() => {
    getdonutvalues().then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        setDonutValues([response.data.review, response.data.completed, response.data.revision, response.data.approved, response.data.duplicate, response.data.declined]);
        setDonutDataLoaded(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    });

    getbarvalues().then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        setBarValuesCounts(response.data.data.counts);
        setBarValuesPerc(response.data.data.percentages);
        setBarDataLoaded(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }, [])

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 px-4 rounded-lg">
          {donutDataLoaded &&
            <DonutChart values={donutValues} />
          }
          {!donutDataLoaded && 
            <ReactLoading
              className="margin-auto mt-3 mb-3"
              type={"bars"}
              color={"#ee416f"}
              height={"20%"}
              width={"20%"}
            />
          }
        </div>
        <div className="w-full chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 px-4 rounded-lg">
          {barDataLoaded && 
            <CardBarChart counts={barValuesCounts} percentages={barValuesPerc} />
          }
          {!barDataLoaded && 
            <ReactLoading
              className="margin-auto mt-3 mb-3"
              type={"bars"}
              color={"#ee416f"}
              height={"20%"}
              width={"20%"}
            />
          }
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 px-4">
          <CardLineChart />
        </div>
      </div>
      {/* <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 px-4">
          <CardBarChartFull />
        </div>
      </div> */}
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 px-4">
          <GeoChart />
        </div>
      </div>
    </>
  );
}
