import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link as Link1 } from "react-scroll";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useParams } from "react-router";
import ImageGallery from "react-image-gallery";
import countryList from "react-select-country-list";
import ReactTooltip from "react-tooltip";
import {
  getdetail
} from "services/casemanagement/BankOnboardingKYB";
import ReactLoading from "react-loading";
import Moment from "moment";
import { Link as Link2 } from "react-router-dom";

// components
import { MemoizedMap } from "components/Maps/MapExample.js";

// images
import downloadIcon from "../../assets/img/icons/downloadIcon.png";
import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import ElipsesYellow from "../../assets/img/event_elp_yellow.png";
import VerticalLine from "../../assets/img/verticalLine.png";
import dropDownIcon from "../../assets/img/dropdown.png";
import idVerRectImg from "../../assets/img/idVerRectImg.png";
import greenWarning from "../../assets/img/greenWarning.png";
import longVertLine from "../../assets/img/longVertLine.png";
import smallVertLine from "../../assets/img/smallVertLine.png";
import locIcon from "../../assets/img/locIcon.png";

export default function CaseManagementDetailOnboarding(props) {
  const { inq_id } = useParams();
  const [images, setImages] = React.useState([]);
  const history = useHistory();
  const countryoptions = React.useMemo(() => countryList().getData(), []);
  countryoptions.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [assignedTo, setAssignedTo] = React.useState(null);
  const [inquiryStatus, setInquiryStatus] = React.useState(null);
  const [livenessData, setLivenessData] = React.useState([]);
  const [onboardingData, setOnboardingData] = React.useState([]);
  const [onboardingEventData, setOnboardingEventData] = React.useState([]);
  const [onboardingEventDataLength, setOnboardingEventDataLength] = React.useState(0);
  const [aiData, setAiData] = React.useState([]);
  const [checks, setChecks] = React.useState([]);

  const [businessInformation, setBusinessInformation] = React.useState([]);
  const [repInformation, setRepInformation] = React.useState([]);
  const [paymentInformation, setPaymentInformation] = React.useState([]);
  const [browserInformation, setBrowserInformation] = React.useState([]);

  const [checksId, setChecksId] = React.useState([]);
  const [checksLiveess, setChecksLiveess] = React.useState([]);
  const [checksAddress, setChecksAddress] = React.useState([]);

  const [idVerActive, setIdVerActive] = React.useState(true);
  const [livenessActive, setLivenessActive] = React.useState(false);
  const [addressVerActive, setAddressVerActive] = React.useState(false);
  const [eventTimeActive, setEventTimeActive] = React.useState(false);

  const [idShow, setIdShow] = React.useState(true);
  const [liveShow, setLiveShow] = React.useState(true);
  const [addShow, setAddShow] = React.useState(true);
  const [eventShow, setEventShow] = React.useState(true);

  const [ocrData, setOcrData] = React.useState(null);


  // google maps script include
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_API_KEY;
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, []);

  React.useEffect(() => {
    if (!inq_id) {
      history.push("/case/bank/casemanagement");
      window.location.reload();
    } else {
      getdetail(inq_id)
        .then((response) => {
          if (response.data.success) {
            console.log("response: ", response);
            setBusinessInformation(response.data.onboardings.kyb_business);
            setRepInformation(response.data.onboardings.kyb_v_business);
            setPaymentInformation(response.data.onboardings.kyb_payment);
            setBrowserInformation(response.data.onboardings.kyb_laungage);
            setOnboardingEventData(response.data.EventTimeline);
            setOnboardingEventDataLength(response.data.EventTimeline.length);
            
            setDataLoaded(true);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }, []);

  function setIdActive() {
    setIdVerActive(true);
    setLivenessActive(false);
    setAddressVerActive(false);
    setEventTimeActive(false);
  }
  function setLiveActive() {
    setIdVerActive(false);
    setLivenessActive(true);
    setAddressVerActive(false);
    setEventTimeActive(false);
  }
  function setAddActive() {
    setIdVerActive(false);
    setLivenessActive(false);
    setAddressVerActive(true);
    setEventTimeActive(false);
  }
  function setEvTActive() {
    setIdVerActive(false);
    setLivenessActive(false);
    setAddressVerActive(false);
    setEventTimeActive(true);
  }

  return (
    <>
      {dataLoaded && (
        <div className="casemanagement casemanagementdetail flex flex-wrap">
          <div className="w-full lg:w-9/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
              <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                <div className="flex items-center flex-wrap justify-between">
                  <div
                    className="px-4 cursor-pointer"
                    style={{ paddingLeft: "0" }}
                  >
                    <h4 className="borderDefault summaryHeading pb-1 font-bold text-base">
                      Summary
                    </h4>
                  </div>
                </div>
              </div>

              <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                <div className="flex flex-wrap">
                  <Link1 to="businessRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        idVerActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setIdActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Business Information
                      </h4>
                    </div>
                  </Link1>
                  <Link1 to="repRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        livenessActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setLiveActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Representative Information
                      </h4>
                    </div>
                  </Link1>
                  <Link1 to="paymentRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        addressVerActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setAddActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Payment Information
                      </h4>
                    </div>
                  </Link1>
                  <Link1 to="evtRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        eventTimeActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setEvTActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Event Timeline
                      </h4>
                    </div>
                  </Link1>
                </div>
              </div>

              <div id="businessRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setIdShow(!idShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Business Information
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!idShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {idShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {idShow && (
                    <div className="flex justify-between flex-wrap w-full px-12">
                      <div className="px-4 sm:px-4 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                            <table className="w-full table-fixed">
                              <tbody>
                                <tr>
                                  <td className="font-light text-xs">
                                    Business Name
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {businessInformation ? (businessInformation.legal_business_name ? businessInformation.legal_business_name : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Phone Number
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {businessInformation ? (businessInformation.business_phone_number ? businessInformation.business_phone_number : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Registered Address
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {businessInformation ? (businessInformation.business_registered_address ? businessInformation.business_registered_address : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Industry
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {businessInformation ? (businessInformation.industry ? businessInformation.industry : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Doing Business as
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {businessInformation ? (businessInformation.doing_business_as ? businessInformation.doing_business_as : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Website
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {businessInformation ? (businessInformation.company ? businessInformation.company : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Employer Identification Number
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {businessInformation ? (businessInformation.ein ? businessInformation.ein : "N/A") : "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="repRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setLiveShow(!liveShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Representative Information
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!liveShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {liveShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {liveShow && (
                    <div className="flex justify-between flex-wrap w-full px-12">
                      <div className="px-4 sm:px-4 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                            <table className="w-full table-fixed">
                              <tbody>
                                <tr>
                                  <td className="font-light text-xs">
                                    Legal Name
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {repInformation ? (repInformation.legal_name ? repInformation.legal_name : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Phone Number
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {repInformation ? (repInformation.phone_number ? repInformation.phone_number : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Home Address
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {repInformation ? (repInformation.home_address ? repInformation.home_address : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Job Title
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {repInformation ? (repInformation.job_title ? repInformation.job_title : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Date of Birth
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {repInformation ? (repInformation.date_of_birth ? repInformation.date_of_birth : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Email
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {repInformation ? (repInformation.email ? repInformation.email : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Social Security Number (Last 4 digits)
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {repInformation ? (repInformation.SSN ? repInformation.SSN : "N/A") : "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="paymentRef" className="address-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setAddShow(!addShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Payment Information
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!addShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {addShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {addShow && (
                    <div className="flex justify-between flex-wrap w-full px-12">
                      <div className="px-4 sm:px-4 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                            <table className="w-full table-fixed">
                              <tbody>
                                <tr>
                                  <td className="font-light text-xs">
                                    Payment Type
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {paymentInformation ? (paymentInformation.type ? paymentInformation.type : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Account Number
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {paymentInformation ? (paymentInformation.account_no ? paymentInformation.account_no : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Routing Number
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {paymentInformation ? (paymentInformation.routing_no ? paymentInformation.routing_no : "N/A") : "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="evtRef" className="event-timeline relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setEventShow(!eventShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Event Timeline
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!eventShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {eventShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {eventShow && (
                    <div className="flex flex-row w-full p-12">
                      {onboardingEventData && (
                        <div className="p-12 md:w-12/12">
                          <div className="text-left">
                            <div className="flex flex-wrap">
                              <div
                                className="w-full px-12 mt-2 fields block"
                                style={{
                                  maxHeight: "500px",
                                  height: "500px",
                                  overflow: "auto",
                                }}
                              >
                                {onboardingEventData.map((element, ind) => (
                                  <>
                                    {element.event_desc && 
                                      <>
                                        <div className="status mt-2">
                                          <div
                                            className="w-full mt-2"
                                            style={{
                                              display: "grid",
                                              gridTemplateColumns: "20% 80%",
                                            }}
                                          >
                                            {element.passed === 0 && (
                                              <div className="imgTag">
                                                <img src={ElipsesOne} alt="created" />
                                              </div>
                                            )}
                                            {element.passed === 1 && (
                                              <div className="imgTag">
                                                <img src={ElipsesTwo} alt="created" />
                                              </div>
                                            )}
                                            {element.passed === 2 && (
                                              <div className="imgTag">
                                                <img
                                                  src={ElipsesYellow}
                                                  alt="created"
                                                />
                                              </div>
                                            )}
                                            {element.passed === 3 && (
                                              <div className="imgTag">
                                                <img
                                                  src={ElipsesError}
                                                  alt="created"
                                                />
                                              </div>
                                            )}
                                            <div className="text text-sm font-semibold">
                                              {element.event_desc}
                                              {onboardingEventDataLength - 1 ==
                                                ind && (
                                                <p className="text-xs createdDate font-normal mt-2">
                                                  {Moment(element.created_at).format(
                                                    "MM-DD-YYYY"
                                                  )}{" "}
                                                  |{" "}
                                                  {Moment(element.created_at).format(
                                                    "hh:mm a"
                                                  )}
                                                </p>
                                              )}
                                            </div>
                                          </div>

                                          <div
                                            className="w-full items-center justify-center"
                                            style={{
                                              display: "grid",
                                              gridTemplateColumns: "20% 80%",
                                            }}
                                          >
                                            {onboardingEventDataLength - 1 !== ind ? (
                                              <div className="imgTag px-2">
                                                <img
                                                  src={
                                                    ind === 0
                                                      ? longVertLine
                                                      : smallVertLine
                                                  }
                                                  alt="created"
                                                />
                                              </div>
                                            ) : (
                                              <div className="imgTag px-2"></div>
                                            )}
                                            <div className="text text-xs font-semibold">
                                              {onboardingEventDataLength - 1 !==
                                                ind && (
                                                <p className="createdDate font-normal mt-2">
                                                  {Moment(element.created_at).format(
                                                    "MM-DD-YYYY"
                                                  )}{" "}
                                                  |{" "}
                                                  {Moment(element.created_at).format(
                                                    "hh:mm a"
                                                  )}
                                                </p>
                                              )}
                                              <div className="createdDate text-sm font-normal mt-2 flex">
                                                <img
                                                  src={locIcon}
                                                  alt="loc icon"
                                                  className="mr-2"
                                                  style={{ width: "20px" }}
                                                />
                                                <p className="createdDate text-xs font-normal">
                                                  {element.ip_address}
                                                </p>
                                              </div>
                                              {ind === 0 && (
                                                <div className="w-full items-center justify-center mt-8">
                                                  <table className="w-full table-fixed">
                                                    <tbody>
                                                      <tr>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            IP Address
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {element.ip_address
                                                              ? element.ip_address
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                        {browserInformation && 
                                                          <>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                Latitude
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.latitude
                                                                  ? browserInformation.latitude
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                Longitude
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.longitude
                                                                  ? browserInformation.longitude
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                          </>
                                                        }
                                                      </tr>
                                                      <tr>
                                                        {browserInformation && 
                                                          <>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                First Seen
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.first_seen
                                                                  ? Moment(browserInformation.first_seen).format("MM-DD-YYYY")
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                Last Seen
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.last_seen
                                                                  ? Moment(browserInformation.last_seen).format("MM-DD-YYYY")
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                Device OS
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.device_os
                                                                  ? browserInformation.device_os
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                          </>
                                                        }
                                                      </tr>
                                                      <tr>
                                                        {browserInformation && 
                                                          <>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                Browser
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.browser
                                                                  ? browserInformation.browser
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                Browser Version
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.browser_version
                                                                  ? browserInformation.browser_version
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                Incognito
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.incognito
                                                                  ? (browserInformation.incognito == "0" ? "No" : "Yes")
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                          </>
                                                        }
                                                      </tr>
                                                      <tr>
                                                        {browserInformation && 
                                                          <>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                Browser Fingerprint
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.visitor_id
                                                                  ? browserInformation.visitor_id
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                            <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                              <h4 className="font-light text-xs">
                                                                OS Version
                                                              </h4>
                                                              <h4 className="font-semibold text-xs">
                                                                {browserInformation.os_version
                                                                  ? browserInformation.os_version
                                                                  : "N/A"}
                                                              </h4>
                                                            </td>
                                                          </>
                                                        }
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    }
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="p-12 md:w-12/12">
                        <div className="flex flex-wrap">
                          <div className="w-full px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                              {browserInformation && 
                                <MemoizedMap
                                  location={{
                                    lat: browserInformation.latitude,
                                    long: browserInformation.longitude,
                                  }}
                                />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="profileright w-full lg:w-3/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg">
              <div className="flex flex-wrap justify-center">
                <div className="w-full px-4 text-center">
                  <div className="flex justify-center py-4 lg:pt-4 pt-8">
                    {businessInformation.legal_business_name ? (
                      <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2">
                        {businessInformation.legal_business_name}
                      </h3>
                    ) : (
                      <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2">
                        N/A
                      </h3>
                    )}
                  </div>
                </div>
              </div>

              <div className="py-4 pt-0 text-left">
                <div className="flex flex-wrap">
                  <div className="flex items-center justify-center font-semibold w-full">
                    <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                      Info
                    </p>
                    <div
                      className="lg:w-10/12 md:w-10/12 sm:w-10/12"
                      style={{ height: "0", border: "1px solid #D3D3D3" }}
                    ></div>
                  </div>
                  <div className="mt-2 fields block">
                    {businessInformation &&
                      <div
                        className="enquiryId mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <p className="text-xs">Inquiry ID</p>
                        <b className="text-xs">
                          {businessInformation.inq_id ? businessInformation.inq_id : "N/A"}
                        </b>
                      </div>
                    }
                    {businessInformation &&
                      <div
                        className="IdNo mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          wordBreak: "break-all",
                        }}
                      >
                        <p className="text-xs">Industry</p>
                        <b className="text-xs">
                          {businessInformation.industry
                            ? businessInformation.industry
                            : "N/A"}
                        </b>
                      </div>
                    }
                    {businessInformation &&
                      <div
                        className="dob mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <p className="text-xs">Website</p>
                        <b className="text-xs">
                          {businessInformation.company
                            ? businessInformation.company
                            : "N/A"}
                        </b>
                      </div>
                    }
                    {repInformation && 
                      <div
                        className="nationality mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <p className="text-xs">Representative</p>
                        <b className="text-xs">
                          {repInformation.legal_name
                            ? repInformation.legal_name
                            : "N/A"}
                        </b>
                      </div>
                    }
                    {repInformation && 
                      <div
                        className="nationality mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <p className="text-xs">Email</p>
                        <b className="text-xs">
                          {repInformation.email
                            ? repInformation.email
                            : "N/A"}
                        </b>
                      </div>
                    }
                  </div>
                </div>
              </div>

              {onboardingEventData && (
                <div className="py-4 text-left">
                  <div className="flex flex-wrap">
                    <div className="flex items-center justify-center font-semibold w-full">
                      <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                        Event
                      </p>
                      <div
                        className="lg:w-10/12 md:w-10/12 sm:w-10/12"
                        style={{ height: "0", border: "1px solid #D3D3D3" }}
                      ></div>
                    </div>
                    <div className="w-full mt-2 fields block eventTimeline">
                      {onboardingEventData.map((element, ind) => (
                        <>
                          {element.event_desc && 
                            <>
                              <div data-index={ind} className="status mt-2">
                                <div
                                  className="w-full mt-2"
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "20% 80%",
                                  }}
                                >
                                  {element.passed === 0 && (
                                    <div className="imgTag">
                                      <img src={ElipsesOne} alt="created" />
                                    </div>
                                  )}
                                  {element.passed === 1 && (
                                    <div className="imgTag">
                                      <img src={ElipsesTwo} alt="created" />
                                    </div>
                                  )}
                                  {element.passed === 2 && (
                                    <div className="imgTag">
                                      <img src={ElipsesYellow} alt="created" />
                                    </div>
                                  )}
                                  {element.passed === 3 && (
                                    <div className="imgTag">
                                      <img src={ElipsesError} alt="created" />
                                    </div>
                                  )}
                                  <div className="text text-xs font-semibold">
                                    <p data-tip data-for={element.event_desc.replaceAll(' ', '')}>
                                      {element.event_desc}
                                    </p>
                                    {element.notes && 
                                      <ReactTooltip id={element.event_desc.replaceAll(' ', '')} place="top" effect="solid">
                                        <span>{element.notes}</span>
                                      </ReactTooltip>
                                    }
                                    <p className="createdDate font-normal mt-2">
                                      {Moment(element.created_at).format(
                                        "MM-DD-YYYY"
                                      )}{" "}
                                      | {Moment(element.created_at).format("hh:mm a")}
                                    </p>
                                  </div>
                                </div>
                                {onboardingEventDataLength - 1 !== ind && (
                                  <div
                                    className="w-full items-center justify-center"
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "20% 80%",
                                    }}
                                  >
                                    <div className="imgTag px-2">
                                      <img
                                        className="ml-1"
                                        src={VerticalLine}
                                        alt="created"
                                      />
                                    </div>
                                    <div className="text text-xs font-semibold"></div>
                                  </div>
                                )}
                              </div>
                            </>
                          }
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {!dataLoaded && (
        <>
          <ReactLoading
            className="margin-auto mt-3 mb-3 absolute"
            type={"bars"}
            style={{
              zIndex: "100000",
              top: "30%",
              left: "39%",
              width: "8%",
              height: "8%",
              fill: "#ee416f",
            }}
          />
          <div
            id="overlay"
            className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer"
            style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}
          ></div>
        </>
      )}
    </>
  );
}
