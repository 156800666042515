import axios from "axios";
import address from "../Address";


export function getuniqueid() {
    return axios.get(address + "/kyb/getuniqueidkyb");
}

export function savelanguage(formdata) {
    return axios.post(address + "/kyb/laungage", formdata);
}

export function savebasicinfo(formdata) {
    return axios.post(address + "/kyb/savebasickyb", formdata);
}

export function savebusiness(formdata) {
    return axios.post(address + "/kyb/savebusiness", formdata);
}

export function verifybusiness(formdata) {
    return axios.post(address + "/kyb/verifybusiness", formdata);
}

export function payment(formdata) {
    return axios.post(address + "/kyb/payment", formdata);
}

export function getrecordbyid(id) {
    return axios.get(address + "/kyb/getrecord/" + id);
}