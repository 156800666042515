import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import countryList from 'react-select-country-list';
import { checkprevkyc, checkexistingcogito, send_otp, checkcrypto } from "../../services/kyc/KycScreens";
import {KycMobContext} from "../../layouts/KYCMob";
import QrReader from 'react-web-qr-reader';

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import cycle from "../../assets/img/icons/cycle.svg";

export default function ScreenExists1() {
  const [disable, setDisable] = React.useState(false);
  const {data} = useContext(KycMobContext);
  const [facingMode, setFacingMode] = React.useState("user");
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [isCrypto, setIsCrypto] = useState(false);
  
  const [formdata, setFormdata] = useState({
    email: "",
    wallet_address: "",
  });

  React.useEffect(() => {
    checkcrypto().then(response => {
      // console.log("response: ", response);
      if (response.data.success) {
        setIsCrypto(response.data.is_crypto);
      }
    }).catch(error => {
      console.log("error: ", error);
    });
  }, [])

  const handleScan = (result) => {
    if (result) {
      setFormdata((values) => ({ ...values, ["wallet_address"]: result.data }));
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  const handleInputChange = (event) => {
    setMessage("");
    const name = event.target.name;
    const value = event.target.value;

    if (name === "email") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        setMessage("Invalid email address");
      }
      setFormdata((values) => ({ ...values, [name]: value }));
    } else {
      setFormdata((values) => ({ ...values, [name]: value }));
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      paddingTop: 0,
    }),
  };

  const handleSubmit = () => {
    setMessage("");

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formdata.email)) {
      setMessage("Invalid email address");
      return;
    }

    if (isCrypto) {
      if (formdata.wallet_address !== "" && formdata.email !== "") {
        let data_to_send = {
          email: formdata.email,
          wallet_address: formdata.wallet_address,
        };
  
        setDisable(true);
        checkprevkyc(data_to_send).then((response) => {
          if (response.data.success) {
            let inq_id = response.data.inq_id;
            let data = {
              email: formdata.email
            };
            send_otp(data).then((response) => {
              if (response.data.success) {
                history.push({
                  pathname: "/verify/exists/otp",
                  state: {
                    email: formdata.email,
                    inq_id: inq_id
                  },
                });
                window.location.reload(false);
              }
            }).catch((error) => {
              console.log("error: ", error);
            });
          } else {
            let data = {
              email: formdata.email,
              wallet: formdata.wallet_address
            }; 
            checkexistingcogito(data).then((response) => {
              if (response.data.success && !response.data.complete) {
                const redirecturl = "/verify/exists/d";
                history.push(redirecturl);
                window.location.reload(false);
              } else if (response.data.success && response.data.complete) {
                const redirecturl = "/verify/exists/e";
                history.push(redirecturl);
                window.location.reload(false);
              } 
            }).catch((error) => {
              console.log("error: ", error);
            });
          }
        }).catch((error) => {
          console.log("error: ", error);
        });
      } else {
        setMessage("All fields are mandatory!");
      }
    } else {
      if (formdata.email !== "") {
        let data_to_send = {
          email: formdata.email,
          wallet_address: formdata.wallet_address,
        };
  
        setDisable(true);
        checkprevkyc(data_to_send).then((response) => {
          if (response.data.success) {
            let inq_id = response.data.inq_id;
            let data = {
              email: formdata.email
            };
            send_otp(data).then((response) => {
              if (response.data.success) {
                history.push({
                  pathname: "/verify/exists/otp",
                  state: {
                    email: formdata.email,
                    inq_id: inq_id
                  },
                });
                window.location.reload(false);
              }
            }).catch((error) => {
              console.log("error: ", error);
            });
          } else {
            let data = {
              email: formdata.email,
              wallet: formdata.wallet_address
            }; 
            checkexistingcogito(data).then((response) => {
              if (response.data.success && !response.data.complete) {
                const redirecturl = "/verify/exists/d";
                history.push(redirecturl);
                window.location.reload(false);
              } else if (response.data.success && response.data.complete) {
                const redirecturl = "/verify/exists/e";
                history.push(redirecturl);
                window.location.reload(false);
              } 
            }).catch((error) => {
              console.log("error: ", error);
            });
          }
        }).catch((error) => {
          console.log("error: ", error);
        });
      } else {
        setMessage("Email address is mandatory!");
      }
    }
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };
  
  return (
    <>
      <div className="screenThree h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/verify/start"}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "25%" ,backgroundColor:data ? data.progressbar_color:"#ee416f"}}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1 style={{ lineHeight: 1, fontSize: "16px", height: "70px" }}>In order for us to start your KYC, You will need to provide us with an email address {isCrypto ? "and wallet address" : ""}</h1>
          </div>
          <Form className="informationSection mt-8">
            <div className="emailFields">
              <div className="inputfield email">
                <span className="label text-sm">Email Address</span>
                <Input 
                  type="email" 
                  name="email" 
                  className="input emailInput"
                  value={formdata.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {isCrypto && 
              <>
                <div className="dateField" style={{ marginBottom: "10px" }}>
                  <div className="inputfield dob">
                    <span className="label text-sm">Wallet Address</span>
                    <Input 
                      type="text" 
                      name="wallet_address" 
                      className="input lastnameInput" 
                      value={formdata.wallet_address}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <p style={{ textAlign: "center", fontSize: "12px", color: "#a8a8a8", marginBottom: "5px" }}>or Scan your wallet QR</p>
                <div class="mx-auto" style={{ width: "180px" }}>
                  <QrReader
                    onError={handleError}
                    onScan={handleScan}
                    facingMode={facingMode}
                  />
                  <button
                    className="camerafacingchangebutton flex flex-row items-center justify-center"
                    style={{outline: "none", width: "30%", margin: "0 auto", marginTop: "10px"}}
                    type="button"
                    onClick={() =>
                      setFacingMode(facingMode === "user" ? "environment" : "user")
                    }
                  >
                    <img src={cycle} style={{ width: "17px" }} alt="cycle" />
                  </button>
                </div>
              </>
            }

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-sm alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}
          </Form>
        </div>

        <div className="buttonAction">
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#ee416f",
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            Next
          </button>
        </div>

        <div className="footer" >
          <img className="footerImg" src={footerImg} alt="footer"/>
        </div>
      </div>
    </>
  );
}
  
