import React, { Component } from 'react';
import Chart from 'react-google-charts';
import { getriskscores } from "../../services/dashboard/Graphs";
import ReactLoading from 'react-loading';

import BracketImg from "../../assets/img/brackergeochart.png";

class GeoChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        colors: ['#074d2e','#086a3e', '#3f6728', '#778122', '#af9a1c', '#e7b416', '#e0941d', '#da7324', '#d3532b', '#cc3232'], // left to right; low to high
        legend: "none",
      },
      geoData: [
        ['Country', 'Risk Rating & Category', 'Inquiries'],
      ],
      dataLoaded: false
    }
  }

  componentDidMount() {
    getriskscores().then((response) => {
      if (response.data.success) {
        let geodata = [
          ['Country', 'Risk Rating', 'Inquiries']
        ];

        if (response.data.riskScores.length > 0 && response.data.inquiries.length > 0) {
          response.data.riskScores.map((scores) => {
            let matched = false;
            response.data.inquiries.forEach(inq => {
              if (scores.country === inq.country) {
                matched = true;
                let scorevalue = scores.score;
                let risk_category = (scorevalue>50) ? "High" : ((scorevalue>40 && scorevalue<50) ? "Medium-High" : ((scorevalue>30 && scorevalue<40) ? "Medium" : (scorevalue>20 && scorevalue<30) ? "Low-Medium" : "Low"));
                geodata.push([scores.country, scorevalue, inq.count]);
              } else if (!matched) {
                let scorevalue = scores.score;
                let risk_category = (scorevalue>50) ? "High" : ((scorevalue>40 && scorevalue<50) ? "Medium-High" : ((scorevalue>30 && scorevalue<40) ? "Medium" : (scorevalue>20 && scorevalue<30) ? "Low-Medium" : "Low"));
                geodata.push([scores.country, scorevalue, 0]);
              }
            });
          });
        } else if (response.data.riskScores.length > 0) {
          response.data.riskScores.map((scores) => {
            let scorevalue = scores.score;
            let risk_category = (scorevalue>50) ? "High" : ((scorevalue>40 && scorevalue<50) ? "Medium-High" : ((scorevalue>30 && scorevalue<40) ? "Medium" : (scorevalue>20 && scorevalue<30) ? "Low-Medium" : "Low"));
            geodata.push([scores.country, scorevalue, 0]);
          });
        } else {
          geodata.push(["Pakistan", 0, 0]);
        }

        this.setState({geoData: geodata});
        this.setState({dataLoaded: true});
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }

  render() {
    return (
        <div className="relative flex min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg flex-wrap p-4">
            <div className="headingWatchList p-4 px-12 pb-6">
                <h4 className="font-bold text-base py-2" style={{ borderBottom: "2px solid #ee416f" }}>Distribution of Inquiries by Country</h4>
            </div>
            <div className="flex flex-row w-full" style={{ height: "520px" }}>
                <div className="relative w-full flex flex-col">
                  {!this.state.dataLoaded ? 
                    <ReactLoading
                      className="margin-auto mt-3 mb-3"
                      type={"bars"}
                      color={"#ee416f"}
                      height={"20%"}
                      width={"20%"}
                    />
                    :
                    <>
                      <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="GeoChart"
                        data={this.state.geoData}
                        // Note: you will need to get a mapsApiKey for your project.
                        // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                        // mapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        rootProps={{ 'data-testid': '1' }}
                        options={this.state.options}
                      />
                      <div className="flex flex-row flex-wrap w-full justify-between">
                        <div className="geochartlegend w-full flex flex-row mb-4 mt-4 items-center">
                          <p className="text-sm font-bold mr-2">Low Risk</p>
                          <div className="flex flex-col">
                            <div className="w-full flex flex-row">
                              {this.state.options.colors.map((ele, ind) => (
                                <div data-index={ind} className="color" style={{ backgroundColor: ele }}></div>
                              ))}
                            </div>
                            <div className="w-full flex flex-row">
                              <p className="legendMarkers text-xs">0-9</p>
                              <p className="legendMarkers text-xs">10-19</p>
                              <p className="legendMarkers text-xs">20-29</p>
                              <p className="legendMarkers text-xs">30-39</p>
                              <p className="legendMarkers text-xs">40-49</p>
                              <p className="legendMarkers text-xs">50-59</p>
                              <p className="legendMarkers text-xs">60-69</p>
                              <p className="legendMarkers text-xs">70-79</p>
                              <p className="legendMarkers text-xs">80-89</p>
                              <p className="legendMarkers text-xs">90-100</p>
                            </div>
                          </div>
                          <p className="text-sm font-bold ml-2">High Risk</p>
                        </div>
                        <div className="geochart-legendTable">
                          <table>
                            <tbody>
                              <tr>
                                <td colSpan={'2'} className="riskheading">Risk Categories</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="legend-colors" style={{ backgroundColor: "rgb(7, 77, 46)" }}></div>
                                </td>
                                <td>Low</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="legend-colors" style={{ backgroundColor: "rgb(63, 103, 40)" }}></div>
                                </td>
                                <td>Low-Medium</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="legend-colors" style={{ backgroundColor: "rgb(231, 180, 22)" }}></div>
                                </td>
                                <td>Medium</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="legend-colors" style={{ backgroundColor: "rgb(218, 115, 36)" }}></div>
                                </td>
                                <td>Medium-High</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="legend-colors" style={{ backgroundColor: "rgb(204, 50, 50)" }}></div>
                                </td>
                                <td>High</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  }
                </div>
            </div>
        </div>
    )
  }
}

export default GeoChart