import React from "react";
import countryList from "react-select-country-list";
import "react-circular-progressbar/dist/styles.css";
import { getOpenSanctionRecordIndividual, getPepRecords, getListedInDetailPage } from "../../services/AMLInquire";
import { sanctionEntitySearch, sanctionSearch, sanctionActiveLists } from "../../services/aml/Screening";
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import ReactLoading from "react-loading";
import { Link, useParams } from "react-router-dom";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";
import leftArrow from "../../assets/img/leftArrowBlack.png";
import rightArrow from "../../assets/img/rightArrowBlack.png";

export default function AMLScreeningDetail(props) {
  const history = useHistory();
  const state_data = props.location.state;
  const { entity_id, individualscreening, batchscreening } = useParams();
  // const entity_id = state_data["entity_id"];
  // const individualscreening = state_data["individualscreening"];
  // const batchscreening = state_data["batchscreening"];
  const countryoptions = React.useMemo(() => countryList().getData(), []);
  countryoptions.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  const [indData, setIndData] = React.useState([]);
  const [listData, setListData] = React.useState([]);
  const [countMatched, setCountMatched] = React.useState(0);
  const [totalListCount, setTotalListCount] = React.useState(0);

  const [sanctionBtn,setSanctionBtn] = React.useState("")
  const [pepBtn,setPepBtn] = React.useState("")
  const [adverseBtn,setAdverseBtn] = React.useState("")


  const [individualActive, setIndividualActive] = React.useState(
    individualscreening ? true : false
  );
  const [corporateActive, setCorporateActive] = React.useState(
    batchscreening ? true : false
  );

  const [santionActive, setSanctionActive] = React.useState(true);
  const [pepActive, setPepActive] = React.useState(false);
  const [adverseActive, setAdverseActive] = React.useState(false);

  const [pepData, setPepData] = React.useState([]);
  const [pepDataLoaded, setPepDataLoaded] = React.useState(true);
  const [sanctionDataLoaded, setSanctionDataLoaded] = React.useState(true);
  const [indDataLoaded, setIndDataLoaded] = React.useState(true);
  const [potMatchArr, setPotMatchArray] = React.useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  function setActivePotMatchCardLeft(id) {
    if (id > 1) {
      let items = [...potMatchArr];
      items[id - 1] = false;
      items[id - 2] = true;
      setPotMatchArray(items);
    }
  }

  function setActivePotMatchCardRight(id) {
    if (id < 10) {
      let items = [...potMatchArr];
      items[id - 1] = false;
      items[id] = true;
      setPotMatchArray(items);
    }
  }

  function setIndivActive() {
    setIndividualActive(true);
    setCorporateActive(false);
  }

  function setCorpActive() {
    setIndividualActive(false);
    setCorporateActive(true);
  }

  function sanctionActiveFunc() {
    setSanctionActive(true);
    setPepActive(false);
    setAdverseActive(false);
  }

  function pepActiveFunc() {
    setSanctionActive(false);
    setPepActive(true);
    setAdverseActive(false);
  }

  function adverseActiveFunc() {
    setSanctionActive(false);
    setPepActive(false);
    setAdverseActive(true);
  }

  React.useEffect(()=>{
    if(entity_id && entity_id.charAt(0) == "Q") {
      // console.log("inddata: ", indData);
      if ('topics' in indData) {
        indData.topics.map((ele) => {
          // console.log("ele: ", ele);
          if(ele.value == "role.pep") {
            setPepDataLoaded(false);
            getPepRecords(entity_id).then((response) => {
              // console.log("response: ", response);
              setPepData(response.data.results[0]);
              setPepDataLoaded(true);
            }).catch((error) => {
              console.log("error ", error);
            })
            pepActiveFunc();
            setSanctionBtn("disabled");
            setAdverseBtn("disabled");
            setPepBtn("");
          }
        })
      }
    }  
  },[indData.length])

  React.useEffect(()=>{
    if(entity_id) {
      if (indData) {
        if (indData.name) {
          if (indData.name.length > 0) {
            let formdata = {
              name: indData.name[0],
              birthYear: "",
              identifier: "",
              country: "",
              checks: ["sanction", "role.pep", "debarment", "fin.bank", "role.oligarch", "role.rca", "crime", "crime.boss", "crime.fin", "crime.terror", "crime.theft", "crime.war"]
            }
            setSanctionDataLoaded(false);
            sanctionSearch(formdata).then((response) => {
              if (response.data.success) {
                sanctionActiveLists().then((resp) => {
                  if (resp.data.success) {
                    setTotalListCount(resp.data.count);
                    setCountMatched(response.data.results.length);
                    // setTotalListCount(response.data.total_lists);
                    if (response.data.results.length > 0) {
                      setListData(response.data.results);
                    }
                    setSanctionDataLoaded(true);
                  }
                }).catch((err) => {
                  console.log("error: ", err);
                });
              }
            }).catch((error) => {
              console.log("error: ", error);
            })
          }
        }
      }
    }  
  },[indData])

  React.useEffect(() => {
     if (entity_id) {   
      setIndDataLoaded(false);   
      sanctionEntitySearch(entity_id)
        .then((response) => {
          if (response.data.success) {
            console.log("response: ", response);
            setIndData(response.data.results);
            setIndDataLoaded(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      history.push("/aml/amlscreeninginquire");
      window.location.reload();
    }
  }, []);

  const openDetail = (entity_id) => {
    if (entity_id) {
      setIndDataLoaded(false);   
      sanctionEntitySearch(entity_id)
        .then((response) => {
          if (response.data.success) {
            setIndData(response.data.results);
            setIndDataLoaded(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      history.push("/aml/amlscreeninginquire");
      window.location.reload();
    }
  }

  return (
    <>
      <div className="amlscreening amldetailscreen flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="w-full flex flex-row amlDetailSections">
              <div className="scrollmax745 lg:w-6/12 h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white mr-4">
                {potMatchArr &&
                  potMatchArr.map((potMatch, index) => (
                    <div key={index}>
                      {potMatch && (
                        <>
                          {indDataLoaded && 
                            <div className="p-4">
                              {/* <div className="topButtons flex flex-row justify-between items-center">
                                <div className="sanction lg:w-4/12 mr-8">
                                  <button
                                    id="sanctionBtn"
                                    className={"w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonRed mb-2 text-xs "+sanctionBtn}
                                    onClick={sanctionActiveFunc}
                                    disabled={(sanctionBtn === "disabled") ? true : false}
                                  >
                                    Sanction
                                  </button>
                                </div>
                                <div className="pep lg:w-4/12 mr-8">
                                  <button
                                    id="pepBtn"
                                    className={"w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen mb-2 text-xs "+pepBtn}
                                    onClick={pepActiveFunc}
                                    disabled={(pepBtn === "disabled") ? true : false}
                                  >
                                    PEP Class
                                  </button>
                                </div>
                                <div className="adverseMedia lg:w-4/12">
                                  <button
                                    id="adverseBtn"
                                    className={"w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonYellow mb-2 text-xs "+adverseBtn}
                                    onClick={adverseActiveFunc}
                                    disabled={(adverseBtn === "disabled") ? true : false}
                                  >
                                    Adverse Media
                                  </button>
                                </div>
                              </div> */}

                              <div className="block w-full overflow-x-auto">
                                <table className="items-center bg-transparent border-collapse w-full">
                                  <thead className="">
                                    <tr>
                                      <th className=" align-middle py-3 text-xs whitespace-nowrap font-semibold text-left text-black-600 teamSettingTableheading">Type</th>
                                      <th className=" align-middle py-3 text-xs whitespace-nowrap font-semibold text-left text-black-600 teamSettingTableheading">Person</th>

                                    </tr>
                                  </thead>
                                  <tbody className="amlTableBody">
                                    {Object.entries(indData).map(([key, ele]) => (
                                      <>
                                        {ele.length > 0 && 
                                          <tr data-index={key} className="">
                                            <td className=" border-t-0 align-top border-l-0 border-r-0 text-xs whitespace-nowrap text-left teamSettingTable">
                                              {key}
                                            </td>
                                            <td className="flex flex-wrap">
                                              <div 
                                                className="border-t-0 mr-3 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable"
                                                style={{ width: "350px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "break-spaces" }}
                                              >
                                                {ele.map((child, key) => (
                                                  <>
                                                    {child} {(key < (ele.length - 1) ? " - " : " ")} 
                                                  </>
                                                ))}
                                              </div>
                                            </td>
                                          </tr>
                                        }
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              <div className="matchPercentage pt-6 flex flex-col">
                                <h4 className="text-sm font-bold">
                                  Match Percentage
                                </h4>
                                <div
                                  className="relative pt-1"
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "90% 10%",
                                  }}
                                >
                                  <div className="overflow-hidden h-5 mb-4 text-xs flex rounded greyBg">
                                    <div
                                      style={{ width: "60%" }}
                                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bgGreen"
                                    ></div>
                                  </div>
                                  <p className="text-right font-bold text-xs">
                                    60%
                                  </p>
                                </div>
                              </div>
                              <div className="bottomActionButtons flex flex-row justify-center w-full pt-6">
                                <div className="sanction lg:w-4/12 mr-8">
                                  <button className="w-full py-4 px-4 rounded-lg font-semibold text-white-400 buttonYellow mb-2 text-xs">
                                    False Match
                                  </button>
                                </div>
                                <div className="pep lg:w-4/12">
                                  <button className="w-full py-4 px-4 rounded-lg font-semibold text-white-400 buttonGreen mb-2 text-xs">
                                    Positive Match
                                  </button>
                                </div>
                              </div>
                            </div>
                          }
                          {!indDataLoaded && 
                            <ReactLoading
                              className="margin-auto mt-3 mb-3"
                              type={"bars"}
                              color={"#ee416f"}
                              height={"50px"}
                              width={"50px"}
                            />
                          }
                        </>
                      )}
                    </div>
                  ))}
              </div>
              {santionActive && (
                <div className="scrollmax745 sanction lg:w-6/12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white p-4">
                  <div className="mainHeader w-full flex">
                    <div className="adverseMedia w-full">
                      <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonRed mb-2 text-xs text-left flex justify-between items-center">
                        Sanctions
                        {sanctionDataLoaded && 
                          <p>
                            {countMatched} results found{" "}
                            <small className="text-xs font-light">
                              against {totalListCount} list
                            </small>
                          </p>
                        }
                      </button>
                    </div>
                  </div>

                  {sanctionDataLoaded && 
                    <div className="listKYC p-2 pt-2">
                      <h2 className="text-base font-bold pl-3">Also listed In</h2>
                      <div className="tablesSanction w-full pt-2">
                        {listData.length > 0 &&
                          listData.map((item, index) => (
                            <div className="table" data-index={index}>
                              <div className="w-full flex justify-between items-center greyBg whiteText rounded-lg p-4 cursor-pointer" onClick={() => openDetail(item.entity_id)}>
                                <div className="md:w-12/12">
                                  <h4 className="text-xs">
                                    {item.dataset ? item.dataset : "N/A"}
                                  </h4>
                                </div>
                                <div className="md:w-4/12 navigator flex justify-between items-center">
                                  {/* <h5 className="text-sm">Name Matched</h5> */}
                                </div>
                              </div>
                              <div className="rounded-lg mt-2 greybg p-5">
                                <table className="w-full table-fixed">
                                  <tbody>
                                    <tr>
                                      <td className="font-light text-xs">Name</td>
                                      <td className="font-semibold text-xs">
                                        {item.name ? item.name : "N/A"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs">
                                        Date of Birth
                                      </td>
                                      <td className="font-semibold text-xs">
                                        {item.birthDate ? item.birthDate : "N/A"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs">
                                        ID Number
                                      </td>
                                      <td className="font-semibold text-xs">
                                        {item.identifier ? item.identifier : "N/A"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs">
                                        Country
                                      </td>
                                      {item.country ?
                                        <>
                                          {countryoptions.map((country) =>(
                                            <>
                                              {(country.value.toLowerCase() === item.country) && 
                                                <td className="font-semibold text-xs">{country.label}</td>
                                              } 
                                            </>
                                          ))}
                                        </>
                                        :
                                        <>
                                          {item.nationality ?
                                            <>
                                              {countryoptions.map((country) =>(
                                                <>
                                                  {(country.value.toLowerCase() === item.country) && 
                                                    <td className="font-semibold text-xs">{country.label}</td>
                                                  } 
                                                </>
                                              ))}
                                            </>
                                            :
                                            <td className="font-semibold text-xs">{"N/A"}</td>
                                          }
                                        </>
                                      }
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  }

                  {!sanctionDataLoaded && 
                    <ReactLoading
                      className="margin-auto mt-3 mb-3"
                      type={"bars"}
                      color={"#ee416f"}
                      height={"50px"}
                      width={"50px"}
                    />
                  }
                </div>
              )}
              {pepActive && (
                <div className="scrollmax745 pepclass lg:w-6/12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white p-4">
                  <div className="mainHeader w-full flex">
                    <div className="adverseMedia w-full">
                      <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonGreen mb-2 text-xs text-left">
                        PEP Class
                      </button>
                    </div>
                  </div>
                  {pepDataLoaded && 
                    <>
                      <div className="detailsTable pt-8">
                        <table className="w-full table-fixed">
                          <tbody className="amlTableBody">
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Name</td>
                              <td className="capitalize text-xs align-top">{pepData.name ? pepData.name : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Date of Birth</td>
                              <td className=" text-xs align-top">{pepData.birthDate ? Moment(pepData.birthDate.substring(1)).format("MM-DD-YYYY") : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Place of Birth</td>
                              <td className="capitalize text-xs align-top">{pepData.placeOfBirth ? pepData.placeOfBirth : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Gender</td>
                              <td className="capitalize text-xs align-top">{pepData.gender ? pepData.gender : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Father Name</td>
                              <td className="capitalize text-xs align-top">{pepData.fatherName ? pepData.fatherName : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Country of Residence</td>
                              <td className="capitalize text-xs align-top">{pepData.country ? pepData.country : "N/A"} </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Country of Citizenship</td>
                              <td className="capitalize text-xs align-top">{pepData.countryOfCitizenship ? pepData.countryOfCitizenship : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Description</td>
                              <td className="capitalize text-xs align-top">{pepData.description ? pepData.description : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Wikipedia URL</td>
                              <td className=" text-xs align-top" style={{wordBreak:"break-all"}}>
                                <a className="pepClassWikiLink" href={pepData.wiki_url}>{pepData.wiki_url}</a>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">
                                Aliases
                              </td>
                              {pepData.aliases && 
                                <td className="capitalize flex flex-wrap  align-top">{pepData.aliases.map((ele)=>{
                                  return(
                                    <p className=" text-xs"> {ele}{"  "} , </p>
                                  )})}
                                </td>
                              }
                            </tr>
                            <tr>
                              <td className="font-light text-xs align-top"></td>
                              <td className="font-light text-xs align-top" style={{ width: "150px" }}></td>
                              <td className="font-light text-xs align-top"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="images pt-8">
                        <div className="heading">
                          <h2 className="text-base font-bold">Images</h2>
                          <div className="imageGrid pt-6">
                          {pepData.images&&
                          pepData.images.map((ele)=>{
                            return(
                              <img
                              alt="..."
                              src={ele}
                              style={{height:"180px",objectFit:"cover"}}
                              className="shadow-xl align-middle border-none rounded"
                            />
                            )
                          })}
                            
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {!pepDataLoaded && 
                    <ReactLoading
                      className="margin-auto mt-3 mb-3"
                      type={"bars"}
                      color={"#ee416f"}
                      height={"50px"}
                      width={"50px"}
                    />
                  }
                </div>
              )}
              {adverseActive && (
                <div className="scrollmax745 adverseMedia lg:w-6/12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white p-4">
                  <div className="mainHeader w-full flex">
                    <div className="adverseMedia w-full">
                      <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonYellow mb-2 text-xs text-left">
                        Adverse Media
                      </button>
                    </div>
                  </div>
                  <div className="mediaReports pt-6">
                    <div className="heading">
                      <h2 className="text-base font-bold">Media Reports</h2>
                    </div>
                    <div className="mainNews pt-2">
                      <div className="news mb-4">
                        <h3 className="newsHeader text-xs font-bold underline">
                          CNNPolitics - Political News, Analysis and Opinion
                        </h3>
                        <small className="text-xs">
                          edition.cnn.com › politics
                        </small>
                        <p className="text-xs">
                          <small className="font-light">3 days ago -</small>{" "}
                          Politics at CNN has news, opinion and analysis of
                          American and global politics Find news and video about
                          elections, John snow the White House, the U.N and much
                          more. Politics at CNN has news, opinion and analysis
                          of American and global politics Find news and video
                          about elections.
                        </p>
                      </div>
                      <div className="news mb-4">
                        <h3 className="newsHeader text-xs font-bold underline">
                          CNNPolitics - Political News, Analysis and Opinion
                        </h3>
                        <small className="text-xs">
                          edition.cnn.com › politics
                        </small>
                        <p className="text-xs">
                          <small className="font-light">3 days ago -</small>{" "}
                          Politics at CNN has news, opinion and analysis of
                          American and global politics Find news and video about
                          elections, John snow the White House, the U.N and much
                          more. Politics at CNN has news, opinion and analysis
                          of American and global politics Find news and video
                          about elections.
                        </p>
                      </div>
                      <div className="news mb-4">
                        <h3 className="newsHeader text-xs font-bold underline">
                          CNNPolitics - Political News, Analysis and Opinion
                        </h3>
                        <small className="text-xs">
                          edition.cnn.com › politics
                        </small>
                        <p className="text-xs">
                          <small className="font-light">3 days ago -</small>{" "}
                          Politics at CNN has news, opinion and analysis of
                          American and global politics Find news and video about
                          elections, John snow the White House, the U.N and much
                          more. Politics at CNN has news, opinion and analysis
                          of American and global politics Find news and video
                          about elections.
                        </p>
                      </div>
                      <div className="news mb-4">
                        <h3 className="newsHeader text-xs font-bold underline">
                          CNNPolitics - Political News, Analysis and Opinion
                        </h3>
                        <small className="text-xs">
                          edition.cnn.com › politics
                        </small>
                        <p className="text-xs">
                          <small className="font-light">3 days ago -</small>{" "}
                          Politics at CNN has news, opinion and analysis of
                          American and global politics Find news and video about
                          elections, John snow the White House, the U.N and much
                          more. Politics at CNN has news, opinion and analysis
                          of American and global politics Find news and video
                          about elections.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
