import axios from "axios";
import address from "../Address";

export function viewallprocessed(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/kyb/list", formdata, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function viewsingleright(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/kyb/case", formdata, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getdetail(inqId) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/kyb/show/" + inqId, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}