import React, { PureComponent, createRef, Component} from "react";
import { ForceGraph2D } from 'react-force-graph';
import SpriteText from 'three-spritetext';
import { withSize } from "react-sizeme";

import IconInquiry from "../../assets/img/network/icons/inquiry.svg";
import IconBrowser from "../../assets/img/network/icons/browser.svg";
import IconIdentification from "../../assets/img/network/icons/identification.svg";
import IconIp from "../../assets/img/network/icons/ip.svg";
import IconPhone from "../../assets/img/network/icons/phone.svg";
import IconEmail from "../../assets/img/network/icons/email.svg";
import IconDevice from "../../assets/img/network/icons/devicefingerprint.svg";

const withSizeHOC = withSize({monitorWidth:true, monitorHeight:false, noPlaceholder:true});
const NODE_R = 8;

class NetworkChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      highlightNodes: new Set(),
      highlightLinks: new Set(),
      hoverNode: null,
    }
    this.containerRef = createRef()
  }
  
  updateHighlight = () => {
    this.setState({highlightNodes: this.state.highlightNodes});
    this.setState({highlightLinks: this.state.highlightLinks});
  };

  handleNodeHover = node => {
    this.state.highlightNodes.clear();
    this.state.highlightLinks.clear();
    if (node) {
      this.state.highlightNodes.add(node);
      node.neighbors.forEach(neighbor => this.state.highlightNodes.add(neighbor));
      node.links.forEach(link => this.state.highlightLinks.add(link));
    }

    this.setState({hoverNode: this.state.hoverNode || null});
    this.updateHighlight();
  };

  handleLinkHover = link => {
    this.state.highlightNodes.clear();
    this.state.highlightLinks.clear();

    if (link) {
      this.state.highlightLinks.add(link);
      this.state.highlightNodes.add(link.source);
      this.state.highlightNodes.add(link.target);
    }

    this.updateHighlight();
  };

  paintRing = (node, ctx) => {
    // add ring just for highlighted nodes
    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_R * 0.5, 0, 2 * Math.PI, false);
    // ctx.fillStyle = node === this.state.hoverNode ? '#ebedf2' : '#ee416f';
    ctx.fill();
  };

  handleClick = (node) => {
    if (node['type'] === "inquiry") {
      // console.log("node: ", node);
      if (node["name"] != "" || node["name"] != null) {
        this.props.openRightSide(node["name"])
      }
    }
  }

  nodeCanvasObjectImage = (node, ctx, globalScale) => {
    const image = new Image();
    
    // icon selection
    if (node['type'] === "inquiry") {
      image.src = IconInquiry;
    } else if (node['type'] === "email") {
      image.src = IconEmail;
    } else if (node['type'] === "ip") {
      image.src = IconIp;
    } else if (node['type'] === "bfingerprint") {
      image.src = IconBrowser;
    } else if (node['type'] === "dfingerprint") {
      image.src = IconDevice;
    } else if (node['type'] === "id_no") {
      image.src = IconIdentification;
    } else if (node['type'] === "ph_no") {
      image.src = IconPhone;
    }

    image.style = "width: 20px";
    const scale = 0.2 * globalScale; // Adjust the scaling factor as needed

    ctx.drawImage(
      image,
      node.x - scale * image.width / 2,
      node.y - scale * image.height / 2,
      scale * image.width,
      scale * image.height
    );
  };

  render() {
    var width = this.props.size.width

    return (
      <ForceGraph2D
        ref={this.containerRef}
        graphData={this.props.myData}
        width={width}
        linkDirectionalArrowLength={3.5}
        linkDirectionalArrowRelPos={1}
        
        linkPositionUpdate={(sprite, { start, end }) => {
          const middlePos = Object.assign(...['x', 'y', 'z'].map(c => ({
            [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
          })));

          Object.assign(sprite.position, middlePos);
        }}

        onNodeDragEnd={node => {
          node.fx = node.x;
          node.fy = node.y;
          node.fz = node.z;
        }}
        // onEngineStop={() => this.containerRef.current.zoomToFit(100)}

        autoPauseRedraw={false}
        linkWidth={link => this.state.highlightLinks.has(link) ? 5 : 1}
        linkDirectionalParticles={4}
        linkDirectionalParticleWidth={link => this.state.highlightLinks.has(link) ? 4 : 0}
        // nodeCanvasObjectMode={node => this.state.highlightNodes.has(node) ? 'before' : undefined}
        // nodeCanvasObject={this.paintRing}
        onNodeHover={this.handleNodeHover}
        onLinkHover={this.handleLinkHover}
        onNodeClick={this.handleClick}

        nodeCanvasObject={this.nodeCanvasObjectImage}
        nodePointerAreaPaint={this.nodePaint}
      />
    )
  }
}

export default withSizeHOC(NetworkChart)