import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';


class Donut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: props.values,
                    
      options: {
        labels: [
          // "Returning",
          "Incomplete",
          "Manual Review",
          "Updated",
          "Approved",
          "Duplicate",
          "Decline",
        ],
        chart: {
          width: 500,
          type: 'donut',
          fontFamily: 'Gilroy',
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: "Inquiry Breakdown",
              }
            }
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: "10px",
                  color: "#263040",
                  FontWeight: "500",
                  FontFamily: "Gilroy",
                  label: "Total No. of Inquiries",
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: [
          // '#b72e58',
          '#FFC102',
          '#2A94FC',
          '#a959a3',
          '#15E49A',
          '#000000',
          '#FA364C'
        ],
        responsive: [{
          breakpoint: 600,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom',
              customLegendItems: [
                // "<a style='all:unset; cursor: pointer' href='case/casemanagement/returning'><p style='font-family: Gilroy; display: inline; color: #b72e58; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[0]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 20px; color: black'>Returning Cases</p></a>",  
                "<a style='all:unset; cursor: pointer' href='case/casemanagement/review'><p style='font-family: Gilroy; display: inline; color: #FFC102; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[0]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 20px; color: black'>Incomplete Cases</p></a>",
                "<a style='all:unset; cursor: pointer' href='case/casemanagement/complete'><p style='font-family: Gilroy; display: inline; color: #2A94FC; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[1]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 20px; color: black'>Manual Review Cases</p></a>",  
                "<a style='all:unset; cursor: pointer' href='case/casemanagement/revision'><p style='font-family: Gilroy; display: inline; color: #a959a3; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[2]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 20px; color: black'>Updated cases</p></a>", 
                "<a style='all:unset; cursor: pointer' href='case/casemanagement/approve'><p style='font-family: Gilroy; display: inline; color: #15E49A; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[3]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 20px; color: black'>Approved Cases</p></a>",  
                "<a style='all:unset; cursor: pointer' href='case/casemanagement/duplicate'><p style='font-family: Gilroy; display: inline; color: #000000; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[4]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 20px; color: black'>Duplicate Cases</p></a>",  
                "<a style='all:unset; cursor: pointer' href='case/casemanagement/decline'><p style='font-family: Gilroy; display: inline; color: #FA364C; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[5]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 20px; color: black'>Declined Cases</p></a>",  
              ],
            }
          }
        }],
        stroke: {
          width: 10
        },
        legend: {
          customLegendItems: [
            // "<a style='all:unset; cursor: pointer' href='case/casemanagement/returning'><div style='display:inline-flex; align-items: center;'><p style='width: 68px;font-family: Gilroy; display: inline; color: #b72e58; font-weight: bold; margin-left: 10px;'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[0]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 0; color: black'>Returning</p></div></a>",
            "<a style='all:unset; cursor: pointer' href='case/casemanagement/review'><div style='display:inline-flex; align-items: center;'><p style='width: 68px;font-family: Gilroy; display: inline; color: #FFC102; font-weight: bold; margin-left: 10px;'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[0]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 0; color: black'>Incomplete</p></div></a>",
            "<a style='all:unset; cursor: pointer' href='case/casemanagement/complete'><div style='display:inline-flex; align-items: center;'><p style='width: 68px;font-family: Gilroy; display: inline; color: #2A94FC; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[1]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 0; color: black'>Manual Review</p></div></a>",
            "<a style='all:unset; cursor: pointer' href='case/casemanagement/revision'><div style='display:inline-flex; align-items: center;'><p style='width: 68px;font-family: Gilroy; display: inline; color: #a959a3; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[2]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 0; color: black'>Updated</p></div></a>", 
            "<a style='all:unset; cursor: pointer' href='case/casemanagement/approve'><div style='display:inline-flex; align-items: center;'><p style='width: 68px;font-family: Gilroy; display: inline; color: #15E49A; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[3]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 0; color: black'>Approved</p></div></a>",
            "<a style='all:unset; cursor: pointer' href='case/casemanagement/duplicate'><div style='display:inline-flex; align-items: center;'><p style='width: 68px;font-family: Gilroy; display: inline; color: #000000; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[4]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 0; color: black'>Duplicate</p></div></a>",
            "<a style='all:unset; cursor: pointer' href='case/casemanagement/decline'><div style='display:inline-flex; align-items: center;'><p style='width: 68px;font-family: Gilroy; display: inline; color: #FA364C; font-weight: bold; margin-left: 10px'><span style='font-size: 16px ; top: -2px ; position: relative'>"+props.values[5]+"</span></p><p style='font-weight: 400; color: black; font-family: Gilroy; display: inline; margin-left: 0; color: black'>Declined</p></div></a>",
          ],
        }
      },
    };
  }

  render() {

    return (
        <div className="donutChartMain relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg" style={{ height: "350px" }}>
          <div className="w-full flex mt-2 items-center cardLineTopBar">
            <div className="px-4 mr-4 cursor-pointer "
              style={{  marginLeft:"20px" }}
            >
              <h4
                className="borderDefault summaryHeading py-2 font-bold text-base"
                style={{ borderBottom: "2px solid #ee416f" }}
              >
                Inquiries
              </h4>
            </div>
          </div>
          <div className="p-4 flex-auto">
            {/* Chart */}
            <div className="relative w-full h-full">
              <div className="donut xl:w-11/12 lg:w-11/12 md:w-11/12 sm:w-11/12 h-full">
                {/* <Chart options={this.state.options} series={this.state.series} type="donut" width="350" /> */}
                <ReactApexChart className="mx-auto donutChart" options={this.state.options} series={this.state.series} type="donut" width={"100%"}  height={"100%"} />
              </div>
              {/* <div className="totalInquiries flex flex-col absolute text-center">
                <h1 className="font-bold text-4xl"></h1>
                <small className="">Total No. of Inquiries</small>
              </div> */}
            </div>
          </div>
        </div>
    );
  }
}

export default Donut;