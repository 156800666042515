import React from "react";
import { Link, useLocation } from "react-router-dom";
import MapMultiple from "components/Maps/MapMultiple.js";
import { showteammember } from "services/settings/Team";
import { getallactivesessions, revokeaccesstoken, revokeallaccesstoken } from "services/settings/ActiveSession";
import Moment from 'moment';

import profileIcon from "../../assets/img/icons/profile-icon.png";
import profileIconClr from "../../assets/img/icons/profile-iconClr.png";

import teamIcon from "../../assets/img/icons/team-icon.png";
import teamIconClr from "../../assets/img/icons/team-iconClr.png";

import customizationIcon from "../../assets/img/icons/customization-icon.png";
import customizationIconClr from "../../assets/img/icons/customization-iconClr.png";

import auditIcon from "../../assets/img/icons/audit-icon.png";
import auditIconClr from "../../assets/img/icons/audit-iconClr.png";

import deviceLaptop from "../../assets/img/deviceLaptop.png";
import devicePhone from "../../assets/img/devicePhone.png";

export default function Profile() {
  const [generalActive, setGeneralActive] = React.useState(true);
  const [activeSessionActive, setActiveSessionActive] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [activeSessionData, setActiveSessionData] = React.useState({});
  const [isShown, setIsShown] = React.useState("");
  const [locations, setLocations] = React.useState([]);
  const storage = window.localStorage;
  const user = JSON.parse(storage.getItem("user"));
  const token = storage.getItem("token");
  // console.log("user: ", user);

  // google maps script include
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_API_KEY;
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, []);

  React.useEffect(() => {
    if (user) {
      showteammember(user.id)
        .then((response) => {
          // console.log(response.data.user[0]);
          setUserData(response.data.user[0]);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    getallactivesessions().then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        setActiveSessionData(response.data.active_sessions);
        if (response.data.active_sessions.length>0) {
          let data = response.data.active_sessions.map(element => ({
            name: element.location,
            lat: element.latitude,
            long: element.longitude,
          }));
          setLocations(data);
        }
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }, []);

  const revokeAll = () => {
    revokeallaccesstoken().then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        window.location.reload();
      }
    }).catch((error) => {
      console.log("error: ", error)
    })
  }

  const revokeCurrent = (token) => {
    revokeaccesstoken(token).then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        window.location.reload();
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }

  return (
    <>
      <div className="amlscreening flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white border-0 w-full mb-6 shadow-lg settingResp">
              <div
                className="lg:w-2/12 md:w-2/12 sm:w-12/12 nav-bg-grey py-16 rounded-lg shadow-2xl settingSideBarBack"
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                <ul className="md:min-w-full flex flex-col list-none leftSidebarNavListMain settingSideBar rounded-lg">
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs  py-3 flex flex-row items-center font-bold block p-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/profile") !==
                          -1 || isShown === "profile"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/profile"
                      onMouseEnter={() => setIsShown("profile")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/profile") !==
                        -1 || isShown === "profile" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={profileIconClr}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={profileIcon}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Profile</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 flex flex-row items-center font-bold block px-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/team") !==
                          -1 || isShown === "team"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/team"
                      onMouseEnter={() => setIsShown("team")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/team") !== -1 ||
                      isShown === "team" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={teamIconClr}
                          style={{ width: "25px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={teamIcon}
                          style={{ width: "25px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Team</p>
                    </Link>
                  </li>
                  {/* <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 flex flex-row items-center font-bold block px-2 settingSidebarLink " +
                        (window.location.href.indexOf("/setting/kyc") !== -1 ||
                        isShown === "kyc"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/setting/kyc"
                      onMouseEnter={() => setIsShown("kyc")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/setting/kyc") !== -1 ||
                      isShown === "kyc" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={customizationIconClr}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={customizationIcon}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Customization</p>
                    </Link>
                  </li> */}
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs flex flex-row items-center  py-3 font-bold block px-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/audit") !==
                          -1 || isShown === "audit"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/audit"
                      onMouseEnter={() => setIsShown("audit")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/audit") !== -1 ||
                      isShown === "audit" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={auditIconClr}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={auditIcon}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="Regly logo"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Audit Logs</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 px-12 py-16">
                <div className="flex justify-between flex-row profileTopNav ">
                  <div className="flex ">
                    <div
                      className="rounded-lg px-12 py-2 mr-4 cursor-pointer"
                      style={
                        generalActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={() => {
                        setGeneralActive(true);
                        setActiveSessionActive(false);
                      }}
                    >
                      <h4 className="font-bold text-xs">General</h4>
                    </div>
                    <div
                      className="rounded-lg px-12 py-2 cursor-pointer"
                      style={
                        activeSessionActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={() => {
                        setGeneralActive(false);
                        setActiveSessionActive(true);
                      }}
                    >
                      <h4 className="font-bold text-xs">Active Sessions</h4>
                    </div>
                  </div>
                  {activeSessionActive && (
                    <div className="signoutdiv">
                      <button onClick={revokeAll}>
                        <h4 className="font-bold text-xs text-pink">
                          Signout from all devices
                        </h4>
                      </button>
                    </div>
                  )}
                </div>

                {generalActive && (
                  <>
                    <div className="w-full flex flex-col mt-12">
                      <div className="lg:w-6/12 md:w-12/12 sm:w-12/12 flex flex-col">
                        <img
                          alt="..."
                          src={
                            userData.team_detail
                              ? userData.team_detail.image !== ""
                                ? userData.team_detail.image
                                : require("assets/img/ProfilePicture.png")
                                    .default
                              : require("assets/img/ProfilePicture.png").default
                          }
                          className="shadow-xl rounded-full h-auto align-middle border-none max-w-120-px"
                        />

                        <div className="w-full flex flex-col justify-between mt-8">
                          <div className="flex flex-row">
                            <p className="lg:w-6/12 md:w-6/12 sm:w-12/12 text-xs font-normal">
                              Name
                            </p>
                            <p className="lg:w-6/12 md:w-6/12 sm:w-12/12 text-xs font-bold">
                              <b>{userData.name ? userData.name : ""}</b>
                            </p>
                          </div>
                          <div className="flex flex-row mt-4">
                            <p className="lg:w-6/12 md:w-6/12 sm:w-12/12 text-xs font-normal">
                              Role
                            </p>
                            <p className="lg:w-6/12 md:w-6/12 sm:w-12/12 text-xs font-bold">
                              <b>
                                {userData.team_detail
                                  ? userData.team_detail.role
                                  : ""}
                              </b>
                            </p>
                          </div>
                          <div className="flex flex-row mt-4">
                            <p className="lg:w-6/12 md:w-6/12 sm:w-12/12 text-xs font-normal">
                              Two Factor Authentication
                            </p>
                            <p className="lg:w-6/12 md:w-6/12 sm:w-12/12 text-xs font-bold">
                              <b>Enabled</b>
                            </p>
                          </div>
                          <div className="flex flex-row mt-4">
                            <p className="lg:w-6/12 md:w-6/12 sm:w-12/12 text-xs font-normal">
                              Password
                            </p>
                            <p className="lg:w-6/12 md:w-6/12 sm:w-12/12 text-xs font-bold">
                              <b>**********</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {activeSessionActive && (
                  <>
                    {(activeSessionData.length > 0) && 
                      <>
                        <div className="deviceshistory w-full flex flex-col mt-12">
                          {activeSessionData.map((session, i) => (
                            <div data-index={i} className="devicesrow py-6 px-4 flex flex-row justify-between items-center">
                              <div className="devicename flex flex-row">
                                <img
                                  src={session.is_mobile ? devicePhone : deviceLaptop}
                                  className="mr-4"
                                  style={{ width: "auto", height: "23px" }}
                                />
                                <div className="flex flex-col">
                                  <div className="flex flex-row activeSessionDevice">
                                    <p className="font-bold text-sm mr-4">
                                      {session.device_browser} on {session.device_os} - {session.ip_address}
                                    </p>
                                    {(token === session.access_token) && 
                                      <p className="bgGreen px-2 py-1 text-xs font-semibold rounded text-white currentDevice">
                                        CURRENT DEVICE
                                      </p>
                                    }
                                  </div>
                                  <div className="devicedetails flex flex-col mt-4">
                                    <p className="text-stone-500 text-xs pb-2">
                                      Location: <b>{session.location}</b>
                                    </p>
                                    <p className="text-stone-500 text-xs">
                                      Signed in on <b>{Moment(session.signed_in_on).format('DD-MM-YYYY')}  {Moment(session.signed_in_on).format('hh:mm a')}</b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="buttonRevoke pr-12">
                                {/* <button className="px-12 py-2">Revoke</button> */}
                                {!(token === session.access_token) && 
                                  <button className="text-xs" onClick={() => revokeCurrent(session.access_token)}>Revoke</button>
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="w-full px-4 mt-12">
                          <div className="2xl:w-12/12 lg:w-12/12 sm:w-12/12">
                            <div
                              className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded"
                              style={{ maxHeight: "400px" }}
                            >
                              <MapMultiple location={locations} />
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
