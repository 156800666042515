import React from "react";
import { useHistory } from "react-router-dom";
import { getuniqueid } from "services/kyb/KybBankScreens";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

export default function KybStart() {
  const history = useHistory();
  React.useEffect(() => {
    getuniqueid().then((response) => {
      if (response.data.success) {
        // console.log("response: ", response);
        const redirecturl = "/kybverif/a/"+response.data.kyc_id;
        history.push(redirecturl);
        window.location.reload();
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }, []);

  return (
    <>
      
    </>
  );
}
