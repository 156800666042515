import React, { useState,useContext} from "react";
import { Link,useHistory   } from "react-router-dom";
import { getreviewinfo, calculatechecks, savelanguage } from "services/kyc/KycScreens";
import {KycMobContext} from "../../layouts/KYCMob"
import ReactLoading from "react-loading";
import Moment from "moment";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import platform from "platform";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import edit from " ../../assets/img/kycmobile/edit.png";
import idVerRectImg from "../../assets/img/idVerRectImg.png";

export default function ScreenExists2() {
  const {data} = useContext(KycMobContext)
  const [dataLoaded, setDataLoaded] = React.useState(true);
  const history = useHistory();
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [basicInfo, setBasicInfo] = useState(null);
  const [idVerif, setIdVerif] = useState(null);
  const [addressVerif, setAddressVerif] = useState(null);
  const [walletETHVerif, setWalletETHVerif] = useState(null);
  const [walletADAVerif, setWalletADAVerif] = useState(null);
  const [idImages, setIdImages] = useState(null);
  const [addressImages, setAddressImages] = useState(null);
  const [disable, setDisable] = React.useState(false);

  const [latitude, setLatitude] = React.useState(null);
  const [longitude, setLongitude] = React.useState(null);
  var result_fingerprint = null;
  var fpPromise = null;

  function showPosition(position) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }

  React.useEffect(() => {
    getLocation();
    getreviewinfo(url).then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        setBasicInfo(response.data.basicinfo);
        setIdVerif(response.data.idverif);
        setIdImages(response.data.id_img);
        setAddressVerif(response.data.addressverif);
        setAddressImages(response.data.addr_img);
        setWalletETHVerif(response.data.wallet_ETH);
        setWalletADAVerif(response.data.wallet_ADA);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }, [])

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  const checks_calculate = () => {
    setDataLoaded(false);
    setDisable(true);
    calculatechecks(url).then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        // fpPromise = FingerprintJS.load({ apiKey: 'cAI4XJPsx0MaxCh5XnTK' });
        // ;(async () => {
        //   // Get the visitor identifier when you need it.
        //   const fp = await fpPromise;
        //   result_fingerprint = await fp.get({extendedResult: true});
          
        //   let visitor_id = result_fingerprint.visitorId;
        //   let os = result_fingerprint.os;
        //   let os_version = result_fingerprint.osVersion;
        //   let first_seen = result_fingerprint.firstSeenAt.global;
        //   let last_seen = result_fingerprint.lastSeenAt.global;
        //   let ip = result_fingerprint.ip;
        //   let browser_name = result_fingerprint.browserName;
        //   let browser_version = result_fingerprint.browserVersion;
        //   let incognito = result_fingerprint.incognito;

          let formdata = {
            inq_id: url,
            language: null,
            browser_name: null,
            browser_version: null,
            os: null,
            os_version: null,
            visitor_id: null,
            first_seen: null,
            last_seen: null,
            ip: null,
            incognito: null,
            latitude: latitude,
            longitude: longitude
          };
      
          savelanguage(formdata).then((response) => {
            if (response.data.success) {
              setDataLoaded(true);
              history.push("/verify/exists/c/"+url);
              window.location.reload(false);
            }
          }).catch((error) => {
            console.log("error: ", error)
          });
        // })();
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }

  return (
    <>
      <div className="screenTwentyFive h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"verify/exists/a"}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "90%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        {!dataLoaded &&
          <>
            <ReactLoading className="margin-auto mt-3 mb-3 absolute" type={"bars"} style={{ zIndex: "100000", top: "30%", left: "39%", width: "22%", height: "22%", fill: "#ee416f" }} />
            <div id="overlay" className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer" style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}></div>
          </> 
        }

        <div className="mainContent" >
          <div className="mainText">
            <h1>Review your Information</h1>
            <p className="text-center" style={{ color: "#a8a8a8" }}>If all good! Please click submit for verification.</p>
          </div>
          <div className="informationSection">
            <div className="block mb-3">
              <div className="headingText">
                <h5>Questionnaire</h5>
              </div>
              {basicInfo && 
                <>
                  <div className="fields">
                    <p className="title">Email</p>
                    <p className="value" id="email">{(basicInfo.email) ? basicInfo.email : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Full Name</p>
                    <p className="value" id="firstname">{(basicInfo.first_name) ? basicInfo.first_name : 'N/A'} {(basicInfo.last_name) ? basicInfo.last_name : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Date of Birth</p>
                    <p className="value" id="dob">{(basicInfo.dob) ? 
                      (Moment(basicInfo.dob, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                        ? Moment(basicInfo.dob).format("MM-DD-YYYY")
                        : Moment(basicInfo.dob, "DD/MM/YYYY").format("MM-DD-YYYY")
                      : 'N/A'}
                    </p>
                  </div>
                  <div className="fields">
                    <p className="title">Nationality</p>
                    <p className="value" id="nationality_0">{(basicInfo.nationality) ? basicInfo.nationality : 'N/A'}</p>
                  </div>
                </>
              }
            </div>

            <div className="block mb-3">
              <div className="headingText">
                <h5>Identity document</h5>
                <Link to={"/verify/exists/id/a/"+url}>
                  <img
                    src={edit}
                    alt="edit"
                    className="editButton cursor-pointer"
                    style={{ width: "auto" }}
                  />
                </Link>
              </div>
              {idVerif && 
                <>
                  <div className="fields" style={{wordWrap: "break-word" }} >
                    <p className="title">ID Card No</p>
                    <p className="value" id="idno">{(idVerif.id_nnum) ? idVerif.id_nnum : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Issuance Country</p>
                    <p className="value" id="nationality_1">{(idVerif.nationality) ? idVerif.nationality : 'N/A'}</p>
                  </div>
                  {idImages && 
                    <div className="idImage relative mt-2 mb-3">
                      <>
                        {idImages.front && 
                          <>
                            <Link
                              to={{
                                pathname: idImages.front
                                  ? idImages.front
                                  : "#",
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="pt-2 w-full pb-2"
                                src={
                                  idImages.front
                                    ? idImages.front
                                    : idVerRectImg
                                }
                                alt="idImage"
                                width={"200px"}
                                height={"200px"}
                                style={{
                                  maxHeight: "400px",
                                  objectFit: "cover",
                                  cursor: "zoom-in"
                                }}
                              />
                            </Link>
                            <p style={{ textAlign: "center", fontSize: "12px", color: "#a8a8a8" }}>(Image front)</p>
                          </>
                        }
                      </>
                      <>
                        {idImages.back && 
                          <>
                            <hr className="mt-2 py-2" />
                            <Link
                              to={{
                                pathname: idImages.back
                                  ? idImages.back
                                  : "#",
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="pt-2 w-full pb-2"
                                src={
                                  idImages.back
                                    ? idImages.back
                                    : idVerRectImg
                                }
                                alt="idImage"
                                width={"200px"}
                                height={"200px"}
                                style={{
                                  maxHeight: "400px",
                                  objectFit: "cover",
                                  cursor: "zoom-in"
                                }}
                              />
                            </Link>
                            <p style={{ textAlign: "center", fontSize: "12px", color: "#a8a8a8" }}>(Image back)</p>
                          </>
                        }
                      </>
                    </div>
                  }
                </>
              }
            </div>

            <div className="block mb-3">
              <div className="headingText">
                <h5>Proof of residence</h5>
                <Link to={"/verify/exists/add/a/"+url}>
                  <img
                    src={edit}
                    alt="edit"
                    className="editButton cursor-pointer"
                    style={{ width: "auto" }}
                  />
                </Link>
              </div>
              {addressVerif && 
                <>
                  <div className="fields">
                    <p className="title">Country</p>
                    <p className="value" id="country">{(addressVerif.country) ? addressVerif.country : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">City</p>
                    <p className="value" id="city">{(addressVerif.city) ? addressVerif.city : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Postal Code</p>
                    <p className="value" id="postal">{(addressVerif.postal) ? addressVerif.postal : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Address</p>
                    <p className="value" id="address">{(addressVerif.address) ? addressVerif.address : 'N/A'}</p>
                  </div>

                  {addressImages && 
                    <div className="idImage relative mt-2 mb-3">
                      <>
                        {addressImages.url && 
                          <>
                            {addressImages.ext === "pdf" ? 
                              <embed 
                                src={addressImages.url} 
                                type="application/pdf" 
                                width={"200px"}
                                height={"200px"} 
                                style={{width: "100%"}}
                              />
                              :
                              <Link
                                to={{
                                  pathname: addressImages.url
                                    ? addressImages.url
                                    : "#",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="pt-2 w-full pb-2"
                                  src={
                                    addressImages.url
                                      ? addressImages.url
                                      : idVerRectImg
                                  }
                                  alt="idImage"
                                  width={"200px"}
                                  height={"200px"}
                                  style={{
                                    maxHeight: "400px",
                                    objectFit: "cover",
                                    cursor: "zoom-in"
                                  }}
                                />
                              </Link>
                            }
                          </>
                        }
                      </>
                    </div>
                  }
                </>
              }
            </div>

            <div className="block">
              <div className="headingText">
                <h5>Wallet Information</h5>
                <Link to={"/verify/exists/wallet/a/"+url}>
                  <img
                    src={edit}
                    alt="edit"
                    className="editButton cursor-pointer"
                    style={{ width: "auto" }}
                  />
                </Link>
              </div>
              {walletETHVerif && 
                <>
                  {/* <div className="fields">
                    <p className="title">Network</p>
                    <p className="value" id="country">Ethereum</p>
                  </div> */}
                  <div className="fields">
                    <p className="title">Address</p>
                    <p className="value" id="city" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{(walletETHVerif.wallet_address) ? walletETHVerif.wallet_address : 'N/A'}</p>
                  </div>
                  {/* <div className="fields">
                    <p className="title">TGE Contribution</p>
                    <p className="value" id="postal">{walletETHVerif.tge_contribution}</p>
                  </div> */}
                  {/* <div className="fields">
                    <p className="title">AGIX Balance</p>
                    <p className="value" id="postal">{walletETHVerif.agix_balance}</p>
                  </div> */}
                </>
              }
            </div>
          </div>
        </div>

        <div className="buttonAction">
          {/* <Link to={"/verify/q/"+url}> */}
          <button onClick={checks_calculate} disabled={disable} style={{backgroundColor:data?data.button_color:"#ee416f"}} className="action">Submit</button>
          {/* </Link> */}
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}