import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import {KycMobContext} from "../../layouts/KYCMob";
import { isMobile } from 'react-device-detect';
import { saveevent } from "../../services/kyc/KycScreens";
import { savebusiness } from "services/kyb/KybBankScreens";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen3() {
  const [disable, setDisable] = React.useState(false);
  const {data, height} = useContext(KycMobContext);
  const history = useHistory();
  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [message, setMessage] = useState("");
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen3) {
        const redirecturl = "/kybverif/d/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kybverif/d/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);
  
  const [formdata, setFormdata] = useState({
    legal_business_name: "",
    ein: "",
    doing_business_as: "",
    business_registered_address: "",
    business_phone_number: "",
    industry: "",
    company_website: "",
    inq_id: url,
  });

  const handleInputChange = (event) => {
    setMessage("");
    const name = event.target.name;
    const value = event.target.value;

    setFormdata((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSelect = (event, { name }) => {
    setMessage("");
    setFormdata((values) => ({ ...values, [name]: event }));
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      paddingTop: 0,
    }),
  };

  const optionsIndustry = [
    { label: "Consulting", value: 1 },
    { label: "Social Services", value: 2 },
    { label: "Pharmaceuticals", value: 3 },
  ];

  const handleSubmit = () => {
    setMessage("");

    if (formdata.legal_business_name === "") {
      setMessage("Legal business name is required!");
      return;
    }
    if (formdata.ein === "") {
      setMessage("Employee identification number is required!");
      return;
    }
    if (formdata.business_registered_address === "") {
      setMessage("Business registered address is required!");
      return;
    }
    if (formdata.business_phone_number === "") {
      setMessage("Business phone no is required!");
      return;
    }
    if (formdata.industry === "") {
      setMessage("Industry is required!");
      return;
    }
    if (formdata.company_website === "") {
      setMessage("Company website is required!");
      return;
    }

    let data_to_send = {
      legal_business_name: formdata.legal_business_name,
      ein: formdata.ein,
      doing_business_as: formdata.doing_business_as,
      business_registered_address: formdata.business_registered_address,
      business_phone_number: formdata.business_phone_number,
      industry: formdata.industry.label,
      company: formdata.company_website,
      inq_id: url,
    };
    setDisable(true);
    savebusiness(data_to_send)
      .then((response) => {
        if (response.data.success) {
          // console.log("response: ", response);
          let formdata3 = {
            inq_id: url,
            event_desc: "Business Information Saved",
            passed: 1,
          };
          saveevent(formdata3)
            .then((response) => {
              if (response.data.success) {
                let temp = screenStates;
                temp.Screen3docs = true;
                storage.setItem("screenmeta", JSON.stringify(temp));
                const redirecturl = "/kybverif/f/" + url;
                history.push(redirecturl);
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log("error: ", error);
              setDisable(false);
            });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenThree screenThreeKyb screenThreeDocs h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={isMobile ? "/kybverif/b/"+url : "/kybverif/c/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "25%" ,backgroundColor:data ? data.progressbar_color:"#ee416f"}}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button  onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1 style={{ lineHeight: 1 }}>Tell us more about your business.</h1>
          </div>
          <Form className="informationSection mt-8">
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Legal Business Name</span>
                <Input 
                  type="text" 
                  name="legal_business_name" 
                  className="input firstnameInput" 
                  value={formdata.legal_business_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Employer Identification Number (EIN)</span>
                <Input 
                  type="text" 
                  name="ein" 
                  className="input firstnameInput" 
                  value={formdata.ein}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Doing Business as</span>
                <Input 
                  type="text" 
                  name="doing_business_as" 
                  className="input firstnameInput" 
                  value={formdata.doing_business_as}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Registered Business Address</span>
                <Input 
                  type="text" 
                  name="business_registered_address" 
                  className="input firstnameInput" 
                  value={formdata.business_registered_address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="firstNameField firstField mb-3">
              <div className="inputfield firstname">
                <span className="label text-xs">Business Phone No</span>
                <Input 
                  type="text" 
                  name="business_phone_number" 
                  className="input firstnameInput" 
                  value={formdata.business_phone_number}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="nameFields">
              <div className="inputfield lastname mr-2">
                <span className="label text-xs">Industry</span>
                <Select
                  name="industry"
                  className="globeSelect"
                  styles={customStyles}
                  value={formdata.industry}
                  onChange={handleChangeSelect}
                  options={optionsIndustry}
                />
              </div>
              <div className="lastNameField">
                <div className="inputfield lastname">
                  <span className="label text-xs">Company Website</span>
                  <Input 
                    type="text" 
                    name="company_website" 
                    className="input lastnameInput" 
                    value={formdata.company_website}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-xs alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}
          </Form>
        </div>

        <div className="buttonAction">
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#ee416f",
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            Next
          </button>
        </div>

        <div className="footer" >
          <img className="footerImg" src={footerImg} alt="footer"/>
        </div>
      </div>
    </>
  );
}
  
