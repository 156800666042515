import React, { useState,useContext } from "react";
import { Link,useHistory  } from "react-router-dom";
import { saveevent } from "services/kyc/KycScreens";
import {KycMobContext} from "../../../layouts/KYCMob"

import backButtonImg from "../../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from "../../../assets/img/kycmobile/cross_white.png";
import footerImg from "../../../assets/img/kycmobile/footer_trademark.png";
import map from "../../../assets/img/kycmobile/map_marker.png";

export default function Screen18() {
  const {data} = useContext(KycMobContext);
  const history = useHistory();
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [disable, setDisable] = React.useState(false);

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen18) {
        const redirecturl = "/kycreturning/addressverif/a/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kycreturning/addressverif/a/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  const handleNext = () => {
    setDisable(true);
    let formdata = {
      inq_id: url,
      event_desc: "Document verification started",
      passed: 0
    };
    saveevent(formdata).then((response) => {
      if (response.data.success) {
        let temp = screenStates;
        temp.Screen24 = true;
        storage.setItem("screenmeta", JSON.stringify(temp));
        history.push("/kycreturning/addressverif/c/"+url);
        window.location.reload();
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenEighteen h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"#"}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "75%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button  onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mapImgBackground">
            <div className="mapImage">
              <img className="map" src={map} alt="" />
            </div>
            <div className="headingText">
              <h2>We are almost there! Prepare to scan your proof of address.</h2>
              <p className="py-16">Turn to the front of your proof of address and continue on the next screen. Document should not be older than 3 months. Ensure that your full name and address are on the document.</p>
            </div>
          </div>
        </div>

        <div className="buttonAction">
          <button style={{backgroundColor:data?data.button_color:"#ee416f"}} disabled={disable} onClick={handleNext} className="action w-full mx-auto flex flex-row items-center text-center justify-center">
            Next
          </button>
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
