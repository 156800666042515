import React from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table'
import 'react-circular-progressbar/dist/styles.css';

import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";


function Table({columns, data}) {
  const color = "light";
  const [pageNum, setPageNum] = React.useState(1);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    useRowSelect,
  )

  return (
    <>
      <div className="casemanagement flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6 mt-8">
                {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>{' '} */}
                <div className="flex flex-row items-center">
                  <button className="mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <img src={leftPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                  </button>
                  <span className="mr-2 text-xs">
                    Page
                    <strong className="ml-2">
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    <img src={rightPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                  </button>
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Go to page</p>
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    value={pageNum}
                    onChange={(e) => {
                      const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
                      const page = value ? Number(value) - 1 : 0;
                      gotoPage(page);
                      setPageNum(value);
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs ">Records per page</p>
                  <input
                    type="number"
                    min={1}
                    value={pageSize}
                    onChange={e => {
                      if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                        setPageSize(Number(1));
                      } else {
                        setPageSize(Number(e.target.value));
                      }
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                  <button
                    // onClick={() => }
                    style={{outline:"none"}}
                    className="px-2 py-2 bg-white rounded-lg"
                  >
                    <img src={refresh} alt="refresh" style={{width:"13px", height:"13px"}} />
                  </button>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table {...getTableProps()} className="items-center w-full bg-transparent border-collapse">
                  <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th 
                            {...column.getHeaderProps()}
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                            }
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()} className="cursor-pointer">
                          {row.cells.map(cell => {
                            return (
                              <td {...cell.getCellProps()} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function DataTableComponent() {
  const data = [
    {
      url: 'https://customwebsite.com/hedgekyc/inquiry/approve',
      status: 'Enabled',
      event: 'Approved',
      createdAt: '20-05-2019  |  18:10'
    },
    {
      url: 'https://customwebsite.com/hedgekyc/inquiry/decline',
      status: 'Disabled',
      event: 'Decline',
      createdAt: '20-05-2019  |  18:10'
    },
    {
      url: 'https://customwebsite.com/hedgekyc/inquiry/pending',
      status: 'Enabled',
      event: 'Pending',
      createdAt: '20-05-2019  |  18:10'
    }
  ]

  const columns = [
    {
      Header: 'URL',
      accessor: 'url',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: tableProps => (
        <div className="font-bold uppercase" style={tableProps.row.original.status=="Enabled" ? { color: "#15E49A" } : { color: "#FA364C" }}>
          {tableProps.row.original.status}
        </div>
      )
    },
    {
      Header: 'Event',
      accessor: 'event',
      Cell: tableProps => (
        <div className="statusGrid flex flex-col max-w-120-px mx-auto">
          {tableProps.row.original.event.includes("Decline") && 
            <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg font-semibold text-white-400 uppercase buttonRed mb-2">
              Decline
            </button>
          }
          {tableProps.row.original.event.includes("Pending") && 
            <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg font-semibold text-white-400 uppercase buttonYellow mb-2">
              Pending
            </button>
          }
          {tableProps.row.original.event.includes("Approved") && 
            <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg font-semibold text-white-400 uppercase buttonGreen">
              Approved
            </button>
          }
        </div>
      )
    }, 
    {
      Header: 'Created At',
      accessor: 'createdAt'
    }
  ]

  return (
      <Table data={data} columns={columns}/>
  )

}

export default DataTableComponent