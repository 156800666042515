import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";
import { saveliveness, saveevent } from "services/kyc/KycScreens";
import { KycMobContext } from "../../layouts/KYCMob";
import ReactLoading from "react-loading";
import * as Facemesh from "@mediapipe/face_mesh";
import * as cam from "@mediapipe/camera_utils";
import { FaceMesh } from "@mediapipe/face_mesh";
import { drawConnectors, drawLandmarks, lerp } from "@mediapipe/drawing_utils";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import warningImage from " ../../assets/img/kycmobile/warningMessage.png";
import cameraFrameImg from " ../../assets/img/kycmobile/camera_frame.png";
import cameraButton from " ../../assets/img/kycmobile/camera_button.png";
import cycle from "../../assets/img/icons/cycle.svg";

export default function Screen5() {
  const { data } = useContext(KycMobContext);
  const [x, setX] = React.useState();
  const [y, setY] = React.useState();
  const [isMobile, setIsMobile] = useState(false);

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const connect = window.drawConnectors;
  var camera = null;

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen5) {
        const redirecturl = "/kybkyc/d/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kybkyc/d/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const boxRef = useRef();

  const getPosition = () => {
    const x = boxRef.current.offsetLeft;
    setX(x);

    const y = boxRef.current.offsetTop;
    setY(y);
  };

  // Get the position of the red box in the beginning
  useEffect(() => {
    getPosition();
  }, []);

  const [facingMode, setFacingMode] = React.useState("user");

  const videoConstraintsMobile = {
    width: { min: 480 },
    height: { min: 720 },
    aspectRatio: 1.5,
    facingMode: facingMode,
  };

  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const history = useHistory();

  const [start, setStart] = useState(false);
  const [question, setQuestion] = useState("Please click once for a selfie video and wait for the liveness instructions!");
  const [status, setStatus] = useState("");
  const [counter, setCounter] = useState(1);
  const [retry, setRetry] = useState(false);
  // const [wait, setWait] = useState(false);
  var wait = false;
  const [fileArray, setFileAray] = useState([]);
  const [idArray, setIdArray] = useState([]);
  const [first, setFirst] = React.useState(true);
  const [completed, setCompleted] = React.useState(false);
  const [inputVideoReady, setInputVideoReady] = useState(false);

  var pass1 = true;
  var pass2 = false;
  var pass3 = false;
  var fileArrayTemp = [];
  // var idArray = [];
  var count = 0;

  React.useEffect(() => {
    if (data) {
      if (data.screen_4 === 0) {
        history.push("/kybkyc/f/" + url);
        window.location.reload();
      }
    }
  }, [data]);

  const MINUTE_MS = 200;

  const startProcess = () => {
    setStart(true);
    setQuestion("");
    setStatus("");
  };

  const handleUserMedia = React.useCallback(() => {
    const mediaStream = webcamRef.current.getScreenshot();
  }, [webcamRef]);

  // const ws = new WebSocket('ws://ec2-13-40-135-129.eu-west-2.compute.amazonaws.com:7000/ws')
  // const ws = new WebSocket("wss://ai.the-hedge.io:7000/ws");
  // const ws = new WebSocket("wss://ai.hedgetech.ai/");

  function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  }

  function onResults(results) {
    // const video = webcamRef.current.video;
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;

    // Set canvas width
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    if (results.multiFaceLandmarks) {
      for (const landmarks of results.multiFaceLandmarks) {
        let nose_pos;
        let chin_pos;
        
        Object.entries(landmarks).map(([idx, ln]) => {
          // nose
          if (idx === "1") {
            nose_pos = ln;
          }

          // chin
          if (idx === "152") {
            chin_pos = ln;
          }
        });

        let deltaX = chin_pos.x - nose_pos.x;
        let deltaY = chin_pos.y - nose_pos.y;

        const tiltAngle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
        // console.log("tiltAngle: ", tiltAngle);

        drawConnectors(canvasCtx, landmarks, Facemesh.FACEMESH_TESSELATION, {
          color: "#ffffff25",
          lineWidth: 0.5,
        });
        drawConnectors(canvasCtx, landmarks, Facemesh.FACEMESH_RIGHT_EYE, {
          color: "#ffffff25",
          lineWidth: 1,
        });
        drawConnectors(canvasCtx, landmarks, Facemesh.FACEMESH_LEFT_EYE, {
          color: "#ffffff25",
          lineWidth: 1,
        });
        // drawConnectors(canvasCtx, landmarks, Facemesh.FACEMESH_FACE_OVAL, {
        //   color: "#46eb6b",
        // });

        if (start) {
          // setWait(true);
          wait = true;
          var mediaStream = null;
          if (pass1) {
            wait = false;
            // setWait(false);
            setQuestion("Look straight");
            let timeout = setTimeout(() => {
              // straight
              if (tiltAngle >= 87 && tiltAngle <= 93) {
                if (fileArrayTemp.length <= 2) {
                  mediaStream = webcamRef.current.getScreenshot();
                  const blob = dataURItoBlob(mediaStream);
                  let currentTimestamp = Date.now();
                  const file = new File([blob], currentTimestamp, {
                    type: blob.type,
                    lastModified: new Date().getTime(),
                  });
                  fileArrayTemp.push(file);
                  setFileAray((oldArray) => [...oldArray, file]);
                  pass1 = false;
                  pass2 = true;
                  pass3 = false;
                  return;
                }
              }
            }, 3000);
            return () => clearTimeout(timeout);
          } else if (pass2) {
            wait = false;
            // setWait(false);
            setQuestion("Turn Left");
            let timeout = setTimeout(() => {
              // left
              if (tiltAngle >= 100 && tiltAngle <= 115) {
                if (fileArrayTemp.length <= 4) {
                  mediaStream = webcamRef.current.getScreenshot();
                  const blob = dataURItoBlob(mediaStream);
                  let currentTimestamp = Date.now();
                  const file = new File([blob], currentTimestamp, {
                    type: blob.type,
                    lastModified: new Date().getTime(),
                  });
                  fileArrayTemp.push(file);
                  setFileAray((oldArray) => [...oldArray, file]);
                  pass1 = false;
                  pass2 = false;
                  pass3 = true;
                  return;
                }
              }
            }, 1000);
            return () => clearTimeout(timeout);
          } else if (pass3) {
            wait = false;
            // setWait(false);
            setQuestion("Turn Right");
            let timeout = setTimeout(() => {
              // right
              if (tiltAngle >= 65 && tiltAngle <= 80) {
                if (fileArrayTemp.length <= 6) {
                  mediaStream = webcamRef.current.getScreenshot();
                  const blob = dataURItoBlob(mediaStream);
                  let currentTimestamp = Date.now();
                  const file = new File([blob], currentTimestamp, {
                    type: blob.type,
                    lastModified: new Date().getTime(),
                  });
                  fileArrayTemp.push(file);
                  setFileAray((oldArray) => [...oldArray, file]);
                  pass1 = false;
                  pass2 = false;
                  pass3 = false;
                  return;
                } else {
                  setCompleted(true);
                }
              }
            }, 1000);
            return () => clearTimeout(timeout);
          }
        }
      }
    }
    canvasCtx.restore();
  }

  useEffect(() => {
    if (webcamRef) {
      setInputVideoReady(true);
    }
  }, [webcamRef])

  useEffect(() => {
    if (start) {
      if (!inputVideoReady) {
        return;
      }
      if (webcamRef.current && canvasRef.current) {
        const constraints = {
          video: { width: { min: 1280 }},
        };
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          if (webcamRef.current) {
            webcamRef.current.srcObject = stream;
          }
          sendToMediaPipe();
        });

        const faceMesh = new FaceMesh({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
          },
        });
    
        faceMesh.setOptions({
          maxNumFaces: 1,
          minDetectionConfidence: 0.5,
          minTrackingConfidence: 0.5,
        });
    
        faceMesh.onResults(onResults);

        const sendToMediaPipe = async () => {
          if (webcamRef.current) {
            if (!webcamRef.current.props.videoConstraints.width.min) {
              console.log(webcamRef.current.props.videoConstraints.width.min);
              requestAnimationFrame(sendToMediaPipe);
            } else {
              await faceMesh.send({ image: webcamRef.current.video });
              requestAnimationFrame(sendToMediaPipe);
            }
          }
        };
    
        // if (
        //   typeof webcamRef.current !== "undefined" &&
        //   webcamRef.current !== null
        // ) {
        //   camera = new cam.Camera(webcamRef.current.video, {
        //     onFrame: async () => {
        //       await faceMesh.send({ image: webcamRef.current.video });
        //     },
        //     width: 640,
        //     height: 480,
        //     mirrored: facingMode==="user" ? true : false
        //   });
        //   camera.start();
        // }
      }
    }
  }, [facingMode, start]);

  // React.useEffect(() => {
  //   var mediaStream = null;
  //   if (start) {
  //     let filesArrayManual = [];
  //     if (first) {
  //       mediaStream = webcamRef.current.getScreenshot();
  //       const blob = dataURItoBlob(mediaStream);
  //       let currentTimestamp = Date.now();
  //       const file = new File([blob], currentTimestamp, {
  //         type: blob.type,
  //         lastModified: new Date().getTime(),
  //       });
  //       setFileAray((oldArray) => [...oldArray, file]);
  //       filesArrayManual.push(file);
  //       setFirst(false);
  //     }

  //     setWait(true);
  //     let timeout1 = setTimeout(() => {
  //       setWait(false);
  //       setQuestion("Turn Left");

  //       let timeout2 = setTimeout(() => {
  //         mediaStream = webcamRef.current.getScreenshot();
  //         const blob1 = dataURItoBlob(mediaStream);
  //         let currentTimestamp1 = Date.now();
  //         const file1 = new File([blob1], currentTimestamp1, {
  //           type: blob1.type,
  //           lastModified: new Date().getTime(),
  //         });
  //         setFileAray((oldArray) => [...oldArray, file1]);
  //         filesArrayManual.push(file1);
  //         setWait(true);

  //         let timeout3 = setTimeout(() => {
  //           setWait(false);
  //           setQuestion("Turn Right");

  //           let timeout4 = setTimeout(() => {
  //             mediaStream = webcamRef.current.getScreenshot();
  //             const blob1 = dataURItoBlob(mediaStream);
  //             let currentTimestamp1 = Date.now();
  //             const file1 = new File([blob1], currentTimestamp1, {
  //               type: blob1.type,
  //               lastModified: new Date().getTime(),
  //             });
  //             setFileAray((oldArray) => [...oldArray, file1]);
  //             filesArrayManual.push(file1);
  //             setWait(true);

  //             let formdata = {
  //               inq_id: url,
  //               fileArray: filesArrayManual,
  //             };
  //             saveliveness(formdata)
  //               .then((response) => {
  //                 if (response.data.success) {
  //                   let formdata1 = {
  //                     inq_id: url,
  //                     event_desc: "Selfie video verification submitted",
  //                     passed: 0,
  //                   };
  //                   saveevent(formdata1)
  //                     .then((response) => {
  //                       if (response.data.success) {
  //                         let formdata2 = {
  //                           inq_id: url,
  //                           event_desc: "Selfie video verification passed",
  //                           passed: 1,
  //                         };
  //                         saveevent(formdata2)
  //                           .then((response) => {
  //                             if (response.data.success) {
  //                               let temp = screenStates;
  //                               temp.Screen10 = true;
  //                               storage.setItem("screenmeta", JSON.stringify(temp));
  //                               const redirecturl = "/kybkyc/f/" + url;
  //                               history.push(redirecturl);
  //                               window.location.reload();
  //                             }
  //                           })
  //                           .catch((error) => {
  //                             console.log("error: ", error);
  //                           });
  //                       }
  //                     })
  //                     .catch((error) => {
  //                       console.log("error: ", error);
  //                     });
  //                 }
  //               })
  //               .catch((error) => {
  //                 console.log("error: ", error);
  //               });
  //           }, 3000);
  //           return () => clearTimeout(timeout4);
  //         }, 2000);
  //         return () => clearTimeout(timeout3);
  //       }, 3000);
  //       return () => clearTimeout(timeout2);
  //     }, 2000);
  //     return () => clearTimeout(timeout1);
  //   }
  // }, [start]);

  useEffect(() => {
    if (completed) {
      setQuestion("Please wait while we process...");
      let formdata = {
        inq_id: url,
        fileArray: fileArray,
      };
      saveliveness(formdata)
        .then((response) => {
          if (response.data.success) {
            let formdata1 = {
              inq_id: url,
              event_desc: "Selfie video verification submitted",
              passed: 0,
            };
            saveevent(formdata1)
              .then((response) => {
                if (response.data.success) {
                  let formdata2 = {
                    inq_id: url,
                    event_desc: "Selfie video verification passed",
                    passed: 1,
                  };
                  saveevent(formdata2)
                    .then((response) => {
                      if (response.data.success) {
                        let temp = screenStates;
                        temp.Screen10 = true;
                        storage.setItem("screenmeta", JSON.stringify(temp));
                        const redirecturl = "/kybkyc/f/" + url;
                        history.push(redirecturl);
                        window.location.reload();
                      }
                    })
                    .catch((error) => {
                      console.log("error: ", error);
                    });
                }
              })
              .catch((error) => {
                console.log("error: ", error);
              });
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }, [completed]);

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div
        className="CameraScreen screenFive"
        style={{ fontFamily: data ? data.font_family : "Gilroy" }}
      >
        <div className="mainContent">
          <div className="cameraFrame">
            <div
              className="w-full flex flex-row justify-between items-center p-4 pt-4"
              style={{ position: "absolute", top: "0", zIndex: "100" }}
            >
              <div className="backArrow">
                <Link to={"/kybkyc/d/" + url}>
                  <button>
                    <img src={backButtonImg} alt="backArrow" className="" />
                  </button>
                </Link>
              </div>
              <div className="progressBar w-full py-3 px-12">
                <div className="relative lg:w-8/12 mx-auto">
                  <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                    <div
                      style={{
                        width: "35%",
                        backgroundColor: data
                          ? data.progressbar_color
                          : "#ee416f",
                      }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="crossArrow">
                <button onClick={handleCross}>
                  <img src={closeButtonImg} alt="crossArrow" className="" />
                </button>
              </div>
            </div>
            <div className="cameraText" style={{position:"absolute", top:"10%", zIndex:"10"}}>
            {wait ? (
              <ReactLoading
                className="margin-auto mt-3 mb-3"
                type={"bars"}
                color={"#ee416f"}
                height={"10%"}
                width={"10%"}
              />
            ) : (
              
              <p style={{color:"white"}}>{question} </p>
             
            )}
            </div>
            {/* <img
              className="cameraFrameImg mx-auto"
              src={cameraFrameImg}
              alt="camera_frame"
              style={{ position: "absolute", zIndex: "100", left: "15%" }}
            /> */}
            <Webcam
              hidden
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={"100%"}
              mirrored={facingMode==="user"?true:false}
              onUserMedia={handleUserMedia}
              videoConstraints={videoConstraintsMobile}
              forceScreenshotSourceSize
              style={{ opacity: start ? "0" : "1" }}
            />
            <canvas
              ref={canvasRef}
              className="output_canvas_liveness"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zindex: 9,
                width: "100%",
                transform: "scaleX(-1)"
              }}
            ></canvas>
            <div
              className="footer"
              style={{ position: "absolute", bottom: "0px" }}
            >
              {!start ? 
                <Link onClick={startProcess}>
                  <img
                    ref={boxRef}
                    className="cameraButton mx-auto"
                    src={cameraButton}
                    alt="camera_button"
                  />
                </Link>
                :
                <ReactLoading
                  className="margin-auto mt-3 mb-3"
                  type={"bars"}
                  color={"#ee416f"}
                  height={"10%"}
                  width={"10%"}
                />
              }
              <button
                className="camerafacingchangebutton"
                style={{outline: "none" , position: "absolute", top: y + 30, left: x + 150 }}
                onClick={() =>
                  setFacingMode(facingMode === "user" ? "environment" : "user")
                }
              >
                <img src={cycle} alt="Switch" className="switchCamera" />
              </button>
              <img className="footerImg" src={footerImg} alt="footer" />
            </div>
          </div>
          {retry && (
            <div className="warningMessage livenesswarning">
              <div className="warningImage">
                <img src={warningImage} alt="warningImage" />
              </div>
              <div className="message">
                <p>Please try again!</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
