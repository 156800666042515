import React from "react";
import "react-circular-progressbar/dist/styles.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useParams } from "react-router";
import ReactLoading from "react-loading";
import { getfillings } from "../../services/opencorporates/Lookup";
import Moment from "moment";

export default function Fillings(props) {
  const { id } = useParams();
  const history = useHistory();
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    if (!id) {
        history.push("/businesslookup/index");
        window.location.reload();
    } else {
        getfillings(id).then((response) => {
            if (response.status == 200) {
                // console.log("response: ", response);
                setData(response.data.results.filing);
                setDataLoaded(true);
            }
        }).catch((error) => {
            console.log("error: ", error);
        });
    }
  }, []);
  
  return (
    <>
      {dataLoaded && (
        <div className="casemanagement casemanagementdetail flex flex-wrap">
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
                <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                    <div className="w-full flex items-center flex-wrap justify-between">
                        <div
                            className="px-4"
                            style={{ paddingLeft: "0" }}
                        >
                            <h4 className="borderDefault summaryHeading pb-1 font-bold text-base">
                                {data.title ? data.title : "N/A"}
                            </h4>
                        </div>
                    </div>
                </div>

                <div id="fillingRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                    <div className="block w-full overflow-x-auto pb-6">
                        <div
                            className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                        >
                            <div className="flex">
                                <div
                                    className="px-4 mr-4 cursor-pointer"
                                    style={{ paddingLeft: "0" }}
                                >
                                    <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                                        Filling Details
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between flex-wrap w-full px-12">
                            <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                                <div className="flex flex-col idType">
                                    <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                        <h4 className="font-bold text-sm mb-2 flex flex-row">
                                            <p className="w-full">Basic Details</p>
                                        </h4>
                                        <table className="w-full table-fixed">
                                            <tbody>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Filling Code
                                                    </td>
                                                    <td className="font-semibold text-xs text-right">
                                                        {data.filing_code ? data.filing_code : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Filling Type
                                                    </td>
                                                    <td className="font-semibold text-xs text-right">
                                                        {data.filing_type ? data.filing_type : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Description
                                                    </td>
                                                    <td className="font-semibold text-xs text-right">
                                                        {data.description ? data.description : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Date
                                                    </td>
                                                    <td className="font-semibold text-xs text-right">
                                                        {data.date ? data.date : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        UID
                                                    </td>
                                                    <td className="font-semibold text-xs text-right">
                                                        {data.uid ? data.uid : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-light text-xs">
                                                        Filling Url
                                                    </td>
                                                    <td className="font-semibold text-xs text-right">
                                                        <a href={data.url ? data.url : "#"} target="_blank" rel="noreferrer">{data.url ? data.url : "N/A"}</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {data.source && 
                                <div className="right_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                                    <div className="flex flex-col idType">
                                        <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                            <h4 className="font-bold text-sm mb-2 flex flex-row">
                                                <p className="w-full">Filling Source</p>
                                            </h4>
                                            <table className="w-full table-fixed">
                                                <tbody>
                                                    <tr>
                                                        <td className="font-light text-xs">
                                                            Publisher
                                                        </td>
                                                        <td className="font-semibold text-xs text-right">
                                                            {data.source ? (data.source.publisher ? data.source.publisher : "N/A") : "N/A"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-light text-xs">
                                                            Source Type
                                                        </td>
                                                        <td className="font-semibold text-xs text-right">
                                                            {data.source ? (data.source.source_type ? data.source.source_type : "N/A") : "N/A"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-light text-xs">
                                                            Source Url
                                                        </td>
                                                        <td className="font-semibold text-xs text-right">
                                                            <a href={data.source ? (data.source.url ? data.source.url : "#") : "#"} target="_blank" rel="noreferrer">{data.source ? (data.source.url ? data.source.url : "N/A") : "N/A"}</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-light text-xs">
                                                            Retrieved at
                                                        </td>
                                                        <td className="font-semibold text-xs text-right">
                                                            {data.source ? (data.source.retrieved_at ? Moment(data.source.retrieved_at).format("MM-DD-YYYY hh:mm a") : "N/A") : "N/A"}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      )}

      {!dataLoaded && (
        <>
          <ReactLoading
            className="margin-auto mt-3 mb-3 absolute"
            type={"bars"}
            style={{
              zIndex: "100000",
              top: "30%",
              left: "39%",
              width: "8%",
              height: "8%",
              fill: "#ee416f",
            }}
          />
          <div
            id="overlay"
            className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer"
            style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}
          ></div>
        </>
      )}
    </>
  );
}