import axios from "axios";
import address from "../Address";


export function getuniqueid() {
    return axios.get(address + "/kyb/getuniqueid");
}

// export function getcustomoptionsdesktop() {
//     return axios.get(address + "/kyc/getcustomoptionsdesktop");
// }

// export function getcustomoptionsmobile() {
//     return axios.get(address + "/kyc/getcustomoptionsmobile");
// }

export function savelanguage(formdata) {
    return axios.post(address + "/kyb/savelanguage", formdata);
}

export function savecompanyinfo(formdata) {
    return axios.post(address + "/kyb/savecompanyinfo", formdata);
}

export function savecompanydocs(formdata) {
    return axios.post(address + "/kyb/savecompanydocs", formdata, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
}

export function savecompanykycs(formdata) {
    return axios.post(address + "/kyb/savecompanykycs", formdata, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
}

export function savebasic(formdata) {
    return axios.post(address + "/kyb/savebasic", formdata, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
}

// export function savebasicinfo(formdata) {
//     return axios.post(address + "/kyc/savebasicinfo", formdata);
// }

// export function savewalletinfo(formdata) {
//     return axios.post(address + "/kyc/savewalletinfo", formdata);
// }

// export function validatewallet(formdata) {
//     return axios.post(address + "/kyc/validateWallet", formdata);
// }

// export function saveliveness(formdata) {
//     return axios.post(address + "/kyc/saveliveness", formdata, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
// }

// export function saveidverifications(formdata) {
//     return axios.post(address + "/kyc/saveidverifications", formdata, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
// }

// export function saveaddressverifications(formdata) {
//     return axios.post(address + "/kyc/saveaddressverification", formdata, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
// }

// export function checkblur(name, id) {
//     return axios.post(address + "/ai/blur/" + id, {name: name}, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
// }

// export function getreviewinfo(id) {
//     return axios.get(address + "/kyc/reviewinformation/" + id, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
// }

// export function saveevent(formdata) {
//     return axios.post(address + "/kyc/saveevent", formdata, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
// }

// export function calculatechecks(id) {
//     return axios.get(address + "/kyc/calculate_checks/" + id, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
// }