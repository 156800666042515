import React from "react";
import DataTableList from "../../components/Datatables/DataTableList";

export default function Lists() {
  return (
    <>
      <DataTableList />
    </>
  );
}
