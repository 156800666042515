import axios from "axios";
import address from "../Address";

export function showallaudit() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/setting/fetch_logs", { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}
