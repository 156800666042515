import React, { useState } from "react";
import cross from "../../assets/img/kycmobile/cross_white.png";
import mobileLaptop from "../../assets/img/mobile_laptop.png";
import qrCode from "../../assets/img/qr-code.png";
import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import {QRCodeSVG} from 'qrcode.react';

function CardKYCPopup(props) {
  const [main, setMain] = useState(true);
  const [QR, setQR] = useState(false);

  const handleMain = () => {
    setMain(false);
    setQR(true);
  };

  const handleBack = () => {
    setQR(false);
    setMain(true);
  };
  return (
    <div className="popup-box">
      {main && (
        <div className="box text-center">
          <div
            className="close-icon w-full flex justify-end"
            onClick={props.handleClose}
          >
            <img src={cross} style={{width:"20px", height:"20px"}}  alt="close" />
          </div>
          <img
            style={{ width: "100px" }}
            className="mt-8 mx-auto"
            src={mobileLaptop}
            alt="mobileLaptop"
          />
          <h1 className="text-xl font-bold mt-8">Please continue on mobile</h1>
          <div className="w-full mt-4">
            <p
              className="text-sm mx-auto "
              style={{ width: "60%", minWidth: "250px" }}
            >
              To avoid problems with the quality of your verification, please
              continue on mobile.
            </p>
          </div>
          <div className="w-full flex justify-end mt-8">
            <button
              type="button"
              className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs"
              onClick={handleMain}
            >
              Continue
            </button>
          </div>
        </div>
      )}

      {QR && (
        <div className="box text-center">
          <div
            className="close-icon w-full flex justify-between"
          >
            <button onClick={handleBack}>
              <img src={backButtonImg} alt="backArrow" className="" />
            </button>
            <img onClick={props.handleClose} style={{width:"20px", height:"20px"}} src={cross} alt="close" />
          </div>
          <h1 className="text-xl font-bold mt-12">Continue on mobile</h1>
          <div className="w-full mt-8">
            <p
              className="text-sm mx-auto "
              style={{ width: "60%", minWidth: "250px" }}
            >
              Please scan the QR code below with your mobile device to continue
              your verification.
            </p>
          </div>
          <div className="w-full mt-8 mb-4">
          <QRCodeSVG className="mx-auto" value={props.link} size="200"/>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardKYCPopup;
