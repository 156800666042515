import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { KycMobContext } from "../../layouts/KYCMob";
import {QRCodeSVG} from 'qrcode.react';
import {isMobile} from 'react-device-detect';

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import mainImg from " ../../assets/img/kycmobile/frame_6.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import { getevents } from "services/casemanagement/Onboarding";

export default function Screen1() {
  const { data } = useContext(KycMobContext);
  const [main, setMain] = useState(true);
  const [QR, setQR] = useState(false);

  const handleMain = () => {
    setMain(false);
    setQR(true);
  };

  const handleBack = () => {
    setQR(false);
    setMain(true);
  };

  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [urlId, setUrlId] = React.useState(url);
  const history = useHistory();
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (isMobile) {
      let temp = screenStates;
      temp.Screen3 = true;
      storage.setItem("screenmeta", JSON.stringify(temp));
      const redirecturl = "/verify/d/"+urlId;
      history.push(redirecturl);
      window.location.reload(false);
    }

    if (screenStates) {
      if (!screenStates.Screen1i) {
        const redirecturl = "/verify/b/"+urlId;
        history.push(redirecturl);
        window.location.reload(false);
      }
    } else {
      const redirecturl = "/verify/b/"+urlId;
      history.push(redirecturl);
      window.location.reload(false);
    }
  }, [screenStates]);

  const handleNext = () => {
    if (screenStates) {
      let temp = screenStates;
      temp.Screen3 = true;
      storage.setItem("screenmeta", JSON.stringify(temp));
      const redirecturl = "/verify/d/"+urlId;
      history.push(redirecturl);
      window.location.reload(false);
    }
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div
        className="screenOne QRScreen h-full flex flex-col"
        style={{ fontFamily: data ? data.font_family : "Gilroy" }}
      >
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/verify/b/" + urlId}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{
                    width: "15%",
                    backgroundColor: data ? data.progressbar_color : "#ee416f",
                  }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div class="mainContent">
          <div class="mainText mt-4">
            <h2>Do you want to continue on the Phone?</h2>
          </div>
          <div class="mainImage mt-2">
            <QRCodeSVG className="mx-auto" value={window.location.origin + "/verify/a/" + urlId} size="200"/>
          </div>
          <div class="mainText mt-4">
            <h2>Scan QR with your phone's camera</h2>
          </div>
          <div class="supportingText">
            <p class="supportingTextPara">
              Point your phone's camera to this QR code and follow the link.
            </p>
          </div>
        </div>

        <div class="buttonAction">
          {/* <Link to={"/verify/d/" + urlId}> */}
            <button
              style={{ backgroundColor: data ? data.button_color : "#ee416f" }}
              class="action"
              onClick={handleNext}
            >
              Continue here
            </button>
          {/* </Link> */}
        </div>

        <div class="footer">
          <img class="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
