import React from "react";
import DataTableTransMonitoring from "../../components/Datatables/DataTableTransMonitoring";

export default function TransactionMonitoring() {
  return (
    <>
      <DataTableTransMonitoring />
    </>
  );
}
