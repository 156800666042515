import React, { useState,useContext } from "react";
import { Link } from "react-router-dom";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import map from " ../../assets/img/kycmobile/screen27.png";
import {KycMobContext} from "../../layouts/KYCMob"

export default function Screen27() {
  const {data} = useContext(KycMobContext)

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };
    return (
      <>
        <div className="screenTwentySeven h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
          <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
            <div className="backArrow">
              <Link to="/kyb/screen26">
                <button>
                  <img src={backButtonImg} alt="backArrow" className="" />
                </button>
              </Link>
            </div>
            <div className="progressBar w-full py-3 px-12">
              <div className="relative lg:w-8/12 mx-auto">
                <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                  <div
                    style={{ width: "100%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                  ></div>
                </div>
              </div>
            </div>
            <div className="crossArrow">
              <button  onClick={handleCross}>
                <img src={closeButtonImg} alt="crossArrow" className="" />
              </button>
            </div>
          </div>

          <div className="mainContent">
            <div className="mapImgBackground">
              <div className="mapImage">
                <img className="map" src={map} alt="" />
              </div>
              <div className="headingText">
                <h2>Unable to submit the information</h2>
                <p>The information you provided is incorrect. Please try again.</p>
              </div>
            </div>
          </div>

          <div className="buttonAction">
            {/* <Link to="/kyb/screen0"> */}
              <button  style={{backgroundColor:data?data.button_color:"#ee416f"}} className="action">Go Back</button>
            {/* </Link> */}
          </div>

          <div className="footer">
            <img className="footerImg" src={footerImg} alt="footer" />
          </div>
        </div>
      </>
    );
}
  
