import axios from "axios";
import address from "../Address";

export function showallmembers() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/setting/show_members", { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function addteammember(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/setting/member_add", formdata, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function showteammember(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/setting/edit_member/" + id, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function updateteammember(id, formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/setting/update_member/" + id, formdata, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function deleteteammember(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/setting/delete_member/" + id, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function getroles() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/setting/get_roles", { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}