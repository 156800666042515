import React from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from 'react-select';
import { Link } from "react-router-dom";

import devCodeImg from "../../assets/img/devCodeImg.png";

export default function Developers() {

  const themes = [
    { label: "Light", value: 1 },
    { label: "Dark", value: 2 }
  ];

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#828282' : '#828282',
      backgroundColor: state.isSelected ? '#EBEDF2' : '#FFF',
      padding: 10,
      fontSize: '12px',
      height:"50px"
    }),
  }

  return (
    <>
      <div className="amlscreening casemanagement flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6">
            <div className="w-full flex flex-row">
              <div className="lg:w-12/12 h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white mr-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white rounded-lg">
                  <div className="mb-0 p-16 border-0 developerDiv">
                    <div className="flex flex-wrap items-center">
                      <div className="relative w-full max-w-full flex-grow flex-1">
                        <Form class="inquireForm aml" 
                          // onSubmit={(e) => handleLogin(e)} 
                          // ref={c => {form = c;}}
                        >
                          <div className="flex flex-col items-center">
                            <div className="flex w-full flex-row mb-6 developerInputSection">
                              <div className="lg:w-3/12 md:w-4/12 sm:w-10/12 mr-4">
                                <div className="searchInputTables">
                                  <div className="fieldInquire relative w-full mb-3">
                                    <label
                                      className="label block font-semibold text-black-600 text-xs mb-2"
                                    >
                                      Search Template
                                    </label>
                                    <Input
                                      type="text"
                                      className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      placeholder="dark version"
                                        style={{ fontSize: "16px" }}
                                        // value={email}
                                      // onChange={(e) => setEmail(e.target.value)}
                                      // validations={[required, validEmail]}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="lg:w-3/12 md:w-4/12 sm:w-10/12 mr-4">
                                <div className="searchInputTables">
                                  <div className="fieldInquire relative w-full mb-3">
                                    <label
                                      className="label block font-semibold text-black-600 text-xs mb-2"
                                    >
                                      Template ID
                                    </label>
                                    <Input
                                      type="text"
                                      className="amlInquireFields cursor-pointer input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      placeholder="1JCKD0JJG5GBCKF7ISJD"
                                        style={{ fontSize: "16px" }}
                                        // value={email}
                                      // onChange={(e) => setEmail(e.target.value)}
                                      // validations={[required, validEmail]}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="lg:w-3/12 md:w-4/12 sm:w-10/12">
                                <div className="searchInputTables">
                                  <div className="field dropdown relative w-full mb-3">
                                    <label
                                      className="label block font-semibold text-black-600 text-xs mb-2"
                                      style={{zIndex:"10"}}
                                    >
                                      Theme
                                    </label>
                                    <Select styles={customStylesSelect} options={ themes } isSearchable={false} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full flex-row mb-6">
                              <div className="lg:w-3/12 md:w-4/12 sm:w-10/12 mr-4">
                                <div className="searchInputTables">
                                  <div className="fieldInquire relative w-full mb-3">
                                    <label
                                      className="label block font-semibold text-black-600 text-xs mb-2"
                                    >
                                      API Link
                                    </label>
                                    <Input
                                      type="text"
                                      className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      placeholder="Country"
                                        style={{ fontSize: "16px" }}
                                        // value={email}
                                      // onChange={(e) => setEmail(e.target.value)}
                                      // validations={[required, validEmail]}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full flex-col mb-12 mt-8">
                              <h4 className="font-bold text-sm mb-6">Embedded Code</h4>
                              <p className="font-light text-sm mb-6">Embedded Flow Code Sample</p>
                              <div className="w-full p-16 text-sm devCode" style={{backgroundImage: `url(${devCodeImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                                <code className="text-white">
                                  # Switcher for implementing switch case options <br /> <br />
                                </code>
                                <code className="text-white">
                                  def employee_details(ID): <br /> <br />
                                </code>
                                <code className="text-white">
                                  '''The first argument will be returned if the match found and <br /> <br />
                                </code>
                                <code className="text-white">
                                  nothing will be returned if no match found''' <br /> <br />
                                </code>
                                <code className="text-white">
                                  return switcher.get(ID, "nothing") <br /> <br />
                                </code>
                                <code className="text-white">
                                  # Take the employee ID <br /> <br />
                                </code>
                                <code className="text-white">
                                  ID = input("Enter the employee ID: ") <br /> <br />
                                </code>
                                <code className="text-white">
                                  # Print the output <br /> <br />
                                </code>
                                <code className="text-white">
                                  print(employee_details(ID)) <br /> <br />
                                </code>
                              </div>
                              {/* <img src={devCodeImg} alt="code background" /> */}
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
