import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link as Link1 } from "react-scroll";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useParams } from "react-router";
import ImageGallery from "react-image-gallery";
import countryList from "react-select-country-list";
import ReactTooltip from "react-tooltip";
import ReactImageZoom from 'react-image-zoom';
import {
  viewdetail,
  saveassigned,
  updatestatus,
  getevents,
  revisesection
} from "services/casemanagement/Onboarding";
import Select from "react-select";
import ReactLoading from "react-loading";
import Moment from "moment";
import { Link as Link2 } from "react-router-dom";
import { showallmembers } from "services/settings/Team";
import { saveevent } from "services/kyc/KycScreens";

import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Image,
  Note,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Modal from 'react-modal';

// components
import { MemoizedMap } from "components/Maps/MapExample.js";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";
import downloadIcon from "../../assets/img/icons/downloadIcon.png";
import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import ElipsesYellow from "../../assets/img/event_elp_yellow.png";
import VerticalLine from "../../assets/img/verticalLine.png";
import dropDownIcon from "../../assets/img/dropdown.png";
import idVerRectImg from "../../assets/img/idVerRectImg.png";
import greenWarning from "../../assets/img/greenWarning.png";
import leftArrow from "../../assets/img/leftarrow.png";
import rightArrow from "../../assets/img/rightarrow.png";
import longVertLine from "../../assets/img/longVertLine.png";
import smallVertLine from "../../assets/img/smallVertLine.png";
import locIcon from "../../assets/img/locIcon.png";
import hedgelogo from "../../assets/img/hedge-logo.png";
import EyeIcon from "../../assets/img/icons/eyeicon.png";
import ReactCountryFlag from "react-country-flag";

export default function CaseManagementDetailOnboarding(props) {
  // google maps script include
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_API_KEY;
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, []);
  
  let location = {
    lat: "33.487514",
    long: "74.105076",
  };
  const [assignedTo, setAssignedTo] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [matchesArray, setMatchesArray] = React.useState([]);

  const [idVerActive, setIdVerActive] = React.useState(true);
  const [walletVerActive, setWalletVerActive] = React.useState(false);
  const [livenessActive, setLivenessActive] = React.useState(false);
  const [addressVerActive, setAddressVerActive] = React.useState(false);
  const [screeningActive, setScreeningActive] = React.useState(false);
  const [eventTimeActive, setEventTimeActive] = React.useState(false);
  const [imgFrontActive, setImgFrontActive] = React.useState(true);
  const [imgBackActive, setImgBackActive] = React.useState(false);
  const [imgWithDocActive, setImgWithDocActive] = React.useState(false);

  const [riskShow, setRiskShow] = React.useState(true);
  const [transShow, setTransShow] = React.useState(true);
  const [trans1Show, setTrans1Show] = React.useState(true);
  const [trans2Show, setTrans2Show] = React.useState(true);
  const [eventShow, setEventShow] = React.useState(true);

  const [revisit, setRevisit] = React.useState(false);
  const [revisitSection, setRevisitSection] = React.useState(null);
  const [revisitNotes, setRevisitNotes] = React.useState(null);
  const [revisionSent, setRevisionSent] = React.useState(false);

  const [noteInt, setNoteInt] = React.useState(null);
  const [notesInternal, setNotesInternal] = React.useState([]);
  const [notesdivkey, setNotesdivkey] = React.useState(1);

  const addNotesInternal = () => {
    let currentNotes = notesInternal;
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${month}/${day}/${year}`;
    let currentTime = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    currentNotes.unshift({
      note: noteInt,
      date: currentDate,
      time: currentTime
    });

    setNotesInternal(currentNotes);
    setNotesdivkey(Math.random());
  }

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      border: 0,
      padding: 10,
      fontSize: "14px",
    }),
  };

  function setIdActive() {
    setIdVerActive(true);
    setWalletVerActive(false);
    setLivenessActive(false);
    setAddressVerActive(false);
    setScreeningActive(false);
    setEventTimeActive(false);
  }
  function setWalletActive() {
    setIdVerActive(false);
    setWalletVerActive(true);
    setLivenessActive(false);
    setAddressVerActive(false);
    setScreeningActive(false);
    setEventTimeActive(false);
  }
  function setEvTActive() {
    setIdVerActive(false);
    setWalletVerActive(false);
    setLivenessActive(false);
    setAddressVerActive(false);
    setScreeningActive(false);
    setEventTimeActive(true);
  }

  return (
    <>
      <div className="casemanagement casemanagementdetail flex flex-wrap">
        <div className="w-full lg:w-9/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex items-center flex-wrap justify-between">
                <div
                  className="px-4 cursor-pointer"
                  style={{ paddingLeft: "0" }}
                >
                  <h4 className="borderDefault summaryHeading pb-1 font-bold text-base">
                    Summary
                  </h4>
                </div>
              </div>
            </div>

            <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex flex-wrap">
                <Link1 to="riskRef" spy={true} smooth={true}>
                  <div
                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                    style={
                      idVerActive
                        ? { backgroundColor: "#EBEDF2" }
                        : { backgroundColor: "#FFF" }
                    }
                    onClick={setIdActive}
                  >
                    <h4 className="font-semibold text-xs">
                      Risk Level
                    </h4>
                  </div>
                </Link1>
                <Link1 to="transRef" spy={true} smooth={true}>
                  <div
                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                    style={
                      walletVerActive
                        ? { backgroundColor: "#EBEDF2" }
                        : { backgroundColor: "#FFF" }
                    }
                    onClick={setWalletActive}
                  >
                    <h4 className="font-semibold text-xs">
                      Transaction History
                    </h4>
                  </div>
                </Link1>
                <Link1 to="evtRef" spy={true} smooth={true}>
                  <div
                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                    style={
                      eventTimeActive
                        ? { backgroundColor: "#EBEDF2" }
                        : { backgroundColor: "#FFF" }
                    }
                    onClick={setEvTActive}
                  >
                    <h4 className="font-semibold text-xs">
                      Event Timeline
                    </h4>
                  </div>
                </Link1>
              </div>
            </div>

            <div id="riskRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto pb-6">
                <div
                  className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                  onClick={() => setRiskShow(!riskShow)}
                >
                  <div className="flex">
                    <div
                      className="px-4 mr-4 cursor-pointer"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                        Risk Level
                      </h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {!riskShow && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                    {riskShow && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3 dropDownIconRotated"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                  </div>
                </div>
                {riskShow && (
                  <div className="flex justify-between flex-wrap w-full px-12">
                    <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                      <div className="flex flex-col idType">
                        <div className="row flex flex-row justify-between items-center">
                          <div className="col-md-6 flex flex-col">
                            <span className="text-stone-500 uppercase text-xs font-bold">
                              Risk Level
                            </span>
                          </div>
                          <div className="col-md-6 flex flex-col text-right">
                            <span className="text-sm font-bold textRed">
                              High Risk
                            </span>
                          </div>
                        </div>
                        <div className="mt-4 documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                          <h4 className="font-bold text-sm mb-2">
                            Risk Scoring
                          </h4>
                          <table className="w-full table-fixed">
                            <tbody>
                              <tr>
                                <td className="font-light text-xs">
                                  Country/Jurisdiction
                                </td>
                                <td className="font-semibold text-xs text-right">
                                  <img
                                    className="mb-2 ml-auto"
                                    style={{ width: "20px" }}
                                    src={ElipsesError}
                                    alt="icon"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="font-light text-xs">
                                  Nationality
                                </td>
                                <td className="font-semibold text-xs text-right">
                                  <img
                                    className="mb-2 ml-auto"
                                    style={{ width: "20px" }}
                                    src={ElipsesError}
                                    alt="icon"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="font-light text-xs">
                                  Product
                                </td>
                                <td className="font-semibold text-xs text-right">
                                  <img
                                    className="mb-2 ml-auto"
                                    style={{ width: "20px" }}
                                    src={ElipsesError}
                                    alt="icon"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="font-light text-xs">
                                  Customer
                                </td>
                                <td className="font-semibold text-xs text-right">
                                  <img
                                    className="mb-2 ml-auto"
                                    style={{ width: "20px" }}
                                    src={ElipsesError}
                                    alt="icon"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="font-light text-xs">
                                  Occupation
                                </td>
                                <td className="font-semibold text-xs text-right">
                                  <img
                                    className="mb-2 ml-auto"
                                    style={{ width: "20px" }}
                                    src={ElipsesError}
                                    alt="icon"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="right_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                      <div
                        className="enquiryId mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "30% 70%",
                          alignItems: "center",
                        }}
                      >
                        <p className="text-xs">Notes</p>
                        <div className="field dropdown relative w-full">
                          <textarea onChange={(e) => setNoteInt(e.target.value)} style={{ background: "#fff", borderRadius: "10px", border: "1px solid #ababab", width: "100%" }}></textarea>
                        </div>
                      </div>
                      <button className="w-full py-2 px-2 rounded-lg font-semibold text-white-400 buttonPink text-sm float-right max-w-120-px" onClick={addNotesInternal}>Submit</button>
                      
                      <div className="savedNotes mt-2 w-full" key={notesdivkey} style={{ maxHeight: "150px", overflowY: "scroll", marginTop: "55px" }}>
                        {notesInternal.map((ele, ind) => (
                          <div data-index={ind} className="status mb-2">
                            <div className="w-full" style={{ display: "grid", gridTemplateColumns: "100%" }}>
                              <div className="text text-sm font-semibold">
                                {ele.note}
                              </div>
                            </div>
                            <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "100%" }}>
                              <div className="text text-xs font-semibold">
                                <p className="createdDate font-normal mt-2">{ele.date}  |  {ele.time}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div id="transRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto">
                <div
                  className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                >
                  <div className="flex">
                    <div
                      className="px-4 mr-4 cursor-pointer"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                        Transaction History
                      </h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {/* {!transShow && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                    {transShow && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3 dropDownIconRotated"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )} */}
                  </div>
                </div>
              </div>
            </div>

            <div id="transRef1" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto pb-6">
                <div
                  className="rounded-lg flex justify-between px-12 py-6 border-0 w-full cursor-pointer"
                  onClick={() => setTransShow(!transShow)}
                >
                  <div className="flex justify-between w-full">
                    <div
                      className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="summaryHeading py-2 font-bold text-sm">
                        Transaction ID
                      </h4>
                      <h4 className="summaryHeading py-2 font-bold text-xs">
                        trx7271798
                      </h4>
                    </div>
                    <div
                      className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="summaryHeading py-2 font-bold text-sm">
                        Transaction Date
                      </h4>
                      <h4 className="summaryHeading py-2 font-bold text-xs">
                        12/23/2022
                      </h4>
                    </div>
                    <div
                      className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="summaryHeading py-2 font-bold text-sm">
                        Transaction Amount
                      </h4>
                      <h4 className="summaryHeading py-2 font-bold text-xs">
                        $8379
                      </h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {!transShow && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                    {transShow && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3 dropDownIconRotated"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                  </div>
                </div>
                {transShow && (
                  <div className="flex justify-between flex-wrap w-full px-12">
                    <div className="flex justify-between w-full pb-6">
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Sender Name
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          John Spencer
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Benificiary Name
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Mr. Hooman
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Sending Country
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Pakistan
                        </h4>
                      </div>
                    </div>
                    <div className="flex justify-between w-full">
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Receiving Country
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Japan
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Purpose
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Other
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Relationship
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Family
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div id="transRef2" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto pb-6">
                <div
                  className="rounded-lg flex justify-between px-12 py-6 border-0 w-full cursor-pointer"
                  onClick={() => setTrans1Show(!trans1Show)}
                >
                  <div className="flex justify-between w-full">
                    <div
                      className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="summaryHeading py-2 font-bold text-sm">
                        Transaction ID
                      </h4>
                      <h4 className="summaryHeading py-2 font-bold text-xs">
                        trx7271798
                      </h4>
                    </div>
                    <div
                      className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="summaryHeading py-2 font-bold text-sm">
                        Transaction Date
                      </h4>
                      <h4 className="summaryHeading py-2 font-bold text-xs">
                        12/23/2022
                      </h4>
                    </div>
                    <div
                      className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="summaryHeading py-2 font-bold text-sm">
                        Transaction Amount
                      </h4>
                      <h4 className="summaryHeading py-2 font-bold text-xs">
                        $8379
                      </h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {!trans1Show && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                    {trans1Show && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3 dropDownIconRotated"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                  </div>
                </div>
                {trans1Show && (
                  <div className="flex justify-between flex-wrap w-full px-12">
                    <div className="flex justify-between w-full pb-6">
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Sender Name
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          John Spencer
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Benificiary Name
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Mr. Hooman
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Sending Country
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Pakistan
                        </h4>
                      </div>
                    </div>
                    <div className="flex justify-between w-full">
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Receiving Country
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Japan
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Purpose
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Other
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Relationship
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Family
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div id="transRef2" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto pb-6">
                <div
                  className="rounded-lg flex justify-between px-12 py-6 border-0 w-full cursor-pointer"
                  onClick={() => setTrans2Show(!trans2Show)}
                >
                  <div className="flex justify-between w-full">
                    <div
                      className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="summaryHeading py-2 font-bold text-sm">
                        Transaction ID
                      </h4>
                      <h4 className="summaryHeading py-2 font-bold text-xs">
                        trx7271798
                      </h4>
                    </div>
                    <div
                      className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="summaryHeading py-2 font-bold text-sm">
                        Transaction Date
                      </h4>
                      <h4 className="summaryHeading py-2 font-bold text-xs">
                        12/23/2022
                      </h4>
                    </div>
                    <div
                      className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="summaryHeading py-2 font-bold text-sm">
                        Transaction Amount
                      </h4>
                      <h4 className="summaryHeading py-2 font-bold text-xs">
                        $8379
                      </h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {!trans2Show && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                    {trans2Show && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3 dropDownIconRotated"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                  </div>
                </div>
                {trans2Show && (
                  <div className="flex justify-between flex-wrap w-full px-12">
                    <div className="flex justify-between w-full pb-6">
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Sender Name
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          John Spencer
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Benificiary Name
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Mr. Hooman
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Sending Country
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Pakistan
                        </h4>
                      </div>
                    </div>
                    <div className="flex justify-between w-full">
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Receiving Country
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Japan
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Purpose
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Other
                        </h4>
                      </div>
                      <div
                        className="px-4 mr-4 cursor-pointer flex flex-col text-center justify-center"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="summaryHeading py-2 font-bold text-sm">
                          Relationship
                        </h4>
                        <h4 className="summaryHeading py-2 font-bold text-xs">
                          Family
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div id="evtRef" className="event-timeline relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="block w-full overflow-x-auto pb-6">
                <div
                  className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                  onClick={() => setEventShow(!eventShow)}
                >
                  <div className="flex">
                    <div
                      className="px-4 mr-4 cursor-pointer"
                      style={{ paddingLeft: "0" }}
                    >
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                        Event Timeline
                      </h4>
                    </div>
                  </div>
                  <div className="flex px-4 py-2 cursor-pointer">
                    {!eventShow && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                    {eventShow && (
                      <img
                        src={dropDownIcon}
                        alt="down icon"
                        className="mr-3 dropDownIconRotated"
                        style={{ width: "15px", height: "10px" }}
                      />
                    )}
                  </div>
                </div>
                {eventShow && (
                  <div className="flex flex-row  w-full p-12 mapSection">
                    <div className="p-12 md:w-12/12">
                      <div className="text-left">
                        <div className="flex flex-wrap">
                          <div className="w-full px-12 mt-2 fields block" style={{ maxHeight: "500px", height: "500px", overflow: "auto" }}>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Created
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={longVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:33pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-sm font-normal">192.162.1.10</p>
                                  </div>
                                  <div className="w-full items-center justify-center mt-8">
                                    <table className="w-full table-fixed">
                                      <tbody>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">IP Address</h4>
                                            <h4 className="font-semibold text-xs">127.12.78.1</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Network Level Threat</h4>
                                            <h4 className="font-semibold text-xs">lorem</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Network Country</h4>
                                            <h4 className="font-semibold text-xs">Pakistan</h4>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Latitude</h4>
                                            <h4 className="font-semibold text-xs">71.12</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Longitude</h4>
                                            <h4 className="font-semibold text-xs">72.66</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Tor Connection</h4>
                                            <h4 className="font-semibold text-xs">lorem</h4>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Public Proxy</h4>
                                            <h4 className="font-semibold text-xs">12.124.12.54</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Private Proxy</h4>
                                            <h4 className="font-semibold text-xs">12.54.65.25</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">ISP</h4>
                                            <h4 className="font-semibold text-xs">Nayatel</h4>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Device Type</h4>
                                            <h4 className="font-semibold text-xs">Desktop</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Device OS</h4>
                                            <h4 className="font-semibold text-xs">Windows</h4>
                                          </td>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Browser</h4>
                                            <h4 className="font-semibold text-xs">Google Chrome</h4>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pb-4">
                                            <h4 className="font-light text-xs">Browser Fingerprint</h4>
                                            <h4 className="font-semibold text-xs">21.232.235</h4>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Government ID verification started
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:37pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Government ID verification submitted
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:43pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Government ID verification passed
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:44pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Selfie video verification started
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:47pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Selfie video verification submitted
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:51pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Selfie video verification passed
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:52pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Document verification started
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:53pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Document verification submitted
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:57pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Document verification passed
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  8:58pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Inquiry completed
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  9:01pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Inquiry approved by Workflow
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  9:02pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Transaction Initiated
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  11:50pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Transaction Successful
                                </div>
                              </div>
                              <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag px-2"><img src={smallVertLine} alt="created" /></div>
                                <div className="text text-xs font-semibold">
                                  <p className="createdDate font-normal mt-2">07/23/2022  |  1:00pm</p>
                                  <div className="createdDate text-sm font-normal mt-2 flex">
                                    <img src={locIcon} alt="loc icon" className="mr-2" style={{ width: "20px" }} />
                                    <p className="createdDate text-xs font-normal">192.162.1.10</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="status mt-2">
                              <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                                <div className="imgTag"><img src={ElipsesError} alt="created" /></div>
                                <div className="text text-sm font-semibold">
                                  Transaction Flagged
                                  <p className="createdDate text-xs font-normal mt-2">07/23/2022  |  1:10pm</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-12 md:w-12/12 mt-5">
                      <div className="flex flex-wrap">
                        <div className="w-full px-4">
                          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                            <MemoizedMap location={location} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="profileright w-full lg:w-3/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-16">
            <div className="flex flex-wrap justify-center">
              <div className="w-full px-4 flex justify-center">
                <div className="relative">
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "cover",
                    }}
                    alt="..."
                    src={require("assets/img/ProfilePicture.png").default}
                    className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-12 lg:-ml-12 max-w-100-px"
                  />
                </div>
              </div>
              <div className="w-full px-4 text-center mt-12">
                <div className="flex justify-center py-4 lg:pt-4 pt-8">
                  <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2">
                    Sara Turner
                  </h3>
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className="action-buttons text-base leading-normal mt-0 mb-2 text-white-400 font-bold">
                <button
                  className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen"
                  style={{ background: "#15e49a80" }}
                >
                  Approve
                </button>
                <button
                  className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonRed"
                  style={{ background: "#fa364c80" }}
                >
                  Decline
                </button>
                <button
                  className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonYellow"
                  style={{ background: "#ffc10280" }}
                >
                  Review
                </button>
                <button
                  className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonBlack"
                  style={{ background: "#00000080" }}
                >
                  Duplicate
                </button>
                <button
                  className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen"
                  style={{ background: "#ef0d6f80" }}
                >
                  Revise
                </button>
              </div>
            </div>

            <div className="py-4 text-left">
              <div className="flex flex-wrap">
                <div className="flex items-center justify-between font-semibold w-full">
                  <p className="lg:w-4/12 md:w-2/12 sm:w-2/12 text-sm">
                    Revise Section
                  </p>
                  <div
                    className="lg:w-8/12 md:w-10/12 sm:w-10/12"
                    style={{ height: "0", border: "1px solid #D3D3D3" }}
                  ></div>
                </div>
                <div className="mt-2 fields block filtersTable w-full">
                  <div
                    className="enquiryId mt-2"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      alignItems: "center",
                    }}
                  >
                    <p className="text-xs">Section</p>
                    <div className="field dropdown relative w-full mb-3">
                      <Select
                        styles={customStylesSelect}
                        options={[
                          {label: "Basic Info", value: "basic"},
                          {label: "Liveness", value: "liveness"},
                          {label: "ID Verification", value: "idverif"},
                          {label: "Address Verification", value: "addressverif"},
                          {label: "Wallet Verification", value: "wallet"}
                        ]}
                        style={{ width: "100%" }}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                  <div
                    className="enquiryId mt-2"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      alignItems: "center",
                    }}
                  >
                    <p className="text-xs">Notes</p>
                    <div className="field dropdown relative w-full mb-3">
                      <textarea></textarea>
                    </div>
                  </div>
                  <button className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonPink text-sm mt-2">Submit</button>

                </div>
              </div>
            </div>
            {revisionSent && 
              <p className="text-xs mt-2">Revision email sent successfully!</p>
            }

            <div className="py-4 text-left">
              <div className="flex flex-wrap">
                <div className="flex items-center justify-center font-semibold w-full">
                  <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                    Info
                  </p>
                  <div
                    className="lg:w-10/12 md:w-10/12 sm:w-10/12"
                    style={{ height: "0", border: "1px solid #D3D3D3" }}
                  ></div>
                </div>
                <div className="mt-2 fields block">
                  <div
                    className="enquiryId mt-2"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    <p className="text-xs">Trx ID</p>
                    <b className="text-xs">
                      9798798789
                    </b>
                  </div>
                  <div
                    className="IdNo mt-2"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      wordBreak: "break-all",
                    }}
                  >
                    <p className="text-xs">ID Number</p>
                    <b className="text-xs">
                      79798798798798
                    </b>
                  </div>
                  <div
                    className="dob mt-2"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    <p className="text-xs">Date of Birth</p>
                    <b className="text-xs">
                      1-1-1990
                    </b>
                  </div>
                  <div
                    className="nationality mt-2"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    <p className="text-xs">Nationality</p>
                    <b className="text-xs">
                      Pakistani
                    </b>
                  </div>
                  <div
                    className="nationality mt-2"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    <p className="text-xs">Email</p>
                    <b className="text-xs">
                      email@email.com
                    </b>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-4 text-left">
              <div className="flex flex-wrap">
                <div className="flex items-center justify-center font-semibold w-full">
                  <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                    Event
                  </p>
                  <div
                    className="lg:w-10/12 md:w-10/12 sm:w-10/12"
                    style={{ height: "0", border: "1px solid #D3D3D3" }}
                  ></div>
                </div>
                <div className="w-full mt-2 fields block eventTimeline">
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Created
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:33pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Government ID verification started
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:37pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Government ID verification submitted
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:43pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Government ID verification passed
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:44pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Selfie video verification started
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:47pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Selfie video verification submitted
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:51pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Selfie video verification passed
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:52pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Document verification started
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:53pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Document verification submitted
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:57pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Document verification passed
                        <p className="createdDate font-normal mt-2">07/23/2022  |  8:58pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Inquiry completed
                        <p className="createdDate font-normal mt-2">07/23/2022  |  9:01pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Inquiry approved by Workflow
                        <p className="createdDate font-normal mt-2">07/23/2022  |  11:01pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Transaction Initiated
                        <p className="createdDate font-normal mt-2">07/23/2022  |  11:50pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Transaction Successful
                        <p className="createdDate font-normal mt-2">07/23/2022  |  1:00pm</p>
                      </div>
                    </div>
                    <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                      <div className="text text-xs font-semibold"></div>
                    </div>
                  </div>
                  <div className="status mt-2">
                    <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                      <div className="imgTag"><img src={ElipsesError} alt="created" /></div>
                      <div className="text text-xs font-semibold">
                        Transaction Flagged
                        <p className="createdDate font-normal mt-2">07/23/2022  |  1:10pm</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
