import React, { useState,useContext} from "react";
import { Link,useHistory   } from "react-router-dom";
import {KycMobContext} from "../../layouts/KYCMob"
import ReactLoading from "react-loading";
import { getrecordbyid } from "../../services/kyb/KybBankScreens";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import edit from " ../../assets/img/kycmobile/edit.png";
import Moment from "moment";

export default function Screen25() {
  const {data} = useContext(KycMobContext)
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const history = useHistory();
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [finalData, setFinalData] = useState([]);
  const [disable, setDisable] = React.useState(false);

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen25) {
        const redirecturl = "/kybverif/g/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kybverif/g/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  React.useEffect(() => {
    getrecordbyid(url).then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        setFinalData(response.data.bank_kyb);
        setDataLoaded(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }, [])

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  const checks_calculate = () => {
    setDisable(true);
    let temp = screenStates;
    temp.Screen26 = true;
    storage.setItem("screenmeta", JSON.stringify(temp));
    history.push("/kybverif/i/"+url);
    window.location.reload(false);
  }

  return (
    <>
      <div className="screenTwentyFive h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kybverif/g/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "97%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        {!dataLoaded &&
          <>
            <ReactLoading className="margin-auto mt-3 mb-3 absolute" type={"bars"} style={{ zIndex: "100000", top: "30%", left: "39%", width: "22%", height: "22%", fill: "#ee416f" }} />
            <div id="overlay" className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer" style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}></div>
          </> 
        }

        <div className="mainContent" >
          <div className="mainText">
            <h1>Review your Information</h1>
            <p className="text-center" style={{ color: "#a8a8a8" }}>If all good! Please click submit for verification.</p>
          </div>
          <div className="informationSection">
            <div className="block mb-2">
              <div className="headingText">
                <h5>Personal Information</h5>
              </div>
              {finalData && 
                <>
                  <div className="fields">
                    <p className="title">Email</p>
                    <p className="value" id="email">{(finalData.email) ? finalData.email : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Mobile Number</p>
                    <p className="value" id="firstname">{(finalData.phone_no) ? finalData.phone_no : 'N/A'}</p>
                  </div>
                </>
              }
            </div>

            <div className="block mb-2">
              <div className="headingText">
                <h5>Business Information</h5>
              </div>
              {finalData.kyb_business && 
                <>
                  <div className="fields" style={{wordWrap: "break-word" }} >
                    <p className="title">Legal Business Name</p>
                    <p className="value" id="idno">{(finalData.kyb_business.legal_business_name) ? finalData.kyb_business.legal_business_name : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Employer Identification Number</p>
                    <p className="value" id="nationality_1">{(finalData.kyb_business.ein) ? finalData.kyb_business.ein : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Doing Business as</p>
                    <p className="value" id="nationality_1">{(finalData.kyb_business.doing_business_as) ? finalData.kyb_business.doing_business_as : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Registered Business Address</p>
                    <p className="value" id="nationality_1">{(finalData.kyb_business.business_registered_address) ? finalData.kyb_business.business_registered_address : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Business Phone No</p>
                    <p className="value" id="nationality_1">{(finalData.kyb_business.business_phone_number) ? finalData.kyb_business.business_phone_number : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Industry</p>
                    <p className="value" id="nationality_1">{(finalData.kyb_business.industry) ? finalData.kyb_business.industry : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Company Website</p>
                    <p className="value" id="nationality_1">{(finalData.kyb_business.company) ? finalData.kyb_business.company : 'N/A'}</p>
                  </div>
                </>
              }
            </div>

            <div className="block mb-2">
              <div className="headingText">
                <h5>Business Representative Verification</h5>
              </div>
              {finalData.kyb_v_business && 
                <>
                  <div className="fields">
                    <p className="title">Legal Name</p>
                    <p className="value" id="country">{(finalData.kyb_v_business.legal_name) ? finalData.kyb_v_business.legal_name : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Email</p>
                    <p className="value" id="city">{(finalData.kyb_v_business.email) ? finalData.kyb_v_business.email : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Date of Birth</p>
                    <p className="value" id="dob">{(finalData.kyb_v_business.date_of_birth) ? 
                      (Moment(finalData.kyb_v_business.date_of_birth, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                        ? Moment(finalData.kyb_v_business.date_of_birth).format("MM-DD-YYYY")
                        : Moment(finalData.kyb_v_business.date_of_birth, "DD/MM/YYYY").format("MM-DD-YYYY")
                      : 'N/A'}
                    </p>
                  </div>
                  <div className="fields">
                    <p className="title">Job Title</p>
                    <p className="value" id="address">{(finalData.kyb_v_business.job_title) ? finalData.kyb_v_business.job_title : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Home Address</p>
                    <p className="value" id="address">{(finalData.kyb_v_business.home_address) ? finalData.kyb_v_business.home_address : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Phone No</p>
                    <p className="value" id="address">{(finalData.kyb_v_business.phone_number) ? finalData.kyb_v_business.phone_number : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Social Security Number (Last 4 digits)</p>
                    <p className="value" id="address">{(finalData.kyb_v_business.SSN) ? finalData.kyb_v_business.SSN : 'N/A'}</p>
                  </div>
                </>
              }
            </div>

            <div className="block mb-2">
              <div className="headingText">
                <h5>Payment Information</h5>
              </div>
              {finalData.kyb_payment && 
                <>
                  <div className="fields">
                    <p className="title">Account Type</p>
                    <p className="value" id="country">{(finalData.kyb_payment.type) ? finalData.kyb_payment.type : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Routing Number</p>
                    <p className="value" id="city">{(finalData.kyb_payment.routing_no) ? finalData.kyb_payment.routing_no : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Account Number</p>
                    <p className="value" id="address">{(finalData.kyb_payment.account_no) ? finalData.kyb_payment.account_no : 'N/A'}</p>
                  </div>
                </>
              }
            </div>
          </div>
        </div>

        <div className="buttonAction">
          {/* <Link to={"/verify/q/"+url}> */}
          <button onClick={checks_calculate} disabled={disable} style={{backgroundColor:data?data.button_color:"#ee416f"}} className="action">Submit</button>
          {/* </Link> */}
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
