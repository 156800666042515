import React from "react";
import Select from "react-select";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { getProperties, getSettings, saveSettings } from "../../services/network/Network";
import { WithContext as ReactTags } from 'react-tag-input';
import ReactLoading from "react-loading";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function NetworkSettings() {
  const color = "light";
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [propertyOptions, setPropertyOptions] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [properties, setProperties] = React.useState([]);
  const [defProperties, setDefProperties] = React.useState([]);
  const [accountTags, setAccountTags] = React.useState([]);
  const [minCluster, setMinCluster] = React.useState(null);
  const [maxCluster, setMaxCluster] = React.useState(null);
  const [dateRange, setDateRange] = React.useState(null);
  
  const handleDelete = i => {
    let tempTags = tags.filter((tag, index) => index !== i);
    let accTag = [];
    tempTags.forEach(ele => {
      accTag.push(ele.text);
    });
    setTags(tempTags);
    setAccountTags(accTag);
  };

  const handleAddition = tag => {
    let tempTags = [...tags, tag];
    let accTag = [];
    tempTags.forEach(ele => {
      accTag.push(ele.text);
    })
    setTags(tempTags);
    setAccountTags(accTag);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    let accTag = [];
    newTags.forEach(ele => {
      accTag.push(ele.text);
    })
    setAccountTags(accTag);
    setTags(newTags);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
  };

  React.useEffect(() => {
    getProperties().then((response) => {
      if (response.data.properties.length > 0) {
        let optionsArray = response.data.properties.map(ele => {
          return {
            value: ele.id,
            label: ele.property
          }
        });
        setPropertyOptions(optionsArray);
        
        getSettings().then((response) => {
          setMinCluster(response.data.settings.cluster_min);
          setMaxCluster(response.data.settings.cluster_max);
          setDateRange(response.data.settings.days_range);
    
          let tempProp = [];
          if (response.data.settings.properties.length > 0) {
            response.data.settings.properties.forEach(prop => {
              if (optionsArray.length > 0) {
                optionsArray.forEach(propOpt => {
                  if (propOpt.value == prop) {
                    tempProp.push(propOpt);
                  }
                })
              }
            })
          }
          setDefProperties(tempProp);
    
          setProperties(response.data.settings.properties);
          setAccountTags(response.data.settings.tags);
          let tempTags = [];
          response.data.settings.tags.forEach(tag => {
            tempTags.push({
              id: tag,
              text: tag
            });
          });
          setTags(tempTags);

          setIsLoaded(true);
        }).catch((error) => {
          console.log("error: ", error);
        })
      }
    }).catch((error) => {
      console.log("error: ", error);
    });

  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    let formdata = {
      properties: properties.toString(),
      tags: accountTags.toString(),
      cluster_min: minCluster,
      cluster_max: maxCluster,
      days_range: dateRange
    };

    saveSettings(formdata).then((response) => {
      if (response.data.success) {
        setIsLoaded(true);
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }

  return (
    <>
      <div className="networkSetting flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                {isLoaded ? 
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 ">
                      <div className="px-4 mr-4" style={{ paddingLeft: "0" }}>
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Nodes of Interest
                        </h4>
                      </div>
                      <p class="text-xs whitespace-nowrap mt-2">
                        These settings will be used as default for graph
                        functionality where applicable.
                      </p>
                      <div className="w-full mb-8 mt-6">
                        <Form onSubmit={handleSubmit} class="inquireForm w-full">
                          <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 mr-4">
                            <div className="searchInputTables mt-4">
                              <div className="fieldInquire relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-xs mb-2">
                                  Properties
                                </label>
                                <Select
                                  closeMenuOnSelect={false}
                                  isMulti
                                  options={propertyOptions}
                                  value={defProperties}
                                  style={{ all: "unset"}}
                                  className="propertySelect input_Fields"
                                  menuPortalTarget={document.body}
                                  styles={customStyles}
                                  onChange={(e) => {
                                    let temp = [];
                                    e.map(ele => {
                                      temp.push(ele.value);
                                    });
                                    setProperties(temp);
                                    setDefProperties(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="searchInputTables mt-6">
                              <div className="fieldInquire relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-xs mb-2">
                                  Accounts Tags
                                </label>
                                <ReactTags
                                  tags={tags}
                                  delimiters={delimiters}
                                  handleDelete={handleDelete}
                                  handleAddition={handleAddition}
                                  handleDrag={handleDrag}
                                  inputFieldPosition="bottom"
                                  placeholder="Enter here..."
                                />
                              </div>
                            </div>
                          </div>

                          <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 mr-4">
                            <div className="searchInputTables mt-4">
                              <div className="fieldInquire relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-xs mb-2">
                                  Minimum Cluster Size
                                </label>
                                <Input
                                  type="number"
                                  name="minCluster"
                                  value={minCluster}
                                  className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  onChange={(e) => setMinCluster(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="searchInputTables mt-6">
                              <div className="fieldInquire relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-xs mb-2">
                                  Maximum Cluster Size
                                </label>
                                <Input
                                  type="number"
                                  name="maxCluster"
                                  value={maxCluster}
                                  className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  onChange={(e) => setMaxCluster(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="searchInputTables mt-6">
                              <div className="fieldInquire relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-xs mb-2">
                                  Data Range (days)
                                </label>
                                <Input
                                  type="number"
                                  name="dataRange"
                                  value={dateRange}
                                  className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  onChange={(e) => setDateRange(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <button type="submit" class="buttonPink px-6 py-2 rounded-lg text-sm font-bold mt-4">Save Configurations</button>
                        </Form>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="lg:w-12/12 md:w-12/12 sm:w-6/12">
                    <ReactLoading
                      className="margin-auto mt-3 mb-3"
                      type={"bars"}
                      color={"#ee416f"}
                      height={"50px"}
                      width={"50px"}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
