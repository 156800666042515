import React from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Moment from 'moment';
import Select from "react-select";
import "react-circular-progressbar/dist/styles.css";
import { showallaudit } from "services/settings/AuditLog";
import ReactLoading from "react-loading";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";


import ExportIcon from "../../assets/img/icons/exporticon.png";
import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <div className="selectBox">
          <label className="inline-flex items-center cursor-pointer text-sm">
            <input
              ref={resolvedRef}
              {...rest}
              id="customCheckLogin"
              type="checkbox"
              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
            />
          </label>
        </div>
      </>
    );
  }
);

const IndeterminateCheckboxHeader = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          id="test"
          ref={resolvedRef}
          {...rest}
          style={{ display: "none" }}
        />
        <label for="test" className="cursor-pointer">
          Select All
        </label>
      </>
    );
  }
);

function Table({ columns, data }) {
  const color = "light";

  const [pageNum, setPageNum] = React.useState(1);

  const [fromDate,setFromDate] = React.useState("")
  const [fromTime,setFromTime] = React.useState("")
  const [toDate,setToDate] = React.useState("")
  const [toTime,setToTime] = React.useState("")

  const [filteredData, setFilteredData] = React.useState(data);

  const handleDateFilter =()=>{
    // console.log(fromDate + "from")
    // console.log(toDate + "to")

    const newFilter = data.filter((value)=>{
      const date = new Date(value.time_of_action)
      return fromDate.getTime()<= date.getTime()&& toDate.getTime()>=date.getTime()
    })
    setFilteredData(newFilter)
  }

  // console.log(fromDate);
  // console.log(fromTime);
  // console.log(toDate);
  // console.log(toTime);

  const searchby = [
    { label: "Search By", value: 1 },
    { label: "Search By Name", value: 2 },
    { label: "Search By ID Number", value: 3 },
    { label: "Search By User", value: 4 },
  ];
  const reporttype = [
    { label: "Report Type", value: 1 },
    { label: "Case Inquiries", value: 2 },
    { label: "Screening Reports", value: 3 },
    { label: "Monitoring Reports", value: 4 },
  ];
  const timeperiod = [{ label: "Time Period", value: 1 }];
  const country = [{ label: "Country Wise", value: 1 }];
  const risklevel = [
    { label: "Risk Level", value: 1 },
    { label: "Low", value: 2 },
    { label: "Medium", value: 3 },
    { label: "High", value: 4 },
  ];

  const defaultValueSearchBy = searchby[0];
  const defaultValueReportType = reporttype[0];
  const defaultValueTime = timeperiod[0];
  const defaultValueCountry = country[0];
  const defaultValueRiskLevel = risklevel[0];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {}
  );
  const disableDates = () => {
    const today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }

    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <>
      <div className="casemanagement flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 "
              }
            >
              <div className="rounded-t mb-0 py-4 border-0 px-12">
                <div className="row flex flex-row w-full px-4 justify-between items-center">
                  <h2
                    className="font-bold text-base pb-2"
                    style={{ borderBottom: "2px solid #ee416f" }}
                  >
                    Audit Logs
                  </h2>
                  <div className="flex items-center flex-wrap">
                    <div className="flex px-4 py-2 cursor-pointer">
                      <img
                        src={ExportIcon}
                        alt="export icon"
                        className="mr-3"
                        style={{ width: "15px", height: "15px" }}
                      />
                      <h4
                        className="font-semibold text-xs"
                        style={{ color: "#ee416f" }}
                      >
                        Export CSV
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1 mt-8">
                    <Form
                      class="filtersTable"
                      // onSubmit={(e) => handleLogin(e)}
                      // ref={c => {form = c;}}
                    >
                      <div className="flex justify-between items-center justify-center flex-wrap">
                        <div className="flex justify-between items-center justify-center flex-wrap mb-6">
                          <div className="searchInputTables mr-3 flex flex-row mr-8">
                            <div className="fieldSearch relative w-full mb-3 mr-2">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                From
                              </label>
                              {/* <Input
                                type="date"
                                max={disableDates()}
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ maxWidth: "170px" }}
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                                // validations={[required, validEmail]}
                              /> */}
                              <DatePicker 
                                portalId="root-portal"
                                maxDate={addDays(new Date(),0)}
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ maxWidth: "170px" }}
                                selected={fromDate} 
                                onChange={(date) => setFromDate(date)} 
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                            </div>

                            <div className="fieldSearch relative w-full mb-3">
                              <Input
                                type="time"
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ maxWidth: "170px" }}
                                value={fromTime}
                                onChange={(e) => setFromTime(e.target.value)}
                                // validations={[required, validEmail]}
                              />
                            </div>
                          </div>
                          <div className="searchInputTables mr-3 flex flex-row">
                            <div className="fieldSearch relative w-full mb-3 mr-2">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Username
                              </label>
                              <Input
                                type="text"
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ width: "200px" }}

                                // placeholder="Username"
                                // value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                // validations={[required, validEmail]}
                              />
                            </div>

                            <div className="fieldSearch relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Action
                              </label>
                              <Input
                                type="text"
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ width: "200px" }}

                                // placeholder="Action"
                                // value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                // validations={[required, validEmail]}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between items-center justify-center flex-wrap">
                          <div className="searchInputTables mr-3 flex flex-row mr-8">
                            <div className="fieldSearch relative w-full mb-3 mr-2">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                To
                              </label>
                              {/* <Input
                                type="date"
                                max={disableDates()}
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ maxWidth: "170px" }}
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                                // validations={[required, validEmail]}
                              /> */}
                               <DatePicker 
                              portalId="root-portal"
                              maxDate={addDays(new Date(),0)}
                              className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              style={{ maxWidth: "170px" }}
                              selected={toDate} 
                              onChange={(date) => setToDate(date)} 
                              />
                            </div>

                            <div className="fieldSearch relative w-full mb-3">
                              <Input
                                type="time"
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ maxWidth: "170px" }}
                                value={toTime}
                                onChange={(e) => setToTime(e.target.value)}
                                // validations={[required, validEmail]}
                              />
                            </div>
                          </div>
                          <div className="searchInputTables mr-3 flex flex-row">
                            <div className="fieldSearch relative w-full mb-3 mr-2">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Username
                              </label>
                              <Input
                                type="text"
                                style={{ width: "200px" }}
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                // placeholder="Username"
                                // value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                // validations={[required, validEmail]}
                              />
                            </div>

                            <div className="fieldSearch relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Action
                              </label>
                              <Input
                                type="text"
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ width: "200px" }}
                                // placeholder="Action"
                                // value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                // validations={[required, validEmail]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="searchButton mt-4">
                        <button onClick={handleDateFilter} type="button" className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs">
                          Search
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
                {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>{' '} */}
                <div className="flex flex-row items-center">
                  <button
                    className="mr-2"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    style={{outline:"none"}}
                  >
                    <img
                      src={leftPag}
                      alt="previous icon"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                  <span className="mr-2 text-xs">
                    Page
                    <strong className="ml-2">
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage} style={{outline:"none"}}>
                    <img
                      src={rightPag}
                      alt="previous icon"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Go to page</p>
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    value={pageNum}
                    onChange={(e) => {
                      const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
                      const page = value ? Number(value) - 1 : 0;
                      gotoPage(page);
                      setPageNum(value);
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Records per page</p>
                  <input
                    type="number"
                    min={1}
                    value={pageSize}
                    onChange={(e) => {
                      if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                        setPageSize(Number(1));
                      } else {
                        setPageSize(Number(e.target.value));
                      }
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                  <button
                    // onClick={() => }
                    className="px-2 py-2 bg-white rounded-lg"
                    style={{outline:"none"}}
                    onClick={() => window.location.reload()}
                  >
                    <img
                      src={refresh}
                      alt="refresh"
                      style={{ width: "13px", height: "13px" }}
                    />
                  </button>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table
                  {...getTableProps()}
                  className="items-center w-full bg-transparent border-collapse"
                >
                  <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-left text-black-600"
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function DataTableComponent() {
  const [data, setData] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    showallaudit()
      .then((response) => {
        // console.log("response", response);
        setData(response.data.activities);
        setDataLoaded(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const columns = [
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Username",
      accessor: "username",
      Cell: (tableProps) => (
        <>
          <div className="flex flex-col text-left">
            <span className={"text-sm font-bold text-black-600"}>
              {tableProps.row.original.username}
            </span>
          </div>
        </>
      ),
    },
    {
      Header: "User Type",
      accessor: "usertype",
    },
    {
      Header: "Audit Duration",
      accessor: "auditDuration",
      Cell: (tableProps) => (
        <>
          {Moment(tableProps.row.original.time_of_action).format('MM-DD-YYYY')}  {Moment(tableProps.row.original.time_of_action).format('hh:mm a')}
        </>
      ),
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  return <>
    {dataLoaded ? 
      <Table data={data} columns={columns} />
      :
      <ReactLoading
        className="margin-auto mt-3 mb-3"
        type={"bars"}
        color={"#ee416f"}
        height={"50px"}
        width={"50px"}
      />
    }
  </>;
}

export default DataTableComponent;
