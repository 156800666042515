import React, { useState } from "react";
import { useEffect } from "react";
import DeleteIcon from "../../assets/img/delete.png";
import NewType from "../../assets/img/newtype.png";
import riskScore from "../../assets/img/RiskScore.png";
import DataTableRiskScoring from "../../components/Datatables/DataTableRiskScoring";
import sliderPoint from "../../assets/img/sliderPoint.png";

export default function RiskScoringAndRating() {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(5);
  const [minPos, setMinPos] = useState("0%")
  const [maxPos, setMaxPos] = useState("90%")

  const handleMin = (e) => {
    const val = e.target.value
    if (val < max && val >=0) {
      setMin(val);
      setMinPos(((parseFloat(min)/5)*90).toString()+"%");
    }
  };

  const handleMax = (e) => {
    const val = e.target.value
    if (val > min && val <=5) {
      setMax(val);
      setMaxPos(((parseFloat(max)/5)*90).toString()+"%");
    }
  };
  //   const [range1, setRange1] = useState(0);
  //   const [range2, setRange2] = useState(5);
  // let priceGap = 0.5;

  // const handleChange=(e)=>{
  // e.preventDefault()

  // if((parseInt(range2) - parseInt(range1) >= priceGap)){
  //   if(e.target.className=="input-min") {
  //   setRange1(e.target.value)
  //   }
  //   else{
  //       setRange2(e.target.value)
  //   }
  // }

  // if((range2 - range1) < priceGap){
  //     if(e.target.className === "input-min"){
  //         setRange1(parseInt(range2) + priceGap)
  //     }else{
  //         setRange2(parseInt(range1) - priceGap)
  //     }
  // }else{
  //     document.querySelector(".slider .progress").style.left = ((parseInt(range1) / max) * 100) + "%";
  //     document.querySelector(".slider .progress").style.right = 100 - (parseInt(range2) / max) * 100 + "%";
  // }

  // }

  return (
    <>
      <div className="amlscreening casemanagement flex flex-wrap">
        <div className="w-full lg:w-12/12 ">
          <div className="relative flex flex-col min-w-0 break-words mb-6">
            <div className="w-full flex flex-row">
              <div className="lg:w-12/12 h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white mr-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white rounded-lg">
                  <div className="mb-0 p-16 border-0 px-12">
                    <div className="flex flex-wrap items-center mb-12">
                      <div className="lg:w-4/12 md:w-12/12 sm:w-12/12">
                        <h2 className="font-bold text-xl">
                          Risk Score Criteria
                        </h2>
                      </div>
                      <div className="lg:w-8/12 md:w-12/12 sm:w-12/12 text-left justify-end flex flex-row w-full riskMangBtns">
                        <h2 className="font-bold text-sm flex items-center textGray mr-4">
                          <img
                            src={DeleteIcon}
                            className="mr-2"
                            alt="deleteicon"
                            width={"13px"}
                            style={{ height: "17px" }}
                          />
                          Delete Selected
                        </h2>
                        <button style={{outline:"none"}}>
                          <img
                            src={NewType}
                            style={{ width: "170px" }}
                            alt="newtypebutton"
                          />
                        </button>
                      </div>
                    </div>

                    <DataTableRiskScoring />
                    
                    <div
                      className="riskMangBarDiv"
                      style={{
                        width: "55%",
                        marginTop: "40px",
                      }}
                    >
                      <div className="flex justify-between px-12 riskBar items-center">
                        <p className="text-xs font-bold">LOW</p>
                        <p className="text-xs font-bold">MEDIUM</p>
                        <p className="text-xs font-bold">HIGH</p>
                      </div>
                      <div className="flex justify-between" width>
                        <p>0</p>
                        <p>5</p>
                      </div>

                      <div className="flex" style={{marginTop:"-25px"}} >
                        <div style={{position:"relative",left:minPos}}>
                          <p style={{position:"relative", left:"11px", top:"40px", color:"#fff", zIndex:"2"}}>{min}</p>
                          <img  src={sliderPoint}/ >
                        </div>
                        <div style={{position:"relative",left:maxPos}}>
                          <p style={{position:"relative", left:"11px", top:"40px", color:"#fff", zIndex:"2"}}>{max}</p>
                          <img  src={sliderPoint}/ >
                        </div>
                      </div>

                      <div className="flex items-center justify-center my-4">
                        <div className="flex items-center">
                          <p className="text-xs mr-2">Min</p>
                          <input
                            className="text-xs"
                            name="min"
                            type="number"
                            value={min}
                            min="0"
                            max="5"
                            step="0.1"
                            onChange={(e) => handleMin(e)}
                          />
                        </div>
                        <div className="text-lg font-bold mx-4">-</div>
                        <div className="flex items-center">
                          <p className="text-xs mr-2">Max</p>
                          <input
                            className="text-xs"
                            name="max"
                            type="number"
                            value={max}
                            min="0"
                            max="5"
                            step="0.1"
                            onChange={(e) => handleMax(e)}
                          />
                        </div>
                      </div>
                      {/* <div>
                        <div className="slider">
                          <div className="progress"></div>
                        </div>
                        <div className="range-input">
                          <input
                            type="range"
                            className="input-min"
                            min={min}
                            max="5"
                            value={range1}
                            step="0.01"
                            onChange={(e)=>handleChange(e)}

                          />
                          <input
                            type="range"
                            className="input-max"
                            min="0"
                            max={max}
                            value={range2}
                            step="0.01"
                            onChange={(e)=>handleChange(e)}
                          />
                          <p>{range1}</p>
                          <p>{range2}</p>

                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
