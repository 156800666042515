import axios from "axios";
import address from "../Address";

export function sanctionSearch(formData) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    // console.log("formData: ", formData);

    // if (formData.birthYear != "") {
    //     formData.birthYear = formData.birthYear.getFullYear();
    // }
    if (formData.country != "" || formData.country != null || formData.country != undefined) {
        formData.country = formData.country ? formData.country.value.toLowerCase() : formData.country;
    }
    return axios.post(address + "/watchlists/search", formData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
}

export function sanctionEntitySearch(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    return axios.get(address + "/watchlists/entity/" + id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
}

export function sanctionActiveLists() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    return axios.get(address + "/watchlists/lists", { headers: {"Authorization" : `Bearer ${accessToken}`} });
}

export function sanctionSearchCSV(formData) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    return axios.post(address + "/watchlists/search_csv", formData, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Authorization" : `Bearer ${accessToken}` } });
}