import React from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table'
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from 'react-select';
import 'react-circular-progressbar/dist/styles.css';

import ExportIcon from "../../assets/img/icons/exporticon.png";
import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";
import searchIcon from "../../assets/img/search-icon.png";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <div className="selectBox">
          <label className="inline-flex items-center cursor-pointer text-sm">
            <input
              ref={resolvedRef} {...rest}
              id="customCheckLogin"
              type="checkbox"
              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
            />
          </label>
        </div>
      </>
    )
  }
)

const IndeterminateCheckboxHeader = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
          <input type="checkbox" id="test" ref={resolvedRef} {...rest} style={{display:"none"}} /><label for="test" className="cursor-pointer">Select All</label>
      </>
    )
  }
)

function Table({columns, data}) {
  const color = "light";
  const [pageNum, setPageNum] = React.useState(1);

  const searchby = [
    { label: "Search By", value: 1 },
    { label: "Search By Name", value: 2 },
    { label: "Search By ID Number", value: 3 },
    { label: "Search By User", value: 4 },
  ];
  const reporttype = [
    { label: "Report Type", value: 1 },
    { label: "Case Inquiries", value: 2 },
    { label: "Screening Reports", value: 3 },
    { label: "Monitoring Reports", value: 4 },
  ];
  const timeperiod = [
    { label: "Time Period", value: 1 },
  ];
  const country = [
    { label: "Country Wise", value: 1 },
  ];
  const risklevel = [
    { label: "Risk Level", value: 1 },
    { label: "Low", value: 2 },
    { label: "Medium", value: 3 },
    { label: "High", value: 4 },
  ];

  const defaultValueSearchBy = searchby[0];
  const defaultValueReportType = reporttype[0];
  const defaultValueTime = timeperiod[0];
  const defaultValueCountry = country[0];
  const defaultValueRiskLevel = risklevel[0];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckboxHeader {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#828282' : '#828282',
      backgroundColor: state.isSelected ? '#EBEDF2' : '#FFF',
      padding: 10,
      fontSize: '14px',
    }),
  }

  return (
    <>
      <div className="casemanagement flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 mt-8">
                    <Form class="filtersTable" 
                      // onSubmit={(e) => handleLogin(e)} 
                      // ref={c => {form = c;}}
                    >
                      <div className="flex justify-between justify-center flex-wrap">
                        <div className="flex flex-col">
                          <div className='flex justify-between items-center justify-center flex-wrap'>
                          <div className="searchInputTables mr-3">
                            <div className="fieldSearchTables relative w-full mb-3">
                              <label
                                className="label block font-semibold text-black-600 text-sm mb-2"
                              >
                                <img className="searchIcon" src={searchIcon} />
                              </label>
                              <Input
                                type="text"
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                // placeholder="Search by name, reference ID or inquiry ID"
                                // value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                // validations={[required, validEmail]}
                              />
                            </div>
                          </div>
                          <div className="">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ searchby } isSearchable={false} defaultValue={defaultValueSearchBy} />
                            </div>
                          </div>
                          </div>
                          <div className="searchButton mt-2">
                        <button className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs">
                          Search
                        </button>
                      </div>
                        </div>
                        <div className="flex flex-wrap">
                          <h1 className="text-base font-bold items-center mr-3">Filter</h1>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ reporttype } isSearchable={false} defaultValue={defaultValueReportType} />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ timeperiod } isSearchable={false} defaultValue={defaultValueTime} />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ country } isSearchable={false} defaultValue={defaultValueCountry} />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ risklevel } isSearchable={false} defaultValue={defaultValueRiskLevel} />
                            </div>
                          </div>

                          <div className="flex pr-4 py-2 cursor-pointer">
                            <img src={ExportIcon} alt="export icon" className="mr-3" style={{width:"20px", height:"20px"}} />
                            <h4 className="font-semibold text-sm" style={{color: "#ee416f"}}>Export CSV</h4>
                          </div>
                        </div>
                      </div>
                      
                    </Form>
                  </div>
                </div>
              </div>
              <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
                {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>{' '} */}
                <div className="flex flex-row items-center">
                  <button className="mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <img src={leftPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                  </button>
                  <span className="mr-2 text-xs">
                    Page
                    <strong className="ml-2">
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    <img src={rightPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                  </button>
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Go to page</p>
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    value={pageNum}
                    onChange={(e) => {
                      const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
                      const page = value ? Number(value) - 1 : 0;
                      gotoPage(page);
                      setPageNum(value);
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Records per page</p>
                  <input
                    type="number"
                    min={1}
                    value={pageSize}
                    onChange={e => {
                      if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                        setPageSize(Number(1));
                      } else {
                        setPageSize(Number(e.target.value));
                      }
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                  <button
                    // onClick={() => }
                    style={{outline:"none"}}
                    className="px-2 py-2 bg-white rounded-lg"
                  >
                    <img src={refresh} alt="refresh" style={{width:"15px", height:"15px"}} />
                  </button>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table {...getTableProps()} className="items-center w-full bg-transparent border-collapse">
                  <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th 
                            {...column.getHeaderProps()}
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                            }
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()} className="cursor-pointer">
                          {row.cells.map(cell => {
                            return (
                              <td {...cell.getCellProps()} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function DataTableComponent() {
  const data = [
    {
      name: 'Sara Turner 1',
      id: 'inq_128710343324adawkm',
      creationDate: '21-02-2022',
      email: 'usama@gmail.com',
      country: 'Pakistani',
      date: '12-09-1993',
      lastModifiedBy: 'Admin',
      kycStatus: 'Approved'
    },
    {
      name: 'Sara Turner 2',
      id: 'inq_128710343324adawkm',
      creationDate: '21-02-2022',
      email: 'usama@gmail.com',
      country: 'Pakistani',
      date: '12-09-1993',
      lastModifiedBy: 'Moderator',
      kycStatus: 'Pending'
    },
    {
      name: 'Sara Turner 3',
      id: 'inq_128710343324adawkm',
      creationDate: '21-02-2022',
      email: 'usama@gmail.com',
      country: 'Pakistani',
      date: '12-09-1993',
      lastModifiedBy: 'Admin',
      kycStatus: 'Decline'
    },
    {
      name: 'Sara Turner 4',
      id: 'inq_128710343324adawkm',
      creationDate: '21-02-2022',
      email: 'usama@gmail.com',
      country: 'Pakistani',
      date: '12-09-1993',
      lastModifiedBy: 'Admin',
      kycStatus: 'Approved'
    },
    {
      name: 'Sara Turner 5',
      id: 'inq_128710343324adawkm',
      creationDate: '21-02-2022',
      email: 'usama@gmail.com',
      country: 'Pakistani',
      date: '12-09-1993',
      lastModifiedBy: 'Admin',
      kycStatus: 'Approved'
    },
    {
      name: 'Sara Turner 6',
      id: 'inq_128710343324adawkm',
      creationDate: '21-02-2022',
      email: 'usama@gmail.com',
      country: 'Pakistani',
      date: '12-09-1993',
      lastModifiedBy: 'Admin',
      kycStatus: 'Decline'
    },
    {
      name: 'Sara Turner 7',
      id: 'inq_128710343324adawkm',
      creationDate: '21-02-2022',
      email: 'usama@gmail.com',
      country: 'Pakistani',
      date: '12-09-1993',
      lastModifiedBy: 'Admin',
      kycStatus: 'Pending'
    },
    {
      name: 'Sara Turner 8',
      id: 'inq_128710343324adawkm',
      creationDate: '21-02-2022',
      email: 'usama@gmail.com',
      country: 'Pakistani',
      date: '12-09-1993',
      lastModifiedBy: 'Admin',
      kycStatus: 'Decline'
    }
  ]

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: tableProps => (
        <>
        <div className="flex flex-col">
          <span
            className={
              "text-sm font-bold text-black-600"
            }
          >
            {tableProps.row.original.name}
          </span>
          <span className="font-light text-xs textLightGray">
            Inquiry ID: {tableProps.row.original.id}
          </span>
          <span className="font-light text-xs textLightGray">
            Creation Date: {tableProps.row.original.creationDate}
          </span>
        </div>
        </>
      )
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Country',
      accessor: 'country'
    }, 
    {
      Header: 'Date',
      accessor: 'date'
    },
    {
      Header: 'Last Modified By',
      accessor: 'lastModifiedBy'
    },
    {
      Header: 'KYC Status',
      accessor: 'kycStatus',
      Cell: tableProps => (
        <div className="statusGrid flex flex-col max-w-120-px mx-auto">
          {tableProps.row.original.kycStatus.includes("Decline") && 
            <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg font-semibold text-white-400 uppercase buttonRed mb-2">
              Decline
            </button>
          }
          {tableProps.row.original.kycStatus.includes("Pending") && 
            <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg font-semibold text-white-400 uppercase buttonYellow mb-2">
              Pending
            </button>
          }
          {tableProps.row.original.kycStatus.includes("Approved") && 
            <button className="w-full amlbuttonsSmall px-4 py-2 rounded-lg font-semibold text-white-400 uppercase buttonGreen">
              Approved
            </button>
          }
        </div>
      )
    }
  ]

  return (
      <Table data={data} columns={columns}/>
  )

}

export default DataTableComponent