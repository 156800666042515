import React from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Link } from "react-router-dom";
import { searchAllContracts } from "services/onchain/Onchain";
import ReactLoading from "react-loading";

import searchIcon from "../../assets/img/search-icon.png";

export default function OnchainSearch() {
  const [searchValue, setSearchValue] = React.useState("");
  const [showSearchDetails, setShowSearchDetails] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const bitcoinWalletArray = [
    "bitcoin",
    "litecoin",
    "dogecoin",
    "dash",
    "zcash",
    "bitcash",
    "bitcoinsv",
    "ethereum",
    "ethclassic",
    "ethclassic_reorg",
    "ethpow",
    "algorand",
    "bsc",
    "celo_rc1",
    "conflux_hydra",
    "eos",
    "tron",
    "solana",
    "matic",
    "velas",
    "klaytn",
    "elrond",
    "avalanche",
    "fantom",
    "moonbeam",
    "cronos",
    "flow",
    "everscale",
    "cardano",
    "algorand_testnet",
    "algorand_beatnet",
    "celo_alfajores",
    "bsc_testnet",
    "goerli",
    "celo_baklava",
    "conflux_oceanus",
    "medalla",
    "velas_testnet",
    "crypto_testnet",
    "binance",
    "consmoshub",
    "crypto_mainnet",
    "hemidall",
    "terra",
    "diem_testnet",
    "libra_testnet",
    "stellar",
    "ripple",
    "hedera",
    "tezos",
    "eth2",
    "filecoin",
  ];

  const handleSearch = (e) => {
    setShowSearchDetails(false);
    if (searchValue != "") {
      setShowLoader(true);
      let formdata = {
        "address": searchValue,
        "network": "string",
        "date_from": "string",
        "date_till": "string",
        "limit": 0,
        "nativeCurrency": "string",
        "offset": 0
      };
  
      searchAllContracts(formdata).then((response) => {
        if (response.data.success) {
          // console.log("result: ", response);
          if (response.data.response) {
            setSearchResults(response.data.response.data.search);
            setShowSearchDetails(true);
          }
          setShowLoader(false);
        }
      }).catch((error) => {
        console.log("error: ", error);
      })
    }
  }

  return (
    <>
      <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
        <div className="lg:w-12/12 md:w-12/12 sm:w-6/12">
          <Form class="inquireForm aml flex flex-col">
            <div className="searchInputTables">
              <div className="fieldInquire relative w-full mb-3">
                <label className="label block font-semibold text-black-600 text-xs mb-2">
                  Search
                </label>
                <Input
                  type="text"
                  name="searchtoken"
                  className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  style={{ fontSize: "12px" }}
                  value={searchValue}
                  placeholder={"Search in all blockchains"}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
            {/* <Link to={"/onchain/smartcontract"}> */}
            <button
              onClick={handleSearch}
              type={"button"}
              className="py-3 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs max-w-150-px"
            >
              Search
            </button>
            {/* </Link> */}
          </Form>
        </div>
      </div>

      {showSearchDetails && 
        <>
          <div className="rounded-lg flex justify-between bg-white px-8 py-4 border-0 w-full mb-6 shadow-lg">
            <div className="lg:w-12/12 md:w-12/12 sm:w-6/12">
              <div className="p-2 flex flex-col w-full" style={{}}>
                <div className="flex flex-row w-full items-center border-bottom-grey-2 pb-4">
                  <img src={searchIcon} className="mr-4" style={{ width: "20px", height: "20px" }} />
                  <h1 className="font-normal text-base">Search: <b>{searchResults[0].subject.address}</b> in all blockchains.</h1>
                </div>
                <div className="max-h-40-vh overflow-y-auto">
                  {searchResults.length > 0 && (
                    <>
                      {searchResults.map((ele, ind) => (
                        <>
                          <div data-index={ind}>
                            {(ele.network.network != "conflux_tethys") && 
                              <Link to={"/onchain/smartcontract/"+ele.subject.address+"/"+ele.network.protocol+"/"+ele.subject.__typename}>
                                <div className="flex flex-row searchContract mt-4 border-bottom-grey-1 pb-4">
                                  {(bitcoinWalletArray.indexOf(ele.network.network) > -1) && 
                                    <img style={{ width: "20px", height: "20px", marginRight: "10px" }} src={require(`assets/img/onchain/${ele.network.network}.svg`).default} />
                                  }
                                  <span className="capitalize text-sm"><b>{ele.network.network ? ele.network.network : "N/A"}</b> {ele.subject.__typename ? ele.subject.__typename : "N/A"} - {ele.subject.address ? ele.subject.address : "N/A"}</span>
                                </div>
                              </Link>
                            }
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {showLoader && 
        <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
          <div className="lg:w-12/12 md:w-12/12 sm:w-6/12">
            <ReactLoading
              className="margin-auto mt-3 mb-3"
              type={"bars"}
              color={"#ee416f"}
              height={"50px"}
              width={"50px"}
            />
          </div>
        </div>
      }
    </>
  );
}