import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
// import FooterAdmin from "components/Footers/FooterAdmin.js";

// asset
// import BackgroundImg from "../assets/img/dashboardBg.png";
import BackgroundImg from "../assets/img/bglo.svg";
import sidebarIcon from "../assets/img/icons/sidebarIcon.png";

// views
import AlertsCase from "views/admin/AlertsCase";
import TransactionMonitoring from "views/admin/TransactionMonitoring";
import TransactionMonitoringDetailsOne from "views/admin/TransactionMonitoringDetailsOne";
import TransactionMonitoringDetailsTwo from "views/admin/TransactionMonitoringDetailsTwo";
import Rules from "views/admin/Rules";
import CaseManagementOnboarding from "views/admin/CaseManagementOnboarding";
import CorporateKYCCaseManagementOnboarding from "views/admin/CorporateKYCCaseManagementOnboarding";
import CorporateCaseManagementOnboarding from "views/admin/CorporateCaseManagementOnboarding";
import CaseManagementOnboarding2 from "views/admin/CaseManagementOnboarding2";
import BankCaseManagementOnboarding from "views/admin/BankCaseManagementOnboarding";
import BankCaseManagementDetailOnboarding from "views/admin/BankCaseManagementDetailOnboarding";

// import CaseManagementDetailOnboardingOne from "views/admin/CaseManagementDetailOnboardingOne";
// import CaseManagementDetailOnboardingTwo from "views/admin/CaseManagementDetailOnboardingTwo";
import AlertsTransMonitoring from "views/admin/AlertsTransMonitoring";
import CaseManagementDetailOnboarding from "views/admin/CaseManagementDetailOnboarding";
import CorporateCaseManagementDetailOnboarding from "views/admin/CorporateCaseManagementDetailOnboarding";
import CorporateKYCCaseManagementDetailOnboarding from "views/admin/CorporateKYCCaseManagementDetailOnboarding";
import CreateRules from "views/admin/CreateRule";

export default function CaseManagementAdmin() {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} />
      {sidebarOpen ? (
        <button
          className=" sidebarOpenButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen && <img src={sidebarIcon} />}
        </button>
      ) : (
        <button
          className=" sidebarCloseButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {!sidebarOpen && <img src={sidebarIcon} className="sidebarRotated" />}
        </button>
      )}
      <div 
        className={"relative bg-no-repeat bg-cover " + (sidebarOpen ? "md:ml-52" : "md:ml-18")}
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundPosition: 'center', 
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className=" md:px-4 mx-auto w-full -m-24">
          <Switch>
            <Route path="/case/transmonitoring" exact component={TransactionMonitoring} />
            <Route path="/case/transmonitoring/details/1" exact component={TransactionMonitoringDetailsOne} />
            <Route path="/case/transmonitoring/details/2" exact component={TransactionMonitoringDetailsTwo} />
            <Route path="/case/transmonitor/alerts" exact component={AlertsTransMonitoring} />
            <Route path="/case/transmonitor/rules" exact component={Rules} />
            <Route path="/case/transmonitor/createrules" exact component={CreateRules} />

            {/* casemanagement onboarding */}
            <Route path="/case/casemanagement/:category" exact component={CaseManagementOnboarding} />
            {/* <Route path="/case/casemanagement2" exact component={CaseManagementOnboarding2} /> */}

            {/* <Route path="/case/casemanagementdetail/1" exact component={CaseManagementDetailOnboardingOne} />
            <Route path="/case/casemanagementdetail/2" exact component={CaseManagementDetailOnboardingTwo} /> */}
            <Route path="/case/casemanagementdetail/:inq_id" exact component={CaseManagementDetailOnboarding} />

            {/* KYB casemanagement onboarding */}
            <Route path="/case/corporate/casemanagement" exact component={CorporateCaseManagementOnboarding} />
            <Route path="/case/corporate/casemanagementdetail/:inq_id" exact component={CorporateCaseManagementDetailOnboarding} />

            {/* KYB KYC casemanagement onboarding */}
            <Route path="/case/corporate/kyc/casemanagement/:inq_id" exact component={CorporateKYCCaseManagementOnboarding} />
            <Route path="/case/corporate/kyc/casemanagementdetail/:inq_id" exact component={CorporateKYCCaseManagementDetailOnboarding} />

            {/* Bank KYB routes */}
            <Route path="/case/bank/casemanagement" exact component={BankCaseManagementOnboarding} />
            <Route path="/case/bank/casemanagementdetail/:inq_id" exact component={BankCaseManagementDetailOnboarding} />

            <Route path="/case/alerts" exact component={AlertsCase} />
            
            <Redirect from="/case" to="/case/casemanagement" />
          </Switch>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
