import React, { createContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { getcustomoptionsmobile } from "services/kyc/KycScreens";
import {QRCodeSVG} from 'qrcode.react';

// resources
import "../assets/styles/kycmobile.css";
import "../assets/styles/kycMobileScreens.css";
import footerLogo from "../assets/img/footerLogo.png";
import CardKYCPopup from "../components/Cards/CardKYCPopup"
import BackgroundImg from "../assets/img/bglo.svg";

// views
import KybStart from "views/kybmob/KybStart";
import Screen from "views/kybmob/Screen";
import Screen1 from "views/kybmob/Screen1";
import Screen1info from "views/kybmob/Screen1info";
import Screen1i from "views/kybmob/Screen1i";
import Screen2 from "views/kybmob/Screen2";
import Screen3 from "views/kybmob/Screen3";
import Screen4 from "views/kybmob/Screen4";
import Screen26 from "views/kybmob/Screen26";
import Screen27 from "views/kybmob/Screen27";
import Screen3docs from "views/kybmob/Screen3docs";

export const KycMobContext = createContext({});

export default function KYB() {
  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [urlId, setUrlId] = React.useState(url);
  const [screensData, setScreensData] = React.useState({});
  const [height,setHeight] = React.useState(window.innerHeight)
  const [isOpen, setIsOpen] = React.useState(true);

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    getcustomoptionsmobile()
      .then((response) => {
        // console.log("response: ", response.data);
        setScreensData(response.data.cust_options);
        // console.log(response.data.cust_options);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, []);

  return (
    <>
      {urlId && 
        <div 
          className={"bg-no-repeat bg-cover topdivkyc"}
          style={{
            backgroundImage: `url(${BackgroundImg})`,
            backgroundPosition: 'center', 
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: "100%",
            minHeight: "100vh",
          }}
        >
          <main className="bg-white main rounded-2xl shadow-2xl KYC-FullScreen" style={{ height: height }}>
            <section className="relative h-full md:top-0 rounded-2xl KYC-mobileAll" style={{width:"100%"}}>
              <div
                className="kycinnerdiv row h-full lg:w-12/12 md:w-12/12 sm:w-12/12 rounded-2xl relative bg-white"
                style={{ height: height, overflowY: "none" }}
              >
                <KycMobContext.Provider value={{data:screensData, height:height}}>
                  <Switch>
                    <Route path={"/kyb/start"} exact component={KybStart} />
                    <Route path={"/kyb/screen/" + urlId} exact component={Screen} />
                    <Route path={"/kyb/a/" + urlId} exact component={Screen1} />
                    <Route path={"/kyb/b/" + urlId} exact component={Screen1info} />
                    <Route path={"/kyb/c/" + urlId} exact component={Screen1i} />
                    <Route path={"/kyb/d/" + urlId} exact component={Screen2} />
                    <Route path={"/kyb/e/" + urlId} exact component={Screen3} />
                    <Route path={"/kyb/f/" + urlId} exact component={Screen3docs} />
                    <Route path={"/kyb/g/" + urlId} exact component={Screen4} />
                    <Route path={"/kyb/h/" + urlId} exact component={Screen26} />
                    <Route path={"/kyb/i/" + urlId} exact component={Screen27} />

                    {/* <Redirect from={"/kyb/"} to={"/kyb/a/"+urlId} /> */}
                  </Switch>
                </KycMobContext.Provider>
              </div>
            </section>
            {/* {isOpen &&<CardKYCPopup handleClose={handleClose} link={window.location.origin + "/kyb/a/" + urlId}/>} */}
          </main>

          <div className="qrcodediv-kyc absolute bg-white main rounded-2xl shadow-2xl">
            <div class="mainContent flex flex-col items-center p-4">
              <div class="mainImage mt-2">
                <QRCodeSVG className="mx-auto" value={window.location.origin + "/kyb/a/" + urlId} size="50"/>
              </div>
              <div className="mainText mt-4">
                <h2 className="text-md font-bold">Scan QR with your phone's camera</h2>
              </div>
              <div className="supportingText">
                <p className="supportingTextPara text-xs">
                  Point your phone's camera to this QR code and follow the link.
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
