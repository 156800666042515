import axios from "axios";
import address from "../Address";


export function getProperties() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.get(address + "/network_analysis/properties", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getSettings() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.get(address + "/network_analysis/settings", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function saveSettings(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.post(address + "/network_analysis/settings", formdata, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getGraphData(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    
    if (accessToken) {
        return axios.post(address + "/network_analysis/graph", formdata, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}