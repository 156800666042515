import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

// import Navbar from "components/Navbars/AuthNavbar.js";
// import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

import "../assets/styles/index.css"
import TwoFactorAuth from "views/auth/TwoFactorAuth";

export default function Auth() {
  return (
    <>
      <main>
        <section className="relative w-full h-full min-h-screen authScreens">
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            {/* <Route path="/auth/register" exact component={Register} /> */}
            {/* <Route path="/auth/twofactor" exact component={TwoFactorAuth} /> */}
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </section>
      </main>
    </>
  );
}
