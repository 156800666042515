import React from "react";
import { useState } from "react";
import Select from "react-select";
import Form from "react-validation/build/form";

import createRule from "../../assets/img/createRulePage.png";
function CreateRule() {
  const color = "light";

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      padding: 10,
      fontSize: "12px",
    }),
  };
  const [range, setRange] = useState(10);

  return (
    <>
      <div className="createRule flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 mt-8 ">
                    <div className="flex">
                      <div className="searchButton mr-4">
                        <button className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs">
                          Create/Edit Rule
                        </button>
                      </div>
                      <div className="searchButton ">
                        <button className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs">
                          Create Tag
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flex justify-between">
                      <p className="font-bold text-lg">Trigger Details</p>
                      <Form
                        class="filtersTable"
                        // onSubmit={(e) => handleLogin(e)}
                        // ref={c => {form = c;}}
                      >
                        <div className="flex flex-wrap">
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                // options={timeperiod}
                                isSearchable={false}
                                // defaultValue={defaultValueTime}
                              />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                // options={risklevel}
                                isSearchable={false}
                                // defaultValue={defaultValueRiskLevel}
                              />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                // options={status}
                                isSearchable={false}
                                // defaultValue={defaultValueStatus}
                              />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                // options={countrywise}
                                isSearchable={false}
                                // defaultValue={defaultValueCountryWise}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                // options={tags}
                                isSearchable={false}
                                // defaultValue={defaultValueTags}
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="flex justify-between createRuleMainDiv mt-8">
                      <div
                        className="lg:w-5/12 md:w-12/12 sm:w-12/12 px-4"
                        style={{ height: "400px" }}
                      >
                        <div
                          className="h-full w-full"
                          style={{
                            background: "#e0e0e0",
                            borderRadius: "10px",
                          }}
                        ></div>
                      </div>
                      <div className="lg:w-7/12 md:w-12/12 sm:w-12/12 flex flex-col justify-between">
                        <div className="mt-12 flex items-start justify-between">
                          <p className="font-bold text-base">Amount</p>
                          <div className="range">
                            <div class="field">
                              <input
                                className="rangeInput"
                                type="range"
                                min="10"
                                max="1000"
                                step="10"
                                value={range}
                                onChange={(e) => setRange(e.target.value)}
                              />
                            </div>
                            <div className="flex justify-between">
                              <p className="text-sm">10k</p>
                              <p className="text-sm">1000k</p>
                            </div>
                          </div>

                          <input
                            style={{
                              width: "15%",
                              height: "40px",
                              paddingLeft: "10px",
                              border: "2px solid #e0e0e0",
                              borderRadius: "8px",
                              fontSize: "14px",
                            }}
                            className="field dropdown relative w-full mb-3"
                            value={range + "k"}
                            onChange={(e) => setRange(e.target.value)}
                          />
                        </div>
                        <div className="flex w-full  justify-end createRuleButtons">
                          <button
                            style={{ width: "20%" }}
                            className="mr-4 px-4 py-2 text-xs rounded-lg font-semibold text-white-400 buttonDarkGrey"
                          >
                            Delete
                          </button>
                          <button
                            style={{ width: "20%" }}
                            className="mr-4 px-4 py-2 text-xs rounded-lg font-semibold text-white-400 buttonDarkGrey"
                          >
                            Save
                          </button>
                          <button
                            style={{ width: "20%" }}
                            className=" px-4 py-2 text-xs rounded-lg font-semibold text-white-400 buttonDarkGrey"
                          >
                            Generate
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateRule;
