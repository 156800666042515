import axios from "axios";
import address from "../Address";

export function viewallprocessed() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/kybcasemanagement/viewall_processed", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function viewsingleright(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/kybcasemanagement/showrightdetail/" + id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function viewdetail(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/kybcasemanagement/showdetail/" + id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function showkycs(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/kybcasemanagement/showkycs/" + id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function updatestatus(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/kybcasemanagement/updatestatus", formdata, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}