import React from "react";

function MapMultiple(props) {
  const mapRef = React.useRef(null);
  
  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    let locations = props.location;
    // console.log("locations: ", locations);
    let lat = locations[0].lat;
    let lng = locations[0].long;
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 12,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#4299e1" }, { visibility: "on" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);
    const contentString = '';

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    var marker, i;
    var markersArray = [];

    for (i = 0; i < locations.length; i++) {  
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[i].lat, locations[i].long),
        map: map,
        animation: google.maps.Animation.DROP,
      });
      markersArray.push(marker);
      
      google.maps.event.addListener(marker, 'click', (function(marker, i) {
        return function() {
          infowindow.setContent(locations[i].name);
          infowindow.open(map, marker);
        }
      })(marker, i));
    }

    var bounds = new google.maps.LatLngBounds();
    for(i = 0; i < markersArray.length; i++) {
      bounds.extend(markersArray[i].getPosition());
    }

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);
  });
  return (
    <>
      <div className="relative w-full rounded h-350-px">
        <div className="rounded h-full" ref={mapRef} style={{ borderRadius: "30px" }} />
      </div>
    </>
  );
}

export default MapMultiple;
