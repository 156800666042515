import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import {KycMobContext} from "../../layouts/KYCMob"
import { savebasicinfo } from "services/kyb/KybBankScreens";
import { saveevent } from "../../services/kyc/KycScreens";
import { isMobile } from 'react-device-detect';

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen2() {
  const {data} = useContext(KycMobContext)
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [urlId, setUrlId] = React.useState(url);
  const [message, setMessage] = useState("");
  const history = useHistory();
  const [disable, setDisable] = React.useState(false);
  const [formdata, setFormdata] = React.useState({
    email: null,
    mobile: null
  });
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen2) {
        const redirecturl = isMobile ? "/kybverif/b/"+url : "/kybverif/c/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = isMobile ? "/kybverif/b/"+url : "/kybverif/c/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  React.useEffect(()=>{
    if (data) {
      if(data.screen_2 === 0){
        history.push("/kybverif/e/"+urlId);
        window.location.reload();
      }
    }
  },[data]);

  const handleInputChange = (event) => {
    setMessage("");
    const name = event.target.name;
    const value = event.target.value;

    if (name === "email") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        setMessage("Invalid email address");
      }
      setFormdata((values) => ({ ...values, [name]: value }));
    } else {
      setFormdata((values) => ({ ...values, [name]: value }));
    }
  };

  const handleSubmit = () => {
    setMessage("");
    
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formdata.email)) {
      setMessage("Invalid email address");
      return;
    }
    setDisable(true);
    
    if (formdata.email && formdata.mobile) {
      let data_to_send = {
        email: formdata.email,
        phone_no: formdata.mobile,
        inq_id: urlId
      }
      savebasicinfo(data_to_send).then((response) => {
        // console.log("response: ", response);
        if (response.data.success) {
          let formdata3 = {
            inq_id: url,
            event_desc: "Initial Details Saved",
            passed: 1,
          };
          saveevent(formdata3).then((response) => {
            if (response.data.success) {
              let temp = screenStates;
              temp.Screen3 = true;
              storage.setItem("screenmeta", JSON.stringify(temp));
              const redirecturl = "/kybverif/e/"+urlId;
              history.push(redirecturl);
              window.location.reload();
            }
          }).catch((error) => {
            console.log("error: ", error);
          })
        }
      }).catch((error) => {
        console.log("error: ", error);
      })
    } else {
      setDisable(false);
      setMessage("Please fill out all the fields.");
    }
  }
  
  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenThree screenThreeKyb screenThreeDocs screenTwo h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kybverif/c/"+urlId}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "20%",backgroundColor:data ? data.progressbar_color:"#ee416f" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText mb-4">
            <h1 style={{ lineHeight: 1 }}>Please fill out these details to get started.</h1>
          </div>
          <Form className="informationSection mt-8">
            <div className="emailFields">
              <div className="inputfield email">
                <span className="label text-xs">Email Address</span>
                <Input 
                  type="email" 
                  name="email" 
                  className="input emailInput"
                  value={formdata.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="emailFields">
              <div className="inputfield email">
                <span className="label text-xs">Mobile Number</span>
                <Input 
                  type="text" 
                  name="mobile" 
                  className="input emailInput"
                  value={formdata.mobile}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-sm alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}
          </Form>
        </div>

        <div class="buttonAction">
          {/* <Link to="/kybverif/screen3"> */}
            <button onClick={handleSubmit} disabled={disable} style={{backgroundColor:data?data.button_color:"#ee416f"}}  class="action">Continue</button>
          {/* </Link> */}
        </div>

        <div class="footer">
          <img class="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
