import React from "react";
import { Link } from "react-router-dom";
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table'
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  // viewall,
  // viewallprocessed,
  viewsingleright,
  getevents,
} from "services/casemanagement/Onboarding";
import { showkycs } from "services/casemanagement/OnboardingKYB";
import Moment from "moment";
import ReactLoading from "react-loading";
import { CSVLink, CSVDownload } from "react-csv";

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";
import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import ElipsesYellow from "../../assets/img/event_elp_yellow.png";
import VerticalLine from "../../assets/img/verticalLine.png";
import statusAccountGreen from "../../assets/img/statusAccountGreen.png";
import statusLocGreen from "../../assets/img/statusLocGreen.png";
import statusIDGreen from "../../assets/img/statusIDGreen.png";
import statusMapGreen from "../../assets/img/statusMapGreen.png";
import statusAccountRed from "../../assets/img/statusAccountRed.png";
import statusLocRed from "../../assets/img/statusLocRed.png";
import statusIDRed from "../../assets/img/statusIDRed.png";
import statusMapRed from "../../assets/img/statusMapRed.png";
import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";

function Table({columns, data, setTableData, filteredData, setFilteredData, rightId, allDataLoaded}) {
  const color = "light";
  const [pageNum, setPageNum] = React.useState(1);

  const handleFilter = (word) => {
    if(word==""){
      setFilteredData(data)
      return
    }
    const newFilter = data.filter((value) => {
      if (value.name) {
        return (value.name).toLowerCase().includes(word.toLowerCase()) || (value.inq_id).toLowerCase().includes(word.toLowerCase()) || (value.email ? (value.email).toLowerCase().includes(word.toLowerCase()) : (value.name).toLowerCase().includes(word.toLowerCase())) || (value.wallet !== "N/A" ? (value.wallet).toLowerCase().includes(word.toLowerCase()) : (value.name).toLowerCase().includes(word.toLowerCase()));
      }
    });
    setFilteredData(newFilter);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        ...columns,
      ])
    }
  )

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#828282' : '#828282',
      backgroundColor: state.isSelected ? '#EBEDF2' : '#FFF',
      padding: 10,
      fontSize: '14px',
    }),
  }

  return (
    <>
      <div className="casemanagement flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <Form
                      class="filtersTable"
                    >
                      <div className="flex justify-between items-center justify-center flex-wrap">
                        <div className="searchInputTables">
                          <div className="fieldSearch relative w-full mb-3">
                            <label className="label block font-semibold text-black-600 text-xs mb-2">
                              Search
                            </label>
                            <Input
                              type="text"
                              className="searchFilter casemanagementsearch input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              onChange={(e) => handleFilter(e.target.value)}
                              placeholder="Search by name, inquiry ID or email address"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
                <div className="flex flex-row items-center">
                  <button className="mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <img src={leftPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                  </button>
                  <span className="mr-2 text-xs">
                    Page
                    <strong className="ml-2">
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    <img src={rightPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                  </button>
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Go to page</p>
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    value={pageNum}
                    onChange={(e) => {
                      const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
                      const page = value ? Number(value) - 1 : 0;
                      gotoPage(page);
                      setPageNum(value);
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Records per page</p>
                  <input
                    type="number"
                    min={1}
                    value={pageSize}
                    onChange={e => {
                      if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                        setPageSize(Number(1));
                      } else {
                        setPageSize(Number(e.target.value));
                      }
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                  <button
                    onClick={() => window.location.reload()}
                    style={{outline:"none"}}
                    className="px-2 py-2 bg-white rounded-lg"
                  >
                    <img src={refresh} alt="refresh" style={{width:"15px", height:"15px"}} />
                  </button>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table {...getTableProps()} className="items-center w-full bg-transparent border-collapse">
                  <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th 
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600 cursor-pointer"
                            }
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  {!allDataLoaded ? 
                    <tbody>
                      <tr className="text-center" colspan="100%">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <ReactLoading
                            className="margin-auto mt-3 mb-3"
                            type={"bars"}
                            color={"#ee416f"}
                            height={"20%"}
                            width={"20%"}
                          />
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    :
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()} className={"cursor-pointer " + (rightId === row.original.inq_id ? "activeRow" : "")}>
                            {row.cells.map(cell => {
                              return (
                                <td {...cell.getCellProps()} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                  {cell.render('Cell')}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default function CaseManagementOnboarding() {
  const { inq_id } = useParams();
  const history = useHistory();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [csvData, setCsvData] = React.useState([]);
  const [csvDataLoaded, setCsvDataLoaded] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize",handleResize);
    };
  }, []);


  const renderTooltipID = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="popoverHover px-4 py-2 rounded-lg font-bold text-sm"
        style={{ background: "#ee416f", color: "#FFF" }}
      >
        Identity Verification
      </div>
    </Tooltip>
  );

  const renderTooltipGovt = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="popoverHover px-4 py-2 rounded-lg font-bold text-sm"
        style={{ background: "#ee416f", color: "#FFF" }}
      >
        Government ID Verification
      </div>
    </Tooltip>
  );

  const renderTooltipAddress = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="popoverHover px-4 py-2 rounded-lg font-bold text-sm"
        style={{ background: "#ee416f", color: "#FFF" }}
      >
        Address Verification
      </div>
    </Tooltip>
  );

  const renderTooltipAml = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="popoverHover px-4 py-2 rounded-lg font-bold text-sm"
        style={{ background: "#ee416f", color: "#FFF" }}
      >
        AML Screening
      </div>
    </Tooltip>
  );

  const color = "light";
  const [individualActive, setIndividualActive] = React.useState(false);
  const [corporateActive, setCorporateActive] = React.useState(false);
  const [onboardingData, setOnboardingData] = React.useState([]);
  const [checkData, setChecksData] = React.useState([]);
  const [onboardingEventData, setOnboardingEventData] = React.useState([]);
  const [onboardingEventDataLength, setOnboardingEventDataLength] = React.useState(0);
  const [onboardingRightData, setOnboardingRightData] = React.useState(null);
  const [onboardingRightImage, setOnboardingRightImage] = React.useState(null);
  const [openRight, setOpenRight] = React.useState(null);
  const [rightId, setRightId] = React.useState(null);
  const [filteredData, setFilteredData] = React.useState([]);
  const [allDataLoaded, setAllDataLoaded] = React.useState(false);

  const [identityVerifCheck, setIdentityVerifCheck] = React.useState([]);
  const [govtIdentityVerifCheck, setGovtIdentityVerifCheck] = React.useState([]);
  const [addressVerifCheck, setAddressVerifCheck] = React.useState([]);
  const [sanctionVerifCheck, setSanctionVerifCheck] = React.useState([]);

  const [tableData, setTableData] = React.useState([]);
  const [tableColumns, setTableColumns] = React.useState([
    {
      Header: 'Name',
      accessor: 'name',
      Cell: tableProps => (
        <>
        <div className="text-left flex flex-col items-left" onClick={() => openRightSide(tableProps.row.original.inq_id)}>
          <span
            className={
              "text-sm font-bold text-black-600"
            }
          >
            {tableProps.row.original.name === " " ? "N/A" : tableProps.row.original.name}
          </span>
          <span className="font-light text-xs textLightGray">
            Inquiry ID: {tableProps.row.original.inq_id}
          </span>
          <span className="font-light text-xs textLightGray">
            Creation Date: {Moment(tableProps.row.original.created_at).format("MM-DD-YYYY")}
          </span>
        </div>
        </>
      )
    },
    {
      Header: 'KYC Status',
      accessor: 'checks',
      Cell: tableProps => (
        <div className="statusGrid flex flex-row justify-center ">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipID}
          >
            <img
              className="account mr-3"
              src={tableProps.row.original.checks.liv ? statusAccountGreen : statusAccountRed}
              alt="status-account"
            />
          </OverlayTrigger>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipGovt}
          >
            <img
              className="id mr-3"
              src={tableProps.row.original.checks.idv ? statusIDGreen : statusIDRed}
              alt="status-id"
            />
          </OverlayTrigger>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipAddress}
          >
            <img
              className="loc mr-3"
              src={tableProps.row.original.checks.adv ? statusLocGreen : statusLocRed}
              alt="status-loc"
            />
          </OverlayTrigger>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipAml}
          >
            <img
              className="map"
              src={tableProps.row.original.checks.san ? statusMapGreen : statusMapRed}
              alt="status-map"
            />
          </OverlayTrigger>
        </div>
      )
    },
    // {
    //   Header: 'Risk Level',
    //   accessor: 'risk_level',
    //   Cell: tableProps => (
    //     <div className="circularProgress mx-auto">
    //       <CircularProgressbar
    //         value={tableProps.row.original.risk_level}
    //         strokeWidth={25}
    //         styles={buildStyles({
    //           strokeLinecap: "butt",
    //           pathColor: `${(tableProps.row.original.risk_level > 84) ? circularBarColorAccepted : (tableProps.row.original.risk_level < 84 && tableProps.row.original.risk_level > 33) ? circularBarColorPending : circularBarColorDeclined}`,
    //           trailColor: "#FFF",
    //         })}
    //       />
    //     </div>
    //   )
    // }, 
    {
      Header: 'Assigned To',
      accessor: 'assigned_to',
      Cell: tableProps => (
        <div className="flex items-center">
          <div className="relative w-full">
            <p className="text-sm font-normal">
              {tableProps.row.original.assigned_to}
            </p>
          </div>
        </div>
      )
    },
    {
      Header: 'Reviewed By',
      accessor: 'reviewed_by',
      Cell: tableProps => (
        <div className="flex items-center">
          <div className="relative w-full">
            <p className="text-sm font-normal">
              {tableProps.row.original.reviewed_by}
            </p>
          </div>
        </div>
      )
    },
    {
      Header: 'Inquiry Status',
      accessor: 'status',
      Cell: tableProps => (
        <div className="buttonAction" onClick={() => openRightSide(tableProps.row.original.inq_id)}>
          {tableProps.row.original.status === "review" && (
            <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonYellow">
              Incomplete
            </button>
          )}
          {tableProps.row.original.status === "approve" && (
            <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonGreen">
              Approved
            </button>
          )}
          {tableProps.row.original.status === "decline" && (
            <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonRed">
              Declined
            </button>
          )}
          {tableProps.row.original.status === "complete" && (
            <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonBlue">
              Manual Review
            </button>
          )}
          {tableProps.row.original.status === "revision" && (
            <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonPurpe">
              Updated
            </button>
          )}
          {tableProps.row.original.status === "duplicate" && (
            <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonBlack">
              Duplicate
            </button>
          )}
        </div>
      )
    }
  ]);

  const handleFilter = (word) => {
    if(word==""){
      setFilteredData(onboardingData)
      return
    }
    const newFilter = onboardingData.filter((value) => {
      if(value.basicinfo.first_name || value.basicinfo.last_name){
      return (value.basicinfo.first_name +(" ") + value.basicinfo.last_name).toLowerCase().includes(word.toLowerCase());}
    });
    setFilteredData(newFilter);
  };

  const circularBarColorAccepted = "#15E49A";
  const circularBarColorPending = "#FFC102";
  const circularBarColorDeclined = "#FA364C";

  function setIndivActive() {
    setIndividualActive(true);
    setCorporateActive(false);
  }
  function setCorpActive() {
    setIndividualActive(false);
    setCorporateActive(true);
  }

  const risklevel = [{ label: "Risk Level", value: 1 }];
  const countrywise = [{ label: "Country Wise", value: 1 }];
  const status = [
    { label: "Status", value: 1 },
    { label: "Active", value: 2 },
    { label: "Inactive", value: 3 },
  ];
  const timeperiod = [{ label: "Time Period", value: 1 }];
  const tags = [{ label: "Tags", value: 1 }];

  const defaultValueRiskLevel = risklevel[0];
  const defaultValueCountryWise = countrywise[0];
  const defaultValueStatus = status[0];
  const defaultValueTime = timeperiod[0];
  const defaultValueTags = tags[0];

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      padding: 10,
      fontSize: "12px"
    }),
  };

  React.useEffect(() => {
    if (!inq_id) {
      history.push("/case/corporate/casemanagement");
      window.location.reload();
    } else {
      showkycs(inq_id)
        .then((response) => {
          if (response.data.success) {
            // console.log("response: ", response);
            setTableData(response.data.onboardings);
            setFilteredData(response.data.onboardings);
  
            setAllDataLoaded(true);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }, []);

  React.useEffect(() => {
    if (openRight === false) {
      viewsingleright(rightId)
        .then((response) => {
          // console.log("response: ", response);
          if (response.data.success) {
            setOnboardingRightData(response.data.onboardingsingle);
            setOnboardingRightImage(response.data.image);
            getevents(rightId)
              .then((response) => {
                // console.log("response: ", response);
                if (response.data.success) {
                  setOnboardingEventData(response.data.event);
                  if (response.data.event.length > 0) {
                    setOnboardingEventDataLength(response.data.event.length);
                  }
                  setOpenRight(!openRight);
                }
              })
              .catch((error) => {
                console.log("error: ", error);
              });
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }, [openRight, rightId]);

  const openRightSide = (id) => {
    // setOpenRight(false);
    if (rightId !== null) {
      if (id === rightId) {
        setOpenRight(null);
        setRightId(null);
        return;
      } else {
        if(width>=1280){window.location.href = "#"}
        else{window.location.href = "#right"}
        setOpenRight(false);
        setRightId(id);
      }
    }
    if (rightId === null) {
      if(width>=1280){window.location.href = "#"}
      else{window.location.href = "#right"}
      setRightId(id);
      setOpenRight(false);
      return;
    }
  };

  return (
    <>
      <div className="casemanagement casemanagementdetail flex flex-wrap ">
        <div
          className={
            "w-full px-1 " +
            (openRight
              ? "xl:w-9/12 lg:w-12/12 md:w-12/12 sm:w-12/12"
              : "xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12")
          }
          style={{ transition: "all 0.25s ease-in-out" }}
        >
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex">
                <div
                  className="rounded-lg py-2 mr-4"
                  style={
                    individualActive
                      ? { backgroundColor: "#EBEDF2" }
                      : { backgroundColor: "#FFF" }
                  }
                  // onClick={setIndivActive}
                >
                  <h4 className="borderDefault summaryHeading pb-1 font-bold text-base">Corporate</h4>
                </div>
                {/* <div 
                  className="rounded-lg px-4 py-2 cursor-pointer" 
                  style={corporateActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                  onClick={setCorpActive}
                >
                  <h4 className="font-semibold text-sm">Corporate</h4>
                </div> */}
              </div>
              {/* {csvDataLoaded ? 
                <div className="flex px-4 cursor-pointer">
                  <CSVLink
                    data={csvData}
                    className="riskScoresCsvBtn"
                    filename={"onboardings-individual.csv"}
                  >
                    <div className="flex px-4 py-2 cursor-pointer">
                      <img
                        src={ExportIcon}
                        alt="export icon"
                        className="mr-3"
                        style={{ width: "20px", height: "20px" }}
                      />
                      <h4
                        className="font-semibold text-xs"
                        style={{ color: "#ee416f" }}
                      >
                        Export CSV
                      </h4>
                    </div>
                  </CSVLink>
                </div>
                :
                <h4
                  className="font-semibold text-xs"
                  style={{ color: "#ee416f" }}
                >
                  Loading CSV
                </h4>
              } */}
            </div>
            <Table data={tableData} setTableData={setTableData} filteredData={filteredData} setFilteredData={setFilteredData} columns={tableColumns} rightId={rightId} allDataLoaded={allDataLoaded} />
          </div>
        </div>
        {openRight && (
          <div className="w-full xl:w-3/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4" id="right">
            {onboardingRightData && (
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-16">
                <div className="px-6">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full px-4 flex justify-center">
                      <div className="relative">
                        <img
                          alt="..."
                          src={
                            onboardingRightImage
                              ? onboardingRightImage
                              : require("assets/img/ProfilePicture.png").default
                          }
                          style={{height:"100px", width:"100px", objectFit:"cover"}}
                          className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-12 lg:-ml-12 max-w-100-px"
                        />
                      </div>
                    </div>
                    <div className="w-full px-4 text-center mt-12">
                      <div className="flex justify-center py-4 lg:pt-4 pt-8">
                        {onboardingRightData.basicinfo ? 
                          <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2 capitalize">
                            {onboardingRightData.basicinfo.first_name +
                              " " +
                              onboardingRightData.basicinfo.last_name}
                          </h3>
                          :
                          <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2 capitalize">
                            N/A
                          </h3>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-base leading-normal mt-0 mb-2 text-white-400 font-bold uppercase">
                      {onboardingRightData.status === "review" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonYellow text-xs">
                          Incomplete
                        </div>
                      )}
                      {onboardingRightData.status === "approve" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen text-xs">
                          Approved
                        </div>
                      )}
                      {onboardingRightData.status === "decline" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonRed text-xs">
                          Declined
                        </div>
                      )}
                      {onboardingRightData.status === "complete" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonBlue text-xs">
                          Manual Review
                        </div>
                      )}
                      {onboardingRightData.status === "revision" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonPurpe text-xs">
                          Updated
                        </div>
                      )}
                      {onboardingRightData.status === "duplicate" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonBlack text-xs">
                          Duplicate
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-base leading-normal mt-0 mb-2 text-white-400 font-bold uppercase">
                      <Link
                        to={`/case/corporate/kyc/casemanagementdetail/${onboardingRightData.inq_id}`}
                      >
                        <button
                          className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen text-xs"
                          style={{ background: "#ee416f" }}
                        >
                          Open Case
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="py-4 text-left">
                    <div className="flex flex-wrap">
                      <div className="flex items-center justify-center font-semibold w-full">
                        <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                          Info
                        </p>
                        <div
                          className="lg:w-10/12"
                          style={{ height: "0", border: "1px solid #D3D3D3" }}
                        ></div>
                      </div>
                      <div className="mt-2 fields block">
                        <div
                          className="enquiryId mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Inquiry ID</p>
                          <b className="text-xs">
                            {onboardingRightData.inq_id}
                          </b>
                        </div>
                        <div
                          className="IdNo mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            wordBreak:"break-all"                         }}
                        >
                          <p className="text-xs">ID Number</p>
                          <b className="text-xs " >
                            {onboardingRightData.idverification.id_nnum ? onboardingRightData.idverification.id_nnum : "N/A"}
                          </b>
                        </div>
                        <div
                          className="dob mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Date of Birth</p>
                          <b className="text-xs">
                            {onboardingRightData.basicinfo.dob
                              ? onboardingRightData.basicinfo.dob.includes("T") ?
                                Moment(onboardingRightData.basicinfo.dob.split("T")[0]).format(
                                  "MM-DD-YYYY"
                                )
                                :
                                (Moment(onboardingRightData.basicinfo.dob, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                                  ? Moment(onboardingRightData.basicinfo.dob).format(
                                    "MM-DD-YYYY"
                                  ) : Moment(onboardingRightData.basicinfo.dob, "DD/MM/YYYY").format(
                                    "MM-DD-YYYY"
                                  )
                              : 
                              "N/A"
                            }
                          </b>
                        </div>
                        <div
                          className="nationality mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Nationality</p>
                          <b className="text-xs">
                            {onboardingRightData.basicinfo.nationality}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>

                  {onboardingEventData && (
                    <div className="py-4 text-left">
                      <div className="flex flex-wrap">
                        <div className="flex items-center justify-center font-semibold w-full">
                          <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                            Event
                          </p>
                          <div
                            className="lg:w-10/12"
                            style={{ height: "0", border: "1px solid #D3D3D3" }}
                          ></div>
                        </div>
                        <div className="w-full mt-2 fields block eventTimeline">
                          {onboardingEventData.map((element, ind) => (
                            <div data-index={ind} className="status mt-2">
                              <div
                                className="w-full mt-2"
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "20% 80%",
                                }}
                              >
                                {element.passed === 0 && (
                                  <div className="imgTag">
                                    <img src={ElipsesOne} alt="created" />
                                  </div>
                                )}
                                {element.passed === 1 && (
                                  <div className="imgTag">
                                    <img src={ElipsesTwo} alt="created" />
                                  </div>
                                )}
                                {element.passed === 2 && (
                                  <div className="imgTag">
                                    <img src={ElipsesYellow} alt="created" />
                                  </div>
                                )}
                                {element.passed === 3 && (
                                  <div className="imgTag">
                                    <img src={ElipsesError} alt="created" />
                                  </div>
                                )}
                                <div className="text text-xs font-semibold">
                                  {element.event_desc}
                                  <p className="createdDate font-normal mt-2">
                                    {Moment(element.created_at).format(
                                      "MM-DD-YYYY"
                                    )}{" "}
                                    |{" "}
                                    {Moment(element.created_at).format(
                                      "hh:mm a"
                                    )}
                                  </p>
                                </div>
                              </div>
                              {onboardingEventDataLength - 1 !== ind && (
                                <div
                                  className="w-full items-center justify-center"
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "20% 80%",
                                  }}
                                >
                                  <div className="imgTag px-2">
                                    <img
                                      className="ml-1"
                                      src={VerticalLine}
                                      alt="created"
                                    />
                                  </div>
                                  <div className="text text-xs font-semibold"></div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
